import { Box , Typography } from "@mui/material";
import { Translate, translate } from "react-i18nify";
import PatientDashboardCategory from "../../components/Patient/PatientDashboardCategory";
import { PatientDetailText , ReportDetailText} from "../../components/Patient/PatientDetailText";

export const GenerateTreatmentInfo = ({treatment}) => {
  if (treatment?.id == null) {
    return null;
  }
  let SpO2Minimum,
    SpO2Maximum,
    LowO2ShortTerm,
    LowO2LongTerm,
    HighO2ShortTerm,
    HihgO2LongTerm,
    DurLod1,
    OAD
    ;
  let name, treatmentId, FlowResponse;
  let oxygenPrescription = treatment?.minutesPrescribed / 60




  name = treatment.name;
  treatmentId = treatment.id;
  treatment.values.forEach((item) => {
    switch (item.code) {
      case "LSpO2": {
        SpO2Minimum = item.value;
        break;
      }
      case "DurLod1": {
        DurLod1 = item.value;
        break;
      }
      case "HO2LT": {
        HihgO2LongTerm = item.value;
        break;
      }
      case "HO2ST": {
        HighO2ShortTerm = item.value;
        break;
      }
      case "HSpO2": {
        SpO2Maximum = item.value;
        break;
      }
      case "OAD": {
        OAD = item.value;
        break;
      }
      case "Ki": {
        FlowResponse = "Custom (" + item.value + ")";

        if (item.value == 150) FlowResponse = "Very fast";

        if (item.value == 100) FlowResponse = "Fast";

        if (item.value == 25) FlowResponse = "Normal";

        break;
      }
      case "LO2LT": {
        LowO2LongTerm = item.value;
        break;
      }
      case "LO2ST": {
        LowO2ShortTerm = item.value;
        break;
      }
    }
  });

  let shortTerm = ""
  switch (OAD) {
    case 15 * 60: shortTerm = "15"; translate("MinutesPerHour"); break;
    case 30 * 60: shortTerm = "30"; translate("MinutesPerHour"); break;
    case 45 * 60: shortTerm = "45"; translate("MinutesPerHour"); break;
    case 61 * 60: shortTerm = translate("Always"); break;
  }
  return (
    <Box sx={{width:"500px" }}>
      <PatientDashboardCategory
        title={<Typography variant='h6'><Translate value="Treatment" /> {treatmentId}</Typography>}
      >
        <ReportDetailText
          label={<Translate value="TreatmentName" />}
          value={name}
        ></ReportDetailText>
        <ReportDetailText
          label={<Translate value="TreatmentId" />}
          value={treatmentId}
        ></ReportDetailText>
        {/* //First 5 min of treatment with fixed flow* */}
        <ReportDetailText
          label={<Translate value="AnalysingPhase" />}
          value={DurLod1 == 300 ? translate("Yes") : translate("No")}
        ></ReportDetailText>
        <ReportDetailText
          label={<Translate value="FlowResponse" />}
          value={FlowResponse}
        ></ReportDetailText>
        <ReportDetailText
          //label={<Translate value="OxygenPrescription" />}
          label={<Translate value="O2ComplianceGoal" />}
          value={oxygenPrescription + " " + translate("hour/day")}
        ></ReportDetailText>

        <ReportDetailText
          label={<Translate value="SpO2Range" />}
          value={SpO2Minimum + " - " + SpO2Maximum + " %"}
        ></ReportDetailText>
        <Translate value="O2FlowRanges" />:
        <ReportDetailText
          //label={<Translate value="AllowedLtRange" />}
          label={<Translate value="WithoutPO"/>}
          value={LowO2LongTerm + " - " + HihgO2LongTerm + " L/min"}
        ></ReportDetailText>
        <ReportDetailText
          //label={<Translate value="AllowedStRange" />}
          label={<Translate value="WithPO"/>}
          value={LowO2ShortTerm + " - " + HighO2ShortTerm + " L/min"}
        ></ReportDetailText>
        <Translate value="AllowedTimeWithin" />
        <ReportDetailText
          //label={<Translate value="ShortTermLength" />}
          label={LowO2ShortTerm + " - " + LowO2LongTerm + " " + translate("and") + " " + HihgO2LongTerm + " - " + HighO2ShortTerm + " " + translate("l/min")}
          value={shortTerm}
        ></ReportDetailText>
        <Translate value="withPulseOximeterOn" />

      </PatientDashboardCategory>
    </Box>
  );
};