import axios from 'axios';
import AuthHeader from './AuthHeader';

export class PaginationDownloader {
    constructor() {
        this.ids = {};
    }

    generateGUID() {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            const r = Math.random() * 16 | 0;
            const v = c === 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    }

    hasGUID(guid) {
        return Object.keys(this.ids).includes(guid);
    }

    removeGUID(guid) {
        delete this.ids[guid];
    }

    removeAllGUIDs() {
        this.ids = {};
    }

    addGUID(guid) {
        if (!this.hasGUID(guid)) {
            this.ids[guid] = { controller: new AbortController() };
        }
    }

    getAbortController(guid) {
        return this.ids[guid]?.controller;
    }

    cancelGuid(guid) {
        const controller = this.getAbortController(guid);
        if (controller) {
            controller.abort();
        }
        this.removeGUID(guid);
    }

    cancelAll() {
        Object.keys(this.ids).forEach((guid) => {
            this.cancelGuid(guid);
        });
    }
}


class SessionServiceClass {
    /* search(patientId = "", location = "", before = "", after = "", skip = 0) {
        return axios.get('Session?' +
            'patientId=' + patientId +
            '&location=' + location +
            '&to=' + before +
            '&from=' + after +
            '&skip=' + skip,
            //'&devicePuid=' + devicePuid,
            { headers: AuthHeader() });
    } */

    getDetailedSessionsByPatientId(patientId, from, to) {
        return axios.get('Session/detailed/patient/' + patientId + "?from=" + from + "&to=" + to, { headers: AuthHeader() });

    }

    getActiveDaysByPatientId(patientId, from, to) {
        return axios.get('Session/activeDays/patient/' + patientId + "?from=" + from + "&to=" + to, { headers: AuthHeader() });

    }

    getDayData(patientId,date,from,to) {
        var data =  axios.get(`Session/dayData/patient?patientId=${patientId}&date=${date}&from=${from}&to=${to}`, { headers: AuthHeader() });
        return data
    }
    //getDayData(patientId, date, timezone) {
      //  return axios.get(`Session/dayData/patient?patientId=${patientId}&date=${date}&timezone=${timezone}`, { headers: AuthHeader() });

//    }

    getByPatientId(id) {
        return axios.get('Session/patient/' + id, { headers: AuthHeader() });
    }
    getByPatientId(id, from, to, take, skip, controller) {
        let url = 'Session/patient/' + id;
        let symbol = "?"
        if (take != null) {
            url += symbol + 'take=' + take;
            symbol = "&"
        }
        if (from != null) {
            url += symbol + 'from=' + from;
            symbol = "&"
        }
        if (to != null) {
            url += symbol + 'to=' + to;
            symbol = "&"
        }
        if (skip != null) {
            url += symbol + "skip=" + skip;
            symbol = "&"
        }
        return axios.get(url, { headers: AuthHeader(), signal: controller.signal });
    }

    getSessionOverviewByPatientId(id, from, to) {
        let url = 'Session/patient/overview/' + id ;
        let symbol = "?"
        if (from != null) {
            url += symbol + 'from=' + from;
            symbol = "&"
        }
        if (to != null) {
            url += symbol + 'to=' + to;
            symbol = "&"
        }
        return axios.get(url, { headers: AuthHeader()});
    }

    getLatestByPatientId(id) {
        let url = 'Session/patient/' + id + "/latest";
        return axios.get(url, { headers: AuthHeader() });
    }

    getCountByPatientId(id, from, to, controller) {
        let url = `Session/patient/${id}/count`;
        let symbol = "?"
        if (from != null) {
            url += symbol + 'from=' + from;
            symbol = "&"
        }
        if (to != null) {
            url += symbol + 'to=' + to;
            symbol = "&"
        }
        return axios.get(url, { headers: AuthHeader(), signal: controller.signal });
    }

    getSessionIdsByPatientId(id, from, to) {
        let url = `Session/patient/${id}/list`;
        let symbol = "?"
        if (from != null) {
            url += symbol + 'from=' + from;
            symbol = "&"
        }
        if (to != null) {
            url += symbol + 'to=' + to;
            symbol = "&"
        }
        return axios.get(url, { headers: AuthHeader()});
    }
}
class DeviceServiceClass {
    create(UDSN, PUID) {
        let body = { 'udsn': UDSN.toString(), 'puid': PUID.toString() };
        return axios.post('Device',
            body,
            {
                headers: AuthHeader()
            }
        );
    }
    createHot(hotData) {
        let body = hotData;
        return axios.post('Component/HOT',
            body,
            {
                headers: AuthHeader()
            }
        )
    }
    createPro(proData) {
        let body = proData;
        return axios.post('Component/PRO',
            body,
            {
                headers: AuthHeader()
            }
        )
    }


    testPro(testData) {
        let body = testData;
        return axios.post('Component/PRO/tester',
            body,
            {
                headers: AuthHeader()
            }
        )
    }
    testHot(testData) {
        let body = testData;
        return axios.post('Component/HOT/tester',
            body,
            {
                headers: AuthHeader()
            }
        )
    }
    packagePro(testData) {
        let body = testData;
        return axios.post('Component/PRO/packager',
            body,
            {
                headers: AuthHeader()
            }
        )
    }
    packageHot(testData) {
        let body = testData;
        return axios.post('Component/HOT/packager',
            body,
            {
                headers: AuthHeader()
            }
        )
    }
    get() {
        return axios.get('Device', { headers: AuthHeader() });
    }
    getBatchByTemplate(status, templateId, roles = null) {
        let query = `Component/batch?status=${status}&templateId=${templateId}`
        if (roles != null) {
            query += "&roles=" + roles;
        }
        return axios.get(query, { headers: AuthHeader() });
    }

    getByTemplate(templateId, skip = 0, limit = 200, status = null) {
        let query = `Component?templateId= + ${templateId} + &skip= + ${skip} + &limit= + ${limit}`;
        if (status != null) {
            query += "&status=" + status;
        }
        return axios.get(query, { headers: AuthHeader() });
    }
    getByTemplate(templateId, skip = 0, limit = 200, status = null, roles = null) {
        let query = `Component?templateId= + ${templateId} + &skip= + ${skip} + &limit= + ${limit}  `;
        if (status != null) {
            query += "&status=" + status;
        }
        if (roles != null) {
            query += "&roles=" + roles;
        }
        return axios.get(query, { headers: AuthHeader() });
    }
    getHotByUdsn(udsn) {
        return axios.get('Component/HOT/udsn/' + udsn, { headers: AuthHeader() });
    }

    getProByUdsn(udsn) {
        return axios.get('Component/Pro/udsn/' + udsn, { headers: AuthHeader() });
    }
    getDeviceByUdsn(udsn) {
        return axios.get('Component/udsn/' + udsn, { headers: AuthHeader() });
    }
    getByPatientId(patientId) {
        return axios.get('/Component/patient/' + patientId, { headers: AuthHeader() });
    }
    updateDeviceMetadata(componentID, metaDataTypeId, metaDataValueId) {
        let body = {
            "componentId": componentID,
            "metaDataTypeId": metaDataTypeId,
            "metaDataValueId": metaDataValueId
        };
        return axios.put('component/' + componentID + '/metadata',
            body,
            {
                headers: AuthHeader()
            }
        );
    }
    updateProDevice(componentID, proDevice) {
        let body = proDevice;
        return axios.put('component/' + componentID + '/PRO',
            body,
            {
                headers: AuthHeader()
            }
        );
    }
    updateHotDevice(componentID, hotDevice) {
        let body = hotDevice;
        return axios.put('component/' + componentID + '/HOT',
            body,
            {
                headers: AuthHeader()
            }
        );
    }
    qaApproveDevice(data) {
        let body = { deviceIds: data }
        return axios.post('component/QAApprove',
            body,
            {
                headers: AuthHeader()
            }
        );
    }
    qaApproveDevice(data) {
        let body = { deviceIds: data }
        return axios.post('component/QAApprove',
            body,
            {
                headers: AuthHeader()
            }
        );
    }
    o2maticQaApproveDevice(data) {
        let body = { deviceIds: data }
        return axios.post('component/O2maticQAApprove',
            body,
            {
                headers: AuthHeader()
            }
        );
    }
    o2maticQaApproveDeviceFromO2maticTested(data) {
        let body = { deviceIds: data }
        return axios.post('component/O2maticQAApproveFromO2maticTested',
            body,
            {
                headers: AuthHeader()
            }
        );
    }
    o2maticShipDevices(data) {
        let body = { shipperDtoData: data }
        return axios.post('component/Shipper',
            body,
            {
                headers: AuthHeader()
            }
        );
    }
    o2maticQaApproveDevice(data) {
        let body = { deviceIds: data }
        return axios.post('component/O2maticQAApprove',
            body,
            {
                headers: AuthHeader()
            }
        );
    }
    updateDeviceOwner(componentID, newOwnerId) {
        let body = {};

        return axios.post('component/' + componentID + '/owner/' + newOwnerId,
            body,
            {
                headers: AuthHeader()
            }
        );
    }
    updateDeviceLocation(componentID, newLocationId) {
        let body = {};

        return axios.post('component/' + componentID + '/location/' + newLocationId,
            body,
            {
                headers: AuthHeader()
            }
        );
    }
    addDeviceLog(componentID, log) {

        let body = {
            "componentId": componentID,
            "value": log
        };

        return axios.post('component/' + componentID + '/log',
            body,
            {
                headers: AuthHeader()
            }
        );
    }
    edit(UDSN, PUID) {
        let body = { 'udsn': UDSN.toString(), 'puid': PUID.toString() };
        return axios.put('Device',
            body,
            {
                headers: AuthHeader()
            }
        );
    }

    repairDevice(id, type, data, status) {
        let body = { deviceId: id, components: data, type: type, status: status };
        return axios.put('component/Repair',
            body,
            {
                headers: AuthHeader()
            }
        );
    }

    underInvestigation(id,status){
        let body = {deviceId:id,status:status};
        return axios.put('component/UnderInvestigation',
            body,
            {
                headers: AuthHeader()
            }
        );
    }
    delete(id) {

        return axios.delete('Device/' + id,
            {
                headers: AuthHeader()
            }
        );
    }
}

class LicenseServiceClass {
    getAll() {
        return axios.get('License', { headers: AuthHeader() });
    }

    get(id) {
        return axios.get('License/id/' + id, { headers: AuthHeader() });
    }
    create(Name, Roles, LocationId) {

        let body = { 'name': Name, 'roles': Roles, 'locationId': LocationId };
        return axios.post('License',
            body,
            {
                headers: AuthHeader()
            }
        );
    }
    delete(id) {

        return axios.delete('license/id/' + id,
            {
                headers: AuthHeader()
            }
        );
    }
}
class ConfigurationServiceClass {
    get() {
        return axios.get('Configuration', { headers: AuthHeader() });
    }
    getIpAddresses() {
        return axios.get('Configurations/ip', { headers: AuthHeader() });
    }

    create(Name, Value) {
        let body = { 'name': Name, 'value': Value };
        return axios.post('Configurations',
            body,
            {
                headers: AuthHeader()
            }
        );
    }
    delete(id) {

        return axios.delete('Configurations/' + id,
            {
                headers: AuthHeader()
            }
        );
    }

}
class PatientServiceClass {
    getAll() {
        return axios.get('Patient', { headers: AuthHeader() });
    }
    getAllByQuery(query) {
        return axios.get('Patient?' + query, { headers: AuthHeader() });
    }
    get(id) {
        return axios.get('Patient/' + id, { headers: AuthHeader() });
    }
    getTreatmentInstallationConfirmation(patientId ,componentId) {
        return axios.get('Patient/' + patientId + "/" + componentId, { headers: AuthHeader() });
    }
    getTreatmentPrescribedTime(id) {
        return axios.get('Patient/' + id + "/prescribed", { headers: AuthHeader() });
    }
    getTreatmentPrescribedTimeByTreatmentId(patientId, treatmentId) {
        return axios.get('Patient/' + patientId + "/prescribed/" + treatmentId, { headers: AuthHeader() });
    }
    getPatientComponent(patientId, componentId) {
        return axios.get('Patient/patientcomponent/' + patientId + "/" + componentId, { headers: AuthHeader() });
    }
    linkTreatment(patientId, TreatmentId) {
        return axios.post('Patient/' + patientId + '/treatment/' + TreatmentId, {}, { headers: AuthHeader() });
    }
    delete(id) {
        return axios.delete('Patient/' + id, { headers: AuthHeader() });
    }
    create(body) {
        return axios.post('Patient',
            body,
            {
                headers: AuthHeader()
            }
        );
    }
    update(body) {
        return axios.put('Patient',
            body,
            {
                headers: AuthHeader()
            }
        );
    }
    update(body) {
        return axios.put('Patient',
            body,
            {
                headers: AuthHeader()
            }
        );
    }
    getAllPatientLogs(id) {
        return axios.get('Patient/log/' + id, { headers: AuthHeader() });
    }

    insertPatientLog(id, message) {
        return axios.post('Patient/log',
            { "patientId": id, "message": message },
            {
                headers: AuthHeader()
            }
        );
    }
}
class GenericServiceClass {
    getRoles() {
        return axios.get('Role', { headers: AuthHeader() });
    }
}
class TreatmentServiceClass {
    getAll() {
        return axios.get('Treatment', { headers: AuthHeader() });
    }
    getCT(isCT) {
        return axios.get('Treatment?includeCtTreatments=' + isCT, { headers: AuthHeader() });
    }
    get(id) {
        return axios.get('Treatment/' + id, { headers: AuthHeader() });
    }
    getByLocationId(locationId, isCT){
        return axios.get('Treatment/location?id=' + locationId + "&includeCtTreatments=" + isCT, { headers: AuthHeader() });
    }
    create(body) {
        return axios.post('Treatment',
            body,
            {
                headers: AuthHeader()
            }
        );
    }
    disable(id) {
        return axios.post('Treatment/disable/' + id,
            {},
            {
                headers: AuthHeader()
            }
        )
    }

    enable(id) {
        return axios.post('Treatment/enable/' + id,
            {},
            {
                headers: AuthHeader()
            }
        )
    }
}
class LocationServiceClass {
    getAll() {
        return axios.get('Location', { headers: AuthHeader() });
    }
    getByRoles(roles) {
        let url = roles.map((item, index) => {
            if (index == 0) {
                return "?roles=" + item
            }
            return "&roles=" + item
        })
        return axios.get('Location' + url.join(""), { headers: AuthHeader() });
    }
    getByTag(roles, tags) {
        let url = tags.map((item, index) => {
            if (index == 0) {
                return "?tags=" + item
            }
            return "&tags=" + item
        })
        return axios.get('Location' + url.join(""), { headers: AuthHeader() });
    }
    get(id) {
        return axios.get('Location/' + id, { headers: AuthHeader() });
    }
    create(body) {
        return axios.post('Location',
            body,
            {
                headers: AuthHeader()
            }
        );
    }
    update(body) {
        return axios.put('Location',
            body,
            {
                headers: AuthHeader()
            }
        );
    }
}

class PermissionServiceClass {
    get() {
        return axios.get('Permission', { headers: AuthHeader() });
    }
}

class RoleServiceClass {
    getAll() {
        return axios.get('Role', { headers: AuthHeader() });
    }
    getByRoleTypeId(roleTypeId) {
        return axios.get('Role?roleTypeId=' + roleTypeId, { headers: AuthHeader() });
    }
    getByRoleCode(code) {
        return axios.get('Role/code?roleTypeCode=' + code, { headers: AuthHeader() });
    }
    getByRoleNames(codes) {
        return axios.get('Role/codeIds?roleTypeIdsString=' + codes, { headers: AuthHeader() });
    }
    addRole(userId, locationId, roleIds, roleChecked) {
        let roleList = [];
        if (roleIds.length > 1) {
            roleIds.forEach(roleId => {
                let body = { userId: userId, locationId: locationId, roleId: roleId, IsChecked: roleChecked}
                roleList.push(body)
            })
        } else {
            let body = { userId: userId, locationId: locationId, roleId: roleIds[0], IsChecked: roleChecked}
            roleList.push(body)
        }
        return axios.post('User/roles',
            roleList,
            {
                headers: AuthHeader()
            }
        );
    }
    getRolesBasedOnUserLocation(){
        return axios.get('Role/byRequestingUser', { headers: AuthHeader() });
    }
    removeRole(userId, locationId, roleId) {
        let body = { userId: userId, locationId: locationId, roleId: roleId }

        return axios.delete('User/roles',
            {
                headers: AuthHeader(),
                data: [body]
            }
        );
    }
    updateRoles(rolesToUpdate) {
        return axios.put('User/updateRoles',
            rolesToUpdate,
            {
                headers: AuthHeader()
            }
        );
    }
}

class CountryServiceClass {
    getAll() {
        return axios.get('Country', { headers: AuthHeader() });
    }
}

class EventLogServiceClass {
    getAll() {
        return axios.get('EventLog', { headers: AuthHeader() });
    }
    getEventTypes() {
        return axios.get('EventLog/eventtypes', { headers: AuthHeader() })
    }
    getByUdsn(udsn, take = 50, skip = 0) {
        return axios.get('EventLog/device/udsn/' + udsn + '?take=' + take + "&skip=" + skip, { headers: AuthHeader() });
    }
    getLatestByUdsn(udsn) {
        return axios.get('EventLog/device/udsn/' + udsn + '/latest', { headers: AuthHeader() });
    }
    getByUdsnType(udsn, from, to, type) {
        let url = 'EventLog/device/udsn/' + udsn + '?from=' + from + "&to=" + to;
        url += type.map(eventType => "&eventTypeId=" + eventType).join("")
        return axios.get(url, { headers: AuthHeader() });
    }
    getByPatientId(id, from, to, type) {
        let url = 'EventLog/patient/' + id + '?from=' + from + "&to=" + to;
        url += type.map(eventType => "&eventTypeId=" + eventType).join("")
        return axios.get(url, { headers: AuthHeader() });
    }

    getWithAllParameters(udsn, from, to, type, orderByAsc) {

        let url = 'EventLog/device/udsn/' + udsn;
        url += "?orderByAsc=" + (orderByAsc == null ? true : false);
        if (from != null) {
            url += '&from=' + from;
        }
        if (to != null) {
            url += "&to=" + to;
        }
        if (type != null) {
            url += type.map(eventType => "&eventTypeId=" + eventType).join("")
        }
        return axios.get(url, { headers: AuthHeader() });
    }
    getByUdsnTypeLimit(udsn, from, to, type, limit) {
        let url = 'EventLog/device/udsn/' + udsn + '?from=' + from + "&to=" + to + "&limit=" + limit;
        url += type.map(eventType => "&eventTypeId=" + eventType).join("")
        return axios.get(url, { headers: AuthHeader() });
    }
}

class LogServiceClass {
    get(take = 1000, skip = 0) {
        return axios.get('Log?take=' + take + "&skip=" + skip, { headers: AuthHeader() });
    }
    getDeviceLogs(udsn, take = 50, skip = 0) {
        return axios.get('Log/device/udsn/' + udsn + '?take=' + take + "&skip=" + skip, { headers: AuthHeader() });

    }
    get(from, to){
        let stringQuery = 'Log?from=' + from + "&to=" + to;
        return axios.get(stringQuery, {headers: AuthHeader()});
    }
    saveLog(identifiers, type, fileType, targetId){
        let body = {
            "identifiers": identifiers,
            "type": type,
            "fileType": fileType,
            "targetId": targetId
        }
        return axios.post('Log/sessiondownload', body, {headers: AuthHeader()});
    }

}

class UserServiceClass {
    getAll() {
        return axios.get('User', { headers: AuthHeader() });
    }
    get(id) {
        return axios.get('User/' + id, { headers: AuthHeader() });
    }
    getSearch(queryParameters) {
        let query = "User/search?" + queryParameters ;
        // queryElements.forEach(element => {
        //     query += element.key + "=" + element.value + "&";
        // })
        return axios.get(query, { headers: AuthHeader() });
    }
    create(firstName, lastName, email, location) {
        var body = {
            "firstName": firstName,
            "lastName": lastName,
            "email": email,
            "locationId": location,
            "roleLocations": []
        }
        return axios.post('User',
            body,
            {
                headers: AuthHeader()
            }
        );
    }
    create(firstName, lastName, email, location, roles) {
        var body = {
            "firstName": firstName,
            "lastName": lastName,
            "email": email,
            "locationId": location,
            "roles": roles
        }
        return axios.post('User',
            body,
            {
                headers: AuthHeader()
            }
        );
    }
    delete(userId) {
        return axios.delete('User/' + userId,
            {
                headers: AuthHeader()
            }
        );
    }
    getActiveUserRolesLocations(){
        return axios.get('User/activeUserRolesLocations', { headers: AuthHeader() });
    }
}

class MetadataServiceClass {

    getValues() {
        return axios.get('metadata/values', { headers: AuthHeader() });
    }
    getValuesByName(name) {
        return axios.get(`/Component/metadataValues?name=${name}`, { headers: AuthHeader() });
    }
    getTypes() {
        return axios.get('metadata/types', { headers: AuthHeader() });
    }
    getAddableMetadata(){
        return axios.get('metadata/addable', { headers: AuthHeader() });
    }
    createMetadataValue(data){
        const body = data
        return axios.post("metadata/createmetadatavalue", body, { headers: AuthHeader() });
    }
    getAddableMetadataValues(){
        return axios.get('metadata/getaddablemetadatavalues', {headers: AuthHeader()})
    }
    getSerialNumberByComponentId(deviceUdsn){
        return axios.get('metadata/serialForSession/' + deviceUdsn, {headers: AuthHeader()});
    }
}

class TemplateServiceClass {

    getAll() {
        return axios.get('Template', { headers: AuthHeader() });
    }


}

class OximeterServiceClass {
    addOximeter(oximeterMac){
        const body = { "macAddress": oximeterMac }
        return axios.post('/Component/oximeter',
        body,
        {
            headers: AuthHeader()
        });
    }
    getOximeterMacs(){
        return axios.get("/Metadata/oximetermacs", {headers: AuthHeader()})
    }
}

class NotificationServiceClass {
    updateNotification(notificationText, notificationActive) {
        const body = {
            "message": notificationText,
            "isActive": notificationActive
        }
        return axios.post('Notification',
            body,
            { headers: AuthHeader() });
    }
    getNotification() {
        return axios.get('Notification', { headers: AuthHeader() });
    }
}


export const LocationService = new LocationServiceClass();
export const TreatmentService = new TreatmentServiceClass();
export const PatientService = new PatientServiceClass();
export const LicenseService = new LicenseServiceClass();
export const DeviceService = new DeviceServiceClass();
export const ConfigurationService = new ConfigurationServiceClass();
export const PermissionService = new PermissionServiceClass();
export const RoleService = new RoleServiceClass();
export const SessionService = new SessionServiceClass();
export const CountryService = new CountryServiceClass();
export const EventLogService = new EventLogServiceClass();
export const LogService = new LogServiceClass();
export const UserService = new UserServiceClass();
export const MetadataService = new MetadataServiceClass();
export const TemplateService = new TemplateServiceClass();
export const OximeterService = new OximeterServiceClass();
export const NotificationService = new NotificationServiceClass();