import { useEffect, useState } from "react";
import {
  Height,
  KeyboardArrowDown,
  KeyboardArrowUp,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Checkbox,
  Collapse,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { Translate } from "react-i18nify";
export const BatchTableWithLocation = (props) => {
  const [selectAll, setSelectAll] = useState(false);
  const [locationAll, setLocationAll] = useState("");
  const [ownerAll, setOwnerAll] = useState("");
  const [state, newState] = useState({});

  const handleSelectAll = (event) => {
    let newValue = event.target.checked;
    setSelectAll(newValue);
    newState((prevState) => {
      const updatedState = { ...prevState };
      Object.keys(updatedState).forEach((batch) => {
        updatedState[batch].selected = newValue;
        updatedState[batch].devices.forEach((device) => {
          device.selected = newValue;
        });
        return updatedState;
      });
      return prevState;
    });
  };

  const handleAssignAllLocation = (event) => {
    let newValue = event.target.value;
    setLocationAll(newValue);
    newState((prevState) => {
      const updatedState = { ...prevState };
      Object.keys(updatedState).forEach((batch) => {
        updatedState[batch].locationId = newValue;
        updatedState[batch].devices.forEach((device) => {
          device.locationId = newValue;
        });
        return updatedState;
      });
      return prevState;
    });
  };
  const handleAssignAllOwner = (event) => {
    let newValue = event.target.value;
    setOwnerAll(newValue);
    newState((prevState) => {
      const updatedState = { ...prevState };
      Object.keys(updatedState).forEach((batch) => {
        updatedState[batch].ownerId = newValue;
        updatedState[batch].devices.forEach((device) => {
          device.ownerId = newValue;
        });
        return updatedState;
      });
      return prevState;
    });
  };

  const handleBatchCheckboxChange = (event, batch) => {
    const newValue = event.target.checked;
    newState((prevState) => {
      const updatedState = { ...prevState };
      if (updatedState[batch]) {
        updatedState[batch].selected = newValue;
        updatedState[batch].devices.forEach((device) => {
          device.selected = newValue;
          device.locationId = updatedState[batch].locationId;
          device.ownerId = updatedState[batch].ownerId;
        });
      }
      return updatedState;
    });
  };
  const handleExpendedChange = (event, batch) => {
    newState((prevState) => {
      const updatedState = { ...prevState };
      if (updatedState[batch]) {
        updatedState[batch].expanded = !updatedState[batch].expanded;
      }
      return updatedState;
    });
  };

  const handleDeviceCheckboxChange = (event, batchNumber, id) => {
    const newValue = event.target.checked;
    newState((prevState) => {
      const updatedState = { ...prevState };
      if (updatedState[batchNumber]) {
        const deviceIndex = updatedState[batchNumber].devices.findIndex(
          (device) => device.device.id === id
        );
        if (deviceIndex !== -1) {
          updatedState[batchNumber].devices[deviceIndex].selected = newValue;
        }
      }
      return updatedState;
    });
  };

  const handleBatchDeviceLocationChange = (event, batch) => {
    const newValue = event.target.value;
    newState((prevState) => {
      const updatedState = { ...prevState };
      if (updatedState[batch]) {
        updatedState[batch].locationId = newValue;
        updatedState[batch].devices.forEach((device) => {
          device.locationId = newValue;
        });
      }
      return updatedState;
    });
  };
  const handleBatchDeviceOwnerChange = (event, batch) => {
    const newValue = event.target.value;
    newState((prevState) => {
      const updatedState = { ...prevState };
      if (updatedState[batch]) {
        updatedState[batch].ownerId = newValue;
        updatedState[batch].devices.forEach((device) => {
          device.ownerId = newValue;
        });
      }
      return updatedState;
    });
  };

  const handleDeviceLocationChange = (event, batchNumber, id) => {
    const newValue = event.target.value;
    newState((prevState) => {
      const updatedState = { ...prevState };
      if (updatedState[batchNumber]) {
        const deviceIndex = updatedState[batchNumber].devices.findIndex(
          (device) => device.device.id === id
        );
        if (deviceIndex !== -1) {
          updatedState[batchNumber].devices[deviceIndex].locationId = newValue;
        }
      }
      return updatedState;
    });
  };

  const handleDeviceOwnerChange = (event, batchNumber, id) => {
    const newValue = event.target.value;
    newState((prevState) => {
      const updatedState = { ...prevState };
      if (updatedState[batchNumber]) {
        const deviceIndex = updatedState[batchNumber].devices.findIndex(
          (device) => device.device.id === id
        );
        if (deviceIndex !== -1) {
          updatedState[batchNumber].devices[deviceIndex].ownerId = newValue;
        }
      }
      return updatedState;
    });
  };

  const createStateFromDevices = (batches) => {
    if (batches == null || batches == undefined) {
      return {};
    }
    let state = {};
    Object.keys(batches).forEach((batch) => {
      console.log({ batches });
      console.log({ batch });

      let devices = [];
      batches[batch].forEach((device) => {
        devices.push({ selected: false, device: device,ownerId:device.ownerId,locationId:device.locationId });
      });
      state[batch] = { selected: false, expanded: false, devices: devices };
    });
    return state;
  };

  useEffect(() => {
    let createState = createStateFromDevices(props.data);
    newState(createState);
    console.log({ newState: newState });
  }, [props.data]);

  useEffect(() => {
    props?.onSelectedUpdate(state);
  }, [state]);

  const Row = (props) => {
    const { row } = props;
    console.log({ rowProps: props });

    const open = props.data.expanded;

    return (
      <>
        <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
          <TableCell>
            <Checkbox
              checked={props.data.selected}
              onChange={(e) => handleBatchCheckboxChange(e, props?.batch)}
            />
          </TableCell>
          <TableCell component="th" scope="row">
            {props?.batch}
          </TableCell>
          <TableCell component="th" scope="row">
            {props.data.selected ? (
              <Select
                sx={{ height: "40px" }}
                required
                id="location"
                displayEmpty
                value={props.data.locationId || ""}
                onChange={(e) =>
                  handleBatchDeviceLocationChange(e, props?.batch)
                }
              >
                <MenuItem value={""}>
                  <Translate value="SelectLocation" />
                </MenuItem>
                {props?.locations?.map((location) => {
                  return (
                    <MenuItem key={location.id} value={location.id}>
                      {location.name}
                    </MenuItem>
                  );
                })}
              </Select>
            ) : (
              <Translate value="Location" />
            )}
          </TableCell>
          <TableCell component="th" scope="row">
            {props.data.selected ? (
              <Select
                sx={{ height: "40px" }}
                required
                id="owner"
                displayEmpty
                value={props.data.ownerId || ""}
                onChange={(e) => handleBatchDeviceOwnerChange(e, props?.batch)}
              >
                <MenuItem value={""}>
                  <Translate value="SelectOwner" />
                </MenuItem>
                {props?.locations?.map((location) => {
                  return (
                    <MenuItem key={location.id} value={location.id}>
                      {location.name}
                    </MenuItem>
                  );
                })}
              </Select>
            ) : (
              <Translate value="Owner" />
            )}
          </TableCell>
          <TableCell>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={(e) => handleExpendedChange(e, props?.batch)}
            >
              {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
            </IconButton>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box sx={{ margin: 1 }}>
                <Typography variant="h6" gutterBottom component="div">
                  Devices
                </Typography>
                <Table size="small" aria-label="purchases">
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ width: "50px" }}>Select</TableCell>
                      <TableCell>UDSN</TableCell>
                      <TableCell>Location</TableCell>
                      <TableCell>Owner</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {props.data.devices.map((deviceState) => {
                      console.log({ device: deviceState });
                      return (
                        <TableRow key={deviceState?.device?.id}>
                          <TableCell>
                            <Checkbox
                              checked={deviceState.selected}
                              onChange={(e) =>
                                handleDeviceCheckboxChange(
                                  e,
                                  props.batch,
                                  deviceState.device.id
                                )
                              }
                            />
                          </TableCell>
                          <TableCell align="left">
                            {deviceState.device?.udsn}
                          </TableCell>
                          <TableCell>
                            {deviceState.selected ? (
                              <Select
                                required
                                id="location"
                                displayEmpty
                                value={deviceState.locationId || ""}
                                onChange={(e) =>
                                  handleDeviceLocationChange(
                                    e,
                                    props.batch,
                                    deviceState.device.id
                                  )
                                }
                              >
                                <MenuItem value={""}>
                                  <Translate value="SelectLocation" />
                                </MenuItem>
                                {props?.locations?.map((location) => {
                                  return (
                                    <MenuItem
                                      key={location.id}
                                      value={location.id}
                                    >
                                      {location.name}
                                    </MenuItem>
                                  );
                                })}
                              </Select>
                            ) : null}
                          </TableCell>
                          <TableCell>
                            {deviceState.selected ? (
                              <Select
                                required
                                id="owner"
                                displayEmpty
                                value={deviceState.ownerId || ""}
                                onChange={(e) =>
                                  handleDeviceOwnerChange(
                                    e,
                                    props.batch,
                                    deviceState.device.id
                                  )
                                }
                              >
                                <MenuItem value={""}>
                                  <Translate value="SelectOwner" />
                                </MenuItem>
                                {props?.locations?.map((location) => {
                                  return (
                                    <MenuItem
                                      key={location.id}
                                      value={location.id}
                                    >
                                      {location.name}
                                    </MenuItem>
                                  );
                                })}
                              </Select>
                            ) : null}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </>
    );
  };

  return (
    <TableContainer>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow align="left">
            <TableCell align="left" sx={{ width: "50px" }}>
              <Checkbox checked={selectAll} onChange={handleSelectAll} />
            </TableCell>
            <TableCell>Batch number</TableCell>
            <TableCell>
              {selectAll ? (
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  <Translate value="Location" />

                  <Select
                    sx={{ height: "40px" }}
                    required
                    id="location"
                    displayEmpty
                    value={locationAll || ""}
                    onChange={handleAssignAllLocation}
                  >
                    <MenuItem value={""}>
                      <Translate value="SelectLocation" />
                    </MenuItem>
                    {props?.locations?.map((location) => {
                      return (
                        <MenuItem key={location.id} value={location.id}>
                          {location.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </Box>
              ) : (
                <Translate value="Location" />
              )}
            </TableCell>
            <TableCell>
              {selectAll ? (
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  <Translate value="Owner" />

                  <Select
                    sx={{ height: "40px" }}
                    required
                    id="owner"
                    displayEmpty
                    value={ownerAll || ""}
                    onChange={handleAssignAllOwner}
                  >
                    <MenuItem value={""}>
                      <Translate value="SelectOwner" />
                    </MenuItem>
                    {props?.locations?.map((location) => {
                      return (
                        <MenuItem key={location.id} value={location.id}>
                          {location.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </Box>
              ) : (
                <Translate value="Owner" />
              )}
            </TableCell>
            <TableCell sx={{ width: "50px" }}>Expand</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {state != null || state != undefined
            ? Object.keys(state)?.map((batch) => {
                return (
                  <Row
                    locations={props.locations}
                    key={batch}
                    batch={batch}
                    data={state[batch]}
                  />
                );
              })
            : null}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
