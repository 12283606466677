import React, {useState} from "react";
import { useNavigate } from "react-router-dom";
import { OximeterService } from "../../services/DataService";
import { Translate, translate } from 'react-i18nify';
import { PageHeader } from "../../components/Layout/PageHeader";
import { Box, InputLabel, Button, TextField } from "@mui/material";
import { ExistingMacsList } from "./ExistingMacsList";
import AlertBox from "../../components/AlertBox";
import PageContainer from "../../components/PageContainer";


export const Oximeter = () => {
    const [macAddress, setMacAddress] = useState("");
    const [hasBeenCreated, setHasBeenCreated] = useState(false);
    const [createdOximeter, setCreatedOximeter] = useState();
    const [alertBox, setAlertBox] = useState({
        "visiblity": false,
        "statusCode": null,
        "text": null,
        "error": null
        }
    );

    const navigate = useNavigate();

    const handleMacChange = (event) => {
        event.preventDefault();
        setMacAddress(event.target.value);
    }
    
    const handleAlertBox = (visiblity, statusCode, text, error) => {
        setAlertBox({
            "visiblity": visiblity,
            "statusCode": statusCode,
            "text": text,
            "error": error
        }
        )
    }
    const dismissAlertBox=()=>{
        setAlertBox({
            "visiblity": false,
            "statusCode": null,
            "text": null,
            "error": null

        })
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        console.log(event.target)
        dismissAlertBox()
        await OximeterService.addOximeter(macAddress).then(result => {
            if(result.data.id !== null){
                setHasBeenCreated(true);
                setCreatedOximeter(result.data)
            }
            
        })
        .catch(err => {
            if(err?.response?.status === 403){
                handleAlertBox(true, err?.response?.status, "Your role is not authorized to make this request", err?.response?.data?.title); 
            }else{
                handleAlertBox(true, err?.response?.status, null, err?.response?.data); 
            }
        })
    }

    const navigateAdmin = (e) => {
        e.preventDefault();
        // 👇️ navigate to /
        navigate('/O2maticAdministrator');
    };

    const columns = [
        {
            field: 'id', headerName: 'Component ID', flex: 1,
            valueGetter: (oximeterData) => {
                return oximeterData?.row.componentId;
            }
        },
        {
            field: 'mac', headerName: 'MAC address', flex: 1,
            valueGetter: (oximeterData) => {
                return oximeterData?.row.macAddress;
            }
        },
    ]    



    const addAnother = (e) => {
        e.preventDefault()
        setHasBeenCreated(false)
        setCreatedOximeter(undefined)
    }


    return(
        <PageContainer>
            <PageHeader leftAlign><Translate value="AddOximeterToWhitelist" /></PageHeader>
            {hasBeenCreated ? (
                <Box  justifyContent="space-between" sx={{mt:2}} >
                    <h3>Oximeter with MAC-address: {createdOximeter.componentmetadatauniques[0].value} has been created</h3>
                    
                    <Button onClick={addAnother} sx={{ mt: 4,  minWidth: '200px' }} variant="contained"><Translate value="AddAnotherOximeter" /></Button>
                    <br/>
                    <Button onClick={navigateAdmin} sx={{ mt: 4,  minWidth: '200px' }} variant="contained"><Translate value="ReturnToAdminpage" /></Button> 
                    
                </Box>
            ) : (
                <Box component="form" onSubmit={handleSubmit} sx={{ mt: 2, width:{xs:"100%", sm:"250px"}}} >
                    <InputLabel id="OximeterMacAddressLabel"><Translate value="MacAddress"/></InputLabel>
                    <TextField
                        sx={{ width:{xs:"100%", sm:"250px"}}}
                        id="macAddressField" 
                        required
                        placeholder={translate("MacAddress")}
                        onChange={handleMacChange}
                    >
                    </TextField>     
                    <AlertBox visible={alertBox.visiblity} text={alertBox.text} error={alertBox.error} statusCode={alertBox.statusCode} onHide={dismissAlertBox} />
                    <br/>
                    <Button sx={{ mt: 2, width:{xs:"100%", sm:"250px"}}} color="primary" variant="contained" type="submit">
                        <Translate value="AddOximeter" />
                    </Button>
                    <ExistingMacsList />
                </Box>
            )}
        </PageContainer>
        
    );
}