import React, { useState, useEffect } from 'react'
import { useParams, Navigate, useNavigate } from 'react-router-dom';
import { PageDetailText } from "../../components/PageDetailText";
import PageContainer from '../../components/PageContainer';
import { PageHeader } from '../../components/Layout/PageHeader';
import { Box } from '@mui/system';
import { Typography, Button, FormControl, TextField, InputLabel, Select, MenuItem, FormGroup, FormControlLabel, Switch, Slider, Checkbox } from '@mui/material'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import SaveIcon from '@mui/icons-material/Save';
import { LocationService, TreatmentService, CountryService, MetadataService } from '../../services/DataService'
import { Translate, Localize } from 'react-i18nify';
import { RequireRole } from '../../utils/RequireRole';
import { Roles } from '../../redux/actions/auth';
import AlertBox from "../../components/AlertBox"
import OutlinedInput from '@mui/material/OutlinedInput';
import Chip from '@mui/material/Chip';
import { useTheme } from '@emotion/react';
const LocationForm = props => {

    let navigate = useNavigate();
    const theme = useTheme();

    let location = props.location;
    let currentParentLocation = props.location?.parentLocation;
    let currentCountryCode = props.location?.countryCode;
    let currentRetentionPeriod = props.location?.dataRetenetionPeriod;
    let isClinicalTrialVersion = props.location?.clinicalTrial;
    let locationTags= props.location?.locationProperties?.map(property=>property.value);

    const [doneLoadingLocation, setDoneLoadingLocation] = useState(false);
    const [doneLoadingCountry, setDoneLoadingCountry] = useState(false);
    const [parentLocation, setParentLocation] = useState(currentParentLocation ? currentParentLocation?.id : "");
    const [address, setAddress] = useState(location ? location?.address : "");
    const [locationName, setLocationName] = useState(location ? location?.name : "");
    const [country, setCountry] = useState(currentCountryCode ? currentCountryCode : "");
    const [locations, setLocations] = useState([]);
    const [countries, setCountries] = useState([]);
    const [availableTags, setAvailableTags] = useState([]);
    const [tags, setTags] = useState(locationTags?locationTags:[]);
    const [dataRetentionPeriod, setDataRetentionPeriod] = useState(currentRetentionPeriod ? currentRetentionPeriod : 180);
    const [clinicalTrial, setClinicalTrial] = useState(isClinicalTrialVersion ? isClinicalTrialVersion : false);
    const [requestSubmited, setRequestSubmited] = useState(false);

    const [alertBox, setAlertBox] = useState({
        "visiblity": false,
        "statusCode": null,
        "text": null,
        "error": null
    }
    );


    const handleAlertBox = (visiblity, statusCode, text, error) => {
        setAlertBox({
            "visiblity": visiblity,
            "statusCode": statusCode,
            "text": text,
            "error": error
        }
        )
    }
    const dismissAlertBox=()=>{
        setAlertBox({
            "visiblity": false,
            "statusCode": null,
            "text": null,
            "error": null

        })
    }

    const handleParentLocationChange = (event, newValue) => {
        setParentLocation(newValue.props.value);
    };
    const handleCountryChange = (event, newValue) => {
        setCountry(newValue.props.value);
    };
    const handleAddressChange = (event, newValue) => {
        setAddress(event.target.value);
    };
    const handleNameChange = (event, newValue) => {
        setLocationName(event.target.value);
    };
    const handleClinicalTrial = (event, newValue) => {
        setClinicalTrial(event.target.value)
    };
    const handleRetentionPeriodChange = (event, newValue) => {
        setDataRetentionPeriod(event.target.value);
    };
    useEffect(() => {
        (async () => {
            let locationsResult = await LocationService.getByRoles(["Administrator"]);
            let countriesResult = await CountryService.getAll();
            let locationMetdadataResult= await MetadataService.getValuesByName("LocationProperty");
            let locationMetaData=locationMetdadataResult.data.filter(data=>data.value!="O2MATIC")
            let sorted=countriesResult.data.sort((a, b) => a.code.localeCompare(b.code))
            setCountries(sorted);
            setAvailableTags(locationMetaData);
            setLocations(locationsResult.data.sort((a, b) => a.name > b.name ? 1 : -1));
            setDoneLoadingLocation(true);
            setDoneLoadingCountry(true);
        })();
    }, []);
    const handleSubmit = (event) => {
        event.preventDefault();
        dismissAlertBox()
        setRequestSubmited(true)
        let locationProperties=availableTags.filter(tag =>tags.includes(tag.value));
        var body = {
            "name": locationName,
            "address": address,
            "countryCode": country,
            "parentLocationId": parentLocation,
            "dataRetentionPeriod": dataRetentionPeriod,
            "locationProperties":locationProperties
        }
        if (location.id != null) {
            body.id = location.id;


            LocationService.update(body).then(result => {
                handleAlertBox(true, result.status, result.statusText, null) 
                setTimeout(()=>{
                    navigate("/location/" + result.data.id, { replace: true });
                },1000)

            }).catch(err => {
                setRequestSubmited(false)
                handleAlertBox(true, err?.response?.status, null, err?.response?.data?.join(",")) 
            })
        }
        else {
            LocationService.create(body).then(result => {
                handleAlertBox(true, result.status, result.statusText, null) 
                setTimeout(()=>{
                    navigate("/location/" + result.data.id, { replace: true });
                },1000)
            }).catch(err => {
                setRequestSubmited(false)
                handleAlertBox(true, err?.response?.status, null, err?.response?.data?.join(",")) 
            })
        }



    }
    const handleChange = (event) => {
        let stringRoles = event.target.value;
        setTags(stringRoles);
    };


    const renderLocationForm = (location) => {
        if (location == undefined)
            return <Typography>Loading...</Typography>
        return (
            <Box onSubmit={handleSubmit} component="form" sx={{ width: { xs: "95%", sm: "400px" }, mt: 2, display: "flex", flexDirection: "column" }}>



                <InputLabel id="nameLabel"><Translate value="Name" /></InputLabel>
                <TextField sx={{ width: "100%" }}
                    id="locationName"
                    required
                    onChange={handleNameChange}
                    value={locationName}>
                </TextField>


                <InputLabel id="addressLabel"><Translate value="Address" /></InputLabel>
                <TextField sx={{ width: "100%" }}
                    id="locationAddress"
                    required
                    inputProps={{ maxLength: 199 }}
                    onChange={handleAddressChange}
                    value={address}>
                </TextField>

                <InputLabel id="addressLabel"><Translate value="ParentLocation" /></InputLabel>
                {doneLoadingLocation ?
                    <Select
                        required
                        id="parentLocation"
                        value={parentLocation || ""}
                        onChange={handleParentLocationChange}
                    >
                        {locations.map(l => {
                            return <MenuItem key={l.id} value={l.id}>{l.name}</MenuItem>
                        })}
                    </Select>
                    : null}


                <InputLabel id="countryLabel"><Translate value="Country" /></InputLabel>


                {doneLoadingCountry ?
                    <Select
                        required
                        id="country"
                        value={country || ""}
                        onChange={handleCountryChange}
                    >
                        {countries.map(c => {
                            return <MenuItem key={c.id} value={c.code}>  <Translate value={`Country-${c.code}`} /> </MenuItem>
                        })}
                    </Select>
                    : null}


                <InputLabel id="retentionPeriodLabel">{<Translate value="DataRetentionPeriod" />} ({<Translate value="Days" />}) </InputLabel>
                <TextField sx={{ width: "100%" }}
                    id="dataRetentionPeriod"
                    required
                    disabled
                    type="number"
                    onChange={handleRetentionPeriodChange}
                    value={dataRetentionPeriod}>
                </TextField>
                <RequireRole authorize={[Roles.CtManager]}>
                <InputLabel sx={{ mt: 0 }} id="labelSelectTags"><Translate value="SelectTags" /></InputLabel>
                <Select
                    sx={{ mb: 2, width:"100%", fontWeight: theme.typography.fontWeightRegular }}
                    labelid="labelSelectTags"
                    id="tags"
                    multiple
                    value={tags}
                    onChange={handleChange}
                    input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                    renderValue={(selected) => {
                        return (
                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                {selected.map((value) => (
                                    <Chip key={value} label={value} />
                                ))}
                            </Box>
                        )
                    }}
                >
                    {availableTags.map((tag) => (
                        <MenuItem
                            key={tag.id}
                            value={tag.value}
                        >
                            {tag.value}
                        </MenuItem>
                    ))}
                </Select>

                </RequireRole>


                <Button sx={{ width: "200px", mt:2, mr: 2 }} disabled={requestSubmited} color="success" type="submit" variant='contained' startIcon={<SaveIcon />}><Translate value="Save" /> </Button>
                <AlertBox visible={alertBox.visiblity} text={alertBox.text} error={alertBox.error} statusCode={alertBox.statusCode} onHide={dismissAlertBox}/>

            </Box>


        )

    }
    return (
        <>
            {renderLocationForm(location)}
        </>
    )
}
export default LocationForm