import React, { forwardRef, useEffect, useRef, useState } from "react";
import { Fragment } from "react";
import { useParams, useNavigate } from "react-router-dom";
import PageContainer from "../../components/PageContainer";
import { PageHeader } from "../../components/Layout/PageHeader";
import { Box } from "@mui/system";
import PropTypes from 'prop-types';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import DownloadIcon from '@mui/icons-material/Download';
import SensorsIcon from '@mui/icons-material/Sensors';
import WarningIcon from '@mui/icons-material/Warning';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import {
  Typography,
  IconButton,
  Button,
  Modal,
  Fade,
  Backdrop,
  Paper,
  TextField,
  InputLabel,
  Checkbox,
  Select,
  MenuItem,
  FormGroup,
  Switch,
  FormControl,
  FormControlLabel,
  Radio,
  FormLabel,
  RadioGroup,
  Link,
  useTheme,
  useMediaQuery,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TextareaAutosize,
  Collapse
} from "@mui/material";
import { PatientDetailText , PatientDetailTextInfo, PatientDetailTextWarning} from "../../components/Patient/PatientDetailText";
import { DataGrid, GridExpandMoreIcon } from "@mui/x-data-grid";
import CircularProgress from '@mui/material/CircularProgress';
import { SessionDialog } from "../../components/Patient/SessionDialog";
import { LiveDataDialog } from "../../components/Patient/LiveDataDialog";
import {
  PatientService,
  SessionService,
  DeviceService,
  EventLogService,
  TreatmentService,
  MetadataService,
} from "./../../services/DataService";
import PatientDashboardCategory from "../../components/Patient/PatientDashboardCategory";
import PatientDashboardCategoryWarning from "../../components/Patient/PatientDashboardCategoryWarning";
import NewPreviewChart from "../../components/Session/NewPreviewChart";
import SessionsOverviewChart from "../../components/Session/SessionsOverviewChart";
import { Translate, Localize, getLocale, translate } from "react-i18nify";
import PatientUtils from "../../utils/PatientUtil";
import { RequireRole } from "../../utils/RequireRole";
import TimeUtils from "../../utils/TimeUtils";
import {
  generateComplaience,
  addEventsToTimeline,
  adjustStartTime,
} from "../../components/Compliance/ComplainceCalculation";
import ComplainceCalendar from "../../components/Compliance/ComplianceCalendar";
import FromToPicker from "../../components/FromToPicker";
import { FromToPickerDate } from "../../components/FromToPicker";
import { DayDataDialog } from "../../components/Patient/DayDataDialog";
import { PaginationDownloader } from "../../components/Patient/PaginationDownloader";
import { usePaginationDownloader } from "../../components/Patient/usePaginationDownloader";
import AlertBox from "../../components/AlertBox";
import { PatientLog } from "../../components/Patient/PatientLog";
import {UpdatesBanner} from "../../components/UpdatesBanner";

import { styled } from '@mui/material/styles';
import JSZip from "jszip";
import FileSaver from "file-saver";

import DataTab from "../../components/Patient/Datatab";
import { PatientTreatmentInfoComponent } from "../../components/Patient/PatientTreatmentInfo";
import PatientDeviceInformation from "../../components/Patient/PatientDeviceInformation";

export default function Patient() {
  let toDate = new Date();
  let fromDate = new Date();
  fromDate.setMonth(fromDate.getMonth() - 1); // last month = from same day last month
  let fromDateOverview = new Date();
  fromDateOverview.setDate(fromDateOverview.getDate() - 6); // last 7 days

  const [sessionDialogOpen, setSessionDialogOpen] = useState(false);
  const [dayDataDialogOpen, setDayDataDialogOpen] = useState(false);
  const [liveDataDialogOpen, setLiveDataDialogOpen] = useState(false);
  const [dialogDate,setDialogDate] = useState(null)
  const [liveData,setLiveData] = useState([])
  const [sessionDevice, setSessionDevice] = useState('')
  const [dialogSession, setDialogSession] = useState({});
  const [detailedSessions, setDetailedSessions] = useState([]);
  const [patient, setPatient] = useState(null);
  // const [patientComponent, setPatientComponent] = useState(null);
  const [session, setSession] = useState(null);
  const [chartOverviewSessions, setChartOverviewSessions] = useState(null);
  const [sessionClicked, setSessionClicked] = useState(null);
  const [device, setDevice] = useState(null);
  // const [deviceMac, setDeviceMac] = useState(null);
  // const [latestDeviceLog, setLatestDeviceLog] = useState(null);
  const [treatmentPending, setTreatmentPending] = useState(null); //true
  const [deviceCurrentTreatment, setDeviceCurrentTreatment] = useState(null); // try and change to have loading aswell
  const [previousTreatment, setPreviousTreatment] = useState(null);
  const [locationMismatch, setlocationMismatch] = useState(null);
  const [latestSession, setLatestSession] = useState(null);
  const [xaxisRange, setXaxisrange] = useState("last7days");
  const [sessionsFrom, setSessionsFrom] = useState(fromDate);
  const [sessionsTo, setSessionsTo] = useState(toDate);
  const [sessionsOverviewFrom, setSessionsOverviewFrom] = useState(fromDateOverview);
  const [sessionsOverviewTo, setSessionsOverviewTo] = useState(toDate);
  const [datePickerVisibility, setDatePickerVisibility] = useState(false);
  const [complaienceYear, setComplaienceYear] = useState(new Date(Date.now()));
  const [complaienceMonth, setComplaienceMonth] = useState(new Date(Date.now()));
  const [complaienceData, setComplaienceData] = useState([]);
  const [complaienceDataLoading, setComplaienceDataLoading] = useState(true);
  const [awaitingDevice, setAwaitingDevice] = useState(true);

  const { id } = useParams();
  const theme = useTheme();
  const lessThanSmall = useMediaQuery(theme.breakpoints.down("sm"));
  const navigate = useNavigate();
  const {
    addListener,
    removeListener,
    loadPage,
    destroy,
    hasObjectWithId,
    setFetchFunctionInState,
    addInitialData,
    addLoadedPages,
    generateAssembledPlaceholders,
    pageData,
    assembledData,
  } = usePaginationDownloader();

  const locale = getLocale();
  

  async function fetchSessionData(id, sessionsFrom, sessionsTo, limit, offset, abortController) {
    let from = TimeUtils.localToUtc(sessionsFrom)
    let to = TimeUtils.localToUtc(sessionsTo)
  
    let sessionData_ = await SessionService.getByPatientId(
      id,
      from,
      to,
      limit,
      offset,
      abortController
    );
    return sessionData_
  }

  const handleRadioChange = (event) => {
    setXaxisrange(event.target.value);
    if (event.target.value == "custom") {
      setDatePickerVisibility(true)
    } else {
      if (event.target.value == "last7days") {
        setDatePickerVisibility(false)
        let fromDateOverview = new Date();
        fromDateOverview.setDate(fromDateOverview.getDate() - 6); // last 7 days
        setSessionsOverviewFrom(fromDateOverview);
        setSessionsOverviewTo(new Date());
      } else {
        setDatePickerVisibility(false)
        let fromDateOverview = new Date();
        fromDateOverview.setDate(fromDateOverview.getDate() - 13); // last 14 days
        setSessionsOverviewFrom(fromDateOverview);
        setSessionsOverviewTo(new Date());
      }
      }
  };

  useEffect(() => { // data for session overview chart at the top of the page
    setChartOverviewSessions(null);

    (async () => {
    let fromOverview = TimeUtils.localToUtc(sessionsOverviewFrom)
    let toOverview = TimeUtils.localToUtc(sessionsOverviewTo)
    let sessionOverviewData_ = await SessionService.getSessionOverviewByPatientId(
      id,
      fromOverview,
      toOverview
    )
    setChartOverviewSessions(sessionOverviewData_.data);
  })();

  }, [sessionsOverviewFrom,sessionsOverviewTo]);

  useEffect(() => {
    const listener = newData => {
      setSession(newData); //
    };

    addListener(listener);
    setFetchFunctionInState(() => fetchSessionData)
    return () => {
      removeListener(listener);
      destroy();
    };
  }, [])

  useEffect(() => { 
    if(!awaitingDevice){
      (async () => {
        try{
          let apiTreatmentResult = await PatientService.getTreatmentInstallationConfirmation(id, device?.id);
          setDeviceCurrentTreatment(apiTreatmentResult.data)
        }catch(error){
          console.error("unable to fetch treatment\n", error)
          setDeviceCurrentTreatment("Unknown")
        }
      })();
    }

    if (patient != null) {
      if (device?.location?.id !== patient?.location?.id) {
        setlocationMismatch(true);
      } else {
        setlocationMismatch(false);
      }
    }
  }, [device, awaitingDevice]); // on device or patient change

  useEffect(() => { // find device mac address
    if (deviceCurrentTreatment !== null ) {
      if (patient?.currentTreatment?.id !== deviceCurrentTreatment?.treatmentId || deviceCurrentTreatment === "Unknown") {
        setTreatmentPending(true);
      } else {
        setTreatmentPending(false);
      }
      if (treatmentPending && previousTreatment === null) {
        (async () => {
          let apiPrevTreatmentResult = await TreatmentService.get(deviceCurrentTreatment?.treatmentId);
          setPreviousTreatment(apiPrevTreatmentResult.data)
        })();
      }
    }
  }, [deviceCurrentTreatment]); // on deviceCurrentTreatment change

  useEffect(() => {
    (async () => { // find patient, patient logs and session
      let apiPatientResult = await PatientService.get(id);
      setPatient(apiPatientResult.data);
      // let apiLatestSession = await SessionService.getLatestByPatientId(id);
      // setLatestSession(apiLatestSession.data);
    })();
    (async () => { // find device
      setAwaitingDevice(true);
      await DeviceService.getByPatientId(id, 50, 0).then(response => {
        setDevice(response.data);
      })
      .catch(error => {
        console.error("unable to fetch device\n", error)
      }).finally(() => {
        setAwaitingDevice(false);
      }); //should this not just be id?
    })();
  }, []); // initially

  // when session is clicked in sessionsOverviewChart
  useEffect(() => {
    if(sessionClicked){
      setDialogSession(sessionClicked);
      (async () => {
        var from = sessionClicked?.parts.start?.timestamp;
        var to = sessionClicked?.parts.end?.timestamp;
        setSessionDialogOpen(true);
        let apiResultDetailedSessions = await SessionService.getDetailedSessionsByPatientId(
          id,
          from,
          to
        );
        let detailedSessionDeviceSN = await MetadataService.getSerialNumberByComponentId(sessionClicked.component.id)
        setSessionDevice(detailedSessionDeviceSN.data)
        setDetailedSessions(apiResultDetailedSessions.data);
      })();
    }
  }, [sessionClicked]);

  useEffect(() => {
    let date = new Date();
    let from = TimeUtils.getBeginningOfDay(date);
    let to = TimeUtils.getEndingOfDay(date);
    (async () => {
      let apiResultDetailedSessions = await SessionService.getDetailedSessionsByPatientId(
        id,
        from.toISOString(),
        to.toISOString()
      );
      setLiveData(apiResultDetailedSessions.data);
      
    })();

  }, []);


  useEffect(() => {
    (async () => {
      setComplaienceDataLoading(true);
      let firstDay = new Date(
        complaienceYear.getFullYear(),
        complaienceMonth.getMonth(),
        1
      );
      let lastDayOfMonth = new Date(
        complaienceYear.getFullYear(),
        complaienceMonth.getMonth() + 1,
        0
      );
      let lastDay = new Date(
        complaienceYear.getFullYear(),
        complaienceMonth.getMonth() + 2,
        0
      );
      let newmonth = new Date(
        complaienceYear.getFullYear(),
        complaienceMonth.getMonth() + 2,
        0
      );
      let eventResult = await EventLogService.getByPatientId(
        id,
        TimeUtils.formatDateYYMMDD(firstDay),
        TimeUtils.formatDateYYMMDD(lastDay),
        [405]
      );

      let activeDaysResult = await SessionService.getActiveDaysByPatientId(
        id,
        TimeUtils.getBeginningOfMonth(firstDay).toISOString(),
        TimeUtils.getEndingOfMonth(firstDay).toISOString()
      );

      let activeDays = activeDaysResult?.data;
      if (eventResult?.data?.length == 0) {
        let data = eventResult.data;
        let complaience = generateComplaience(
          data,
          null,
          complaienceYear.getFullYear(),
          complaienceMonth.getMonth(),
          24,
          activeDays
        );
        setComplaienceData(complaience);
        setComplaienceDataLoading(false);
        return;
      }

      let eventsUntil = eventResult?.data[eventResult?.data?.length - 1];
      let eventUntilDate = new Date(eventsUntil?.timestamp);
      let turnOfEventsUntil = new Date(eventsUntil?.timestamp);
      if (eventUntilDate > lastDayOfMonth) {
        //WE SHOULD HAVE ALL 405 EVENTS TO CALCULATE COMPLAIENCE
        let data = eventResult.data;
        let complaience = generateComplaience(
          data,
          null,
          complaienceYear.getFullYear(),
          complaienceMonth.getMonth(),
          24,
          activeDays
        );
        setComplaienceData(complaience);
        setComplaienceDataLoading(false);
        return;
      }

      let eventResult2 = await EventLogService.getByPatientId(
        id,
        TimeUtils.formatDateYYMMDD(eventUntilDate),
        TimeUtils.formatDateYYMMDD(newmonth),
        []
      );
      if (eventResult2?.data?.length != 0) {
        //requesting additional events
        let data = eventResult.data;
        let mergeddata = addEventsToTimeline(eventResult2.data);

        let fixedStartTimes = adjustStartTime(mergeddata, turnOfEventsUntil);
        let complaience = generateComplaience(
          data,
          fixedStartTimes,
          complaienceYear.getFullYear(),
          complaienceMonth.getMonth(),
          24,
          activeDays
        );
        setComplaienceData(complaience);
        setComplaienceDataLoading(false);
        return;
        // fill complaence from until event to last timestamp
      }
      let data = eventResult.data;
      let complaience = generateComplaience(
        data,
        null,
        complaienceYear.getFullYear(),
        complaienceMonth.getMonth(),
        24,
        activeDays
      );
      setComplaienceData(complaience);
      setComplaienceDataLoading(false);
    })();
  }, [complaienceYear, complaienceMonth, ]);

  useEffect(() => {
    var abortController = new AbortController()
    const fetchData = async () => {
      if (patient == null) {
        return;
      }
      try {
        const apiResultCount = await SessionService.getCountByPatientId(
          id,
          TimeUtils.localToUtc(sessionsFrom),
          TimeUtils.localToUtc(sessionsTo),
          abortController
        );
        const numberOfSessions = apiResultCount.data
        const apiResult = await SessionService.getByPatientId(
          id,
          TimeUtils.localToUtc(sessionsFrom),
          TimeUtils.localToUtc(sessionsTo),
          150,
          0,
          abortController
        );
        if (!abortController?.signal?.aborted) {
          let sessions = apiResult.data;
          addInitialData(sessions, { 0: {}, 1: {}, 2: {} })
          if (numberOfSessions > 150) {
            generateAssembledPlaceholders(numberOfSessions, id)
          }
          setSession(assembledData);
        }

      } catch (e) {
        if (!abortController?.signal?.aborted) {
        }
        console.error("An error occurred while fetching data:", e);
      }

    };

    fetchData();
    return () => {
      abortController.abort();
    };

  }, [patient, sessionsTo, sessionsFrom]);

  const handleEdit = () => {
    navigate("/patient/" + id + "/edit", { replace: true });
  };

  const handleViewLiveData = () => {
    setLiveDataDialogOpen(true);
  };

  // previous treatment modal

  const openSessionDetail = (row) => {
    setDialogSession(row.row);
    (async () => {
      var from = row.row.parts.start?.timestamp;
      var to = row.row.parts.end?.timestamp;
      setSessionDialogOpen(true);
      let apiResultDetailedSessions = await SessionService.getDetailedSessionsByPatientId(
        id,
        from,
        to
      );
      let detailedSessionDeviceSN = await MetadataService.getSerialNumberByComponentId(row.row.component.id)
      setSessionDevice(detailedSessionDeviceSN.data)
      setDetailedSessions(apiResultDetailedSessions.data);
    })();

  };

  const closeSessionDetail = (row) => {
    setSessionDialogOpen(false);
    setSessionClicked(null);
  };

  const openDayDialogDetail = (date) => {
    setDayDataDialogOpen(true);
    // setReportDialogOpen(true);
    
    (async () => {
      // if(data!=null){
      // }
      setDialogDate(date)
      var from = TimeUtils.getBeginningOfDay(date); // added
      var to = TimeUtils.getEndingOfDay(date); // added
      
      let apiResult = await SessionService.getDetailedSessionsByPatientId(
        id,
        TimeUtils.localToUtc(from),
        TimeUtils.localToUtc(to)
      );
      apiResult.data.date = from; // KAC: what does this line do?
      setDetailedSessions(apiResult.data);
    })();
  };

  const updateLiveDataDialog = () => {
    let date = new Date();
    let from = TimeUtils.getBeginningOfDay(date);
    let to = TimeUtils.getEndingOfDay(date);
    (async () => {
      let apiResultDetailedSessions = await SessionService.getDetailedSessionsByPatientId(
        id,
        from.toISOString(),
        to.toISOString()
      );
      setLiveData(apiResultDetailedSessions.data);
    })();
    setLiveDataDialogOpen(true);
  };
  
  const getSessionsByPagination = (currentPage, allSessions) => {
    if (allSessions == null) {
      return null;
    }
    return allSessions?.slice(currentPage * 50, (currentPage + 1) * 50) ?? [];
  };
  
  const GeneratePatientInfo = (props) => {
    const patient = props.patient

    return (
      <Box sx={{ width:"100%", mt: 1, borderRadius: "15px", border: 1, borderColor: "primary.main", bgcolor: "primary.main", color: "primary.contrastText", p: 2}}>
      
      <Typography sx={{ mt: 1 }} variant="body">
        {<Translate value="PatientInformation" />}
      </Typography>
          
      <PatientDashboardCategory // patient name
        title={patient?.patient?.name?.[0]?.given?.[0]?.value
        ? patient.patient.name[0].given[0].value + " " + patient.patient.name[0].family.value
        : "-"}
      >
      </PatientDashboardCategory>

      <Box sx={{mt: 2}}>
        <PatientDetailText // O2matic ID
          label={<Translate value="O2maticId" />}
          value={patient?.patient?.id?.value? patient?.patient?.id?.value : "-"}
        ></PatientDetailText>
        <PatientDetailText
          label={<Translate value="IdentifierType" />}
          value={patient?.patient?.identifier?.[0]?.system?.value? patient.patient.identifier[0].system.value : "-"}
        ></PatientDetailText>
        <PatientDetailText
          label={<Translate value="IdentifierValue" />}
          value={patient?.patient?.identifier?.[0]?.value?.value? patient.patient.identifier[0].value.value : "-"}
        ></PatientDetailText>
      </Box>

       <Box>
        <Box sx={{mt: 2}}>
            <PatientDetailText
              label={<Translate value="DateOfBirth" />}
              value={patient?.patient?.birthDate?.value? patient?.patient?.birthDate?.value : "-"}
            ></PatientDetailText>
            <PatientDetailText
              label={""}
              value={patient?.patient?.birthDate?.value? TimeUtils.getAge(patient?.patient?.birthDate?.value) : "-"}
              unit={patient?.patient?.birthDate?.value? translate("years") : ""}
            ></PatientDetailText>
            <PatientDetailText

              label={<Translate value="Address" />}
              value={patient?.patient?.address[0]?.line[0]?.value? patient?.patient?.address[0]?.line[0]?.value : "-"}
            ></PatientDetailText>
            <PatientDetailText
              label={<Translate value="City" />}
              value={patient?.patient?.address[0]?.city?.value? patient?.patient?.address[0]?.city?.value : "-"}
            ></PatientDetailText>
            <PatientDetailText
              label={<Translate value="PostalCode" />}
              value={patient?.patient?.address[0]?.postalCode?.value? patient?.patient?.address[0]?.postalCode?.value : "-"}
            ></PatientDetailText>
            <PatientDetailText
              label={<Translate value="Country" />}
              value={patient?.patient?.address[0]?.country?.value? patient?.patient?.address[0]?.country?.value : "-"}
          ></PatientDetailText>
        </Box>

          

           

          <Box sx={{mt: 2}}>
            {locationMismatch? // add warning color to location if there is a mismatch between patient location and device location
              <PatientDetailTextWarning
                label={<Translate value="Location" />}
                value={patient?.location?.name}
                explanation={<Translate value="There is a mismatch between the location of the patient and the location of the HOT device. In order for data to be received in the right location, please change locations to match." />}
              ></PatientDetailTextWarning>
              :
              <PatientDetailText
                label={<Translate value="Location" />}
                value={patient?.location?.name? patient?.location?.name : "-"}
              ></PatientDetailText>
            }
            <PatientDetailText
              label={<Translate value="Status" />}
              value={patient?.patient?.active?.value ? "Active" : "Inactive"}
            ></PatientDetailText>
          </Box>

          <Box sx={{ mt: 2, width:"100%", display:"flex", justifyContent: 'space-between'}}>
            <RequireRole authorize={["PatientManager"]}>
            <Button
                onClick={handleEdit}
                sx={{minWidth:"150px", alignSelf: "start"}}
                color="secondary"
                variant="contained"
                component="label"
                startIcon={<EditIcon/>}
              >
                <Translate value="EditPatient" />
              </Button>
            </RequireRole>

            <Box sx={{width:"10"}}>

            </Box>

            <Button
                onClick={handleViewLiveData}
                sx={{minWidth:"150px", alignSelf: "start", "&.Mui-disabled": {
                  background: "#eaeaea",
                  color: "#c0c0c0"
                }}}
                color="secondary"
                variant="contained"
                component="label"
                disabled={liveData.length == 0}
                
                startIcon={liveData.length == 0? <SensorsIcon sx={{color:"grey"}}/>: <SensorsIcon sx={{color:"red"}}/>}
              >
                <Translate value="ViewLiveData" />
              </Button>
          </Box>

        </Box>
    </Box>

    );
  };



  const columnsSession = [
    {
      field: "id",
      headerName: "UID",
      type: "number",
      width: 50,
      flex: 0,
      hide: true,
    },
    {
      field: "deviceSessionId",
      headerName: translate("Id"),
      type: "number",
      width: 25,
      flex: 1,
    },
    {
      field: "treatmentProfile",
      headerName: translate("TreatmentProfile"),
      flex: 5,
      valueGetter: (params) => {
        let row = params.row;
        return row.treatment.name;
      },
    },
    {
      field: "time",
      headerName: translate("Timestamp"),
      flex: 5,
      valueGetter: (params) => {
        let timestamp = params?.row?.parts?.start?.timestamp;
        return TimeUtils.utcToLocal(timestamp);
      },
    },
  ];

  return (
    <PageContainer>
      <SessionDialog
        // labels={labels}
        open={sessionDialogOpen}
        onClose={closeSessionDetail}
        session={dialogSession}
        detailedSessions={detailedSessions}
        sessionDevice={sessionDevice}
      />

      <LiveDataDialog
        open={liveDataDialogOpen}
        onClose={()=>setLiveDataDialogOpen(false)}
        onDataUpdateRequest={()=>updateLiveDataDialog()}
        date={new Date()}
        liveData={liveData}
      ></LiveDataDialog>

      <PageHeader leftAlign>
        <Translate value="PatientDashboard" />
      </PageHeader>

      <UpdatesBanner update_description="UpdatesDescriptionPatientPage"/>

      <Box
        sx={{
          width: { xs: "100%", sm: "100%", md: "100%", lg: "1000px" },
          maxWidth: {md: "1000px"},
          display: "flex",
          flexDirection: { xs: "column", sm: "column", md: "row", lg: "row" },
          justifyContent: 'space-between',
        }}
      >

        <Box
          sx={{
            width: { xs: "100%", sm: "100%", md: "49%", lg: "49%" },
            display: "flex", mb: 3
          }}
        >
          <GeneratePatientInfo patient={patient}/>
        </Box>



        <Box
          sx={{
            width: { xs: "100%", sm: "100%", md: "49%", lg: "49%" },
            display: "flex", mb: 3
          }}
        >
          <PatientTreatmentInfoComponent 
            patientId={id}
            treatment={patient?.currentTreatment} 
            treatmentPending={treatmentPending}
            // treatmentPrescribed={treatmentPrescribed}
            previousTreatment={previousTreatment}
            deviceCurrentTreatment={deviceCurrentTreatment}
          />
        </Box>


      </Box>

      <Box
        sx={{
          width: { xs: "100%", sm: "100%", md: "100%", lg: "1000px" },
          maxWidth: {md: "1000px"},
          border: 3,
          borderRadius: "15px", border: 1, borderColor: "primary.main", bgcolor: "#ffffff",
          display: "flex", mb:3
        }}
      >
        <Box
          sx={{ width:"100%", display: "flex", flexDirection: "column"}}
        >
          <Box sx={{ width:"100%", display: "flex", flexDirection: "row", justifyContent: 'space-between', mt:2, flexWrap: 'wrap'}}>
            <Box sx={{flexGrow: 1, color:"primary.main"}}>
              <Typography variant="h5" sx={{ml:2}}>
                <Translate value="SessionsOverview" />
              </Typography>
            </Box>

            <Box sx={{display: "flex", flexDirection: "row", ml:2}}>
              <FormControl>
              <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  onChange={handleRadioChange}
                  defaultValue = "last7days"
              >
                  <FormControlLabel value={"last7days"} control={<Radio />} label={translate("Last7days")} />
                  <FormControlLabel value={"last14days"} control={<Radio />} label={translate("Last14days")} />
                  <FormControlLabel value={"custom"} control={<Radio />} label={translate("Select")} />
              </RadioGroup>
              </FormControl>


              <Box sx={{width:"340px", display:"flex", flexDirection: 'row-reverse'}}>
                {datePickerVisibility?
                <Box sx={{ display:'flex', flexDirection: 'row', height:"20px", mr:2, mt:"-15px"}}>
                  <FromToPickerDate
                    from={sessionsOverviewFrom}
                    to={sessionsOverviewTo}
                    setFromValue={setSessionsOverviewFrom}
                    setToValue={setSessionsOverviewTo}
                  />
                </Box> : <></> }
              </Box>
            </Box>
          </Box>

          <Box sx={{ width: "100%", ml:-1, mb:-2}}>
          <SessionsOverviewChart selectedSession={chartOverviewSessions} sessionsOverviewFrom={sessionsOverviewFrom} sessionsOverviewTo={sessionsOverviewTo} height={"300px"} setSessionClicked={setSessionClicked}/>
          </Box>
        </Box>
      </Box>
              
      <Box
        sx={{
          // width: "70%",
          width: { xs: "100%", sm: "100%", md: "100%", lg: "1000px" },
          maxWidth: {md: "1000px"},
          border: 3,
          borderRadius: "15px", border: 1, borderColor: "primary.main", bgcolor: "primary.main", color: "primary.contrastText",
          display: "flex",
          // flexDirection: { xs: "column", md: "row" },
        }}
      >
        <Box
          sx={{ width:"100%", display: "flex", flexDirection: { xs: "column", md: "row" } }}
        >
          <PatientDeviceInformation patient={patient} device={device} locationMismatch={locationMismatch} awaitingDevice={awaitingDevice} />
        </Box>
      </Box>

      <DataTab 
        id={id} 
        setSessionClicked={setSessionClicked}
      />

       <Accordion sx={{ width: { xs: "100%", sm: "100%", md: "100%", lg: "1000px" }, maxWidth: {md: "1000px"}, mt: 2 }}>
        <AccordionSummary
          expandIcon={<GridExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography variant="h5"><Translate value="Compliance" /></Typography>

        </AccordionSummary>
        <AccordionDetails>
          <ComplainceCalendar
            loading={complaienceDataLoading}
            data={complaienceData}
            complaienceMonth={complaienceMonth}
            complaienceYear={complaienceYear}
            setComplaienceMonth={setComplaienceMonth}
            setComplaienceYear={setComplaienceYear}
            onDayClicked={(date) => openDayDialogDetail(date)}
            patientId={id}
          />

          <Box sx={{my:2, display: 'flex', flexDirection: 'row', width:"100%"}}>
            <Box sx={{ display: 'flex', flexDirection: 'column', width:"55%"}}>
              <Box sx={{mb:1}}>
                <Typography variant="body1"><Translate value="ComplianceGoalLegendPercent" /></Typography>
              </Box>

              <Box sx={{display: 'flex', flexDirection: 'column'}}>
                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                  <Box sx={{width:"40px", display: 'flex', justifyContent: 'center'}}>
                    <Paper sx={{width:"20px",height:"20px",background:"green"}} elevation={1} />
                  </Box>
                  <Box sx={{display:"flex", flexDirection:"row"}}>
                    <Box sx={{width:"60px", display:"flex", justifyContent: 'flex-end', mr:1}}>
                    {">100%"}
                    </Box>
                    <Typography variant="body1"><Translate value="ofComplianceGoal"/></Typography>
                  </Box>
                </Box>

                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                  <Box sx={{width:"40px", display: 'flex', justifyContent: 'center'}}>
                    <Paper sx={{width:"20px",height:"20px",background:"orange"}} elevation={1} />
                  </Box>
                  <Box sx={{display:"flex", flexDirection:"row"}}>
                    <Box sx={{width:"60px", display:"flex", justifyContent: 'flex-end', mr:1}}>
                    {">50%"}
                    </Box>
                    <Typography variant="body1"><Translate value="ofComplianceGoal"/></Typography>
                  </Box>
                </Box>

                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                  <Box sx={{width:"40px", display: 'flex', justifyContent: 'center'}}>
                    <Paper sx={{width:"20px",height:"20px",background:"red"}} elevation={1} />
                  </Box>
                  <Box sx={{display:"flex", flexDirection:"row"}}>
                    <Box sx={{width:"60px", display:"flex", justifyContent: 'flex-end', mr:1}}>
                    {"<50%"}
                    </Box>
                    <Typography variant="body1"><Translate value="ofComplianceGoal"/></Typography>
                  </Box>
                </Box>

                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                  <Box sx={{width:"40px", display: 'flex', justifyContent: 'center'}}>
                    <Paper sx={{width:"20px",height:"20px",background:"grey"}} elevation={1} />
                  </Box>
                  <Box sx={{display:"flex", flexDirection:"row"}}>
                    <Box sx={{width:"60px", display:"flex", justifyContent: 'flex-end', mr:1}}>
                    {"0%"}
                    </Box>
                    <Typography variant="body1"><Translate value="ofComplianceGoal"/></Typography>
                  </Box>
                </Box>

              </Box>
            </Box>

            {/* hours */}
            <Box sx={{ display: 'flex', flexDirection: 'column', width:"45%"}}>
              <Box sx={{mb:1}}>
                <Typography variant="body1"><Translate value="ComplianceGoalLegendHours" /></Typography>
              </Box>

              <Box sx={{display: 'flex', flexDirection: 'column'}}>
                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                  <Box sx={{width:"80px", display: 'flex', justifyContent: 'center', mr:2}}>
                    <Typography variant="body1" sx={{fontWeight: 'bold'}}>2 <Translate value="hours" /></Typography>
                  </Box>
                    <Typography variant="body1"><Translate value="2HoursOfVerifiedUse"/></Typography>
                </Box>

                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                  <Box sx={{width:"80px", display: 'flex', justifyContent: 'center', mr:2}}>
                    <Typography variant="body1" sx={{fontWeight: 'bold'}}>? <Translate value="hours"/></Typography>
                  </Box>
                    <Typography variant="body1"><Translate value="HoursOfUseUnverified"/></Typography>
                </Box>

                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                  <Box sx={{width:"80px", display: 'flex', justifyContent: 'center', mr:2}}>
                    <Typography variant="body1">2 <Translate value="hours"/></Typography>
                  </Box>
                    <Typography variant="body1"><Translate value="2HoursOfUnverifiedUse"/></Typography>
                </Box>

                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                  <Box sx={{width:"80px", display: 'flex', justifyContent: 'center', mr:2}}>
                    <Typography variant="body1"><Translate value="*empty*"/></Typography>
                  </Box>
                    <Typography variant="body1"><Translate value="NoData"/></Typography>
                </Box>

              </Box> 
            </Box>
          </Box>
          
        </AccordionDetails>
      </Accordion>
      <PatientLog id={id} /> 
    </PageContainer>
  );
}

