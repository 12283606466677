import { Box } from '@mui/system'
import { IconButton, FormControl, TextField,  MenuItem, FormHelperText } from '@mui/material'
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import FromToPicker from '../FromToPicker';
import { Translate, Localize } from 'react-i18nify';
import React, { useState, useEffect } from 'react'
import { LocationService } from '../../services/DataService';
import { DatePicker } from '@mui/lab';
import InputMask from 'react-input-mask';
import SearchIcon from '@mui/icons-material/Search';
import { ca } from 'date-fns/locale';
import { Form } from 'react-router-dom';
import { set } from 'date-fns';


export default function PatientLookup(props) {

    const [loadingLocation, setloadingLocation] = useState(false);
    const [locations, setLocations] = useState([]);
    const [locationId, setLocationId] = useState();
    const [dateofBirthError, setDateOfBirthError] = useState(false);
    const [o2maticIdError, seto2maticIdError] = useState(false);
    const [dateOfBirthString, setDateOfBirthString] = useState();
    const [to, setTo] = useState(new Date().toString());
    const [active, setActive] = useState(true);

    function convertUTCDateToLocalDate(date) {
        if (date == null || date == undefined)
            return "";

        var newDate = new Date(date.getTime() - date.getTimezoneOffset() * 60 * 1000);
        return newDate;
    }


    useEffect(() => {
        (async () => {
            let apiResult = await LocationService.getAll();
            setLocations(apiResult.data.sort((a, b) => a.name > b.name ? 1 : -1));
        })();
    }, []);

    const handleLocationChange = (event) => {
        setLocationId(event.target.value);
    }

    const activeHandler = (event) => {
        setActive(event.target.checked)
    }

    const handleSubmit = async (event) => {
        var data = event.target;

        event.preventDefault();

        var query = {}
        if (locationId) {
            query.locationId = locationId;
        }
        if (data?.o2maticId?.value) {
            if(!isNaN(data?.o2maticId?.value)){
                query._id = data?.o2maticId?.value;
                seto2maticIdError(false);
            }else{
                seto2maticIdError(true);
            }
        }
        if (active) {
            //query.active = active;
        }
        if (data?.name?.value) {
            query.name = data?.name?.value;
        }
        if (data?.identifierValue?.value) {
            query.identifier = data?.identifierValue?.value;
        }
        if (dateOfBirthString) {
            const dateOfBirth = new Date(dateOfBirthString);
            try{
                query.birthdate = dateOfBirth.toISOString().split('T')[0];
                setDateOfBirthError(false);
            }catch(RangeError){
                setDateOfBirthError(true);
            }
        } 
        props.handleSearchQuery(query);
    }

    const inputFieldsWidths = {
        o2maticId: "10%",
        identifierValue: "12%",
        name: "15%",
        dateOfBirth: "12%",
        location: "30%"
    }
    
    return (
        <>
        <Box sx={{ display: "flex", flexDirection: "column" }}
            component="form" onSubmit={handleSubmit}
        >
            <Box variant="form" sx={{ mt: 2, display: "flex", flexDirection: "column"}}>
                <Box sx={{ display: "flex", flexDirection: "row", justifyContent: 'space-between'}}>
                    <Box sx={{width: inputFieldsWidths.o2maticId, color:"#9F9F9F"}}><Translate value="O2maticId" /></Box>
                    <Box sx={{width: inputFieldsWidths.identifierValue, color:"#9F9F9F"}}><Translate value="IdentifierValue"/></Box>
                    <Box sx={{width: inputFieldsWidths.name, color:"#9F9F9F"}}><Translate value="Name" /></Box>
                    <Box sx={{width: inputFieldsWidths.dateOfBirth, color:"#9F9F9F"}}><Translate value="DateOfBirth"/><br/>YYYY-MM-DD</Box>
                    <Box sx={{width: inputFieldsWidths.location, color:"#9F9F9F"}}><Translate value="Location"/></Box>
                    <Box sx={{width:"40px", color:"#9F9F9F"}}></Box>

                </Box>
                <FormControl variant="standard" sx={{ display: "flex" , flexDirection: "row", justifyContent: 'space-between', alignItems: 'flex-start'}}>                   
                    <TextField
                        id="o2maticId"
                        defaultValue=""
                        sx={{ width: inputFieldsWidths.o2maticId}}
                        variant="standard"
                        error={o2maticIdError}
                        helperText={o2maticIdError ? <FormHelperText><Translate value="InvalidValue"/></FormHelperText> : null}
                        onChange={() => seto2maticIdError(false)}
                        />  
                    <TextField
                        sx={{ width: inputFieldsWidths.identifierValue }}
                        id="identifierValue"
                        defaultValue=""
                        variant="standard"/>
                    <TextField
                        sx={{ width: inputFieldsWidths.name}}
                        id="name"
                        defaultValue=""
                        variant="standard" />
                    <InputMask 
                        sx={{ width: inputFieldsWidths.dateOfBirth}}
                        mask="9999-99-99"
                        maskChar={null}
                        onChange={(e) => {
                            setDateOfBirthString(e.target.value)
                            setDateOfBirthError(false);
                        }}
                        >
                        {() => 
                            <TextField 
                            sx={{width: inputFieldsWidths.dateOfBirth}}
                            variant="standard" 
                            error={dateofBirthError}
                            helperText={dateofBirthError ? <FormHelperText><Translate value="InvalidDate"/></FormHelperText> : null}
                            />
                        }
                    </InputMask>
                    <TextField
                    sx={{ width: inputFieldsWidths.location}}
                    select
                    value={locationId}
                    variant="standard"
                    onChange={handleLocationChange}
                    >
                        <MenuItem value={""}><Translate value="None"/></MenuItem>
                        {locations.map(location => {
                            return <MenuItem key={location.id} value={location.id}>{location.name}</MenuItem>
                        })}
                    </TextField>
                    <Box sx={{backgroundColor:"primary.main", width:40, borderRadius:3}}>
                    <IconButton
                    type="submit"
                    variant="contained">
                    <SearchIcon sx={{color:"#FFFFFF"}}/>
                    </IconButton>
                    </Box>
                    
                </FormControl>
            </Box>
        </Box>
        <br></br>
        </>
    )
}
