
import { Box, Container } from '@mui/material';
const PageContainer = (props) => {
    return <Box
        component="main"
        sx={{
            flexGrow: 1,
            mt: {
                xs: 8,
                md: 10
            },
            pl: { sm: 0, md: 0, lg: 4 },
            pr: { sm: 0, md: 0, lg: 4 },
            pb: 4
        }}
    >
        <Container maxWidth={false} sx={{
            width: "100%", display: "flex",
            flexDirection: "column",
            alignItems: { xs: "center", sm: "flex-start" }
        }}>
            {props.children}
        </Container>

    </Box>
}
export default PageContainer;