import { useState, useEffect } from "react";
import { Typography } from "@mui/material"
import Chart from "react-apexcharts";
import ApexCharts from 'apexcharts'
import TimeUtils from "../../utils/TimeUtils";
import { translate } from "react-i18nify";


const SessionsOverviewChart = (props) => {
    const [chartData, setChartData] = useState([]);
    var spo2axisMin = 80;
    var spo2axisMax = 100;
    var xaxisMin = Date.parse(TimeUtils.getBeginningOfDay(props.sessionsOverviewFrom));
    var xaxisMax = Date.parse(TimeUtils.getEndingOfDay(props.sessionsOverviewTo));

    const generateChartData = (data) => {
        try {
            let spo2Data = [];
            let spo2StdData = [];
            let spo2StdUData = [];
            let spo2StdLData = [];
            let o2Data = [];
            let spo2rangeMax = [];
            let spo2rangeMin = [];
            let treatmentIdChange = [];

            // add null data to the start
            let spo2Sample = [xaxisMin, null];
            let spo2StdSample = [xaxisMin, null];
            let spo2StdUSample = [xaxisMin, null];
            let spo2StdLSample = [xaxisMin, null];
            let o2Sample = [xaxisMin, null];
            let spo2rangeMaxSample = [xaxisMin, null]
            let spo2rangeMinSample = [xaxisMin, null]
            let treatmentIdChangeSample = [xaxisMin, null]

            spo2Data.push(spo2Sample);
            spo2StdData.push(spo2StdSample);
            spo2StdUData.push(spo2StdUSample);
            spo2StdLData.push(spo2StdLSample);
            o2Data.push(o2Sample);
            spo2rangeMax.push(spo2rangeMaxSample);
            spo2rangeMin.push(spo2rangeMinSample);
            treatmentIdChange.push(treatmentIdChangeSample);
            
            data.forEach((dataSamples,index) => {
                
               let prevIndex = index-1;
               if (prevIndex < 0) {
                prevIndex = 0;
               }
               let nextIndex = index+1;
               if (nextIndex > data.length-1) {
                nextIndex = data.length-1;
               }
               
                let sessionStartTime = Date.parse(TimeUtils.utcToLocal(dataSamples.parts.analyzing.timestamp));

                // set default if treatment doesnt exist for session
                let treatmentIDdiff = null;
                let spo2Min = null;
                let spo2Max = null;

                if (dataSamples.treatment?.id !== null) {
                    var treatmentName = dataSamples.treatment?.name;
                    var treatmentIDcurrent = dataSamples.treatment?.id; // treatment ID of current session
                    if (data[nextIndex].treatment?.id !== null) {
                        var treatmentIDprev = data[nextIndex].treatment?.id; // treatment ID of previous session NOTE: if .slice().reverse() is removed from the TreatmentID array further down, then "nextIndex" must be changed to "prevIndex"
                        treatmentIDdiff = treatmentIDcurrent - treatmentIDprev; // difference in treatment ID value between current and previous

                        if (treatmentIDdiff != 0) {
                            treatmentIDdiff = (treatmentIDcurrent/1000)+100; // if there is a difference, set value equal to 100 (to get vertical line in graph to go up to 100 on the y-axis) + 0.treatmentID (id as decimals)
                        } else {
                            treatmentIDdiff = (treatmentIDcurrent/1000) // or set equal to null to not display anything on the graph (set equal to 0.treatmentID (id as decimals))
                        }

                    } else {
                        treatmentIDdiff = (treatmentIDcurrent/1000)
                    }

                    spo2Min = dataSamples.treatment?.values[7].value;
                    spo2Max = dataSamples.treatment?.values[3].value;
                }


                var adjusting = dataSamples.parts.adjusting.values;
                
                let spo2Sample = [sessionStartTime, adjusting.SpO2Average];
                let spo2StdSample = [sessionStartTime, adjusting.SpO2StandardDeviation];
                let spo2StdUSample = [sessionStartTime, Number.parseFloat(adjusting.SpO2Average)+Number.parseFloat(adjusting.SpO2StandardDeviation)];
                let spo2StdLSample = [sessionStartTime, Number.parseFloat(adjusting.SpO2Average)-Number.parseFloat(adjusting.SpO2StandardDeviation)];
                
                //let spo2StdSample = [sessionStartTime, [Number.parseFloat(adjusting.SpO2Average)-Number.parseFloat(adjusting.SpO2StandardDeviation), Number.parseFloat(adjusting.SpO2Average)+Number.parseFloat(adjusting.SpO2StandardDeviation)]];
                let o2Sample = [sessionStartTime, dataSamples.parts.end.values.OxygenTarget];

                let spo2rangeMinSample = [sessionStartTime, spo2Min]
                let spo2rangeMaxSample = [sessionStartTime, spo2Max]

                let treatmentIdChangeSample = [sessionStartTime, treatmentIDdiff]
                let treatmentIdChangeSample2 = [sessionStartTime, 0]

                spo2Data.push(spo2Sample);
                spo2StdData.push(spo2StdSample);
                spo2StdUData.push(spo2StdUSample);
                spo2StdLData.push(spo2StdLSample);
                o2Data.push(o2Sample);
                spo2rangeMax.push(spo2rangeMaxSample);
                spo2rangeMin.push(spo2rangeMinSample);
                treatmentIdChange.push(treatmentIdChangeSample);


            });

            // add null data to the end
            let spo2SampleEnd = [xaxisMax, null];
            let spo2StdSampleEnd = [xaxisMax, null];
            let spo2StdUSampleEnd = [xaxisMax, null];
            let spo2StdLSampleEnd = [xaxisMax, null];
            let o2SampleEnd = [xaxisMax, null];
            let spo2rangeMaxSampleEnd = [xaxisMax, null]
            let spo2rangeMinSampleEnd = [xaxisMax, null]
            let treatmentIdChangeSampleEnd = [xaxisMax, null]

            spo2Data.push(spo2SampleEnd);
            spo2StdData.push(spo2StdSampleEnd);
            spo2StdUData.push(spo2StdUSampleEnd);
            spo2StdLData.push(spo2StdLSampleEnd);
            o2Data.push(o2SampleEnd);
            spo2rangeMax.push(spo2rangeMaxSampleEnd);
            spo2rangeMin.push(spo2rangeMinSampleEnd);
            treatmentIdChange.push(treatmentIdChangeSampleEnd);
            
            
            let result = [
                // .slice().reverse() is added to ALL series to ensure that they have the same x-axis and therefore can be displayed in the same tooltip.
                // .slice().reverse() flips the data, enabeling the stepline to be keeping the value until a new one is provided. (up/down)
                {
                    type: 'scatter',
                    name: translate("SpO2InAdjustingPhase"), data: spo2Data.slice().reverse()
                },
                {
                    type: "area",
                    name: translate("SpO2StandardDeviation"), data: spo2StdData.slice().reverse()
                },
                {
                    type: "line",
                    name: translate("TargetO2FlowAtEndOfSession"), data: o2Data.slice().reverse()
                },
                {
                    type: "scatter",
                    name: translate("TreatmentId"), data: treatmentIdChange.slice().reverse() // of this is removed, then make sure to change code from "nextIndex" to "prevIndex" to insure that treatment change is not shifted by 1 index.
                },
                {
                    type: "line",
                    name: translate("SpO2RangeMax"), data: spo2rangeMax.slice().reverse()
                },
                {
                    type: "line",
                    name: translate("SpO2RangeMin"), data: spo2rangeMin.slice().reverse()
                },
                {
                    type: "area",
                    name: translate("SpO2StandardDeviation"), data: spo2StdUData.slice().reverse()
                },
                {
                    type: "area",
                    name: translate("SpO2StandardDeviation"), data: spo2StdLData.slice().reverse()
                },
            ];

            return result;
        }
        catch (err) {
            return [];
        };
    };
    useEffect(() => {
        let isCancelled = false;
        if (!isCancelled) {
            /* let index = props.selectedSession?.length+2+1-props.selectedSession
            let chartDataResult = generateChartData(index); */
            let chartDataResult = generateChartData(props.selectedSession);
            setChartData(chartDataResult);
        }

        return () => {
            isCancelled = true;
        };

    }, [props.selectedSession])

    /* const getCurrentChartImage = () => {
        return new Promise((resolve) => {
            try {
                if (chartData == null) {
                    resolve("data");
                }
                ApexCharts.exec("preview", "updateOptions", {
                    xaxis: {
                        labels: {
                            show: false
                        }
                    }
                }, false, true).then(() => {
                    ApexCharts.exec("preview", "dataURI", { width: "1500" }).then(({ imgURI, blob }) => {
                        resolve(imgURI);
                    });
                }).catch(err => {
                    resolve("data");
                });
            } catch (error) {

            }

        });

    } */

    const options = {
        chart: {
            id: "preview",
            type: 'line',
            animations: {
                speed: 1800,
                enabled: false

            /* },
            events: {
                dataPointSelection: (event, chartContext, config) => {
                  props.setSessionClicked(props.selectedSession[config.dataPointIndex])
                  console.log(config.dataPointIndex)
                  console.log(props.selectedSession[config.dataPointIndex])
                } */
            },
            events: {
                dataPointSelection: (event, chartContext, config) => {
                    props.setSessionClicked(props.selectedSession[props.selectedSession?.length-config.dataPointIndex])
                    }
                },
        

            toolbar: {
                show: true,
                offsetX: 0,
                offsetY: 0,
                tools: {
                    download: false,
                }
            }
        },

        colors: ["#009de0","#7fceef","#009933","#E69138","#99001a","#99001a","#7fceef","#ffffff"],
        fill: {
            opacity: [1, 0.5, 1, 1, 1, 1, 0.5, 1]
          },
        noData: {
            text: "Loading"
        },
        grid: {
            show: false
        },
        legend: {
            show: true,
            showForSingleSeries: false,
            showForNullSeries: true,
            showForZeroSeries: true,
            position: 'bottom',
            horizontalAlign: 'left',
            floating: false,
            fontSize: '14px',
            fontFamily: 'Helvetica, Arial',
            fontWeight: 400,
            inverseOrder: false,
            offsetX: 0,
            offsetY: 0,
            itemMargin: {
                horizontal: 15,
                vertical: 10
            },
            customLegendItems: [translate("SpO2Average"),translate("SpO2Std"),translate("TargetO2FlowAtEndOfSession"),translate("TreatmentChanged"),translate("SpO2TargetRange")],
        },
        plotOptions: {

           /*  bar: {
                columnWidth: "100%"
            },

            boxPlot: {
                colors: {
                    upper: '#10bdff',
                    lower: '#10bdff'
                }
            } */
        },
        tooltip: {
            shared: true,
            followCursor: false,
            y: [
                {
                    formatter: function (y) {
                        if(y){
                            return y + " %";
                        }
                    }
                },
                {
                    formatter: function (y) {
                        if(y){
                            return y + " %";
                        }
                    }
                },
                {
                    formatter: function (y) {
                        if(y){
                            return y?.toFixed(1) + " " + translate("l/min");
                        }
                    }
                },
                {
                    formatter: function (y) {
                        if(y){
                            if (y > 100) {
                                return ((y-100)*1000).toFixed(0); //(treatmentIDcurrent/1000)+100;

                        } else if (y == null) {

                           
                        } else {
                                return (y*1000).toFixed(0);
                            }
                        }
                        }
                },
                {
                    formatter: function (y) {

                        if (y == null) {

                        } else {
                            return y + " %";
                        }
                    }
                },
                {
                    formatter: function (y) {

                        if (y == null) {

                        } else {
                            return y + " %";
                    }

}
                },
                {
                    formatter: function (y) {
                        
                    }
                },
                {
                    formatter: function (y) {
                        
                    }
                },
            ],
            x: {
                format: "yyyy-MM-dd HH:mm"
            },
        },
        xaxis: {
            tooltip: {
                enabled: false,
            },
            type: 'datetime',
            labels: {
                //format: 'yyyy-MM-dd',
                datetimeUTC: false,
                //offsetX: 60,
                datetimeFormatter: {
                    year: '',
                    month: "MMM-dd",
                    day: 'MMM-dd',
                    hour: 'HH:mm',
                },
            },

        },
        stroke: {
            width: [0,0,2,0,2,2,0,0],
            curve: ['stepline','straight','stepline','stepline','stepline','stepline','straight','straight']
        },
        markers: {
            size: [4,0,4,5,0,0,0,0],
            strokeWidth: 0,
            showNullDataPoints: false,
            shape: "circle",
        },
        yaxis: [
            {
                seriesName: "SpO2",
                title: {
                    text: (translate("SpO2") + " [%]"),
                    rotate: -90,
                    offsetX: 0,
                    offsetY: 0,
                    style: {
                        color: '#009de0',
                        fontSize: '12px',
                        fontFamily: 'Helvetica, Arial, sans-serif',
                        fontWeight: 600,
                        cssClass: 'apexcharts-yaxis-title',
                    },
                },
                tooltip: {
                    enabled: false,
                    x: {
                        show: false
                    }

                },
                crosshairs: {
                    show: false
                },
                showAlways: true,
                min: spo2axisMin,
                max: spo2axisMax,
                forceNiceScale: false,
                labels: {
                    show: true,
                    align: 'center',
                    style: {
                        colors: ['#009de0'],
                        fontSize: '12px',
                        fontFamily: 'Helvetica, Arial, sans-serif',
                        fontWeight: 400
                    },
                    formatter: (value) => {
                        return value.toFixed(0)
                    },
                },
                
            },
            {
                seriesName: "SpO2StdHidden",
                title: {
                    text: "",
                    rotate: 0,
                    offsetX: 0,
                    offsetY: 0,
                    style: {
                        color: '#009de0',
                        fontSize: '12px',
                        fontFamily: 'Helvetica, Arial, sans-serif',
                        fontWeight: 600,
                        cssClass: 'apexcharts-yaxis-title',
                    },
                },
                tooltip: {
                    enabled: false,
                },
                crosshairs: {
                    show: false
                },
                floating: true,
                showAlways: true,
                min: 0,
                max: spo2axisMax,
                forceNiceScale: false,
                labels: {
                    show: false,
                    align: 'center',
                    style: {
                        colors: ['#009de0'],
                        fontSize: '12px',
                        fontFamily: 'Helvetica, Arial, sans-serif',
                        fontWeight: 400
                    }
                }
            },
            {
                seriesName: "O2",
                opposite: true,
                title: {
                    text: (translate("O2Flow") + " [" + translate("l/min") + "]"),
                    rotate: 90,
                    offsetX: 0,
                    offsetY: 0,
                    style: {
                        color: "#009933",
                        fontSize: '12px',
                        fontFamily: 'Helvetica, Arial, sans-serif',
                        fontWeight: 600,
                        cssClass: 'apexcharts-yaxis-title',
                    },
                },
                tooltip: {
                    enabled: false,
                    offsetX: 0,
                },

                showAlways: true,
                min: 0,
                max: 15,
                forceNiceScale: false,
                tickAmount: 15,
                labels: {
                    show: true,
                    align: 'center',
                    style: {
                        colors: ['#009933'],
                        fontSize: '12px',
                        fontFamily: 'Helvetica, Arial, sans-serif',
                        fontWeight: 400
                    },
                    formatter: (value) => {
                        return value.toFixed(0)
                    },
                }
            },
            {
                seriesName: "TreatmentId",
                title: {
                    text: "",
                    rotate: 0,
                    offsetX: 0,
                    offsetY: 0,
                    style: {
                        color: '#009de0',
                        fontSize: '12px',
                        fontFamily: 'Helvetica, Arial, sans-serif',
                        fontWeight: 600,
                        cssClass: 'apexcharts-yaxis-title',
                    },
                },
                tooltip: {
                    enabled: false,

                },
                crosshairs: {
                    show: false
                },
                floating: true,
                showAlways: true,
                min: spo2axisMin,
                max: spo2axisMax,
                forceNiceScale: false,
                labels: {
                    show: false,
                    align: 'center',
                    style: {
                        colors: ['#009de0'],
                        fontSize: '12px',
                        fontFamily: 'Helvetica, Arial, sans-serif',
                        fontWeight: 400
                    }
                },
            },
            {
                seriesName: "SpO2Max",
                title: {
                    text: "",
                    rotate: 0,
                    offsetX: 0,
                    offsetY: 0,
                    style: {
                        color: '#009de0',
                        fontSize: '12px',
                        fontFamily: 'Helvetica, Arial, sans-serif',
                        fontWeight: 600,
                        cssClass: 'apexcharts-yaxis-title',
                    },
                },
                tooltip: {
                    enabled: false,
                },
                crosshairs: {
                    show: false
                },
                floating: true,
                showAlways: true,
                min: spo2axisMin,
                max: spo2axisMax,
                forceNiceScale: false,
                labels: {
                    show: false,
                    align: 'center',
                    style: {
                        colors: ['#009de0'],
                        fontSize: '12px',
                        fontFamily: 'Helvetica, Arial, sans-serif',
                        fontWeight: 400
                    }
                }
            },
            {
                seriesName: "SpO2Min",
                title: {
                    text: "",
                    rotate: 0,
                    offsetX: 0,
                    offsetY: 0,
                    style: {
                        color: '#009de0',
                        fontSize: '12px',
                        fontFamily: 'Helvetica, Arial, sans-serif',
                        fontWeight: 600,
                        cssClass: 'apexcharts-yaxis-title',
                    },
                },
                tooltip: {
                    enabled: false,

                },
                crosshairs: {
                    show: false
                },
                floating: true,
                showAlways: true,
                min: spo2axisMin,
                max: spo2axisMax,
                forceNiceScale: false,
                labels: {
                    show: false,
                    align: 'center',
                    style: {
                        colors: ['#009de0'],
                        fontSize: '12px',
                        fontFamily: 'Helvetica, Arial, sans-serif',
                        fontWeight: 400
                    }
                },
            },
            {
                seriesName: "SpO2Std",
                title: {
                    text: "",
                    rotate: 0,
                    offsetX: 0,
                    offsetY: 0,
                    style: {
                        color: '#009de0',
                        fontSize: '12px',
                        fontFamily: 'Helvetica, Arial, sans-serif',
                        fontWeight: 600,
                        cssClass: 'apexcharts-yaxis-title',
                    },
                },
                tooltip: {
                    enabled: false,
                },
                crosshairs: {
                    show: false
                },
                floating: true,
                showAlways: true,
                min: spo2axisMin,
                max: spo2axisMax,
                forceNiceScale: false,
                labels: {
                    show: false,
                    align: 'center',
                    style: {
                        colors: ['#009de0'],
                        fontSize: '12px',
                        fontFamily: 'Helvetica, Arial, sans-serif',
                        fontWeight: 400
                    }
                }
            },
            {
                seriesName: "SpO2Std",
                title: {
                    text: "",
                    rotate: 0,
                    offsetX: 0,
                    offsetY: 0,
                    style: {
                        color: '#009de0',
                        fontSize: '12px',
                        fontFamily: 'Helvetica, Arial, sans-serif',
                        fontWeight: 600,
                        cssClass: 'apexcharts-yaxis-title',
                    },
                },
                tooltip: {
                    enabled: false,
                },
                crosshairs: {
                    show: false
                },
                floating: true,
                showAlways: true,
                min: spo2axisMin,
                max: spo2axisMax,
                forceNiceScale: false,
                labels: {
                    show: false,
                    align: 'center',
                    style: {
                        colors: ['#009de0'],
                        fontSize: '12px',
                        fontFamily: 'Helvetica, Arial, sans-serif',
                        fontWeight: 400
                    }
                }
            },
            
            
            
        ],
        events: {
            
        }

    };

    return (

        <div style={{ flexDirection: 'column', width: '100%' }}>
        
        
            <div className="chart" style={{ width: '100%', float: 'none', overflow: "hidden" }}>
                <Chart id="chartData" options={options} type="line" series={chartData} width="100%" height={props.height} />
            </div>
        </div>
    );

}
export default SessionsOverviewChart;