import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import PageContainer from "../../components/PageContainer";
import { useParams, Navigate } from "react-router-dom";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { Delete as DeleteIcon } from "@mui/icons-material";
import { useState, useEffect } from "react";
import { LicenseService, ConfigurationService } from "../../services/DataService";
import { QRCodeSVG } from "qrcode.react"
import TimeUtils from "../../utils/TimeUtils";
import { PageDetailText } from "../../components/PageDetailText";
import { PageHeader } from "../../components/Layout/PageHeader";

import { ConfirmDialog } from "../../components/Layout/Dialog/ConfirmDialog";
import { Translate, translate, Localize } from 'react-i18nify';
import AlertBox from "../../components/AlertBox";
import PatientDashboardCategory from "../../components/Patient/PatientDashboardCategory";
import { PatientDetailText } from "../../components/Patient/PatientDetailText";
import {UpdatesBanner} from "../../components/UpdatesBanner";

const LicenseDetail = () => {
    const [license, setLicense] = useState();
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [showRevokeDialog, setShowRevokeDialog] = useState(false);
    const [redirect, setRedirect] = useState(false);
    const [confirmStatus, setConfirmStatus] = useState({status: 0, body: null});

    const { id } = useParams();

    const [alertBox, setAlertBox] = useState({
        "visiblity": false,
        "statusCode": null,
        "text": null,
        "error": null
    }
    );


    const handleAlertBox = (visiblity, statusCode, text, error) => {
        setAlertBox({
            "visiblity": visiblity,
            "statusCode": statusCode,
            "text": text,
            "error": error
        }
        )
    }
    const dismissAlertBox=()=>{
        setAlertBox({
            "visiblity": false,
            "statusCode": null,
            "text": null,
            "error": null

        })
    }

    useEffect(() => {
        (async () => {

            let apiResult = await LicenseService.get(id);
            let license=apiResult.data;
            let configurationResult=await ConfigurationService.get();
            license.configuration=configurationResult.data;
            setLicense(license);
        })();
        return () => {
            setLicense();
        };
    }, [])



    const handleEdit = () => {
    };
    const handleDelete = () => {
        setShowDeleteDialog(true);
    };
    const handleRevoke = () => {
        setShowRevokeDialog(true);
    };
    const closeRevokeDialog = async () => {
        setShowRevokeDialog(false);
    }
    const closeDeleteDialog = async () => {
        setShowDeleteDialog(false);
    }
    const revokeLicense = async () => {
        LicenseService.delete(id)
            .then(success => {
                setRedirect(true);

            }, err => {
                console.log(err);
            })
    }
    const deleteLicense = async () => {
        setConfirmStatus({status: 1, body: null});
        LicenseService.delete(id)
            .then(success => {
                setConfirmStatus({status: 2, body: success});
                // handleAlertBox(true, success.status, success.statusText, null) 
                setTimeout(()=>{
                    setRedirect(true);
                },2000)


            }, err => {
                setConfirmStatus({status: 3, body: err.response});
                // console.log(err);
                // handleAlertBox(true, err?.response?.status, null, err?.response?.data?.join(",")) 
            })
    }
    const renderRoles = (roles) => {
        if (roles != null) {
            return (roles.map(role => {
                return (
                <PatientDetailText
                    value={translate(role.name)}
                ></PatientDetailText>
                )
            }))
        }
    }

    const renderRolesHeader = (roles) => {
        if (roles != null) {
            return <Typography variant="body1" sx={{mt:2, mb:-3}}>
                {<Translate value="Roles" />}
            </Typography>
            
        }
    }
    

    const renderQRCode=(data)=>{
        data.created=null;
        let QRData={"license":data}
    
        return(
        <QRCodeSVG
        width="300px"
        height="300px"
        value={JSON.stringify(QRData)}
    />)
    }
    const renderLicense = (license) => {
        if (license == undefined)
            return <Typography><Translate value="Loading" /></Typography>
        console.log(license)

        return <Box sx={{ width: "100%" }}>
            <Box sx={{ width: { xs: "100%", sm: "100%", md: "800px", lg: "800px" }, mt: 1, borderRadius: "15px", border: 1, borderColor: "primary.main", bgcolor: "primary.main", color: "primary.contrastText", p: 2, display: "flex", justifyContent: 'space-between', flexDirection: "column"}}>
                <Typography variant="body">
                    {<Translate value="AppLicenseName" />}
                </Typography>
                <PatientDashboardCategory // patient name
                    title={license.name}
                >
                </PatientDashboardCategory>

                <Box sx={{mt: 2}}>
                    <PatientDetailText
                        label={<Translate value="Id" />}
                        value={license.id}
                    ></PatientDetailText>
                    <PatientDetailText
                        label={<Translate value="AppLicenseName" />}
                        value={license.name}
                    ></PatientDetailText>
                    <PatientDetailText
                        label={<Translate value="Created" />}
                        value={TimeUtils.utcToLocal(license.created)}
                    ></PatientDetailText>
                    {/* <PatientDetailText
                        label={<Translate value="ClientId" />}
                        value={license.clientId}
                    ></PatientDetailText>
                    <PatientDetailText
                        label={<Translate value="ClientSecret" />}
                        value={license.clientSecret}
                    ></PatientDetailText> */}
                    <PatientDetailText
                        label={<Translate value="Location" />}
                        value={license.location.name}
                    ></PatientDetailText>
                </Box>

                {renderRolesHeader(license.roles)}
                {renderRoles(license.roles)}
                
            {/* <PageDetailText
                label={<Translate value="Id" />}
                value={license.id}
            />
            <PageDetailText
                label={<Translate value="AppLicenseName" />}
                value={license.name}
            />
            <PageDetailText
                label={<Translate value="Location" />}
                value={license.location.name}
            />
            <PageDetailText
                label={<Translate value="ClientId" />}
                value={license.clientId}
            />
            <PageDetailText
                label={<Translate value="ClientSecret" />}
                value={license.clientSecret}
            />
            <PageDetailText
                label={<Translate value="Created" />}
                value={license.created}
            /> */}
            </Box>
            
            <Typography variant="h6" sx={{mt:2}}>
                {<Translate value="QrCode" />}
            </Typography>
            {renderQRCode(license)}
            <Box sx={{ display: 'flex', justifyContent: { xs: 'flex-start', md: 'flex-start' } }}>

                <Button onClick={handleDelete} sx={{ mt: 2 }} color="error" variant="contained" startIcon={<DeleteIcon />}>
                <Translate value="Delete" />
                </Button>
                
                
            </Box>
        </Box>

    }


    return (
        <>
            {redirect ? <Navigate to='/license' /> : null}

            <PageContainer>
                <PageHeader leftAlign><Translate value="AppLicenseDetails" /></PageHeader>

                <UpdatesBanner update_description="UpdatesDescriptionAppLicensePage"/>

                {renderLicense(license)}
                <AlertBox visible={alertBox.visiblity} text={alertBox.text} error={alertBox.error} statusCode={alertBox.statusCode} onHide={dismissAlertBox}/>
            </PageContainer>
            <ConfirmDialog
                dialogIcon={<ErrorOutlineIcon color="error" sx={{ fontSize: 75 }} />}
                title={<Translate value="PleaseConfirm" />}
                message={<Translate value="DeleteAppLicenseText" />}
                declineText={<Translate value="Close" />}
                confirmText={<Translate value="Delete" />}
                confirmButtonIcon={<DeleteIcon />}
                open={showDeleteDialog}
                onClose={closeDeleteDialog}
                onConfirm={deleteLicense}
                confirmStatus={confirmStatus}
            >
            </ConfirmDialog>
            
        </>
    );
};

export default LicenseDetail;
