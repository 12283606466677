import { Navigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { isAuthenticated,isAuthenticatedTwo, isExpired, loginExpired } from "../redux/actions/auth";
import { useEffect, useState } from "react";
import jwt_decode from 'jwt-decode'

export const RequireRole = ({ children, redirectTo, authorize }) => {

    const { user } = useSelector((state) => state.auth);
    const  roles  = useSelector((state) => state?.auth?.roles);
    const roleData =roles?.data;
    let result=false;
    var isExpiredResult = true;

    const dispatch = useDispatch();

    var res=isAuthenticatedTwo(authorize,roleData);
    result=res;

    if (user == null || user.accessToken == null) {
        return null;
    }

    var encodedToken = user.accessToken;
    var token = jwt_decode(encodedToken);
    isExpiredResult = isExpired(token);
    if (isExpiredResult) {
        return null;
    }

    return (
        <>
            {result ? children : null}
        </>
    )
}
