import React, { useState, useEffect, Component } from 'react'
import ReactDOM from 'react-dom'
import { render } from 'react-dom';
import { createPortal } from "react-dom";
import { Box } from '@mui/system'
import { Typography, Button, FormControl, TextField, InputLabel, Select, MenuItem, FormGroup, FormControlLabel, Switch } from '@mui/material'
import { Translate, Localize, translate } from 'react-i18nify';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';



const AlertBox = (props) => {
    let statusCode = props.statusCode
    let renderSuccess = (text) => {
        return (
            <Alert sx={{alignSelf:"start",m:1, ml:0}} variant="filled" severity="success">
                {text ? text : translate("Success")}
            </Alert>
        )
    }

    let renderWarning = (text, error) => {
        return (<Alert sx={{alignSelf:"start",m:1, ml:0}} variant="filled" severity="warning">
            {text}
            {text && error ? " - " : ""}
            {error ? error : ""}
        </Alert>)
    }
    let renderInfo = (text) => {
        return (<Alert sx={{alignSelf:"start",m:1, ml:0}} variant="filled" severity="info">
            {text}
        </Alert>)
    }
    let renderError = (text, error) => {
        return (<Alert sx={{alignSelf:"start",m:1, ml:0}} variant="filled" severity="error">
            {text}
            {text && error ? " - " : ""}
            {error ? error : ""}
        </Alert>)
    }
    if (props.visible) {
        setTimeout(()=>{
            props.onHide()
        },props.duration?props.duration:15000)
        switch (statusCode) {
            case 50: 
                return renderWarning(props.text ? props.text : translate("IntenalServerError"), props.error)
            case 400:
                return renderError(props.text ? props.text : translate("BadRequest"), props.error)
            case 401:
                return renderError(props.text ? props.text : translate("Unauthorized"), props.error)
            case 403:
                return renderError(props.text ? props.text : translate("Forbidden"), props.error)
            case 404:
                return renderError(props.text ? props.text : translate("NotFound"), props.error)
            case 422:
                return renderError(props.text ? props.text : translate("Duplicate"), props.error)
            default:{
                if(statusCode >= 400 && statusCode <= 499){
                    return renderError(props.text ? props.text : translate("BadRequest"), props.error)
                }
                else if(statusCode >= 500 && statusCode <= 599){
                    return renderError(props.text ? props.text : translate("IntenalServerError"), props.error)
                }
                else if(statusCode >= 200 && statusCode <= 299){
                    return renderSuccess(props.text)
                }
                return renderError(props.text ? props.text : translate("UnknownError"), props.error)
            }

        }
    }
    else{
        return(null)
    }
}
export default AlertBox