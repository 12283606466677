import { Typography } from "@mui/material/"
import {Link} from "@mui/material/";

export const PageDetailText = (props) => {
	return (
		<Typography
			sx={{
				mb: 0,
				mt: 2,
				fontSize: 20,
				alignItems: {
					xs: "flex-start",
					md: "flex-start",
				},
			}}
			component="h1"
			variant="body2"
		>
			{props.label} : {props.value}  {props.type?props.type:""}
		</Typography>
	);
}

export const PageDetailTextClickable = (props) => {
	return (
		<Typography
			sx={{
				mb: 0,
				mt: 2,
				fontSize: 20,
				alignItems: {
					xs: "flex-start",
					md: "flex-start",
				},
			}}
			component="h1"
			variant="body2"
		>
			{props.label} : <Link href={props.url}>{props.value}  {props.type?props.type:""}</Link>
		</Typography>
	);
}