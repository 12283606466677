import React, { useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom';
import PageContainer from '../../components/PageContainer';
import { PageHeader } from '../../components/Layout/PageHeader';
import { Box } from '@mui/system';
import { Paper, Typography, Divider } from '@mui/material';
import { Translate , translate} from 'react-i18nify';

const CalendarElement = (props) => {
    let color = props.data.complaienceColor != undefined ? props.data.complaienceColor : "gray"
    return (
        <Box sx={{ cursor:props.data.isActive?"pointer":"default", aspectRatio: "1/1", background: "gainsboro", width: "100%", height: "100%" }} onClick={()=>props.data.isActive?props.onClick(props.data.date):null}>
            <Box sx={{ flexDirection: "column", display: "flex", flex: "1 1 auto", width: "100%", height: "100%" }}>
                <Box sx={{p:1,display:"flex", alignItems:"center", justifyContent:"space-between"}}>
                    <Typography sx={{  }} variant="h5">{props.data.date.getDate()+"/"+ (props.data.date.getMonth() + 1)}</Typography>
                    <Paper sx={{width:"20px",height:"20px",background:color}} elevation={1} />
                </Box>
                <Divider sx={{bgcolor: "gray"}}/>
                <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", flexGrow: "inherit" }}>
                    <Typography sx={{ textAlign: "center",fontWeight:props.data.isActive?"bold":"none" }}>{props.data.unknown&&props.data.secondsUsed==0&&!props.data.isActive?null:props.data.secondsUsed==0?"? hours":(props.data.secondsUsed / 60 / 60).toFixed(1).toString()+" " +translate("hours")}</Typography>
                </Box>

            </Box>

        </Box>
    )
}
export default CalendarElement