import React, { useState, useEffect } from 'react'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { Dialog, Typography, Box, Button } from "@mui/material";
import { Translate, Localize, translate } from 'react-i18nify';
import DescriptionIcon from '@mui/icons-material/Description';
import { Divider } from '@material-ui/core';
import { PageDetailText, PageDetailTextClickable } from './PageDetailText';
import { TextareaAutosize } from '@material-ui/core';
import TimeUtils from '../utils/TimeUtils';
export const SystemDetailPopUp = (props) => {
    const systemlog = props.systemlog
    return (
        <Dialog
            fullWidth
            sx={{
                display: "flex",
                justifyContent: "center",
            }}
            open={props.open}
        >

            <Box sx={{
                width: { xs: "300px", md: "500px", display: "flex", flexDirection: "column" },
                padding: "10px"
            }}>

                <Typography variant='h5'><Translate value="SystemLog" />:{systemlog?.id}</Typography>
                <Box sx={{ height: "2px", background: "black" }}></Box>
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <PageDetailText label={<Translate value="Action" />} value={systemlog?.logAction} />
                    <PageDetailText label={<Translate value="LogDomain" />} value={systemlog?.logDomain} />
                    <PageDetailText label={<Translate value="TargetObjectId" />} value={systemlog?.targetObjectId} />
                    <PageDetailText label={<Translate value="Timestamp" />} value={TimeUtils.formatDate(TimeUtils.addUTCTimezone(systemlog?.timestamp))} />

                    <PageDetailTextClickable label={<Translate value="User" />} value={`${systemlog?.user?.name} (${systemlog?.user?.id})`} url={"user/" + systemlog?.user?.id} />
                    <PageDetailText label={<Translate value="Message" />} />
                    <TextareaAutosize minRows={10} value={systemlog?.verbose} disabled />
                </Box>
                <Button onClick={props.onClose} sx={{ mt: 2, alignSelf: "end" }} color="primary" variant="contained">
                    <Translate value="Close" />
                </Button>
            </Box>
        </Dialog>
    )

}