import React, {useState, useEffect} from "react";
import { useNavigate } from "react-router-dom";
import { PageHeader } from "../../components/Layout/PageHeader";
import PageContainer from "../../components/PageContainer";

import { Translate, Localize, translate } from 'react-i18nify';
import { Typography, Box, Button, Link } from '@mui/material'

import { PatientDetailText } from "../../components/Patient/PatientDetailText";

import { RequireRole } from "../../utils/RequireRole";


import Portal_IFU_Danish from "../../assets/icons/files/Portal IFU - danish.pdf";
import Portal_IFU_English from "../../assets/icons/files/Portal IFU - english.pdf";
import Portal_ReleaseNotes from "../../assets/icons/files/O2matic portal release notes - 2024-08-15.pdf";

import HOT100_IFU_English from "../../assets/icons/files/D 8.4.1.17 HOT 100 IFU.pdf";
//import HOT100_IFU_Spanish from "../../assets/icons/files/D 8.4.3.1 HOT 100 IFU Spanish.pdf";

import HOT100_Technical_IFU_English from "../../assets/icons/files/D 8.4.2.14 HOT 100 Technical User Manual.pdf";
import {UpdatesBanner} from "../../components/UpdatesBanner";
//import HOT100_Technical_IFU_Spanish from "../../assets/icons/files/D 8.4.4.1 HOT 100 Technical User Manual Spanish.pdf";


// import PRO100_IFU_English from "../../assets/icons/files/D 8.4.1.26 O2matic User manual - IFU.pdf";
// import PRO100_IFU_Danish from "../../assets/icons/files/D 8.4.1.24 (Danish) O2matic Brugermanual IFU.pdf";
// import PRO100_IFU_German from "../../assets/icons/files/D 8.4.1.27 O2matic User manual_IFU_DE.pdf";

// import PRO100_Service_manual_English from "../../assets/icons/files/D 8.4.11.02 O2matic PRO Service Manual.pdf";

// import PRO200_IFU_English from "../../assets/icons/files/D 8.4.3.04 O2matic PRO 200 User manual - IFU.pdf";


export const Information = () => {

    let navigate = useNavigate();
    const routeChange = (params, event) => {
        let path = "/Information/" + params.row.id;
        navigate(path);
    };

    const navigateProReport = () => {
        navigate('../ProReport/');
    };

    return(
        <PageContainer>
            <PageHeader leftAlign><Translate value="Information"/></PageHeader>

            <UpdatesBanner update_description="UpdatesDescriptionInformationPage"/>

            <Box sx={{mt:2, width:"50%"}}>
                <Typography variant="h5"><Translate value="ContactInformation" /></Typography>

                <PatientDetailText
                    label={<Translate value="PhoneNumber" />}
                    value={"+45 50 52 98 10"}
                ></PatientDetailText>
                <PatientDetailText
                    label={<Translate value="Webpage" />}
                    value={<a class="word" href="https://o2matic.com/" target="_blank">www.o2matic.com</a>}
                ></PatientDetailText>
                <PatientDetailText
                    label={<Translate value="Email" />}
                    value={"info@o2matic.com"}
                ></PatientDetailText>
            </Box>

            <Box sx={{mt:2, width:"50%"}}>
                <Typography variant="h7"> <Translate value="ForO2maticHotAppSee"/> <a class="word" href="https://play.google.com/store/apps/details?id=com.o2matic.oxygentherapy&hl=da" target="_blank">Google Play Store.</a></Typography>
            </Box>

            <Box sx={{mt:2, width:"50%"}}>
                <Typography variant="h5"><Translate value="ReleaseNotes" /> (EN)</Typography>

                <a class="word" href={Portal_ReleaseNotes} target="_blank">O2matic Portal - release notes (2024-08-15)</a>
            </Box>


            {false? <></>  :
                <>

            {/* <Button variant="contained" sx={{mt:2}} onClick={navigateProReport}>
                <Translate value="ProReportGenerator" />
            </Button> */}


            <br></br>
            <Typography variant="h5"><Translate value="InstructionsAndGuides" /></Typography>
            <Typography variant="h8"><Translate value="InstructionsDescription"/> </Typography>

            <Box sx={{flexWrap: 'wrap'}}>
                <Button sx={{ mr:2, mt:2, width:"180px", height:"250px", zindex:"2000"}} style={{textAlign: 'center'}} variant="outlined">
                    <Box sx={{flexDirection: 'column'}}>
                    <Typography variant="h6">Portal</Typography>
                    <Typography variant="h7"> <Translate value="UserManual"/> </Typography>

                    <img style={{ height: '160px' }} src="../Portal_front_page.png" alt="IFU" />

                    <Box>
                    <a class="word" href={Portal_IFU_Danish} target="_blank"> DK </a>
                    ,
                    <a class="word" href={Portal_IFU_English} target="_blank"> EN </a>
                    </Box>

                    </Box>
                </Button> 

                <Button sx={{ mr:2, mt:2, width:"180px", height:"250px", zindex:"2000"}} style={{textAlign: 'center'}} variant="outlined" >
                    <Box sx={{flexDirection: 'column'}}>
                    <Typography variant="h6">HOT 100</Typography>
                    <Typography variant="h7"> <Translate value="UserManual"/> </Typography>
                    <img style={{ height: '160px' }} src="../HOT_user_manual_front_page.png" alt="IFU" />

                    <Box>
                    <a class="word" href={HOT100_IFU_English} target="_blank">EN</a>
                    </Box>

                    </Box>
                </Button>

                <RequireRole authorize={["DeviceManager"]}>
                <Button sx={{ mr:2, mt:2, width:"180px", height:"250px", zindex:"2000"}} style={{textAlign: 'center'}} variant="outlined" >
                    <Box sx={{flexDirection: 'column'}}>
                    <Typography variant="h6">HOT 100</Typography>
                    <Typography variant="h7"> <Translate value="TechnicalManual"/> </Typography>
                    <img style={{ height: '160px' }} src="../HOT_100_technical_manual_front_page.png" alt="IFU" />

                    <Box>
                    <a class="word" href={HOT100_Technical_IFU_English} target="_blank">EN</a>

                    </Box>

                    </Box>
                </Button>
                </RequireRole>


                
                {/* <Button sx={{ mr:2, mt:2, width:"180px", height:"250px", zindex:"2000"}} style={{textAlign: 'center'}} variant="outlined"  >
                    <Box sx={{flexDirection: 'column'}}>
                    <Typography variant="h6">PRO 100</Typography>
                    <Typography variant="h7">User Manual</Typography>

                    <img style={{ height: '160px' }} src="../PRO_user_manual_front_page.png" alt="IFU" />

                    <Box>
                    <a class="word" href={PRO100_IFU_English} target="_blank">EN</a>
                    ,
                    <a href = {PRO100_IFU_Danish} target = "_blank">DK</a>
                    ,
                    <a href = {PRO100_IFU_German} target = "_blank">DE</a>
                    </Box>

                    </Box>
                </Button> 

                <Button sx={{ mr:2, mt:2, width:"180px", height:"250px", zindex:"2000"}} style={{textAlign: 'center'}} variant="outlined"  >
                    <Box sx={{flexDirection: 'column'}}>
                    <Typography variant="h6">PRO 100</Typography>
                    <Typography variant="h7">Service Manual</Typography>
                    

                    <img style={{ height: '160px' }} src="../PRO_100_service_manual_front_page.png" alt="IFU" />

                    <Box>
                    <a class="word" href={PRO100_Service_manual_English} target="_blank">EN</a>

                    </Box>

                    </Box>
                </Button> 

                <Button sx={{ mr:2, mt:2, width:"180px", height:"250px", zindex:"2000"}} style={{textAlign: 'center'}} variant="outlined"  >
                    <Box sx={{flexDirection: 'column'}}>
                    <Typography variant="h6">PRO 100</Typography>
                    <Typography variant="h7">Quick Guide</Typography>

                    <img style={{ height: '160px' }} src="../PRO_quick_guide.png" alt="IFU" />

                    <Box sx={{flexDirection: 'row'}}>
                    <a class="word" href="https://o2matic.com/wp-content/uploads/2023/07/D.7.2.19.02-QuickGuide-Danish.pdf" target="_blank">DK</a>
                    ,
                    <a class="word" href="https://o2matic.com/wp-content/uploads/2023/07/D.7.2.13.04-QuickGuide-English.pdf" target="_blank">EN</a>

                    </Box>

                    </Box>
                </Button>  */}

                
                
            </Box>
</>
            }
        </PageContainer>
    )
}