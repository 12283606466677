import { add } from 'date-fns';
import React, { useState, useEffect } from 'react';

export function usePaginationDownloader(itemsPerPage = 50) {
    const [pagesLoaded, setPagesLoadedInner] = useState({});
    const [data, setData] = useState({});
    const [assembledData, setAssembledData] = useState([]);
    const [listeners, setListeners] = useState([]);
    const [dataProcessor, setDataProcessor] = useState(null);
    const [fetchFunction, setFetchFunctionInner] = useState(null);



    const notifyListeners = () => {
        for (const listener of listeners) {
            listener(assembledData);
        }
    };
    const addListener = (callback) => {
        setListeners((prevListeners) => [...prevListeners, callback]);
    };

    const removeListener = (callback) => {
        setListeners((prevListeners) => {
            const index = prevListeners.indexOf(callback);
            if (index !== -1) {
                prevListeners.splice(index, 1);
            }
            return prevListeners;
        });
    };
    const setFetchFunctionInState = (func) => {
        setFetchFunctionInner(func);
      };


    const addInitialData=(data,loadedPages)=>{
        //console.log("Adding intial data")
        //console.log({data})
        setPagesLoadedInner(loadedPages)
        setAssembledData(data)
    }

    const addLoadedPages=(pagesLoaded)=>{
        setPagesLoadedInner(prev=>{return {...prev,...pagesLoaded}})
    } 

    const hasObjectWithId = (pageNumber) => {
        //console.log("Has page with id:"+pageNumber)
        return pagesLoaded.hasOwnProperty(pageNumber);
    };

    const generateAssembledPlaceholders=(numberOfSessions, patientId) => {
        const jsonArray = Array.from({ length: numberOfSessions - 150 }, (_, index) => generateLoadingSession(-1 - index, patientId));
        setAssembledData((prevAssembledData) => [
            ...prevAssembledData,
            ...jsonArray,
        ]);
    }

    const loadPage = async (pageNumber, ...fetchParams) => {
        //console.log("Loading page:"+pageNumber)
        //console.log({assembledData})
        if (hasObjectWithId(pageNumber)) {
            return;
        }
        if(itemsPerPage*pageNumber>assembledData.length){
            //console.log("Loading exeded length of data, stoping loading")
            return;
        }
        setPagesLoadedInner((prevPagesLoaded) => ({
            ...prevPagesLoaded,
            [pageNumber]: {},
        }));

        try {
            const apiResult = await fetchFunction(...fetchParams);
            //console.log("Downloaded data for page"+pageNumber)
            //console.log({data:apiResult.data})
            setData((prevData) => ({
                ...prevData,
                [pageNumber]: apiResult.data,
            }));
            setAssembledData((prevAssembledData) => {
                const newAssembledData = [...prevAssembledData];
                newAssembledData.splice(
                  itemsPerPage * pageNumber,
                  itemsPerPage,
                  ...apiResult.data
                );
                return newAssembledData;
              });
        } catch (e) {
            delete pagesLoaded[pageNumber];
        }
    };



    const destroy = () => {
        clearInterval(dataProcessor);
        setDataProcessor(null);
        setListeners([]);
    };

    const generateLoadingSession = (id, patientId) => {
        return (
            {
                "id": id,
                "component": {
                    "id": 3473,
                    "name": null,
                    "owner": null,
                    "ownerId": null,
                    "locationId": null,
                    "location": null,
                    "template": {
                        "id": 0,
                        "name": null,
                        "code": null,
                        "notes": null
                    },
                    "metaData": [

                    ],
                    "children": [

                    ]
                },
                "treatment": {
                    "clinicalTrial": null,
                    "id": null,
                    "name": "Loading",
                    "templateId": null,
                    "location": {
                        "dataRetentionDays": null,
                        "id": null,
                        "name": "null",
                        "locationProperties": [

                        ],
                        "clinicalTrial": null,
                        "address": null,
                        "countryCode": null,
                        "parentLocationId": null,
                        "parentLocation": null
                    },
                    "crc": null,
                    "fpgaProfile": [

                    ],
                    "disabled": null,
                    "minutesPrescribed": null,
                    "values": [
                        {
                            "code": "DurLod1",
                            "value": 0
                        },
                        {
                            "code": "HO2LT",
                            "value": 0
                        },
                        {
                            "code": "HO2ST",
                            "value": 0
                        },
                        {
                            "code": "HSpO2",
                            "value": 0
                        },
                        {
                            "code": "Ki",
                            "value": 0
                        },
                        {
                            "code": "LO2LT",
                            "value": 0
                        },
                        {
                            "code": "LO2ST",
                            "value": 0
                        },
                        {
                            "code": "LSpO2",
                            "value": 0
                        },
                        {
                            "code": "OAD",
                            "value": 0
                        }
                    ]
                },
                "patientId": patientId,
                "received": null,
                "forAudit": false,
                "deviceSessionId": id,
                "parts": {
                    "start": {
                        "timestamp": null,
                        "values": {
                            "PulseInitial": "0",
                            "SpO2Initial": "0",
                            "OxygenInitial": "0"
                        }
                    },
                    "analyzing": {
                        "timestamp": null,
                        "values": {
                            "PulseMinimum": "0",
                            "PulseMaximum": "0",
                            "PulseStandardDeviation": "0",
                            "PulseAverage": "0",
                            "SpO2Minimum": "0",
                            "SpO2Maximum": "0",
                            "SpO2StandardDeviation": "0",
                            "SpO2Average": "0",
                            "OxygenMinimum": "0",
                            "OxygenMaximum": "0",
                            "OxygenStandardDeviation": "0",
                            "OxygenAverage": "0"
                        }
                    },
                    "adjusting": {
                        "timestamp": null,
                        "values": {
                            "Duration": "0",
                            "PulseMinimum": "0",
                            "PulseMaximum": "0",
                            "PulseStandardDeviation": "0",
                            "PulseAverage": "0",
                            "SpO2Minimum": "0",
                            "SpO2Maximum": "0",
                            "SpO2StandardDeviation": "0",
                            "SpO2Average": "0",
                            "OxygenMinimum": "0",
                            "OxygenMaximum": "0",
                            "OxygenStandardDeviation": "0",
                            "OxygenAverage": "0"
                        }
                    },
                    "end": {
                        "timestamp": null,
                        "values": {
                            "NextDiagnosis": null,
                            "Zone": "0",
                            "OxygenTarget": "0",
                            "SpO2Final": "0",
                            "PulseFinal": "0"
                        }
                    }
                }
            }
        )
    }

    return {
        addListener,
        removeListener,
        loadPage,
        hasObjectWithId,
        addInitialData,
        notifyListeners,
        destroy,
        setFetchFunctionInState,
        addInitialData,
        addLoadedPages,
        generateAssembledPlaceholders,
        data,
        assembledData,
    };


}



