
import axios from 'axios';


axios.defaults.baseURL = process.env.REACT_APP_API_URL

axios.interceptors.request.use(request => {
    let headers=request.headers;
    return request;
}, error => {
    console.log(error);
    return Promise.reject(error);
});

axios.interceptors.response.use(response => {
    return response;
}, error => {
    console.log(error);
    return Promise.reject(error);
});