import { Typography } from '@mui/material'
import Breadcrumbss from './Breadcrumbs';
export const PageHeader = (props) => {
    return (
        <>
            <Typography sx={{ width: props.leftAlign ? "100%" : "auto" }}
                component="h1"
                variant="h4">
                {props.children}
            </Typography>
            <Breadcrumbss />
        </>
    );
}