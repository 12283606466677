import { PageDetailText, PageDetailTextClickable } from "../PageDetailText";
import React, { useState, useEffect } from 'react'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { Dialog, Typography, Box, Button, Accordion, AccordionSummary, AccordionDetails, InputLabel } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import { DataGrid, GridExpandMoreIcon } from "@mui/x-data-grid";
import { Translate, Localize, translate } from 'react-i18nify';
import DescriptionIcon from '@mui/icons-material/Description';
import { Divider } from '@material-ui/core';
import { TextareaAutosize } from '@material-ui/core';
import TimeUtils from "../../utils/TimeUtils";
import AlertBox  from "../AlertBox";
import { PatientService } from "../../services/DataService";

export const PatientLog = (props) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [logs, setLogs] = useState([]);
  
  // const RenderPatientLog = ({ logs }) => {    
  useEffect(() => {
    if(isExpanded){
      (async () => {
        await PatientService.getAllPatientLogs(props.id)
          .then(response => {
            setLogs(response.data)
          }).catch(error => {
            console.error(error)
          });
      })();
    }
  }, [isExpanded])

  const logColumns = [
    {
      field: 'id', headerName: translate('Id'), flex: 0,

    },
    {
      field: 'message', headerName: translate('Message'), flex: 1,

    },
    {
      field: 'user', headerName: translate('User'), flex: 0,
      valueGetter: (params) => {
        return (`${params?.value?.name} (${params?.value?.id})`)
      },

    },
    {
      field: 'timestamp', headerName: translate('Timestamp'), flex: 1,
      valueGetter: (params) => {
        try {
          let oldDate = params.value
          let addUtc = TimeUtils.addUTCTimezone(oldDate)
          return TimeUtils.formatDate(addUtc)
        }
        catch (e) {
          return params.value
        }
      },
    },
  ];

  return (
    <Accordion expanded={isExpanded} onChange={() => setIsExpanded(prev => !prev)} sx={{ width: { xs: "100%", sm: "100%", md: "100%", lg: "1000px" }, maxWidth: {md: "1000px"}, mt: 2 }}>
      <AccordionSummary
        expandIcon={<GridExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography variant="h5"><Translate value="PatientLog" /></Typography>

      </AccordionSummary>
      <AccordionDetails>
        <Box sx={{ display: "flex", flexDirection: "column", width: "100%", height: "500px" }}>
          <RenderPatientAddLog id={props.id} setLogs={setLogs} />
          <DataGrid
            ColumnWidth="*"
            sx={{ height: "500px", mt: 2 }}
            columns={logColumns}
            rows={logs}
            onRowClick={
              (params, event, detail) => {
                setLogs(params.row)
              }
            }
            pageSize={100}
            rowsPerPageOptions={[100]}
          />
        </Box>
      </AccordionDetails>
    </Accordion>
  )
};

const RenderPatientAddLog = ({ id, setLogs }) => {
  const [logMessage, setLogMessage] = useState("")
  const [loading, setLoading] = useState(false);
  const [alertBox, setAlertBox] = useState({
    "visiblity": false,
    "statusCode": null,
    "text": null,
    "error": null
  })


  const onSubmit = async (e) => {
    e.preventDefault();
    setLoading(true)
    try {
      let result = await PatientService.insertPatientLog(id, logMessage)
      setAlertBox({ visiblity: true, statusCode: result.status, text: translate("Added") })
      let logs = await PatientService.getAllPatientLogs(id)
      setLogs(logs.data)
    } catch (error) {
      setAlertBox({ visiblity: true, statusCode: error.response.status, text: translate("Error") })
    }
    finally {
      setLoading(false)
      setTimeout(() => {
        setAlertBox({
          "visiblity": false,
          "statusCode": null,
          "text": null,
          "error": null
        })
      }, 1000)
    }

  }

  const dismissAlertBox = () => {
    setAlertBox({
      "visiblity": false,
      "statusCode": null,
      "text": null,
      "error": null

    })
  }

  return (
    <Box onSubmit={onSubmit} component="form" sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
      <InputLabel id="logLabel"><Translate value="Log" /></InputLabel>
      <TextareaAutosize sx={{ mt: 1, width: { xs: "100%", sm: "300px", md: "500px" } }} label={<Translate value="LogEntry" />} required minRows={5} value={logMessage} onChange={(e) => { setLogMessage(e.target.value) }} />
      <Button
        disabled={loading}
        type="submit"
        variant="contained"
        sx={{ mt: 1, mb: 2, width: 150 }}
        startIcon={<AddIcon />}
      > <Translate value="AddLog" /> </Button>
      <AlertBox visible={alertBox.visiblity} text={alertBox.text} error={alertBox.error} statusCode={alertBox.statusCode} onHide={dismissAlertBox} />
    </Box>
  )
}

