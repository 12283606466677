import React, { useState, useRef, useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom';
import PageContainer from '../../components/PageContainer';
import { PageHeader } from '../../components/Layout/PageHeader';
import { Box } from '@mui/system';
import { Dialog, Typography, Button, Stack, FormControl, TextField, InputLabel, Select, MenuItem, FormGroup, FormControlLabel, Switch } from '@mui/material'
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import DownloadIcon from '@mui/icons-material/Download';
import { DataGrid } from '@mui/x-data-grid';
import { TextareaAutosize } from '@material-ui/core';
import { FromToPickerDate } from "../../components/FromToPicker";
import { UserService, LogService } from '../../services/DataService';
import { Translate, translate, Localize } from 'react-i18nify';
import TimeUtils from '../../utils/TimeUtils';
import html2pdf from "html2pdf.js/dist/html2pdf.min";
import { O2maticLogoBlack } from '../../components/Layout/O2maticLogoBlack';
import { filter } from 'jszip';
import { PageDetailText, PageDetailTextClickable } from '../../components/PageDetailText';
import { useSelector } from 'react-redux';
import {UpdatesBanner} from "../../components/UpdatesBanner";



export default function Log() {

    // const userRoles  = useSelector((state) => state?.auth?.roles);
    const user  = useSelector((state) => state?.auth?.user);
    const [logs, setLogs] = useState([]);
    const [users, setUsers] = useState([]);
    const [filteredUsers, setFilteredUsers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [userRoleLocations, setUserRoleLocations] = useState([]);
    const [isO2maticAuditor, setIsO2maticAuditor] = useState(false);
    
    let to = new Date();
    let from = new Date();
    from.setDate(to.getDate() -6 ); // last 7 days
    from.setHours(0);
    from.setMinutes(0);
    from.setSeconds(0);
    to.setHours(23);
    to.setMinutes(59);
    to.setSeconds(59);
    const [logsFrom, setLogsFrom] = useState(from);
    const [logsTo, setLogsTo] = useState(to);

    const [o2maticListDialogOpen, setO2maticListDialogOpen] = useState(false);

    const [logPopupOpen, setLogPopupOpen] = useState(false)
    const [logPopupData, setLogPopupData] = useState()

    const openLogPopup = (logPopupData) => {
        setLogPopupData(logPopupData)
        setLogPopupOpen(true)
    }
    const closeLogPopup = () => {
        setLogPopupOpen(false)
    }


    useEffect(() => {
        (async () => {
            let apiResultUser = await UserService.getAll();
            setUsers(apiResultUser.data);
            await UserService.getActiveUserRolesLocations()
            .then((data) => {
                setUserRoleLocations(data.data);
            }).catch((error) => {
                console.log(error);
            })
        })();
    }, [])

    useEffect(() => {
        (async () => {
            if(isO2maticAuditor){
                const value = 21;
                const filteredUsers = users.filter(user => user.location.id == value);
                setFilteredUsers(filteredUsers);
            }
        })();
    }, [users, isO2maticAuditor])

    useEffect(() => {
        (async () => {
            let apiResult = await LogService.get(TimeUtils.localToUtc(logsFrom),TimeUtils.localToUtc(logsTo));
            setLogs(apiResult.data);
            setLoading(false);        
        })();
    },[logsFrom, logsTo])

    useEffect(() => {
        let isO2maticAuditor = false;
        if(userRoleLocations.length !== 0){
            userRoleLocations.forEach(role => {
                if(role.roleName === "Auditor" && role.locationId === 21){
                    isO2maticAuditor = true;
                }
            })
        }
        setIsO2maticAuditor(isO2maticAuditor);
    },[userRoleLocations])

    const columns = [

        {
            field: 'id', headerName: translate('Id'), width:100
        },
        {
            field: 'timestamp', headerName: translate('Timestamp'), width:150,
            valueGetter: (log) => {
                return TimeUtils.formatDate(TimeUtils.convertUTCDateToLocalDate(new Date(log.row.timestamp)));
            }
        },
        {
            field: 'userId', headerName: translate('User'), width:150,
            valueGetter: (log) => {
                return "(" + log?.row.user.id + ") " + log.row.user.name + "";
            }
        },
        {
            field: 'userLocation', headerName: translate('UserLocation'), width:150,
            valueGetter: (log) => {
                return "(" + log?.row.user.location.id + ") " + log.row.user.location.name + "";
            }
        },
        {
            field: 'logDomain', headerName: translate('Domain'), minWidth:100,
            valueGetter: (log) => {
                return translate(log?.row.logDomain);
            }
        },
        {
            field: 'targetObjectId', headerName: translate('TargetObjectId'), width:130
        },
        {
            field: 'targetObjectLocation', headerName: translate('TargetObjectLocation'), width: 160,
            valueGetter: (log) => {
                return "(" + log?.row.targetObjectLocation.id + ") " + log.row.targetObjectLocation.name + "";
            }
        },
        {
            field: 'logAction', headerName: translate('Action'), width:100,
            valueGetter: (log) => {
                return translate(log?.row.logAction);
            }
        },
        {
            field: 'verbose', headerName: translate("Description"), flex: 1, minWidth:200,
            valueGetter: (log) => {
                return translate(log?.row.verbose);
            }
        },
    ]



    const downloadCsv = (logs) => {
        let todaysDate = new Date();
        // console.log(logs)

        let rows = [
            ["sep=;"],
            [translate("AuditorLogs")], // KAC: format date
            [translate("Downloaded"), TimeUtils.formatDate(todaysDate)],
            [translate("StartTime"),TimeUtils.formatDate(logsFrom)],
            [translate("EndTime"),TimeUtils.formatDate(logsTo)],
            []
        ];

        rows.push([translate("Id"), "UTC " + translate('Timestamp'), translate('User') + " (" + translate('Id') +  ")", translate('UserLocation') + " (" + translate('Id') +  ")", translate('Domain'), translate('TargetObjectId'), translate('TargetObjectLocation') + " (" + translate('Id') +  ")", translate('Action'), translate("Description")]);
        logs.forEach((log) => {
            console.log(log)
            rows.push([log.id, TimeUtils.formatDate(new Date(log.timestamp)), "(" + log.user.id + ") " + log.user.name , "(" + log.user.location.id + ") " + log.user.location.name, log.logDomain, log.targetObjectId, "(" + log.targetObjectLocation.id + ") " + log.targetObjectLocation.name + "", log.logAction, log.verbose])
        });

        let csvContent = "data:text/csv;charset=utf-8,"
            + rows.map(e => e.join(";")).join("\n");
    
        var encodedUri = encodeURI(csvContent);
        var link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", `O2matic - Auditor logs from ${TimeUtils.formatDateYYMMDD(logsFrom)} to ${TimeUtils.formatDateYYMMDD(logsTo)}.csv`);
        document.body.appendChild(link);
        link.click();
    }

    const AddRoles = (roles) => {
        let boxes = [];
        roles.forEach(role => {
            boxes.push(
            <Box sx={{display:"flex", flexDirection:"row"}}>
                <Box sx={{width:"250px"}}>
                    {translate(role.roleName)}
                </Box>
                <Box sx={{}}>
                    {role.locationName}
                </Box>
            </Box>
            )
        })
        return <Box>{boxes}</Box>
    }

    const AddUsersAndRoles = () => {
        let boxes = [];
        // console.log(filteredUsers)
        filteredUsers.forEach(filteredUser => {
            boxes.push(
            <Box sx={{display:"flex", flexDirection:"row", justifyContent: 'flex-start'}}>
                <Box sx={{width:"80px", borderBottom: 1}}>
                    {filteredUser.id}
                </Box>
                <Box sx={{width:"250px", borderBottom: 1, display:"flex", flexDirection:"column"}}>
                    <Box>
                        {filteredUser.name + " " + filteredUser.lastName}
                    </Box>
                    <Box>
                        {filteredUser.email}
                    </Box>
                </Box>
                {/* <Box sx={{width:"250px", borderBottom: 1}}>
                    {filteredUser.email}
                </Box> */}
                <Box sx={{width:"500px", borderBottom: 1}}>
                    {AddRoles(filteredUser.roleLocations)}
                </Box>
            </Box>
            )
        })
        return <Box>{boxes}</Box>
    }

    const LogPopUp = (props) => {
        const logdata = props.logdata
        return (
            <Dialog
                fullWidth
                sx={{
                    display: "flex",
                    justifyContent: "center",
                }}
                open={props.open}
            >
    
                <Box sx={{
                    width: { xs: "300px", md: "500px", display: "flex", flexDirection: "column" },
                    padding: "10px"
                }}>
    
                    <Typography variant='h5'><Translate value="Log" />: {logdata?.id}</Typography>
                    <Box sx={{ height: "2px", background: "black" }}></Box>
                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                        <PageDetailText label={<Translate value="Timestamp"/>} value={TimeUtils.formatDate(TimeUtils.addUTCTimezone(logdata?.timestamp))} />
                        <PageDetailTextClickable label={<Translate value="User"/>} value={`(${logdata?.user?.id}) ${logdata?.user?.name}`} url={"user/" + logdata?.user?.id} />
                        <PageDetailText label={<Translate value="UserLocation"/>} value={"(" + logdata?.user.location.id + ") " + logdata.user.location.name} />
                        <PageDetailText label={<Translate value="Domain"/>} value={translate(logdata?.logDomain)} />
                        <PageDetailText label={<Translate value="TargetObjectId"/>} value={logdata?.targetObjectId} />
                        <PageDetailText label={<Translate value="TargetObjectLocation"/>} value={"(" + logdata?.targetObjectLocation.id + ") " + logdata.targetObjectLocation.name} />
                        <PageDetailText label={<Translate value="Action"/>} value={translate(logdata?.logAction)} />
                        <PageDetailText label={<Translate value="Description"/>}/>
                        <TextareaAutosize minRows={10} value={logdata?.verbose} disabled />
                    </Box>
                    <Button onClick={props.onClose} sx={{ mt: 2, alignSelf: "end" }} color="primary" variant="contained">
                        <Translate value="Close" />
                    </Button>
                </Box>
            </Dialog>
        )
    
    }

    const ListOfO2maticUsers = (props) => {

        let todaysDate = new Date();
        const printElementRef = useRef(null);
    
        const generatePDF = () => {
            const printElement = printElementRef.current;
            let date = TimeUtils.formatDateYYMMDD(new Date())
            var opt = {
                margin: [0, 0, 0, 0],
                filename: `List of O2matic portal users and roles - ${date}.pdf`,
                image: { type: "jpeg", quality: 1 },
                pagebreak: { mode: "css" },
                html2canvas: {
                    scale: 2,
                    letterRendering: true,
                    useCORS: true,
                },
                jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
                noPdfOpenParams: true,
            };
    
            html2pdf().set(opt).from(printElement).save();
        };
        /* if (!props.open) {
            return null;
        } */

        /* let o2maticUsers = [];
        users = props.data;
        users.forEach((user) => {
            o2maticUsers.push([4])
        }); */
    
        return (
    
            <Dialog
                fullWidth
                maxWidth="md"
                sx={{ width: "100%", p: 0 }}
                open={props.open}
                onClose={props.onClose}
    
            >

                    <>
                        <Button sx={{m:2, alignSelf: "flex-start" }} startIcon={<DownloadIcon/>} variant="contained" onClick={() => generatePDF()} >{translate("DownloadAsPdf")} </Button>
                        <Box sx={{p:2 }} ref={printElementRef}>
                            <Box sx={{position:"absolute",top:10,right:0}}> 
                                <O2maticLogoBlack width={"200px"}/>
                            </Box>
                            <Box sx={{width:"100%"}}>
                            <Typography variant="h4"> {translate("ListOfO2maticUsers")} </Typography>
                            </Box>
                            <Box sx={{mt:2, width:"100%", display:"flex", flexDirection:"row", justifyContent: 'flex-start'}}>
                                <Box sx={{width:"80px", borderBottom: 1}}>
                                    <Typography variant="h6"> {translate("Id")} </Typography> 
                                </Box>
                                <Box sx={{width:"250px", borderBottom: 1}}>
                                    <Typography variant="h6"> {translate("Name") + " and " + translate("Email")} </Typography> 
                                </Box>
                                <Box sx={{width:"500px", borderBottom: 1, display:"flex", flexDirection:"row"}}>
                                    <Box sx={{width:"250px"}}>
                                        <Typography variant="h6"> {translate("Roles")} </Typography> 
                                    </Box>
                                    <Box>
                                        <Typography variant="h6"> {translate("RoleLocation")} </Typography> 
                                    </Box>
                                </Box>
                            </Box>
                            <AddUsersAndRoles/>
                            
                            <Box sx={{mt:10, display:"flex", flexDirection:"column"}}>
                                <Box sx={{mb:3, display:"flex", flexDirection:"row"}}>
                                    <Typography variant="h6" sx={{mr:2}}> {translate("ListDownloaded")} </Typography>
                                    <Typography variant="h6"> {TimeUtils.formatDateYYMMDD(todaysDate)} </Typography>
                                </Box>
                                <Box sx={{display:"flex", flexDirection:"row"}}>
                                    <Typography variant="h6" sx={{mr:2}}> {translate("RolesApprovedBy")} </Typography>
                                </Box>
                                <Box sx={{mt:15, display:"flex", flexDirection:"row"}}>
                                    <Typography variant="h6" sx={{width:300, mr:5, borderTop: 1}}> {translate("Date")} </Typography>
                                    <Typography variant="h6" sx={{width:500, borderTop: 1}}> {translate("Signature")} </Typography>
                                </Box>
                            </Box>
                        </Box>
                       
                    </>

            </Dialog >
        )
    }

    return (
        <PageContainer>
            <PageHeader leftAlign>
                <Translate value="AuditorLogs" />
            </PageHeader>

            <UpdatesBanner update_description="UpdatesDescriptionLogsPage"/>

            <ListOfO2maticUsers
                    open={o2maticListDialogOpen}
                    onClose={()=>setO2maticListDialogOpen(false)}
                    data = {filteredUsers}
                    //date={dialogDate}
                    //data={reportData}
                ></ListOfO2maticUsers> 

           
            <Box sx={{width:"100%", display:"flex", flexDirection:"row", justifyContent:"space-between"}}>
                <FromToPickerDate
                from={logsFrom}
                to={logsTo}
                setFromValue={setLogsFrom}
                setToValue={setLogsTo}
                />
                <Box>
                    { isO2maticAuditor?
                          filteredUsers.length>0?
                        <Button
                        sx={{ mt: 1.5, mr:2, minWidth:"200px", alignSelf: "start", pl:1.5, fontSize:13}}
                        variant="contained"
                        component="label"
                        startIcon={<FormatListBulletedIcon/>}
                        onClick={()=>{
                            setO2maticListDialogOpen(true)}}
                        >
                            <Translate value="ListOfO2maticUsers" />
                        </Button> 
                        :
                        <></> 
                    : <></>}
                    <Button
                        sx={{ mt: 1.5, minWidth:"200px", alignSelf: "start", pl:1.5, fontSize:13}}
                        variant="contained"
                        component="label"
                        startIcon={<DownloadIcon/>}
                        onClick={() => downloadCsv(logs)}
                    >
                        <Translate value="DownloadCsv" />
                    </Button>
                </Box>
            </Box>
            <Box sx={{ width: "100%"}}>
                <DataGrid
                    getRowId={(r) => {
                        return r.id;
                    }}
                    ColumnWidth="*"
                    sx={{ height: 630, mt: 2 }}
                    columns={columns}
                    rows={logs}
                    pageSize={100}
                    rowsPerPageOptions={[100]}
                    loading={loading}
                    onRowClick={
                        (params, event, detail) => {
                            openLogPopup(params.row)
                        }
                    }
                    components={{
                        NoRowsOverlay: () => (
                          <Stack height="100%" alignItems="center" justifyContent="center">
                            <Translate value="NoRowsLogs" />
                          </Stack>
                        ),
                        NoResultsOverlay: () => (
                            <Stack height="100%" alignItems="center" justifyContent="center">
                              <Translate value="NoResults" />
                            </Stack>
                        )
                    }}
                />
            </Box>
            {(logPopupOpen) ? 
                <LogPopUp logdata={logPopupData} onClose={closeLogPopup} open={logPopupOpen} /> 
            :
                <> </>
            }
            

        </PageContainer>


    );




}


