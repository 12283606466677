import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom';
import PageContainer from '../../components/PageContainer';
import { PageHeader } from '../../components/Layout/PageHeader';
import { Box } from '@mui/system';
import Button from "@mui/material/Button";
import { TreatmentService } from "../../services/DataService";
import TreatmentControls from '../../components/Treatment/TreatmentControls';
import { Typography } from '@mui/material';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { ConfirmDialog } from '../../components/Layout/Dialog/ConfirmDialog';
import { Translate, Localize } from 'react-i18nify';
import { SteppedForm } from "../../components/SteppedForm"
import CircularChart from '../../components/CircularChart';
import Spo2Configuration from '../../components/TreatmentWizard/Spo2Configuration';
import O2WithPO from '../../components/TreatmentWizard/O2WithPO';
import O2WithOutPO from '../../components/TreatmentWizard/O2WithOutPO';
import FlowResponse from '../../components/TreatmentWizard/FlowResponse';
import FixedFlow from '../../components/TreatmentWizard/FixedFlow';
import AlertBox from "../../components/AlertBox"
import {UpdatesBanner} from "../../components/UpdatesBanner";

const Treatment = (props) => {
    const [showDisableDialog, setShowDisableDialog] = useState(false);
    const [showEnableDialog, setShowEnableDialog] = useState(false);
    const [treatment, setTreatment] = useState();
    const [confirmEnableStatus, setConfirmEnableStatus] = useState({status: 0, body: null});
    const [confirmDisableStatus, setConfirmDisableStatus] = useState({status: 0, body: null});

    
    const { id } = useParams();

    const [alertBox, setAlertBox] = useState({
        "visiblity": false,
        "statusCode": null,
        "text": null,
        "error": null
    }
    );


    const handleAlertBox = (visiblity, statusCode, text, error) => {
        setAlertBox({
            "visiblity": visiblity,
            "statusCode": statusCode,
            "text": text,
            "error": error
        }
        )
    }
    const dismissAlertBox=()=>{
        setAlertBox({
            "visiblity": false,
            "statusCode": null,
            "text": null,
            "error": null

        })
    }

    useEffect(() => {
        getTreatment(id);

    }, [])

    const getTreatment = async (id) => {
        let apiResult = await TreatmentService.get(id);
        setTreatment(apiResult.data);
    }

    const disableProfile = () => {
        setConfirmDisableStatus({status: 1, body: null});
        TreatmentService.disable(id).then(result => {
            // handleAlertBox(true, result.status, result.statusText, null) 
            setConfirmDisableStatus({status: 2, body: result});
            setTimeout(() => {
                setShowDisableDialog(false);
            }, 2000)
            getTreatment(id);
        }).catch(err => {
            setConfirmDisableStatus({
                status: 3, 
                body: {
                    data: "", 
                    status: err?.response.status
                }
            });
            setTimeout(() => {
                setShowDisableDialog(false);
            }, 2000)

            // handleAlertBox(true, err?.response?.status, null, err?.response?.data?.join(",")) 
        });
    }
    const enableProfile = () => {
        setConfirmEnableStatus({status: 1, body: null});
        TreatmentService.enable(id).then(result => {
            setConfirmEnableStatus({status: 2, body: result});
            // handleAlertBox(true, result.status, result.statusText, null) 
            setTimeout(() => {
                setShowEnableDialog(false)
            }, 2000)
            getTreatment(id);
        }).catch(err => {
            setConfirmEnableStatus({status: 3, body: err?.response});
            setTimeout(() => {
                setShowEnableDialog(false)
            }, 2000)

            // handleAlertBox(true, err?.response?.status, null, err?.response?.data?.join(",")) 
        });
    }
    const closeDialog = () => {
        setShowDisableDialog(false)
    }
    const closeEnableDialog = () => {
        setShowEnableDialog(false)
    }
    const handleDisable = () => {
        setShowDisableDialog(true)
    }
    const handleEnable = () => {
        setShowEnableDialog(true)
    }


    return (
        <PageContainer>


            <PageHeader leftAlign><Translate value="TreatmentProfileDetails" /> </PageHeader>

            <UpdatesBanner update_description="UpdatesDescriptionTreatmentPage"/>

            <Box sx={{ display: "flex", flexDirection: "column" }}>
                {(treatment == null)?
                <Typography>
                    <Translate value="Loading"/>
                </Typography>
                :
                <>
                <Typography><Translate value="Status"/>: {treatment.disabled ? <Translate value="Disabled" /> : <Translate value="Enabled" />}</Typography>
                <Typography><Translate value="Location"/>: {treatment.location.name }</Typography>
                <TreatmentControls disabled defaultTreatment={treatment}></TreatmentControls>
                {treatment.disabled == null ?
                    <Button onClick={handleDisable} sx={{ width: "200px", mt: 1 }} color="error" variant="contained" startIcon={<VisibilityOffIcon />}>
                        <Translate value="Disable" />
                    </Button>
                    : <Button onClick={handleEnable} sx={{ width: "200px", mt: 1 }} color="primary" variant="contained" startIcon={<VisibilityIcon />}>
                        <Translate value="Enable" />
                    </Button>}
                </>
                }
            </Box>
            <AlertBox visible={alertBox.visiblity} text={alertBox.text} error={alertBox.error} statusCode={alertBox.statusCode} onHide={dismissAlertBox}/>
            {/* commented for future development */}
            {/* <SteppedForm open={true}>

                <Spo2Configuration key="2" />
                <O2WithPO key="3" />
                <O2WithOutPO minAllowed={2} maxAllowed={10} key="4" />
                <FlowResponse key="5" />
                <FixedFlow key="6"/>

            </SteppedForm> */}

            <ConfirmDialog
                dialogIcon={<ErrorOutlineIcon color="error" sx={{ fontSize: 75 }} />}
                title={<Translate value="PleaseConfirm" />}
                message={<Translate value="EnableTreatmentText" />}
                declineText={<Translate value="Close" />}
                confirmText={<Translate value="Enable" />}
                confirmButtonVariant="primary"
                confirmButtonIcon={<VisibilityIcon />}
                open={showEnableDialog}
                onClose={closeEnableDialog}
                onConfirm={enableProfile}
                confirmStatus={confirmEnableStatus}
            />
            <ConfirmDialog
                dialogIcon={<ErrorOutlineIcon color="error" sx={{ fontSize: 75 }} />}
                title={<Translate value="PleaseConfirm" />}
                message={<Translate value="DisableTreatmentProfileText" />}
                declineText={<Translate value="Close" />}
                confirmText={<Translate value="Disable" />}
                confirmButtonIcon={<VisibilityOffIcon />}
                open={showDisableDialog}
                onClose={closeDialog}
                onConfirm={disableProfile}
                confirmStatus={confirmDisableStatus}
            />
        </PageContainer>
    )
}

export default Treatment