import { useEffect, useRef, useState } from "react";
import { Translate } from 'react-i18nify';
import { Typography, Button, Fade, Backdrop, Modal } from "@mui/material/"
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material";


export const UpdatesBanner = (props) => {
    
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);


    let update_color = "#66E9A6"
    let update_color_light = "#d5f2e3"

    let update_description = props.update_description;

	return (
		<Box sx={{width: { xs: "100%", sm: "100%", md: "100%", lg: "1000px" }}}>
             <Button startIcon={<AutoAwesomeIcon/>} onClick={handleOpen} variant="contained" color="success" sx={{width:"100%", backgroundColor:update_color, mb:1}}  style={{justifyContent: "flex-start"}}>
                <Translate value="UpdatesBanner" />
             </Button>
             <Modal
                open={open}
                onClose={handleClose}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                backdrop: {
                    timeout: 500,
                },
                }}
              >
                <Fade in={open}>
                <Box sx={{position: 'absolute',top: '50%',left: '50%',transform: 'translate(-50%, -50%)',width: "1000px",borderRadius: "15px", borderColor: "warning.main", bgcolor:update_color_light, color: "warning.contrastText",boxShadow: 15}}>
                  <Box sx={{width:"968px", borderColor: "warning.contrastText", bgcolor: update_color, borderRadius: "15px", borderBottom: 15, color: update_color_light,
                  p:2, display: 'flex', alignItems: 'center'}}>
                  <AutoAwesomeIcon sx={{height:"30px", width:"30px", mx:2}}/>
                  <Typography variant="h5" component="h2" color="white">
                      <Translate value="Updates - Here is a description of some of the new features and changes" />
                    </Typography>
                  </Box>
                  <Box sx={{px:2}}>
                    <Typography sx={{color: "#000000", whiteSpace:"pre-line" }}>
                      <Translate value="UpdatesLine1" /> 
                    </Typography>
                    <Typography variant="h6" component="h2" sx={{mt:2, mb:-2, color: "#000000"}}>
                        <Translate value="UpdatesLine2" />
                    </Typography>
                    <Typography sx={{mt:2, mb:2, color: "#000000"}}>
                        <Translate value={update_description} />
                    </Typography>
                  </Box>
                  
                </Box>
                </Fade>
            </Modal>
            </Box>
	);
}
