import React from 'react'
import { TextField,Box,InputLabel } from '@mui/material';
import { Translate, Localize } from 'react-i18nify';

const CustomIdentifier = (props) => {
    return (
        <>
            <InputLabel id="Address"><Translate value="CustomIdentifier" /></InputLabel>
            <Box sx={{ mt:1, display: "flex" }}>

                <TextField
                    sx={{ width: "50%" , mr: 1}}
                    id="IndetifierType"
                    
                    value={props.customIdType}
                    onChange={(event) => {
                        props.setCustomIdType(event.target.value);
                    }}
                    label={<Translate value="IdentifierType" />}
                />
                <TextField
                    sx={{ width: "50%" }}
                    id="IdentifierValue"
                    onChange={(event) => {
                        props.setCustomIdValue(event.target.value);
                    }}
                    value={props.customIdValue}
                    
                    label={<Translate value="IdentifierValue" />}
                />
            </Box>
        </>
    )
}

export default CustomIdentifier