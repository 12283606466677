import React, { useState, useEffect } from 'react'
import PageContainer from '../../components/PageContainer';
import { PageHeader } from '../../components/Layout/PageHeader';
import { Box } from '@mui/system';
import { Typography, Button, FormControl, TextField, InputLabel, Select, MenuItem, FormGroup, FormControlLabel, Switch, Slider } from '@mui/material'
import { DateTimePicker } from '@mui/lab';
import SaveIcon from '@mui/icons-material/Save';
import RangeSliderControler from '../../components/Treatment/RangeSliderControler';
import { LocationService, TreatmentService } from '../../services/DataService'
import FormHelperDialogWrapper from '../../components/Layout/Dialog/FormHelperDialogWrapper';
import InfoIcon from '@mui/icons-material/Info';
import SliderControler from '../../components/Treatment/SliderControler';
import TreatmentControls from "../../components/Treatment/TreatmentControls"
import { useNavigate } from 'react-router-dom';
import { Translate, Localize, translate } from 'react-i18nify';
import AlertBox from "../../components/AlertBox";
import {UpdatesBanner} from "../../components/UpdatesBanner";

export default function TreatmentCreate() {



    const [treatment, setTreatment] = useState({});
    const [location, setLocation] = useState(0);

    let navigate = useNavigate();
    const [loadingLocation, setloadingLocation] = useState(false);
    const [locationSelected, setLocationSelected] = useState(false);
    const [locations, setLocations] = useState([]);
    const [requestSubmited, setRequestSubmited] = useState(false);
    const [alertBox, setAlertBox] = useState({
        "visiblity": false,
        "statusCode": null,
        "text": null,
        "error": null
    }
    );
    const handleAlertBox = (visiblity, statusCode, text, error) => {
        setAlertBox({
            "visiblity": visiblity,
            "statusCode": statusCode,
            "text": text,
            "error": error
        }
        )
    }
    const dismissAlertBox=()=>{
        setAlertBox({
            "visiblity": false,
            "statusCode": null,
            "text": null,
            "error": null

        })
    }

    const handleLocationChange = (event, newValue) => {
        setLocation(newValue.props.value);
    };

    const getLocationById=(id)=>{
        let loc= locations.find(location=>location.id==id)
        if(loc==undefined){
            return null;
        }
        return loc
    }


    useEffect(() => {
        (async () => {
            let apiResult = await LocationService.getByRoles(["TreatmentTemplateManager"]);

            var locations = apiResult.data.sort((a, b) => a.name > b.name ? 1 : -1);

            locations.unshift({
                "dataRetentionDays": 180,
                "id": -1,
                "name": translate("SelectLocation"),
            })
            setLocation(-1);
            setLocations(locations);

            setloadingLocation(true);
        })();
    }, []);

    useEffect(() => {
        location==-1?setLocationSelected(false):setLocationSelected(true)
    }, [location]);

    const updateTreatment = (treatment) => {
        setTreatment(treatment);
    }
    const handleSubmit = async (event) => {
        event.preventDefault();
        dismissAlertBox()
        setRequestSubmited(true)
        let treatmentData = treatment;
        treatmentData.LocationID = location;

        if (treatmentData.ValidationError != null) {

            handleAlertBox(true, 400, null,treatmentData.ValidationError) 
            setRequestSubmited(false)
            return;
        }
        try {
            let result=await TreatmentService.create(treatmentData);
            handleAlertBox(true, result.status, result.statusText, null) 
            setTimeout(()=>{
                navigate("/treatment/" + result.data.id, { replace: true });
            },1000)

        } catch (error) {
            setRequestSubmited(false)
            console.log(error);
            handleAlertBox(true, error?.response?.status, null, error?.response?.data?.join(",")) 
        }

    }



    return (
        <PageContainer>



            <PageHeader leftAlign><Translate value="AddTreatmentProfile" /></PageHeader>
            <UpdatesBanner update_description="UpdatesDescriptionTreatmentCreatePage"/>
            {/* <Typography sx={{ mt: 1, mb: 1 }} variant="body">

                <Translate value="CreateTreatmentText" />

            </Typography> */}
            <Box onSubmit={handleSubmit} component="form" sx={{ width: { xs: "95%", sm: "600px" }, mt: 0, display: "flex", flexDirection: "column" }}>

                <InputLabel id="locationLabel"><Translate value="Location" /></InputLabel>

                {loadingLocation ?
                    <Select
                        required
                        id="location"
                        value={location || ""}
                        onChange={handleLocationChange}
                    >
                        {locations.map(location => {
                            return <MenuItem key={location.id} value={location.id}>{location.name}</MenuItem>
                        })}
                    </Select>
                    : null}

                {               
                locationSelected?
                <>
                    <TreatmentControls isCT={getLocationById(location)?.clinicalTrial}  updateTreatment={updateTreatment} />
                    <Button sx={{ mt: 1, width:"200px"}} disabled={requestSubmited} type="submit" variant='contained' color="success" startIcon={<SaveIcon />}><Translate value="Save" /></Button>
                </>:null

                }
                <AlertBox visible={alertBox.visiblity} text={alertBox.text} error={alertBox.error} statusCode={alertBox.statusCode} onHide={dismissAlertBox}/>

            </Box>
        </PageContainer>
    )
}
