import React, { useEffect, useState, useRef } from "react";
import { DailyReport } from "./DailyReport";
import { Box, Typography, Button, Dialog, Stack, Divider, CircularProgress } from "@mui/material";
import { Translate, translate} from "react-i18nify";
import { GenerateReport, generateChartData } from "./GenerateReport";
import TimeUtils from '../../utils/TimeUtils';
import { DailyReportDownloadCsv } from "./DailyReportDownloadCsv";
import { SessionService } from "../../services/DataService";

const Confirmdownload = ({dataForFiles, fileType, patientId, open, onClose, numberOfFiles}) => {
    const childRefs = useRef([]);
    const [numberOfFilesList, setNumberOfFilesList] = useState([]);
 
    const handleConfirm = (ev, setLoading) => {
        setLoading(true);
        if(fileType === "pdf"){
            dataForFiles.map((data, index) => {
                GenerateReport(childRefs.current[index], patientId, TimeUtils.formatDateYYMMDD(data.date), setLoading);
            })
        } else if(fileType === "csv"){
            dataForFiles.map((data, index) => {
                DailyReportDownloadCsv(data, TimeUtils.formatDateYYMMDD(data.date), setLoading);
            })        
        }
    }

    useEffect(() => {
        childRefs.current = childRefs.current.slice(0, dataForFiles.length);
        let tempNumberOfFilesList = numberOfFilesList.slice(dataForFiles.length, numberOfFilesList.length);
        setNumberOfFilesList(tempNumberOfFilesList);
    },[dataForFiles])

    useEffect(() => {
        setNumberOfFilesList(Array(numberOfFiles).fill(0))
    },[numberOfFiles, open])

    if (!open) {
        return null;
    }

    return(
        <Dialog
            open={open}
            onClose={() => onClose()}
            fullWidth
            maxWidth="md"
            sx={{ width: "100%", height:"100%", overflow: "hidden"}}
        >

            <br/>          
            <Box sx={{ width: "95%", height: "400px", p:2}} >
                <Typography variant="h5" ><Translate value="FilesToBeDownloaded" /></Typography>

                <Stack 
                spacing={2}
                divider={<Divider orientation="horizontal" flexItem />}
                sx={{width:"500px", marginLeft: 5, marginTop: 3}}
                >
                    {dataForFiles.map((fileData, index) => ( 
                        <Box sx={{backgroundColor: '#fff',}} >
                            <Box > 
                                <Typography variant="h6" key={index} >
                                    {"O2matic - " + translate("Patient") + " " + patientId + " - " + translate("DailyReport") + " " + TimeUtils.formatDateYYMMDD(fileData.date) +"." +fileType}
                                </Typography>
                            </Box>
                            <Box
                            sx={{position:"absolute" , zIndex: 2, display: "none"}}
                            > 
                            {fileType === "pdf" ? 
                                <DailyReport 
                                    key={index}
                                    data={fileData} 
                                    reportData={fileData.detailedSessions}
                                    chartData={generateChartData(fileData.detailedSessions)}
                                    open={true}
                                    // onClose={false}
                                    date={fileData.date}
                                    forwardRef={el => childRefs.current[index] = el}
                                />
                            :
                            <></>
                            }

                            </Box>
                        </Box>
                    ))}
                    {
                    numberOfFilesList.map((index) => {
                        return(
                            <CircularProgress size={24}/>
                        )
                    })
                    }

                </Stack>
                <Box height={30}/>
                <Box  position={"absolute"} sx={{bottom: 10, right: 30}} >
                    <LoadingButton handleConfirm={handleConfirm}/>
                </Box>
            </Box>
        </Dialog>
    )

}

const LoadingButton = (props) => {
    const [loading, setLoading] = useState(false);
    const handleConfirm = props.handleConfirm;

    return(
        <Button onClick={ev => handleConfirm(ev, setLoading)} variant="contained" color="primary" sx={{ minWidth:"200px", height:"40px", mb:1, ml:2, }}>    
            {loading ? 
                <CircularProgress size={24} sx={{color: "white"}}/>
            :
                <Translate value="ConfirmDownload"/>
            }
        </Button>
    );

}

export default Confirmdownload;