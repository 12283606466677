import { DataGrid } from '@mui/x-data-grid';
import { Box, Stack } from "@mui/material";
import React, {useState, useEffect} from "react";
import { translate, Translate } from 'react-i18nify';
import { OximeterService } from "../../services/DataService";


export const ExistingMacsList = () => {

    const [existingMacs, setExistingMacs] = useState([]);
    const [loadedMacs, setLoadedMacs] = useState(false); 

    const getOximeterMacs = async () => {
        await OximeterService.getOximeterMacs().then(result => {
            if(result.data != null){
                setExistingMacs(result.data)
                setLoadedMacs(true)
            }
        }).catch(err => {
            console.log(err)
        })
    }

    const columns = [
        {
            field: 'id', headerName: translate("ComponentId"), flex: 1,
            valueGetter: (oximeterData) => {
                return oximeterData?.row.componentId;
            }
        },
        {
            field: 'mac', headerName: translate("MacAddress"), flex: 1,
            valueGetter: (oximeterData) => {
                return oximeterData?.row.macAddress;
            }
        },
    ]    

    useEffect(() => {
        getOximeterMacs()
    },[])

    return(
        <Box sx={{ width: "500px" }}> 
        {existingMacs ?             
            <DataGrid
                sx={{ height: 530, mt: 2 }}
                columns={columns}
                rows={existingMacs}
                pageSize={50}
                rowsPerPageOptions={[50]}
                getRowId={row => row.componentId}
                //loading={true}
                components={{
                    NoRowsOverlay: () => (
                      <Stack height="100%" alignItems="center" justifyContent="center">
                        <Translate value="NoRowsOximeters" />
                      </Stack>
                    ),
                    NoResultsOverlay: () => (
                        <Stack height="100%" alignItems="center" justifyContent="center">
                          <Translate value="NoResults" />
                        </Stack>
                    )
                }}
            />
            : <></>}
        </Box>
    )
}

