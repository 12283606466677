
export const validationColors={
    GREEN:"#a4ff80",
    YELLOW:"#ffea85",
    RED: "#ff1111"
}

export const o2FlowValidation=(o2RangeWOPO,o2RangePO)=>{
    const result={
        o2RangeWOPOColor:validationColors.RED,
        o2RangePOColor:validationColors.RED,
        lowFlowWarning:true

    }
    if (o2RangeWOPO[0] <= o2RangePO[0]) {
        result.o2RangePOColor=validationColors.RED;
        result.o2RangeWOPOColor=validationColors.RED
        result.validationError="Without PO min is smaller or equal to with PO min";
        return result;
    }
    if (o2RangeWOPO[1] >= o2RangePO[1]) {
        result.o2RangePOColor=validationColors.RED;
        result.o2RangeWOPOColor=validationColors.RED
        result.validationError="Without PO max is bigger or equal to with PO max";
        return result;
    }

    result.o2RangePOColor=validationColors.GREEN;
    result.o2RangeWOPOColor=validationColors.GREEN

    if (o2RangeWOPO[0] > 3 || o2RangeWOPO[0] < 0.5) {
        result.o2RangeWOPOColor=validationColors.YELLOW
    }
    if (o2RangeWOPO[1] < 1 || o2RangeWOPO[1] > 4) {
        result.o2RangeWOPOColor=validationColors.YELLOW
    }
    if (o2RangePO[1] < 3 || o2RangePO[0] < 0.5) {
        result.o2RangePOColor=validationColors.YELLOW
    }

    if (o2RangeWOPO[0] < 0.5 || o2RangePO[0] < 0.5) {
        result.lowFlowWarning=true;
    } else {
        result.lowFlowWarning=false;
    }
    return result;
}

export const spo2RangeValidation=(spo2Range)=>{
    if (spo2Range[1] < 80) {
        return validationColors.RED;
    }
    if (spo2Range[0] < 85 || spo2Range[0] > 92) {
        return  validationColors.YELLOW
    }
    if (spo2Range[1] < 88 || spo2Range[1] > 95) {
        return  validationColors.YELLOW
    }
    return validationColors.GREEN;
}
