import { Translate , translate } from "react-i18nify";
import { PageHeader } from "../../components/Layout/PageHeader";
import PageContainer from "../../components/PageContainer";
import { Box, Button, TextField, Typography } from "@mui/material";
import { DropZone } from "../../components/DropZone";
import Papa from "papaparse";
import { ProCSVType, ProCsvParser } from "./ProCsvPareser";
import ReactDOMServer from "react-dom/server";
import html2pdf from "html2pdf.js/dist/html2pdf.min";
import { forwardRef, useEffect, useRef, useState } from "react";
import { Chart } from "../../components/Session/DetailedSessionChartUplot";
import { Spo2Chart } from "./Spo2Chart";
import { O2Chart } from "./O2Chart";
import { PulseChart } from "./PulseChart";
import TimeUtils from "../../utils/TimeUtils";
import SquareIcon from '@mui/icons-material/Square';
import HorizontalRuleIcon from '@mui/icons-material/HorizontalRule';
import { useTheme } from '@mui/material/styles';

const PrintContent = forwardRef((props, ref) => {
  return (
    <Box
      sx={{
        m: 0,
        p: 0,
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
      }}
      ref={ref}
    >
      {props.children}
    </Box>
  );
});

const PrintPage = forwardRef((props, ref) => {
  const data = props?.data?.data;
  const patient = props?.data?.patient;
  const meta = props?.data?.meta;
  const profile = props?.data.profile;
  const testProfile = props?.data.testProfile;
  const average = props?.data?.average;
  const globalMetadata = props.metadata;

  const [manualMode, setManualMode] = useState([]);
  const [oximeterOff, setOximeterOff] = useState([]);
  const [flowError, setFlowError] = useState([]);

  useEffect(() => {
    if(data.length > 4) { // if data has event logs, then lenght of data greater than 4 and file is then not test data.

      let manualMode = [...data[4]];
      let oximeterOff = [...data[4]];
      let flowError = [...data[4]];
  
      var manualModeStates = ['08','09','0a','0b','0c','0d','0e','0f','18','19','1a','1b','1c','1d','1e','1f']; //manual mode 'on'
      var oximeterOffStates = ['00', '01', '04', '05', '08', '09', '0c', '0d', '10', '11', '14', '15', '18', '19', '1c', '1d']; //signal NOT ok
      var flowErrorStates = ['00', '02', '04', '06', '08','0a','0c','0e','10', '12', '14', '16','18','1a','1c','1e']; //flow NOT ok
  
      for (let i = 0; i < data[4].length; i++) {
        if (manualModeStates.indexOf(data[4][i]) > -1) {
          manualMode[i] = '200'
        } else {
          manualMode[i] = '0'
        }
        if (oximeterOffStates.indexOf(data[4][i]) > -1) {
          oximeterOff[i] = '98'
        } else {
          oximeterOff[i] = '0'
        }
        if (flowErrorStates.indexOf(data[4][i]) > -1) {
          flowError[i] = '13'
        } else {
          flowError[i] = '0'
        }
      }
  
      setManualMode(manualMode);
      setOximeterOff(oximeterOff);
      setFlowError(flowError); 
    }
    
  }, [data]);
  

  let spo2LowerRange,
    spo2UpperRange,
    o2LowerRange,
    o2UpperRange = 0;
  if (meta.type === ProCSVType.NORMAL) {
    spo2LowerRange = profile.spo2Acc;
    spo2UpperRange = profile.spo2High;
    o2LowerRange = profile.o2Low;
    o2UpperRange = profile.o2High;
  } else if (meta.type === ProCSVType.TEST) {
    spo2LowerRange = testProfile.spo2Acc;
    spo2UpperRange = testProfile.spo2High;
    o2LowerRange = profile.o2Low;
    o2UpperRange = profile.o2High;
  }

  const NormalO2MaticSettings = ({ profile }) => {
    let flowResponse = "Custom (" + parseInt(profile.flowRate).toString() + ")";
    if (profile.flowRate == 150) flowResponse = "Very fast";

    if (profile.flowRate == 100) flowResponse = "Fast";

    if (profile.flowRate == 25) flowResponse = "Normal";
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          width: "600px",
          justifyContent: "space-between",
        }}
      >
        <Box>
          <ProfileValue name={translate("ProfileName")} value={profile.name} />
          <ProfileValue name={translate("Delay")} value={profile.delay} unit={translate("Sec")}  />
          <ProfileValue name={translate("FlowResponse")} value={flowResponse} />
        </Box>
        <Box>
          <ProfileValue
            name={<>SpO<sub>2</sub> <Translate value="Min" /> </>}
            value={profile.spo2Acc}
            unit="%"
            normilize
          />
          <ProfileValue
            name={<>SpO<sub>2</sub> <Translate value="Max" /> </>}
            value={profile.spo2High}
            unit="%"
            normilize
          />
          <ProfileValue
            name={translate("PulseHigh")}
            value={profile.pulseHigh}
            unit="bpm"
          />
        </Box>
        <Box>
          <ProfileValue
            name={<>O<sub>2</sub> <Translate value="Min" /> </>}
            value={profile.o2Low}
            unit="l/min"
            normilize
          />
          <ProfileValue
            name={<>O<sub>2</sub> <Translate value="Max" /> </>}
            value={profile.o2High}
            unit="l/min"
            normilize
          />
          <ProfileValue
            name={<>O<sub>2</sub> <Translate value="Init" /> </>}
            value={profile.o2Init}
            unit="l/min"
            normilize
          />
        </Box>
      </Box>
    );
  };

  const TestO2MaticSettings = ({ profile }) => {
    let flowResponse = "Custom (" + parseInt(profile.flowRate).toString() + ")";
    if (profile.flowRate == 150) flowResponse = "Very fast";

    if (profile.flowRate == 100) flowResponse = "Fast";

    if (profile.flowRate == 25) flowResponse = "Normal";

    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          width: "600px",
          justifyContent: "space-between",
        }}
      >
        <Box>
          <ProfileValue name="Test" value={profile.name} />
          <ProfileValue name="Profile name" value={profile.profile} />
          <ProfileValue name="Flow response" value={flowResponse} />
          <ProfileValue
            name={translate("Duration")}
            value={profile.duration}
            normilize
            unit={translate("sec")}
          />
        </Box>
        <Box>
          <ProfileValue
            name={<>SpO<sub>2</sub> <Translate value="Min" /> </>}
            value={profile.spo2Acc}
            unit="%"
            normilize
          />
          <ProfileValue
            name={<>SpO<sub>2</sub> <Translate value="Max" /> </>}
            value={profile.spo2High}
            unit="%"
            normilize
          />
          <ProfileValue
            name="Pulse High"
            value={profile.pulseHigh}
            unit="bpm"
            normilize
          />
          <ProfileValue
            name="Warmup"
            value={profile.warmUp}
            normilize
            unit={translate("sec")}
          />
        </Box>
        <Box>
          <ProfileValue
            name={<>O<sub>2</sub> <Translate value="Min" /> </>}
            value={profile.o2Low}
            unit="l/min"
            normilize
          />
          <ProfileValue
            name={<>O<sub>2</sub> <Translate value="Max" /> </>}
            value={profile.o2High}
            unit="l/min"
            normilize
          />
          <ProfileValue
            name={<>O<sub>2</sub> <Translate value="Init" /> </>}
            value={profile.o2Init}
            unit="l/min"
            normilize
          />
          <ProfileValue
            name="Cooldown"
            value={profile.coolDown}
            normilize
            unit={translate("sec")}
          />
        </Box>
      </Box>
    );
  };

  const theme = useTheme()

  return (
    <Box
      ref={ref}
      sx={{
        position: "relative",
        m: 0,
        p: 0,
        pl: 2,
        pr: 2,
        pageBreakAfter: "always",
        width: "775px",
        height: "100%",
        background: "transparent",
        border: "2px solid black",
      }}
    >
      <Typography variant={"h5"}> <Translate value="PatientData"/> </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Box>
          <EmptyValueData
            valueName={translate("FirstName")}
            patient={patient}
            value={globalMetadata["Name"]}
            onTextChange={props.onTextChange}
          />
          <EmptyValueData
            valueName={translate("LastName")}
            value={globalMetadata["Surname"]}
            patient={patient}
            onTextChange={props.onTextChange}
          />
          <EmptyValueData
            valueName={translate("Field1")}
            patient={patient}
            value={globalMetadata["Field 1"]}
            onTextChange={props.onTextChange}
          />
          <EmptyValueData
            valueName={translate("Field2")}
            patient={patient}
            value={globalMetadata["Field 2"]}
            onTextChange={props.onTextChange}
          />
        </Box>
        <Box>
          <ProfileValue
            name={translate("ReportCreated")}
            value={TimeUtils.formatDate(new Date())}
          />
          <ProfileValue
            name={translate("Session")}
            value={TimeUtils.formatDate(new Date(meta.date))}
          />
          <ProfileValue name={<>O2matic <Translate value="PatientId" /> </>} value={patient?.id} />
        </Box>
      </Box>
      <Box
        sx={{ mt: "10px", width: "775px", height: "2px", background: "black" }}
      ></Box>

      <Typography variant={"h5"}>{<>O2matic PRO - <Translate value="ProfileSettings" /> </>}</Typography>
      {meta.type === ProCSVType.NORMAL ? (
        <NormalO2MaticSettings profile={profile} />
      ) : (
        <TestO2MaticSettings profile={testProfile} />
      )}

      <Box
        sx={{ mt: "10px", width: "775px", height: "2px", background: "black" }}
      ></Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Typography sx={{ textAlign: "left" }} variant={"h5"}>
          <Translate value="Statistics"/>
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            justifyContent: "space-evenly",
          }}
        >
          <ProfileValue
            name={<>SpO<sub>2</sub> <Translate value="average" /> </>}
            value={average.spo2 > 100 || average.spo2 < 40 ? "-" : average.spo2}
            unit="%"
            decimal={1}
            normilize={average.spo2 > 100 || average.spo2 < 40 ? false : "float"}
          />
          <ProfileValue
            name={translate("PulseAverage")}
            value={
              average.pulse > 250 || average.pulse < 30 ? "-" : average.pulse
            }
            decimal={0.1}
            unit="bpm"
            normilize={average.pulse > 250 || average.pulse < 30 ? false : "float"}
          />
          <ProfileValue
            name={<>O<sub>2</sub> <Translate value="average" /> </>}
            value={average.o2 > 16 || average.o2 < 0 ? "-" : average.o2}
            decimal={1}
            normilize={average.o2 > 16 || average.o2 < 0 ? false : "float"}
            unit= "l/min"
          />
        </Box>
      </Box>

      
      {/* Manual legend */}
      {(data.length > 4)?
      <div
        style={{ display: "inline-flex", alignItems: "center", textAlign: "center", paddingLeft: "125px"}}
      >
        <HorizontalRuleIcon style={{ color: theme.palette.vitals.spo2 }} /> SpO<sub><sub>2</sub></sub>
        <HorizontalRuleIcon style={{ color: theme.palette.vitals.pulse }} /> <Translate value="Pulse" />
        <HorizontalRuleIcon style={{ color: theme.palette.vitals.o2 }} /> <Translate value="O2Flow" />
        <SquareIcon style={{ color: "rgba(211,211,211,0.5)" }} /> Manual mode 'on'
        <SquareIcon style={{ color: "rgba(255,255,0,0.5)" }} /> Missing data
        <SquareIcon style={{ color: "rgba(224, 0, 0,0.7)" }} /> Flow error
      </div> : 
      <div
        style={{ display: "inline-flex", alignItems: "center", textAlign: "center", paddingLeft: "275px"}}
      >
        <HorizontalRuleIcon style={{ color: theme.palette.vitals.spo2 }} /> SpO<sub><sub>2</sub></sub>
        <HorizontalRuleIcon style={{ color: theme.palette.vitals.pulse }} /> <Translate value="Pulse" />
        <HorizontalRuleIcon style={{ color: theme.palette.vitals.o2 }} /> <Translate value="O2Flow" />
      </div>
      }
      

      <Typography
        sx={{ width: "100%", textAlign: "center", color: "gray", marginBottom:"-10px"}}
        variant={"h6"}
      >
        SpO<sub>2</sub> (%)
      </Typography>
      <Spo2Chart
        spo2LowerRange={spo2LowerRange}
        spo2UpperRange={spo2UpperRange}
        width={725}
        height={245}
        data={[data[0], manualMode, oximeterOff, data[1]]}
      />
      <Typography
        sx={{ width: "100%", textAlign: "center", color: "gray", marginBottom:"-10px"}}
        variant={"h6"}
      >
        {translate("PulseBpm")}
      </Typography>
      <PulseChart width={725} height={245}
      data={[data[0], manualMode, (oximeterOff.map(function(x) { return x * 1.9; })), data[3]]} />
      
      <Typography
        sx={{ width: "100%", textAlign: "center", color: "gray", marginBottom:"-10px"}}
        variant={"h6"}
      >
        <Translate value="O2Flow" /> (<Translate value="l/min" />)
      </Typography>
      <O2Chart
        o2LowerRange={o2LowerRange}
        o2UpperRange={o2UpperRange}
        width={725}
        height={245}
        data={[data[0], manualMode, flowError, data[2]]}
      />

      {/* <div
        style={{ display: "inline-flex", alignItems: "center", textAlign: "center", paddingLeft: "225px", paddingBottom:"40px"}}
      >
        <HorizontalRuleIcon style={{ color: theme.palette.vitals.spo2 }} /> SpO<sub><sub>2</sub></sub>
        <HorizontalRuleIcon style={{ color: theme.palette.vitals.pulse }} /> Pulse
        <HorizontalRuleIcon style={{ color: theme.palette.vitals.o2 }} /> O<sub><sub>2</sub></sub>
        <SquareIcon style={{ color: "rgba(255,255,0,0.4)" }} /> Manual mode 'on'
      </div> */}

      <Typography sx={{ position: "absolute", bottom: 0, fontWeight: "bold" }}>
        {translate("DisclaimerDataVal")}
      </Typography>
    </Box>
  );
});

const ProfileValue = ({ name, value, unit, normilize,decimal}) => {
  let displayValue = value;
  if (normilize=="float") {
    displayValue = parseFloat(value).toString();
    if(decimal){
      displayValue = parseFloat(value).toFixed(decimal).toString();
    }

  }
  else if (normilize) {
    displayValue = parseInt(value).toString();
  }
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
      }}
    >
      <Typography variant="body">{name} {":"}</Typography>
      <Typography sx={{ ml: 1 }} variant="body">
        {displayValue}
        {" "}
        {unit}
</Typography>
    </Box>
  );
};

const EmptyValueData = (props) => {
  let valueName = props.valueName;
  let value = props.value;
  let patient = props.patient;
  const maxChar = 50;
  const toFill = maxChar - valueName.length;
  return (
    <Box
      sx={{
        height: "25px",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "baseline",
      }}
    >
      <Typography variant="body">{valueName + ":"}</Typography>
      <TextField
        sx={{ height: "25px" }}
        variant="standard"
        value={value}
        onChange={(val) =>
          props.onTextChange(val.target.value, valueName, patient.id)
        }
      />
      {/* <Typography variant="body">{"".padEnd(toFill, "_")}</Typography> */}
    </Box>
  );
};

export const ProReport = (props) => {
  const printElementRef = useRef([]);

  const [data, setData] = useState({});
  const [generate, setGenerate] = useState(false);

  const clearClicked = () => {
    setData({});
    setGenerate(false);
    printElementRef.current = [];
  };
  const onMetaChange = (value, field, patientId) => {
    setData((prev) => {
      return {
        ...prev,
        [patientId]: {
          ...prev[patientId],
          metadata: {
            ...prev[patientId].metadata,
            [field]: value,
          },
        },
      };
    });
    printElementRef.current = [];
  };

  // useEffect(() => {
  //   console.log({ data });
  // }, [data]);

  const onDataUpdate = async(data) => {
    if (data.length === 0) {
      return;
    }

    const parseFile = async (item) => {
      return new Promise((resolve) => {
        const reader = new FileReader();
  
        reader.onload = ({ target }) => {
          const csv = Papa.parse(target.result, { header: true });
          const csvParser = new ProCsvParser();
          const parsedData = csvParser.parseCSV(csv, item?.name);
          resolve(parsedData);
        };
  
        reader.readAsText(item);
      });
    };

    const parsedDataArray = await Promise.all(data.map(parseFile));


    setData((prev) => {
      const newState = { ...prev };
  
      parsedDataArray.forEach((parsedData) => {
        const { id } = parsedData.patient;
  
        if (!newState[id]) {
          newState[id] = {
            metadata: { Surname: "", "Field 1": "", "Field 2": "", Name: "" },
            data: [],
          };
        }
  
        newState[id].data.push(parsedData);
      });
  
      return newState;
    });

  };
 
  const generatePDF = () => {
    printElementRef.current.forEach((e) => {
      const printElement = e;

      var opt = {
        margin: [0, 0, 0, 0],
        filename: "O2matic - "+  translate("ProReport") + ".pdf",
        image: { type: "jpeg", quality: 1 },
        pagebreak: { mode: ["css"] },
        html2canvas: {
          scale: 1,
          letterRendering: true,
          useCORS: true,
        },
        jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
        noPdfOpenParams: true,
      };
      html2pdf().set(opt).from(printElement).save();
    });
  };

  return (
    <PageContainer>
      <PageHeader>
        <Translate value="CreateProReport" />
      </PageHeader>
      <Typography sx={{ mt: 1, mb: 1 }} variant="body">
        <Translate value="ProReportMessage" />
      </Typography>
      {!generate ? (
        <DropZone
          types={["csv","CSV"]}
          clear={generate}
          width="500px"
          height="300px"
          onData={(data) => {
            setData({})
            onDataUpdate(data);
          }}
        />
      ) : null}
      {!generate && data.length != 0 ? (
        <Button
          sx={{ mt: 2 , minWidth:"200px"}}
          color="primary"
          variant="contained"
          onClick={() => {
            setGenerate(true);
          }}
        >
          <Translate value="GenerateReport"/>
        </Button>
      ) : null}
      {generate ? (
        <Box sx={{ display: "flex", flexDirection: "row" }}>
          <Button
            sx={{ mt: 2 , mb: 2, mr:2, minWidth:"200px" }}
            color="primary"
            variant="contained"
            onClick={generatePDF}
          >
            <Translate value = "DownloadAsPdf"/>
          </Button>
          <Button
            sx={{ mt: 2 , mb:2, minWidth:"200px" }}
            color="primary"
            variant="contained"
            onClick={clearClicked}
          >
            <Translate value="Clear"/>
          </Button>
        </Box>
      ) : null}
      {generate
        ? Object.keys(data).map((item) => {
            return (
              <PrintContent ref={(e) => printElementRef.current.push(e)}>
                {data[item].data.length != 0
                  ? data[item].data.map((sheet, index) => {
                      return (
                        <>
                          <PrintPage
                            onTextChange={onMetaChange}
                            key={item + index}
                            data={sheet}
                            metadata={data[item].metadata}
                          />
                          <Box
                            key={index + "a"}
                            className="html2pdf__page-break"
                          ></Box>
                        </>
                      );
                    })
                  : null}
              </PrintContent>
            );
          })
        : null}
    </PageContainer>
  );
};
