import { Translate, translate } from "react-i18nify";
import { PageHeader } from "../../../../components/Layout/PageHeader";
import PageContainer from "../../../../components/PageContainer";
import {
  Box,
  Button,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { DEVICE_TYPE } from "../../Production";

import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { useEffect, useState } from "react";
import {
  DeviceService,
  MetadataService,
} from "../../../../services/DataService";
import AlertBox from "../../../../components/AlertBox";

export const Package = (props) => {
  const { udsn, type } = useParams();

  const [state, setState] = useState({});
  const navigate = useNavigate();
  const [submitState, setSubmitState] = useState(false);

  const [alertBox, setAlertBox] = useState({
    visibility: false,
    statusCode: null,
    text: null,
    error: null,
  });

  const handleAlertBox = (visibility, statusCode, text, error) => {
    setAlertBox({
      visibility: visibility,
      statusCode: statusCode,
      text: text,
      error: error,
    });
  };

  const dismissAlertBox = () => {
    setAlertBox({
      visibility: false,
      statusCode: null,
      text: null,
      error: null,
    });
  };

  const setStateHelper = (e, property) => {
    let value = e.target.value;
    setState((prevState) => ({
      ...prevState,
      [property]: value === "true" ? true : value === "false" ? false : value,
    }));
  };

  const setProDefaultState = () => {
    setState({
      containsProDevice: false,
      containsPowerSupply: false,
      containsSensor: false,
      containsOxygenOutputAdapter: false,
      containsInstructions: false,
      containsMasterPin: false,
      containsCertificate: false,
      containsLabels: false
    });
  };

  const setHotDefaultState = () => {
    setState({
      containsHot: false,
      containsTUM: false,
      containsIFU: false,
      containsDoc: false,
      containsPowerSupply: false
    });
  };

  const handleAdapterSerialChange = (event) => {
    const reg = new RegExp("^EJ[0-9]{7}$");
    const value = event.target.value;
    const valid = reg.test(value);

    setState((prevState) => ({
      ...prevState,
      adapterSerial: {
        value: value,
        error: !valid,
        errorText: valid ? null : translate("Format") + " EJ1234567",
      },
    }));
  };



  useEffect(() => {
    switch (type) {
      case DEVICE_TYPE.PRO100.code: {
        setProDefaultState();
        break;
      }
      case DEVICE_TYPE.HOT.code: {
        setHotDefaultState();
        break;
      }
    }
  }, [type]);

  const handleProSubmit = async (e) => {
    e.preventDefault();
    setSubmitState(true);

    try {
      let body = {
        ...state,
        udsn: udsn,
      };
      let result = await DeviceService.packagePro(body);
      if (result.status >= 200 && result.status < 300) {
        handleAlertBox(true, result.status, translate("DevicePacked"), null);
      } else {
        handleAlertBox(true, result.status, result.statusText, null);
      }
      setTimeout(() => {
        navigate(`/production/${DEVICE_TYPE.PRO100.code}`);
      }, 2000);
    } catch (e) {
      handleAlertBox(true, e.status, e.statusText, e.message);
    } finally {
      setSubmitState(false);
    }
  };

  const handleHotSubmit = async (e) => {
    e.preventDefault();
    setSubmitState(true);

    try {
      let body = {
        ...state,
        udsn: udsn,
      };
      let result = await DeviceService.packageHot(body);
      if (result.status >= 200 && result.status < 300) {
        handleAlertBox(true, result.status, translate("DevicePacked"), null);
      } else {
        handleAlertBox(true, result.status, result.statusText, null);
      }
      setTimeout(() => {
        navigate(`/production/${DEVICE_TYPE.HOT.code}`);
      }, 2000);
    } catch (e) {
      handleAlertBox(true, e.status, e.statusText, e.message);
    } finally {
      setSubmitState(false);
    }
  };

  const renderTest = (type) => {
    if (Object.keys(state).length == 0) {
      return "Loading";
    }
    switch (type) {
      case DEVICE_TYPE.PRO100.code: {

        return (
          <Box
            onSubmit={handleProSubmit}
            component="form"
            sx={{
              width: { sm: "650px" },
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box sx={{ display: "flex", flexDirection: "row", width: "100%" }}>
              <FormLabel id="" sx={{ width: "60%" }}>
                <Typography variant="h6"><Translate value="ProDevice"/></Typography>
                <Typography variant="body"><Translate value="ContainsProDevice"/></Typography>
              </FormLabel>
              <RadioGroup
                sx={{ ml: 2 }}
                data-test="mnaw"
                row
                value={state.containsProDevice}
                onChange={(e) => setStateHelper(e, "containsProDevice")}
              >
                <FormControlLabel
                  value={true}
                  control={<Radio />}
                  label={<Translate value="Yes"/>}
                />
                <FormControlLabel
                  value={false}
                  control={<Radio />}
                  label={<Translate value="No"/>}
                />
              </RadioGroup>
            </Box>
            <Box sx={{ display: "flex", flexDirection: "row", width: "100%" , mt:1}}>
              <FormLabel id="" sx={{ width: "60%" }}>
                <Typography variant="h6"><Translate value="PowerSupply"/></Typography>
                <Typography variant="body"><Translate value="ContainsPowerSupply"/></Typography>
              </FormLabel>
              <RadioGroup
                sx={{ ml: 2 }}
                data-test="mnaw"
                row
                value={state.containsPowerSupply}
                onChange={(e) => setStateHelper(e, "containsPowerSupply")}
              >
                <FormControlLabel
                  value={true}
                  control={<Radio />}
                  label={<Translate value="Yes"/>}
                />
                <FormControlLabel
                  value={false}
                  control={<Radio />}
                  label={<Translate value="No"/>}
                />
              </RadioGroup>
            </Box>
            <Box sx={{ display: "flex", flexDirection: "row", width: "100%" , mt:1}}>
              <FormLabel id="" sx={{ width: "60%" }}>
                <Typography variant="h6"><Translate value="Sensor"/></Typography>
                <Typography variant="body"><Translate value="ContainsSensor"/></Typography>
              </FormLabel>
              <RadioGroup
                sx={{ ml: 2 }}
                data-test="mnaw"
                row
                value={state.containsSensor}
                onChange={(e) => setStateHelper(e, "containsSensor")}
              >
                <FormControlLabel
                  value={true}
                  control={<Radio />}
                  label={<Translate value="Yes"/>}
                />
                <FormControlLabel
                  value={false}
                  control={<Radio />}
                  label={<Translate value="No"/>}
                />
              </RadioGroup>
            </Box>
            <Box sx={{ display: "flex", flexDirection: "row", width: "100%", mt:1 }}>
              <FormLabel id="" sx={{ width: "60%" }}>
                <Typography variant="h6"><Translate value="OxygenOutputAdapter"/></Typography>
                <Typography variant="body"><Translate value="ContainsOxygenOutputAdapter"/></Typography>
              </FormLabel>
              <RadioGroup
                sx={{ ml: 2 }}
                data-test="mnaw"
                row
                value={state.containsOxygenOutputAdapter}
                onChange={(e) => setStateHelper(e, "containsOxygenOutputAdapter")}
              >
                <FormControlLabel
                  value={true}
                  control={<Radio />}
                  label={<Translate value="Yes"/>}
                />
                <FormControlLabel
                  value={false}
                  control={<Radio />}
                  label={<Translate value="No"/>}
                />
              </RadioGroup>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "start",
                width: "100%",
                mt: 1,
              }}
            >
              <FormLabel id="" sx={{ width: "60%" }}>
                <Typography variant="h6"><Translate value="InstructionsForUse"/></Typography>
                <Typography variant="body">
                <Translate value="ContainsUserManual"/>
                </Typography>
              </FormLabel>
              <RadioGroup
                sx={{ ml: 2 }}
                row
                value={state.containsInstructions}
                onChange={(e) => setStateHelper(e, "containsInstructions")}
              >
                <FormControlLabel
                  value={true}
                  control={<Radio />}
                  label={<Translate value="Yes"/>}
                />
                <FormControlLabel
                  value={false}
                  control={<Radio />}
                  label={<Translate value="No"/>}
                />
              </RadioGroup>
            </Box>

            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "start",
                width: "100%",
                mt: 1,
              }}
            >
              <FormLabel id="" sx={{ width: "60%" }}>
                <Typography variant="h6"><Translate value="MasterPin"/></Typography>
                <Typography variant="body">
                <Translate value="ContainsMasterPin"/>
                </Typography>
              </FormLabel>
              <RadioGroup
                sx={{ ml: 2 }}
                row
                value={state.containsMasterPin}
                onChange={(e) => setStateHelper(e, "containsMasterPin")}
              >
                <FormControlLabel
                  value={true}
                  control={<Radio />}
                  label={<Translate value="Yes"/>}
                />
                <FormControlLabel
                  value={false}
                  control={<Radio />}
                  label={<Translate value="No"/>}
                />
              </RadioGroup>
            </Box>

            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "start",
                width: "100%",
                mt: 1,
              }}
            >
              <FormLabel id="" sx={{ width: "60%" }}>
                <Typography variant="h6"><Translate value="DeclarationOfConformity"/></Typography>
                <Typography variant="body">
                <Translate value="ContainsDeclarationOfConformity"/>
                </Typography>
              </FormLabel>
              <RadioGroup
                sx={{ ml: 2 }}
                row
                value={state.containsCertificate}
                onChange={(e) => setStateHelper(e, "containsCertificate")}
              >
                <FormControlLabel
                  value={true}
                  control={<Radio />}
                  label={<Translate value="Yes"/>}
                />
                <FormControlLabel
                  value={false}
                  control={<Radio />}
                  label={<Translate value="No"/>}
                />
              </RadioGroup>
            </Box>

            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "start",
                width: "100%",
                mt: 1,
              }}
            >
              <FormLabel id="" sx={{ width: "60%" }}>
                <Typography variant="h6">Contains labels</Typography>
                <Typography variant="body">Package contains labels</Typography>
              </FormLabel>
              <RadioGroup
                sx={{ ml: 2 }}
                row
                value={state.containsLabels}
                onChange={(e) => setStateHelper(e, "containsLabels")}
              >
                <FormControlLabel
                  value={true}
                  control={<Radio />}
                  label={<Translate value="Yes"/>}
                />
                <FormControlLabel
                  value={false}
                  control={<Radio />}
                  label={<Translate value="No"/>}
                />
              </RadioGroup>
            </Box>
            <Button
              sx={{ mt: 2, alignSelf: "flex-start" }}
              disabled={submitState}
              type="submit"
              variant="contained"
            >
              <Translate value="Submit" />{" "}
            </Button>
          </Box>
        );
      }
      case DEVICE_TYPE.HOT.code: {
        return (
          <Box
            onSubmit={handleHotSubmit}
            component="form"
            sx={{
              width: { xs: "100%", sm: "100%", md: "100%", lg: "1000px" },
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box sx={{ display: "flex", flexDirection: "row", width: "100%" }}>
              <FormLabel id="" sx={{ width: "50%" }}>
                <Typography variant="h6"> <Translate value = "HotDevice"/> </Typography>
                <Typography variant="body"><Translate value = "ContainsHotDevice"/></Typography>
              </FormLabel>
              <RadioGroup
                sx={{ ml: 2 }}
                data-test="mnaw"
                row
                value={state.containsHot}
                onChange={(e) => setStateHelper(e, "containsHot")}
              >
                <FormControlLabel
                  value={true}
                  control={<Radio />}
                  label={<Translate value="Yes"/>}
                />
                <FormControlLabel
                  value={false}
                  control={<Radio />}
                  label={<Translate value="No"/>}
                />
              </RadioGroup>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "start",
                width: "100%",
                mt: 1,
              }}
            >
              {/* technical user manual */}
              <FormLabel id="" sx={{ width: "50%" }}>
                <Typography variant="h6"><Translate value = "TechnicalUserManual"/></Typography> 
                <Typography variant="body"><Translate value = "ContainsTechnicalUserManual"/></Typography>
              </FormLabel>
              <RadioGroup
                sx={{ ml: 2 }}
                row
                value={state.containsTUM}
                onChange={(e) => setStateHelper(e, "containsTUM")}
              >
                <FormControlLabel
                  value={true}
                  control={<Radio />}
                  label={<Translate value="Yes"/>}
                />
                <FormControlLabel
                  value={false}
                  control={<Radio />}
                  label={<Translate value="No"/>}
                />
              </RadioGroup>
            </Box>

            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "start",
                width: "100%",
                mt: 1,
              }}
            >
              <FormLabel id="" sx={{ width: "50%" }}>
                <Typography variant="h6"><Translate value = "InstructionsForUse"/></Typography>
                <Typography variant="body"><Translate value = "ContainsInstructionsForUse"/></Typography>
              </FormLabel>
              <RadioGroup
                sx={{ ml: 2 }}
                row
                value={state.containsIFU}
                onChange={(e) => setStateHelper(e, "containsIFU")}
              >
                <FormControlLabel
                  value={true}
                  control={<Radio />}
                  label={<Translate value="Yes"/>}
                />
                <FormControlLabel
                  value={false}
                  control={<Radio />}
                  label={<Translate value="No"/>}
                />
              </RadioGroup>
            </Box>

            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "start",
                width: "100%",
                mt: 1,
              }}
            >
              <FormLabel id="" sx={{ width: "50%" }}>
                <Typography variant="h6"><Translate value = "DeclarationOfConformity"/></Typography>
                <Typography variant="body"><Translate value = "ContainsDeclarationOfConformity"/></Typography>
              </FormLabel>
              <RadioGroup
                sx={{ ml: 2 }}
                row
                value={state.containsDoc}
                onChange={(e) => setStateHelper(e, "containsDoc")}
              >
                <FormControlLabel
                  value={true}
                  control={<Radio />}
                  label={<Translate value="Yes"/>}
                />
                <FormControlLabel
                  value={false}
                  control={<Radio />}
                  label={<Translate value="No"/>}
                />
              </RadioGroup>
            </Box>

            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "start",
                width: "100%",
                mt: 1,
              }}
            >
              <FormLabel id="" sx={{ width: "50%" }}>
                <Typography variant="h6"><Translate value = "PowerSupply"/></Typography>
                <Typography variant="body"><Translate value = "ContainsPowerSupply"/></Typography>
              </FormLabel>
              <RadioGroup
                sx={{ ml: 2 }}
                row
                value={state.containsPowerSupply}
                onChange={(e) => setStateHelper(e, "containsPowerSupply")}
              >
                <FormControlLabel
                  value={true}
                  control={<Radio />}
                  label={<Translate value="Yes"/>}
                />
                <FormControlLabel
                  value={false}
                  control={<Radio />}
                  label={<Translate value="No"/>}
                />
              </RadioGroup>
            </Box>

            <Button
              sx={{ mt: 2, alignSelf: "flex-start" }}
              disabled={submitState}
              type="submit"
              variant="contained"
            >
              <Translate value="Submit" />{" "}
            </Button>
          </Box>
        );
      }
    }
  };

  return (
    <PageContainer>
      <PageHeader leftAlign>
        <Translate value="Packaging" />
      </PageHeader>
      <Typography sx={{ mt: 1, mb: 1 }} variant="h5">
        <Translate value="PackagingDeviceWithUDSN" />: {udsn}
      </Typography>

      {renderTest(type)}
      <AlertBox
        visible={alertBox.visibility}
        text={alertBox.text}
        error={alertBox.error}
        statusCode={alertBox.statusCode}
        onHide={dismissAlertBox}
      />
    </PageContainer>
  );
};
