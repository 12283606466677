import React, { useState, useEffect } from 'react'
import PageContainer from '../../components/PageContainer'
import { Link } from 'react-router-dom'
import { PageHeader } from '../../components/Layout/PageHeader'
import { Box } from '@mui/system'
import { Typography, Button, FormControl, TextField, InputLabel, Select, MenuItem, FormGroup, FormControlLabel, Switch } from '@mui/material'
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { DataGrid } from '@mui/x-data-grid';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import { EventLogService } from '../../services/DataService'
import { LogService } from '../../services/DataService'
import SaveIcon from '@mui/icons-material/Save';
import { DeviceService } from '../../services/DataService'
import { MetadataService } from '../../services/DataService'
import { Translate, Localize, translate } from 'react-i18nify';
import { useParams, useNavigate } from 'react-router-dom';
import { LocationService } from '../../services/DataService';
import { Devices } from '@mui/icons-material'
import AlertBox from '../../components/AlertBox'
import { PatientDetailText } from "../../components/Patient/PatientDetailText";
export const ProDeviceEdit = () => {




    const [device, setDevice] = useState([]);
    const { udsn } = useParams();
    const navigate = useNavigate();
    const [loadingLocation, setloadingLocation] = useState(false);
    const [locations, setLocations] = useState([]);
    const [location, setLocation] = useState();
    const [owner, setOwner] = useState();
    const [metadataValues, setMetadataValues] = useState();
    const [metadataLoading, setMetadataLoading] = useState(false);
    const [loadingDevice, setLoadingDevice] = useState(false);

    const [statusList, setStatusList] = useState();
    const [dmrList, setDmrList] = useState();
    const [swList, setSwList] = useState();
    const [fwList, setFwList] = useState();

    const [fw, setFw] = useState();
    const [sw, setSw] = useState();
    const [dmr, setDmr] = useState();
    const [status, setStatus] = useState();

    const [remark, setRemark] = useState('');

    const [alertBox, setAlertBox] = useState({
        "visibility": false,
        "statusCode": null,
        "text": null,
        "error": null
    }
    );

    const handleAlertBox = (visibility, statusCode, text, error) => {
        setAlertBox({
            "visibility": visibility,
            "statusCode": statusCode,
            "text": text,
            "error": error
        }
        )
    }

    const dismissAlertBox=()=>{
        setAlertBox({
            "visibility": false,
            "statusCode": null,
            "text": null,
            "error": null

        })
    }


    useEffect(() => {
        (async () => {
            let deviceResult = await DeviceService.getProByUdsn(udsn);
            setDevice(deviceResult.data);
            setLoadingDevice(true);
            console.log(deviceResult.data)
        })();

        (async () => {
            let values = await MetadataService.getValues();
            setMetadataValues(values.data);

            setDmrList(values.data.filter(x => x.metaData.id == 4))
            setSwList(values.data.filter(x => x.metaData.id == 8))
            setFwList(values.data.filter(x => x.metaData.id == 9))
            setStatusList(values.data.filter(x => x.metaData.id == 2))
            setMetadataLoading(true);

        })();

        (async () => {
            let apiResult = await LocationService.getByRoles(["DeviceManager"]);
            setLocations(apiResult.data.sort((a, b) => a.name > b.name ? 1 : -1));
            setloadingLocation(true);
        })();
    }, [])

    const handleLocationChange = (event, newValue) => {
        setLocation(newValue.props.value);
    };
    const handleOwnerChange = (event, newValue) => {
        setOwner(newValue.props.value);
    };
    const handleDmrChange = (event, newValue) => {
        setDmr(newValue.props.value);
    };
    const handleSwChange = (event, newValue) => {
        setSw(newValue.props.value);
    };
    const handleFwChange = (event, newValue) => {
        setFw(newValue.props.value);
    };
    const handleStatusChange = (event, newValue) => {
        setStatus(newValue.props.value);
    };
    const handleRemarkChange = (event, newValue) => {
        setRemark(event.target.value);
    };

    const handleSubmit = async() => {
        DeviceService.updateProDevice(device.id,
            {
                "id": device.id,
                "softwareVersionId": sw,
                "firmwareVersionId": fw,
                "dmrMetadataId": dmr,
                "statusId": status,
                "locationId": location,
                "ownerId": owner,
                "log": ((remark=="")? null:("Remarks: "+ remark)),
              }
        ).then(data => {
            handleAlertBox(true, 200, null, null) 
            setTimeout(()=>{
            navigate("/device/PRO/"+udsn)
            },2000)
        })
        .catch(err=>{
                console.log({err:err})
                handleAlertBox(true, err?.response?.status, null, err?.response?.data?.join(",")) 
            })
    }


    return (
        <PageContainer>
            <PageHeader leftAlign><Translate value="ProDeviceEdit" /></PageHeader>
            <Box sx={{ width:{xs:"100%", sm:"500px"}}}>
                {/* <Typography>ID: {device.id}</Typography>
                <Typography>UDSN: {device?.udsn}</Typography>
                <Typography>Printboard: {device?.printSerial}</Typography>
                <Typography>Valve: {device?.valveSerial}</Typography>
                <Typography>Flowmeter: {device?.flowmeterSerial}</Typography>
                <Typography>Oximeter: {device?.oximeterSerial}</Typography>

                <Typography>DMR: {device?.dmrMetadata?.value}</Typography>
                <Typography>SW: {device?.softwareVersion?.value}</Typography>
                <Typography>FW: {device?.firmwareVersion?.value}</Typography>
                <Typography>Status: {device?.status?.value}</Typography>

                <Typography>Location: {device.location?.name}</Typography>
                <Typography>Owner: {device.owner?.name}</Typography> */}
                <PatientDetailText
                    label={<Translate value="Id" />}
                    value={device.id}
                ></PatientDetailText>
                <PatientDetailText
                    label={<Translate value="UDSN" />}
                    value={device.udsn}
                ></PatientDetailText>
                <PatientDetailText
                    label={<Translate value="PUID" />}
                    value={device?.processorSerial? device.processorSerial : "-" }
                ></PatientDetailText>
                <PatientDetailText
                    label={<Translate value="Printboard" />}
                    value={device?.printSerial}
                ></PatientDetailText>
                <PatientDetailText
                    label={<Translate value="Valve" />}
                    value={device?.valveSerial}
                ></PatientDetailText>
                <PatientDetailText
                    label={<Translate value="Flowmeter" />}
                    value={device?.flowmeterSerial}
                ></PatientDetailText>
                <PatientDetailText
                    label={<Translate value="Oximeter" />}
                    value={device?.oximeterSerial}
                ></PatientDetailText>
                <PatientDetailText
                    label={<Translate value="Dmr" />}
                    value={device?.dmrMetadata?.value}
                ></PatientDetailText>
                <PatientDetailText
                    label={<Translate value="Sw" />}
                    value={device?.softwareVersion?.value}
                ></PatientDetailText>
                <PatientDetailText
                    label={<Translate value="Fw" />}
                    value={device?.firmwareVersion?.value}
                ></PatientDetailText>

                <PatientDetailText
                    label={<Translate value="Status" />}
                    value={<Translate value={device?.status?.value} />}
                ></PatientDetailText>
                <PatientDetailText
                    label={<Translate value="DeviceOwner" />}
                    value={device.owner?.name}
                ></PatientDetailText>
                <PatientDetailText
                    label={<Translate value="DeviceLocation" />}
                    value={device.location?.name}
                ></PatientDetailText>
                <br/>

                {loadingDevice ?
                    <>
                        {/* <InputLabel id="dmrLabel"><Translate value="Dmr" /></InputLabel>
                        {metadataLoading ?
                            <Select
                                required
                                id="dmr"
                                value={dmr || device?.dmrMetadata?.id}
                                onChange={handleDmrChange}
                                sx={{ width:"100%"}}
                            >
                                {dmrList.map(value => {
                                    return <MenuItem key={value.id} value={value.id}>{value.value}</MenuItem>
                                })}
                            </Select> : <span><Translate value="Loading" /></span>
                        } */}

                        <InputLabel id="swLabel"><Translate value="Sw" /></InputLabel>
                        {metadataLoading ?
                            <Select
                                required
                                id="sw"
                                value={sw || device.softwareVersion.id}
                                onChange={handleSwChange}
                                sx={{ width:"100%" }}
                            >
                                {swList.map(value => {
                                    return <MenuItem key={value.id} value={value.id}>{value.value}</MenuItem>
                                })}
                            </Select> : <span><Translate value="Loading" /></span>
                        }

                        <InputLabel id="fwLabel"><Translate value="Fw" /></InputLabel>
                        {metadataLoading ?
                            <Select
                                required
                                id="fw"
                                value={fw || device.firmwareVersion?.id}
                                onChange={handleFwChange}
                                sx={{ width:"100%" }}
                            >
                                {fwList.map(value => {
                                    return <MenuItem key={value.id} value={value.id}>{value.value}</MenuItem>
                                })}
                            </Select> : <span><Translate value="Loading" /></span>
                        }

                        <InputLabel id="statusLabel"><Translate value="Status" /></InputLabel>
                        {metadataLoading ?
                            <Select
                                required
                                id="status"
                                value={status || device.status?.id}
                                onChange={handleStatusChange}
                                sx={{ width:"100%" }}
                            >
                            {statusList.map(value =>{
                                let currentStaus = device.status?.value;
                                if(currentStaus=="SCRAPPED") {
                                    // show only SCRAPPED status (it should not be possible to change the status of a scrapped device)
                                    if(value.id===device.status?.id){
                                        return <MenuItem key={value.id} value={value.id}><Translate value={value.value}/></MenuItem>
                                    }
                                } else if (currentStaus=="Assembled" || currentStaus=="Tested" || currentStaus=="ReadyForRepair" || currentStaus=="Packaged") { 
                                    // id device has a status from the production flow prior to production QA approval, device status cannot be changed to anything but SCRAPPED or current status
                                    if(value.value==="SCRAPPED" ||value.id===device.status?.id){
                                        return <MenuItem key={value.id} value={value.id}><Translate value={value.value}/></MenuItem>
                                    }
                                } else {
                                if(value.value==="ReadyForInvestigation"||value.value==="AtRepairO2matic"||value.value==="ReadyForTesting"||value.value==="SCRAPPED"||value.id===device.status?.id){
                                    return <MenuItem key={value.id} value={value.id}><Translate value={value.value}/></MenuItem>
                                }
                                }
                                //return <MenuItem disabled key={value.id} value={value.id}>{value.value}</MenuItem>
                            })}
                            </Select> : <span><Translate value="Loading" /></span>
                        }

                        <InputLabel id="ownerLabel"><Translate value="DeviceOwner" /></InputLabel>
                        {loadingLocation ?
                            <Select
                                required
                                id="owner"
                                value={owner || device?.owner?.id}
                                onChange={handleOwnerChange}
                                sx={{ width:"100%" }}
                            >
                                {locations.map(location => {
                                    return <MenuItem key={location.id} value={location.id}>{location.name}</MenuItem>
                                })}
                            </Select>
                            : <span><Translate value="Loading" /></span>
                        }

                        <InputLabel id="locationLabel"><Translate value="DeviceLocation" /></InputLabel>
                        {loadingLocation ?
                            <Select
                                required
                                id="location"
                                value={location || device?.location?.id}
                                onChange={handleLocationChange}
                                sx={{ width:"100%" }}
                            >
                                {locations.map(location => {
                                    return <MenuItem key={location.id} value={location.id}>{location.name}</MenuItem>
                                })}
                            </Select>
                            : <span><Translate value="Loading" /></span>
                        }

                        <InputLabel id="remarksLabel"><Translate value="Remarks" /></InputLabel>
                        <TextField sx={{ width:"100%" }} id="outlined-basic" inputProps={{ maxLength: 250 }} placeholder={translate("Remarks")} variant="outlined" value={remark} onChange={handleRemarkChange} />


                    </>

                    : <span><Translate value="Loading" /></span>
                }
            </Box>
            <Button onClick={handleSubmit} sx={{ mt: 2, minWidth:"200px"}} color="success" variant="contained" startIcon={<SaveIcon />}>
                <Translate value="Save" />
            </Button>
            <AlertBox visible={alertBox.visibility} text={alertBox.text} error={alertBox.error} statusCode={alertBox.statusCode} onHide={dismissAlertBox}/>
        </PageContainer>
    )
}
