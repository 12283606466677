import React, {useState, useEffect} from "react";
import { useNavigate } from "react-router-dom";
import { PageHeader } from "../../components/Layout/PageHeader";
import PageContainer from "../../components/PageContainer";
import { Translate, Localize, translate } from 'react-i18nify';
import { Button } from '@mui/material'
import {UpdatesBanner} from "../../components/UpdatesBanner";

export const Device = () => {

    let navigate = useNavigate();
    const routeChange = (params, event) => {
        let path = "/device/" + params.row.id;
        navigate(path);
    };

    const navigateQRscanner = () => {
        // 👇️ navigate to /
        navigate('/device/QRscanner');
    };

    const navigateHOTdevices = () => {
        // 👇️ navigate to /
        navigate('/device/HOT');
    };

    const navigatePROdevices = () => {
        // 👇️ navigate to /
        navigate('/device/PRO');
    };


    return(
        <PageContainer>
            <PageHeader leftAlign><Translate value="Devices" /></PageHeader>

            <UpdatesBanner update_description="UpdatesDescriptionDevicesPage"/>

            <Button onClick={navigateQRscanner} sx={{ mt: 4, alignSelf: "start", minWidth: '200px' }} variant="contained"><Translate value="QRScanner" /></Button>
            <Button onClick={navigateHOTdevices} sx={{ mt: 4, alignSelf: "start", minWidth: '200px' }} variant="contained"><Translate value="HotDevices" /></Button>
            <Button onClick={navigatePROdevices} sx={{ mt: 4, alignSelf: "start", minWidth: '200px' }} variant="contained"><Translate value="ProDevices" /></Button>

        </PageContainer>
    )
}

