import { Navigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { isAuthenticated,isAuthenticatedTwo, isExpired, loginExpired } from "../redux/actions/auth";
import { useEffect, useState } from "react";
import jwt_decode from 'jwt-decode'

export const RequireAuth = ({ children, redirectTo, authorize }) => {

    const { user } = useSelector((state) => state.auth);
    const  roles  = useSelector((state) => state?.auth?.roles);
    const roleData =roles?.data;

    //const [hasResult, setHasResult] = useState(false);
    //const [result, setResult] = useState(false);
    let result=false;
    var isExpiredResult = true;

    const dispatch = useDispatch();

    
    var res=isAuthenticatedTwo(authorize,roleData);
    result=res;
    
    //setHasResult(true);


    if (user == null || user.accessToken == null) {
        return <Navigate to="/login" />;
    }

    var encodedToken = user.accessToken;
    var token = jwt_decode(encodedToken);
    isExpiredResult = isExpired(token);
    if (isExpiredResult) {
        dispatch(loginExpired()).then(data => {
            // setResult(data);
            // setHasResult(true);
        });

        return <Navigate to="/login" />;
    }

    return (
        <>
            {result ? children : <Navigate to={redirectTo} />}
        </>
    )
}
