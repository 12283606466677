import React, { Component } from 'react';
import PageContainer from "../components/PageContainer"
import { PageHeader } from "../components/Layout/PageHeader"
export default class UserGuide extends Component {
    //static displayName = Weatherforecast.name;

    constructor(props) {
        super(props);
        this.state = { forecasts: [], loading: true };
    }

    componentDidMount() {

    }
    render() {

        return (
            <PageContainer>
                <PageHeader leftAlign>User guide</PageHeader>
                    <p>A user guide will be available on this page in released product version.</p>
               
            </PageContainer>
        );
    }


}
