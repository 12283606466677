import { translate, Translate, Localize } from 'react-i18nify';
import TimeUtils from '../../utils/TimeUtils';
import { LogService } from "../../services/DataService";


export const DailyReportDownloadCsv = (data, date, setLoading) => {


    let spo2Summary = data?.spO2Summary
    let o2Summary = data?.o2Summary
    let pulseSummary = data?.pulseSummary
    let patient = data?.patient?.patient
    let sessions = data?.sessions
    let detailedSessions= data?.detailedSessions
    let treatments = data?.treatments
    let overalDuration = data?.numberOfSamples

    let treatmentData = data?.treatmentData
    let rows = [
        ["sep=;"],
        [translate("DaySummary"), TimeUtils.formatDateYYMMDD(date)],
        ["Patient O2matic ID", patient.id.value],
        [translate("LengthOfRecordedData"), TimeUtils.secondsToHMS(overalDuration)],
        []
    ];

    rows.push([translate("DetailedDataStatistics")])
    rows.push([translate("ImportantNote") + ": " + translate("DetailedDataDisclaimerTableCsv")])
    rows.push(["SpO2", "SpO2 (%)", translate("TimeSpent(%)")])
    spo2Summary.values.forEach((row) => {
        rows.push([translate(row.type), row.value + " %", TimeUtils.secondsToHMS(row.duration) + " (" + (row.duration / overalDuration * 100).toFixed(2) + ")"])
    })
    rows.push([])
    rows.push([translate("Pulse"), translate("PulseBpm"), translate("TimeSpent(%)")])
    pulseSummary.values.forEach((row) => {
        rows.push([translate(row.type), row.value + " bpm", TimeUtils.secondsToHMS(row.duration) + " (" + (row.duration / overalDuration * 100).toFixed(2) + ")"])
    })
    rows.push([])
    rows.push([translate("O2Flow"), translate("O2Flow")+ " ("  + translate("l/min") + ")", translate("TimeSpent(%)")]);
    o2Summary.values.forEach((row) => {
        rows.push([translate(row.type), row.value + " " + translate("l/min"), TimeUtils.secondsToHMS(row.duration) + " (" + (row.duration / overalDuration * 100).toFixed(2) + ")"])
    });

    rows.push([])
    rows.push([translate("DetailedDataStatisticsByTreatmentProfile")])
    rows.push([translate("ImportantNote") + ": " + translate("DetailedDataDisclaimerTableCsv")])
    rows.push([translate("SpO2")])
    rows.push([translate("TreatmentProfile"), translate("Mode") + " (%) " + translate("TimeSpent(%)"), translate("Minimum") + " (%) " + translate("TimeSpent(%)"), translate("Maximum") + " (%) " + translate("TimeSpent(%)"), translate("AverageRange") + " (%) " + translate("TimeSpent(%)")])
    treatmentData.forEach(row => {
        let name="spO2Summary";
        let treatmentDuration=row.numberOfSamples;
        rows.push([row.treatment.name + "(" + row?.treatment.id + ")",
        row[name].values.find(l => l.type === "MODE").value+" % ("+(row[name].values.find(l => l.type === "MODE").duration / treatmentDuration * 100).toFixed(2)+"%)",
        row[name].values.find(l => l.type === "MINIMUM").value+" % ("+(row[name].values.find(l => l.type === "MINIMUM").duration / treatmentDuration * 100).toFixed(2)+"%)",
        row[name].values.find(l => l.type === "MAXIMUM").value+" % ("+(row[name].values.find(l => l.type === "MAXIMUM").duration / treatmentDuration * 100).toFixed(2)+"%)",
        row[name].values.find(l => l.type === "AVERAGE").value+" % ("+(row[name].values.find(l => l.type === "AVERAGE").duration / treatmentDuration * 100).toFixed(2)+"%)",
        ]
        )
    })
    rows.push([])

    rows.push([translate("Pulse")])
    rows.push([translate("TreatmentProfile"), translate("Mode") + " (" + translate("bpm") + ") " + translate("TimeSpent(%)"), translate("Minimum") + " (" + translate("bpm") + ") " + translate("TimeSpent(%)"), translate("Maximum") + " (" + translate("bpm") + ") " + translate("TimeSpent(%)"), translate("AverageRange") + " (" + translate("bpm") + ") " + translate("TimeSpent(%)")])
    treatmentData.forEach(row => {
        let name="pulseSummary";
        let treatmentDuration=row.numberOfSamples;
        rows.push([row.treatment.name + "(" + row?.treatment.id + ")",
        row[name].values.find(l => l.type === "MODE").value+" bpm ("+(row[name].values.find(l => l.type === "MODE").duration / treatmentDuration * 100).toFixed(2)+"%)",
        row[name].values.find(l => l.type === "MINIMUM").value+" bpm ("+(row[name].values.find(l => l.type === "MINIMUM").duration / treatmentDuration * 100).toFixed(2)+"%)",
        row[name].values.find(l => l.type === "MAXIMUM").value+" bpm ("+(row[name].values.find(l => l.type === "MAXIMUM").duration / treatmentDuration * 100).toFixed(2)+"%)",
        row[name].values.find(l => l.type === "AVERAGE").value+" bpm ("+(row[name].values.find(l => l.type === "AVERAGE").duration / treatmentDuration * 100).toFixed(2)+"%)",
        ]
        )
    })
    rows.push([])

    rows.push([translate("O2Flow")])
    rows.push([translate("TreatmentProfile"), translate("Mode") + " (" + translate("l/min") + ") " + translate("TimeSpent(%)"), translate("Minimum") + " (" + translate("l/min") + ") " +  translate("TimeSpent(%)"), translate("Maximum") + " (" + translate("l/min") + ") " +  translate("TimeSpent(%)"), translate("AverageRange") + " (" + translate("l/min") + ") " +  translate("TimeSpent(%)")])
    treatmentData.forEach(row => {
        let name="o2Summary";
        let treatmentDuration=row.numberOfSamples;
        rows.push([row.treatment.name + " (" + row?.treatment.id + ")",
        row[name].values.find(l => l.type === "MODE").value+" l/min ("+(row[name].values.find(l => l.type === "MODE").duration / treatmentDuration * 100).toFixed(2)+"%)",
        row[name].values.find(l => l.type === "MINIMUM").value+" l/min ("+(row[name].values.find(l => l.type === "MINIMUM").duration / treatmentDuration * 100).toFixed(2)+"%)",
        row[name].values.find(l => l.type === "MAXIMUM").value+" l/min ("+(row[name].values.find(l => l.type === "MAXIMUM").duration / treatmentDuration * 100).toFixed(2)+"%)",
        row[name].values.find(l => l.type === "AVERAGE").value+" l/min ("+(row[name].values.find(l => l.type === "AVERAGE").duration / treatmentDuration * 100).toFixed(2)+"%)",
        ]
        )
    })
    rows.push([])
    rows.push([translate("TreatmentProfiles")])

    rows.push(["ID",translate("Name"),translate("TemplateId"),translate("LocationId"),translate("LocationName"),translate("Address"),translate("Country"),"CRC",translate("Disabled"),translate("O2ComplianceGoal") + " " + translate("hours/day"),translate("AnalysingPhaseDuration(s)"),translate("O2RangeMaxWithout")+" (l/min)",translate("O2RangeMaxWith")+" (l/min)",translate("SpO2RangeMax")+" (%)",translate("FlowResponseRate") + " Ki",translate("O2RangeMinWithout")+" (l/min)",translate("O2RangeMinWith")+" (l/min)",translate("SpO2RangeMin")+" (%)",translate("ShortTermLength")+" (s/h)"])
    treatments.forEach(treatment=>{
        rows.push([
            treatment.id,
            treatment.name,
            treatment.templateId,
            treatment.location.id,
            treatment.location.name,
            treatment.location.address,
            treatment.location.countryCode,
            treatment.crc,
            treatment.disabled,
            treatment.minutesPrescribed/60,
            ...treatment.values.map(item => item.value)
        ])
    })

    rows.push([])
    rows.push([translate("Sessions")])
    rows.push([translate("SessionId"),translate("Treatment"),"",
    translate("Start"),translate("StartTime"),translate("SpO2Initial"),translate("PulseInitial"),translate("O2FlowInitial"),"",
    translate("Analyzing"),translate("StartTime"),translate("SpO2Min"),translate("SpO2Max"),translate("SpO2Average"),translate("SpO2StandardDeviation"),translate("PulseMin"),translate("PulseMax"),translate("PulseAverage"),translate("PulseStandardDeviation"),translate("O2FlowMin"),translate("O2FlowMax"),translate("O2FlowAverage"),translate("O2FlowStandardDeviation"),"",
    translate("Adjusting"),translate("StartTime"),translate("SpO2Min"),translate("SpO2Max"),translate("SpO2Average"),translate("SpO2StandardDeviation"),translate("PulseMin"),translate("PulseMax"),translate("PulseAverage"),translate("PulseStandardDeviation"),translate("O2FlowMin"),translate("O2FlowMax"),translate("O2FlowAverage"),translate("O2FlowStandardDeviation"),"",
    translate("End"),translate("EndTime"),translate("NextSession"),translate("LastMeasuredSpO2"),translate("LastMeasuredPulse"),translate("O2FlowTarget"),translate("Zone")]

    )

    sessions.forEach(session=>{
        rows.push([
            session?.deviceSessionId+" ("+session?.id+")",session?.treatment?.name+" ("+session?.treatment?.id+")","" + session.serialnumber,"",
            TimeUtils.formatDate(TimeUtils.convertUTCDateToLocalDate(new Date(session?.parts?.start?.timestamp))),
            session?.parts?.start?.values?.SpO2Initial,
            session?.parts?.start?.values?.PulseInitial,
            session?.parts?.start?.values?.OxygenInitial,
            "",
            "",
            TimeUtils.formatDate(TimeUtils.convertUTCDateToLocalDate(new Date(session?.parts?.analyzing?.timestamp))),
            session?.parts?.analyzing?.values?.SpO2Minimum,
            session?.parts?.analyzing?.values?.SpO2Maximum,
            session?.parts?.analyzing?.values?.SpO2Average,
            session?.parts?.analyzing?.values?.SpO2StandardDeviation,
            session?.parts?.analyzing?.values?.PulseMinimum,
            session?.parts?.analyzing?.values?.PulseMaximum,
            session?.parts?.analyzing?.values?.PulseAverage,
            session?.parts?.analyzing?.values?.PulseStandardDeviation,
            session?.parts?.analyzing?.values?.OxygenMinimum,
            session?.parts?.analyzing?.values?.OxygenMaximum,
            session?.parts?.analyzing?.values?.OxygenAverage,
            session?.parts?.analyzing?.values?.OxygenStandardDeviation,
            "",
            "",
            TimeUtils.formatDate(TimeUtils.convertUTCDateToLocalDate(new Date(session?.parts?.adjusting?.timestamp))),
            session?.parts?.adjusting?.values?.SpO2Minimum,
            session?.parts?.adjusting?.values?.SpO2Maximum,
            session?.parts?.adjusting?.values?.SpO2Average,
            session?.parts?.adjusting?.values?.SpO2StandardDeviation,
            session?.parts?.adjusting?.values?.PulseMinimum,
            session?.parts?.adjusting?.values?.PulseMaximum,
            session?.parts?.adjusting?.values?.PulseAverage,
            session?.parts?.adjusting?.values?.PulseStandardDeviation,
            session?.parts?.adjusting?.values?.OxygenMinimum,
            session?.parts?.adjusting?.values?.OxygenMaximum,
            session?.parts?.adjusting?.values?.OxygenAverage,
            session?.parts?.adjusting?.values?.OxygenStandardDeviation,
            "",
            "",
            TimeUtils.formatDate(TimeUtils.convertUTCDateToLocalDate(new Date(session?.parts?.end?.timestamp))),
            TimeUtils.formatDate(TimeUtils.convertUTCDateToLocalDate(TimeUtils.addSeconds(new Date(session?.parts?.end?.timestamp),parseInt(session?.parts?.end?.values?.NextDiagnosisSeconds)))),
            session?.parts?.end?.values?.SpO2Final,
            session?.parts?.end?.values?.PulseFinal,
            session?.parts?.end?.values?.OxygenTarget,
            session?.parts?.end?.values?.Zone])
    })
    rows.push([])

    rows.push([translate("DetailedData")])
    rows.push([translate("UnixTimestamp"),translate("UTCTimestamp"),translate("LocalTimestamp"),translate("SpO2")+" (%)",translate("Pulse")+" (" +translate("bpm") + ")",translate("O2Flow")+" (" +translate("l/min")+")"])

    detailedSessions.forEach(detailedSession=>{
        detailedSession.values.forEach(value=>{
            rows.push([new Date(value.timestamp+"Z").getTime(),new Date(value.timestamp + "Z").toISOString(),TimeUtils.utcToLocal(new Date(value.timestamp),true),value.spO2,value.pulse,value.o2])
        })

    })



    let csvContent = "data:text/csv;charset=utf-8,"
        + rows.map(e => e.join(";")).join("\n");

    var encodedUri = encodeURI(csvContent);
    var link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "O2matic - " + translate("Patient") + " " + patient?.id?.value + " - " + translate("DailyReport") + " " + TimeUtils.formatDateYYMMDD(new Date(date)) + ".csv");
    document.body.appendChild(link);
    link.click();
    if(setLoading)
        setLoading(false);


    (async () => {
        await LogService.saveLog([TimeUtils.formatDateYYMMDD(date)], "Daily report", "csv", patient?.id?.value).catch((error) => {
            console.error("Error saving log: ", error);
        })
    })();

}