import { Translate, Localize, getLocale, translate } from 'react-i18nify';
import { Typography, TextField, useTheme, useMediaQuery } from '@mui/material'
import { useState } from 'react';
import { Box } from '@mui/system';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import CalendarElement from './CalendarElement';
import TimeUtils from '../../utils/TimeUtils';
import CalendarDayName from './CalendarDayName';
import CalendarDayEmpty from './CalendarDayEmpty';
import { enGB, da, es } from "date-fns/locale"; /* import the needed languages to translate months */
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DayDataDialog } from '../Patient/DayDataDialog';

const ComplainceCalendar = (props) => {
    const [openDayDialog, setOpenDayDialog] = useState(false);
    const [dialogDate, setDialogDate] = useState(null);
    
    const locale = getLocale();
    const theme = useTheme();
    const lessThanSmall = useMediaQuery(theme.breakpoints.down("sm"));

    const handleOpenDayDialog = (date) => {
        setOpenDayDialog(true);
        setDialogDate(date);
    }

    const closeDayDialogDetail = (row) => {
        setOpenDayDialog(false);
        setDialogDate(null);
    };

    const createHeader = (props) => {
        return (
        <>
            <DayDataDialog
                patientId={props.patientId}
                date={dialogDate}
                open={openDayDialog}
                onClose={closeDayDialogDetail}
            />


            <Box sx={{ mt: 1, mb: 1, width: "100%", backgroundColor: "white", borderRadius: 1 }}>
            <LocalizationProvider dateAdapter={AdapterDateFns} locale={enGB}> {/* Change here to enable translation of month names */}
                <DatePicker
                    views={['year']}
                    label={translate("Year")}
                    value={props.complaienceYear}
                    onChange={props.setComplaienceYear}
                    renderInput={(params) => <TextField sx={{ mr: 2, mb: 2 }} {...params} helperText={null} />}
                    disableFuture={true}
                    yearsPerRow={3}
                />
                <DatePicker
                    views={['month']}
                    label={translate("Month")}
                    value={props.complaienceMonth}
                    onChange={props.setComplaienceMonth}
                    inputFormat="MMMM"
                    mask=""
                    renderInput={(params) => <TextField {...params} helperText={null} />}
                />
                </LocalizationProvider>
            </Box>
        </>
        )
    }

    const createEmptyDays = (numberofDays) => {
        return [...Array(numberofDays)].map((x, i) =>
            <CalendarDayEmpty key={i}></CalendarDayEmpty>
        )
    }
    const createCalendarDays = (data) => {
        return data.map(dayData => {
            return (<CalendarElement key={dayData.date} data={dayData} onClick={(e) => handleOpenDayDialog(e)} />)
        })
    }
    const createDayNames = (weekDays) => {
        return weekDays.map(item => <CalendarDayName key={item}>{item}</CalendarDayName>)
    }
    if (props.loading) {
        return <Box sx={{height:"500px"}}>
            {createHeader(props)}
            <Translate value="Loading"/>
        </Box>
    }
    if (!props.data || props.data.length == 0) {
        return (createHeader(props))
    }
    let weekDays = TimeUtils.getWeekDays(locale)
    let startDay = TimeUtils.getDayName(props.data[0].date, locale)
    let dayGap = weekDays.findIndex(day => day === startDay);

    return (<>

        <Box sx={{ mt: 1, width: "100%", backgroundColor: "white", borderRadius: 1 }}>
            {createHeader(props)}
            <Box sx={{ width:"100%", display: "grid", gap: "5px", gridTemplateColumns: { sm: "repeat(7, 1fr)", xs: "block" } }}>
                {!lessThanSmall ? createDayNames(weekDays) : null}
                {!lessThanSmall ? createEmptyDays(dayGap) : null}
                {createCalendarDays(props.data)}
            </Box>
        </Box>
    </>)
}
export default ComplainceCalendar;