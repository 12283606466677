
import TimeUtils from "../../utils/TimeUtils";
export class DayComplaience {
   constructor(date, id, complaienceDuration) {
      this.date = date
      this.id = id
      this.isActive = false
      this.unknown = true
      this.data = []
      this.secondsUsed = 0
      this.complaienceDuration = complaienceDuration * 60 * 60
   }
   addData(data) {
      this.data.push(data)
   }
   getComplaienceSeconds() {
      return Math.round(this.secondsUsed)
   }
   setFullDayComplaience() {
      this.secondsUsed = 24 * 60 * 60
      this.calculateComplaianceColor()
   }
   calculateComplaianceColor() {
      if (this.unknown == true) {
         this.complaienceColor = "gray"
      }
      else if (this.secondsUsed >= this.complaienceDuration) {
         this.complaienceColor = "green"
      }
      else if (this.secondsUsed > this.complaienceDuration - (this.complaienceDuration / 100) * 50) {
         this.complaienceColor = "orange"
      }
      else {
         this.complaienceColor = "red"
      }
   }
   setActive(value) {
      this.isActive = value
   }
   setKnown() {
      this.unknown = false
   }
   setUnknown() {
      let endComplaienceDate = TimeUtils.addUTCTimezone(this.date.toString())
      endComplaienceDate.setUTCHours(23, 59, 59, 999);
      this.data.every(datapoint => {
         if (datapoint.endTime > endComplaienceDate) {
            this.unknown = false;
            return false;
         }
         return true;
      })
   }
   calculateComplaince() {
      this.setUnknown()
      this.calculateComplaianceColor()
      this.data.forEach(datapoint => {
         if (isTheSameDate(datapoint.startTime, this.date) && isTheSameDate(datapoint.endTime, this.date)) {
            // add difference between starttime and end time
            let difference = datapoint.endTime - datapoint.startTime
            datapoint.addCalulatedComplaience(this.date, (difference / 1000))
            this.secondsUsed += (difference / 1000)
            this.calculateComplaianceColor()
            return;
         }
         else if (isTheSameDate(datapoint.startTime, this.date) && !isTheSameDate(datapoint.endTime, this.date)) {
            //add difference between startTime and end of DayComplaience date
            let endComplaienceDate = new Date(this.date)
            endComplaienceDate.setHours(23, 59, 59, 999);
            let difference = endComplaienceDate - datapoint.startTime;
            datapoint.addCalulatedComplaience(this.date, (difference / 1000))
            this.secondsUsed += (difference / 1000)
            this.calculateComplaianceColor()
            return;
         }
         else if (!isTheSameDate(datapoint.startTime, this.date) && isTheSameDate(datapoint.endTime, this.date)) {
            //add difference between endtime and start of DayComplaience date
            //let startComplaienceDate=TimeUtils.addUTCTimezone(this.date.toString())
            let startComplaienceDate = new Date(this.date);
            startComplaienceDate.setHours(0, 0, 0, 0);
            let difference = datapoint.endTime - startComplaienceDate//-3600000;
            datapoint.addCalulatedComplaience(this.date, (difference / 1000))
            this.secondsUsed += (difference / 1000)
            this.calculateComplaianceColor()
            return;
         }
         else if (!isTheSameDate(datapoint.startTime, this.date) && !isTheSameDate(datapoint.endTime, this.date)) {
            //add 24 hours complaience
            let difference = 24 * 60 * 60 * 1000
            datapoint.addCalulatedComplaience(this.date, (difference / 1000))
            this.secondsUsed += (difference / 1000)
            this.calculateComplaianceColor()
            return;
         }
         else {
            throw new Error("This should not happened")
         }
      })
   }
}

export const addEventsToTimeline = (events) => {
   let timespans = []
   events.forEach(event => {
      timespans.push(new TimeSpan(getStartTimeFromEvent(event.timestamp, event.deviceUpTime), getEndtimeFromEvent(event.timestamp)))
   })
   let timeline = [];

   timespans.sort((a, b) => a.startTime.getTime() - b.startTime.getTime());

   for (const event of timespans) {
      if (timeline.length === 0 || event.startTime > timeline[timeline.length - 1].endTime) {
         timeline.push(event);
      } else {
         const lastEvent = timeline[timeline.length - 1];
         lastEvent.endTime = new Date(Math.max(lastEvent.endTime, event.endTime));
      }
   }
   return timeline;
}

export const adjustStartTime = (timespans, startTime) => {
   for (const event of timespans) {
      if (event.startTime < startTime) {
         event.startTime = startTime
      }
      if (event.startTime > event.endTime) {
         event.endTime = startTime
      }
      event.getDaysArray(event.startTime, event.endTime)
   }
   return timespans;
}

export const generateComplaience = (events, otherEvents, year, month, complaienceDuration, activeDays) => {
   let timespans = []
   events.forEach(event => {
      timespans.push(new TimeSpan(getStartTimeFromEvent(event.timestamp, event.deviceUpTime), getEndtimeFromEvent(event.timestamp)))
   })
   if (otherEvents != null) {
      timespans = timespans.concat(otherEvents)
   }

   let dayComplainces = createListForAMonth(month, year, complaienceDuration)
   dayComplainces = addTimeSpansToDates(timespans, dayComplainces)
   dayComplainces = addisActiveToDates(activeDays, dayComplainces)
   dayComplainces.forEach(dayComplaince => dayComplaince.calculateComplaince())

   return dayComplainces
}

export class TimeSpan {
   constructor(startTime, endTime) {
      let utcStart = new Date(startTime)
      this.startTime = startTime
      this.endTime = endTime
      this.calculatedComplaince = []
      this.getDaysArray(startTime, endTime)
   }
   addCalulatedComplaience(date, complaience) {
      this.calculatedComplaince.push({ "date": date, "complaienceSeconds": complaience })
   }
   getDaysArray(start, end) {
      let startDate = new Date(start)
      let endDate = new Date(end)
      startDate.setHours(0, 0, 0, 0);
      endDate.setHours(23, 59, 59, 999);
      for (var arr = [], dt = new Date(startDate); dt <= new Date(endDate); dt.setDate(dt.getDate() + 1)) {
         arr.push(new Date(dt));
      }
      this.dates = arr;
   };
}
export const createListForAMonth = (month, year, complaienceDuration) => {
   let dayComplaiences = []
   let days = TimeUtils.getDaysInMonthUTC(month, year)
   days.forEach((day, index) => {
      dayComplaiences.push(new DayComplaience(day, index, complaienceDuration))
   })
   return dayComplaiences
}

export const isTheSameDate = (firstDate, secondDate) => {
   const firstDateString = firstDate.toLocaleDateString('en-US', {
      timeZone: 'UTC',
   });
   const secondDateString = secondDate.toLocaleDateString('en-US', {
      timeZone: 'UTC',
   });

   if (firstDate.toDateString() === secondDate.toDateString()) {
      return true;
   }
   return false;
}

export const addTimeSpansToDates = (timeSpans, listOfDays) => {
   timeSpans.forEach(timeSpan => {
      timeSpan.dates.forEach(timeSpanDate => {
         listOfDays.forEach(day => {
            if (isTheSameDate(timeSpanDate, day.date)) {
               day.addData(timeSpan)
            }
         })
      })
   })
   return listOfDays
}

export const addisActiveToDates = (activeDays, listOfDays) => {
   activeDays.forEach(activeDay => {
      listOfDays.forEach(day => {
         let start = TimeUtils.utcToLocalDate(activeDay.start);
         let end = TimeUtils.utcToLocalDate(activeDay.end);
         if (isTheSameDate(start, day.date) ||
            isTheSameDate(end, day.date)) {
            day.setActive(true)
         }
      })
   })
   return listOfDays
}

export const calculateComplaince = (events) => {
   let days = TimeUtils.getDaysInMonthUTC(1, 2022)
}


export const getStartTimeFromEvent = (timestamp, deviceUpTime) => {
   if (deviceUpTime == undefined) {
      deviceUpTime = 0
   }
   // let utc=TimeUtils.utcToLocalDate(timestamp);
   // let utcTimestmap=new Date(TimeUtils.utcToLocal(timestamp));
   return new Date((TimeUtils.utcToLocalDate(timestamp) - deviceUpTime * 1000));
}

export const getEndtimeFromEvent = (timestamp) => {
   return TimeUtils.utcToLocalDate(timestamp)
}


//'2023-07-18T13:03:51.177Z'