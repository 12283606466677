import { Dialog, Typography, Box, Button, FormLabel, TextField } from "@mui/material";
import { useState } from "react";
import { Translate, translate } from "react-i18nify";

export const RepairDialog = (props) => {
    const component = props.component
    const [state, setState] = useState({ value: "" })
    const onChange = (e) => {
        const newValue = e.target.value;
        let valid = true;
        if (component.pattern) {
            valid = false;
            const reg = new RegExp(component.pattern);
            valid = reg.test(newValue);
        }
        setState(prev => { return { ...prev, value: newValue, error: !valid, errorText: valid ? null : translate("Format") + " " + component?.format } })
        //setState(prev=>{return {...prev,value:newValue}})
    }

    const onSubmit = (e) => {
        e.preventDefault()
        props.onConfirm({ value: state.value, name: component.name, code:component.code })
        setState({ value: "" })
    }
    const onClose = (e)=>{
        e.preventDefault()
        setState({ value: "" })
        props.onClose()
    }
    if (component == null) return;
    return (
        <Dialog
            fullWidth
            maxWidth="xs"
            sx={{ width: "100%" }}
            open={props.open}
            onClose={props.onClose}
        >
            <Box sx={{ p: 5 }} component="form" onSubmit={onSubmit}>


                <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <Typography sx={{ width: "100%", textAlign: "center" }} component="h2" variant="h5">
                        <Translate value="Replace" /> <Translate value={component.name} />
                    </Typography>
                    {component.emptyComponent ? null : <>
                        <FormLabel id="" sx={{ width: "60%" }}>
                            <Typography variant="h6"> <Translate value = "OriginalValue"/> </Typography>
                        </FormLabel>
                        <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
                            <TextField
                                sx={{}}
                                id="Original"
                                disabled
                                value={component.value}
                            ></TextField>
                        </Box>

                        <FormLabel id="" sx={{ width: "60%" }}>
                            <Typography variant="h6"> <Translate value = "NewValue"/> </Typography>
                        </FormLabel>
                        <Box sx={{ display: "flex", flexDirection: "column" }}>
                            <TextField
                                sx={{}}
                                id="new"
                                inputProps={{
                                    pattern: component?.pattern

                                }}
                                error={state.error}
                                required
                                onChange={onChange}
                                value={state.value}
                            ></TextField>
                            {state.error ? (
                                <Typography variant="body">
                                    {state.errorText}
                                </Typography>
                            ) : null}
                        </Box>
                    </>
                    }
                </Box>

                <Box sx={{ display: "flex", justifyContent: "space-around", alignItems: "center" }}>
                    {props.onClose ? <Button onClick={onClose} sx={{ ml: 2, mt: 2, minWidth: "100px" }} variant="outlined"> {props.declineText} </Button> : null}
                    {props.onConfirm ? <Button type="submit" sx={{ mt: 2, minWidth: "100px" }} color={props.confirmButtonVariant ? props.confirmButtonVariant : "error"} variant="contained" startIcon={props.confirmButtonIcon}>
                        {props.confirmText}
                    </Button> : null}
                </Box>
            </Box>
        </Dialog>
    );
}
