import { Translate, translate } from "react-i18nify";
import { PageHeader } from "../../../../components/Layout/PageHeader";
import PageContainer from "../../../../components/PageContainer";
import { ConfirmDialog } from "../../../../components/Layout/Dialog/ConfirmDialog";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import ConstructionIcon from "@mui/icons-material/Construction";
import {
  Box,
  Button,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { DEVICE_TYPE } from "../../Production";

import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { useEffect, useState } from "react";
import {
  DeviceService,
  MetadataService,
} from "../../../../services/DataService";
import AlertBox from "../../../../components/AlertBox";

export const DeviceTest = (props) => {
  const { udsn, type } = useParams();

  const navigate = useNavigate();

  const [testState, setTestState] = useState({});
  const [submitState, setSubmitState] = useState(false);
  const [showNotValidTestPopUp, setShowNotValidTestPopUp] = useState(false);
  const [showDuplicateError, setShowDuplicateError] = useState({
    vissible: false,
    text: "",
  });

  const [alertBox, setAlertBox] = useState({
    visibility: false,
    statusCode: null,
    text: null,
    error: null,
  });

  const handleAlertBox = (visibility, statusCode, text, error) => {
    setAlertBox({
      visibility: visibility,
      statusCode: statusCode,
      text: text,
      error: error,
    });
  };

  const dismissAlertBox = () => {
    setAlertBox({
      visibility: false,
      statusCode: null,
      text: null,
      error: null,
    });
  };

  const onInputValueChange = (event) => {};

  const setTestStateHelper = (e, property) => {
    let value = e.target.value;
    setTestState((prevState) => ({
      ...prevState,
      [property]: value === "true" ? true : value === "false" ? false : value,
    }));
  };

  const setProDefaultState = () => {
    setTestState({
      testProcedureFollowed: false,
      turningOn: false,
      deviceStatus: false,
      batteryMode: false,
      pulseOximeter: false,
      alarmSpeakerOne: false,
      alarmSpeakerTwo: false,
      oxygenFlow: false,
      oxygenLeakage: false,
      firmwareVersion: "",
      softwareVersion: "",
      processorSerial: {
        value: "",
        error: false,
        errorText: null,
      },
    });
  };

  // device.metaData?.find(x => x.metadataId == 15)?.value

  const setHotDefaultState = () => {
    setTestState({
      testProcedureFollowed: false,
      turningOn: false,
      batteryMode: false,
      alarmSpeakerOne: false,
      oxygenFlow: false,
      oxygenLeakage: false,
      bluetoothConnection: false,
      zeroFlow: false,
      visualInspection: false,
      firmwareVersion: "",
      softwareVersion: "",
      puid: {
        value: "",
        error: false,
        errorText: null,
      },
      mac: {
        value: "",
        error: false,
        errorText: null,
      },
    });
  };

  const [doneLoadingFirmware, setDoneLoadingFirmware] = useState(true);
  const [firmwareVersions, setFirmwareVersions] = useState([]);

  const [doneLoadingSoftware, setDoneLoadingSoftware] = useState(true);
  const [softwareVersions, setSoftwareVersions] = useState([]);

  const handlePrintSerialChange = (event) => {
    const reg = new RegExp("^[0-9]{3}[a-zA-Z0-9][0-9]{3}[a-zA-Z0-9][0-9]{16}$");
    const value = event.target.value;
    const valid = reg.test(value);

    setTestState((prevState) => ({
      ...prevState,
      processorSerial: {
        value: value,
        error: !valid,
        errorText: valid
          ? null
          : translate("Format") + "\n" + "0032001A3038510232323935",
      },
    }));
  };

  const handlePuidChange = (event) => {
    const reg = new RegExp("^[a-fA-F0-9]{16}$");
    const value = event.target.value;
    const valid = reg.test(value);

    setTestState((prevState) => ({
      ...prevState,
      puid: {
        value: value,
        error: !valid,
        errorText: valid ? null : translate("Format") + " " + "1A2b3C4d5E6f7A8b",
      },
    }));
  };

  const handleMacChange = (event) => {
    const reg = new RegExp("^([0-9A-Fa-f]{2})([0-9A-Fa-f]{2})([0-9A-Fa-f]{2})([0-9A-Fa-f]{2})([0-9A-Fa-f]{2})([0-9A-Fa-f]{2})$");
    const value = event.target.value;
    const valid = reg.test(value);
    setTestState((prevState) => ({
      ...prevState,
      mac: {
        value: value,
        error: !valid,
        errorText: valid ? null : translate("Format") + " " + "XXXXXXXXXX",
      },
    }));
  };

  useEffect(() => {
    switch (type) {
      case DEVICE_TYPE.PRO100.code: {
        (async () => {
          let deviceResult = await DeviceService.getDeviceByUdsn(udsn);
          let processorSerialValue = deviceResult.data.children?.[1]?.children[2]?.children[0]?.metaData?.find(x => x.metadataId == 1)?.value

          setTestState((prevState) => ({
            ...prevState,
            processorSerial: {
              value: processorSerialValue,
              error: false,
              errorText: null,
            },
          }));
        })();

        (async () => {
          let firmwareVersions = await MetadataService.getValuesByName(
            "Firmware_Version"
          );
          let data = firmwareVersions.data;
          data.reverse();
          setFirmwareVersions(data);
          setDoneLoadingFirmware(true);
        })();

        (async () => {
          let softwareVersion = await MetadataService.getValuesByName(
            "Software_Version"
          );
          let data = softwareVersion.data;
          data.reverse();
          setSoftwareVersions(data);
          setDoneLoadingSoftware(true);
        })();
        break;
      }
      case DEVICE_TYPE.HOT.code: {
        (async () => {
          let deviceResult = await DeviceService.getDeviceByUdsn(udsn);
          let macValue = deviceResult.data.children?.[0]?.children[0]?.metaData?.find(x => x.metadataId == 16)?.value
          let puidValue = deviceResult.data.children?.[0]?.children[0]?.metaData?.find(x => x.metadataId == 3)?.value

          setTestState((prevState) => ({
            ...prevState,
            mac: {
              value: macValue,
              error: false,
              errorText: null,
            },
            puid: {
              value: puidValue,
              error: false,
              errorText: null,
            },
          }));
        })();
        
        (async () => {
          let firmwareVersions = await MetadataService.getValuesByName(
            "Hot_Firmware_Version"
          );
          let data = firmwareVersions.data;
          data.reverse();
          setFirmwareVersions(data);
          setDoneLoadingFirmware(true);
        })();

        (async () => {
          let softwareVersion = await MetadataService.getValuesByName(
            "Hot_Software_version"
          );
          let data = softwareVersion.data;
          data.reverse();
          setSoftwareVersions(data);
          setDoneLoadingSoftware(true);
        })();
        break;
      }
    }
  }, []);

  useEffect(() => {
    switch (type) {
      case DEVICE_TYPE.PRO100.code: {
        setProDefaultState();
        break;
      }
      case DEVICE_TYPE.HOT.code: {
        setHotDefaultState();
        break;
      }
    }
  }, [type]);
  const isBoolean = (val) => "boolean" === typeof val;

  const isValidTest = (state) => {
    let isValid = true;
    Object.keys(state).some((key) => {
      let value = state[key];
      if (isBoolean(value)) {
        if (!value) {
          isValid = false;
          return true;
        }
      }
      return false;
    });
    return isValid;
  };

  const handleProSubmit = async (e) => {
    e.preventDefault();
    setSubmitState(true);
    let validTest = isValidTest(testState);
    if (!validTest) {
      setShowNotValidTestPopUp(true);
      return;
    }
    proSubmit();
  };

  const proSubmit = async () => {
    let body = {
      ...testState,
      processorSerial: testState.processorSerial.value,
      udsn: udsn,
    };
    try {
      let result = await DeviceService.testPro(body);
      if (result.status >= 200 && result.status < 300) {
        //processed correctly

        let status = result.data.status.value;
        if (status === "AtRepair") {
          handleAlertBox(true, result.status, translate("DeviceMovedToRepair"), null);
        } else if (status === "Tested") {
          handleAlertBox(
            true,
            result.status,
            translate("DeviceTestedSuccessfully"),
            null
          );
        }
      } else {
        handleAlertBox(true, result.status, result.statusText, null);
      }
      setTimeout(() => {
        navigate(`/production/${DEVICE_TYPE.PRO100.code}`);
      }, 2000);
    } catch (e) {
      if (e?.response?.status === 400) {
        setShowDuplicateError({ vissible: true, text: e?.response?.data[0] });
      }
      handleAlertBox(true, e.status, e.statusText, e.message);
    } finally {
      setSubmitState(false);
    }
  };

  const handleHotSubmit = async (e) => {
    e.preventDefault();
    const validTest = isValidTest(testState);
    if (!validTest) {
      setShowNotValidTestPopUp(true);
      return;
    }
    hotSubmit();
  };

  const hotSubmit = async () => {
    setSubmitState(true);
    let body = {
      ...testState,
      puid: testState.puid.value,
      mac: testState.mac.value,
      udsn: udsn,
    };
    try {
      let result = await DeviceService.testHot(body);
      if (result.status >= 200 && result.status < 300) {
        //processed correctly
        let status = result.data.status.value;
        if (status === "AtRepair") {
          handleAlertBox(true, result.status, translate("DeviceMovedToRepair"), null);
        } else if (status === "Tested") {
          handleAlertBox(
            true,
            result.status,
            translate("DeviceTestedSuccessfully"),
            null
          );
        }
      } else {
        handleAlertBox(true, result.status, result.statusText, null);
      }
      setTimeout(() => {
        navigate(`/production/${DEVICE_TYPE.HOT.code}`);
      }, 2000);
    } catch (e) {
      if (e?.response?.status === 400) {
        setShowDuplicateError({ vissible: true, text: e?.response?.data[0] });
      }
      handleAlertBox(true, e.status, e.statusText, e.message);
    } finally {
      setSubmitState(false);
    }
  };

  const renderTest = (type) => {
    if (Object.keys(testState).length == 0) {
      return "Loading";
    }
    switch (type) {
      case DEVICE_TYPE.PRO100.code: {

        return (
          <Box
            onSubmit={handleProSubmit}
            component="form"
            sx={{
              width: { xs: "100%", sm: "100%", md: "100%", lg: "1000px" },
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box sx={{ display: "flex", flexDirection: "row", width: "100%" }}>
              <FormLabel id="" sx={{ width: "50%" }}>
                <Typography variant="h6"><Translate value="TestProcedureFollowed"/></Typography>
                <Typography variant="body">
                <Translate value="TestProcedureFollowedText"/>
                </Typography>
              </FormLabel>
              <RadioGroup
                sx={{ ml: 2 }}
                data-test="mnaw"
                row
                value={testState.testProcedureFollowed}
                onChange={(e) => setTestStateHelper(e, "testProcedureFollowed")}
              >
                <FormControlLabel
                  value={true}
                  control={<Radio />}
                  label={<Translate value="Yes"/>}
                />
                <FormControlLabel
                  value={false}
                  control={<Radio />}
                  label={<Translate value="No"/>}
                />
              </RadioGroup>
            </Box>

            <Box sx={{ display: "flex", flexDirection: "row", width: "100%", mt:1}}>
              <FormLabel id="" sx={{ width: "50%" }}>
                <Typography variant="h6"><Translate value="TurningOnTheDevice"/></Typography>
                <Typography variant="body">
                <Translate value="TurningOnTheDeviceText"/>
                </Typography>
              </FormLabel>
              <RadioGroup
                sx={{ ml: 2 }}
                data-test="mnaw"
                row
                value={testState.turningOn}
                onChange={(e) => setTestStateHelper(e, "turningOn")}
              >
                <FormControlLabel
                  value={true}
                  control={<Radio />}
                  label={<Translate value="Yes"/>}
                />
                <FormControlLabel
                  value={false}
                  control={<Radio />}
                  label={<Translate value="No"/>}
                />
              </RadioGroup>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "start",
                width: "100%",
                mt: 1,
              }}
            >
              <FormLabel id="" sx={{ width: "50%" }}>
                <Typography variant="h6"><Translate value="DeviceStatus"/></Typography>
                <Typography variant="body"><Translate value="DeviceStatusText"/></Typography>
              </FormLabel>
              <RadioGroup
                sx={{ ml: 2 }}
                row
                value={testState.deviceStatus}
                onChange={(e) => setTestStateHelper(e, "deviceStatus")}
              >
                <FormControlLabel
                  value={true}
                  control={<Radio />}
                  label={<Translate value={<Translate value="Yes"/>}/>}
                />
                <FormControlLabel
                  value={false}
                  control={<Radio />}
                  label={<Translate value="No"/>}
                />
              </RadioGroup>
            </Box>

            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "start",
                width: "100%",
                mt: 1,
              }}
            >
              <FormLabel id="" sx={{ width: "50%" }}>
                <Typography variant="h6"><Translate value="BatteryMode"/></Typography>
                <Typography variant="body"><Translate value="BatteryModeText"/></Typography>
              </FormLabel>
              <RadioGroup
                sx={{ ml: 2 }}
                row
                value={testState.batteryMode}
                onChange={(e) => setTestStateHelper(e, "batteryMode")}
              >
                <FormControlLabel
                  value={true}
                  control={<Radio />}
                  label={<Translate value="Yes"/>}
                />
                <FormControlLabel
                  value={false}
                  control={<Radio />}
                  label={<Translate value="No"/>}
                />
              </RadioGroup>
            </Box>

            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "start",
                width: "100%",
                mt: 1,
              }}
            >
              <FormLabel id="" sx={{ width: "50%" }}>
                <Typography variant="h6"><Translate value="PulseOximeter"/></Typography>
                <Typography variant="body"><Translate value="PulseOximeterText"/></Typography>
              </FormLabel>
              <RadioGroup
                sx={{ ml: 2 }}
                row
                value={testState.pulseOximeter}
                onChange={(e) => setTestStateHelper(e, "pulseOximeter")}
              >
                <FormControlLabel
                  value={true}
                  control={<Radio />}
                  label={<Translate value="Yes"/>}
                />
                <FormControlLabel
                  value={false}
                  control={<Radio />}
                  label={<Translate value="No"/>}
                />
              </RadioGroup>
            </Box>

            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "start",
                width: "100%",
                mt: 1,
              }}
            >
              <FormLabel id="" sx={{ width: "50%" }}>
                <Typography variant="h6"><Translate value="AlarmSpeaker1"/></Typography>
                <Typography variant="body"><Translate value="AlarmSpeaker1Text"/></Typography>
              </FormLabel>
              <RadioGroup
                sx={{ ml: 2 }}
                row
                value={testState.alarmSpeakerOne}
                onChange={(e) => setTestStateHelper(e, "alarmSpeakerOne")}
              >
                <FormControlLabel
                  value={true}
                  control={<Radio />}
                  label={<Translate value="Yes"/>}
                />
                <FormControlLabel
                  value={false}
                  control={<Radio />}
                  label={<Translate value="No"/>}
                />
              </RadioGroup>
            </Box>

            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "start",
                width: "100%",
                mt: 1,
              }}
            >
              <FormLabel id="" sx={{ width: "50%" }}>
                <Typography variant="h6"><Translate value="AlarmSpeaker2"/></Typography>
                <Typography variant="body"><Translate value="AlarmSpeaker2Text"/></Typography>
              </FormLabel>
              <RadioGroup
                sx={{ ml: 2 }}
                row
                value={testState.alarmSpeakerTwo}
                onChange={(e) => setTestStateHelper(e, "alarmSpeakerTwo")}
              >
                <FormControlLabel
                  value={true}
                  control={<Radio />}
                  label={<Translate value="Yes"/>}
                />
                <FormControlLabel
                  value={false}
                  control={<Radio />}
                  label={<Translate value="No"/>}
                />
              </RadioGroup>
            </Box>

            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "start",
                width: "100%",
                mt: 1,
              }}
            >
              <FormLabel id="" sx={{ width: "50%" }}>
                <Typography variant="h6"><Translate value="OxygenFlow"/></Typography>
                <Typography variant="body"><Translate value="OxygenFlowText"/></Typography>
              </FormLabel>
              <RadioGroup
                sx={{ ml: 2 }}
                row
                value={testState.oxygenFlow}
                onChange={(e) => setTestStateHelper(e, "oxygenFlow")}
              >
                <FormControlLabel
                  value={true}
                  control={<Radio />}
                  label={<Translate value="Yes"/>}
                />
                <FormControlLabel
                  value={false}
                  control={<Radio />}
                  label={<Translate value="No"/>}
                />
              </RadioGroup>
            </Box>

            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "start",
                width: "100%",
                mt: 1,
              }}
            >
              <FormLabel id="" sx={{ width: "50%" }}>
                <Typography variant="h6"><Translate value="OxygenLeakage"/></Typography>
                <Typography variant="body"><Translate value="OxygenLeakageText"/></Typography>
              </FormLabel>
              <RadioGroup
                sx={{ ml: 2 }}
                row
                value={testState.oxygenLeakage}
                onChange={(e) => setTestStateHelper(e, "oxygenLeakage")}
              >
                <FormControlLabel
                  value={true}
                  control={<Radio />}
                  label={<Translate value="Yes"/>}
                />
                <FormControlLabel
                  value={false}
                  control={<Radio />}
                  label={<Translate value="No"/>}
                />
              </RadioGroup>
            </Box>

            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "start",
                width: "100%",
                mt: 1,
              }}
            >
              <FormLabel id="" sx={{ width: "50%" }}>
                <Typography variant="h6"><Translate value="FirmwareVersion"/></Typography>
                <Typography variant="body"><Translate value="FirmwareVersionText"/></Typography>
              </FormLabel>
              {doneLoadingFirmware ? (
                <Select
                  sx={{ width: "200px" }}
                  required
                  id="firmware"
                  displayEmpty
                  value={testState.firmwareVersion}
                  onChange={(e) => setTestStateHelper(e, "firmwareVersion")}
                >
                  <MenuItem value={""}>
                    <Translate value="Select" /> firmware
                  </MenuItem>
                  {firmwareVersions.map((l) => {
                    return (
                      <MenuItem key={l.id} value={l.id}>
                        {l.value}
                      </MenuItem>
                    );
                  })}
                </Select>
              ) : null}
            </Box>

            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "start",
                width: "100%",
                mt: 1,
              }}
            >
              <FormLabel id="" sx={{ width: "50%" }}>
                <Typography variant="h6"><Translate value="SoftwareVersion"/></Typography>
                <Typography variant="body"><Translate value="SoftwareVersionText"/></Typography>
              </FormLabel>
              {doneLoadingSoftware ? (
                <Select
                  sx={{ width: "200px" }}
                  required
                  id="software"
                  displayEmpty
                  value={testState.softwareVersion}
                  onChange={(e) => setTestStateHelper(e, "softwareVersion")}
                >
                  <MenuItem value={""}>
                    <Translate value="Select" /> software
                  </MenuItem>
                  {softwareVersions.map((l) => {
                    return (
                      <MenuItem key={l.id} value={l.id}>
                        {l.value}
                      </MenuItem>
                    );
                  })}
                </Select>
              ) : null}
            </Box>

            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "start",
                width: "100%",
                mt: 1,
              }}
            >
              <FormLabel id="" sx={{ width: "50%" }}>
                <Typography variant="h6"><Translate value="ProcessorSerialNumber"/></Typography>
                {testState.processorSerial.error ? (
                  <Typography variant="body">
                    {testState.processorSerial.errorText}
                  </Typography>
                ) : null}
              </FormLabel>
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <TextField
                  sx={{ width: "200px" }}
                  id="printSerial"
                  inputProps={{
                    pattern:
                      "^[0-9]{3}[a-zA-Z0-9][0-9]{3}[a-zA-Z0-9][0-9]{16}$",
                  }}
                  required
                  onChange={handlePrintSerialChange}
                  value={testState.processorSerial.value}
                ></TextField>
              </Box>
            </Box>
            <Button
              sx={{ mt: 2, alignSelf: "flex-start" }}
              disabled={submitState}
              type="submit"
              variant="contained"
            >
              <Translate value="Submit" />{" "}
            </Button>
          </Box>
        );
      }
      case DEVICE_TYPE.HOT.code: {
        return (
          <Box
            onSubmit={handleHotSubmit}
            component="form"
            sx={{
              width: { xs: "100%", sm: "100%", md: "100%", lg: "1000px" },
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box sx={{ display: "flex", flexDirection: "row", width: "100%" }}>
              <FormLabel id="" sx={{ width: "50%" }}>
                <Typography variant="h6"><Translate value="TestProcedureFollowed"/></Typography>
                <Typography variant="body">
                <Translate value="TestProcedureFollowedText"/>
                </Typography>
              </FormLabel>
              <RadioGroup
                sx={{ ml: 2 }}
                data-test="mnaw"
                row
                value={testState.testProcedureFollowed}
                onChange={(e) => setTestStateHelper(e, "testProcedureFollowed")}
              >
                <FormControlLabel
                  value={true}
                  control={<Radio />}
                  label={<Translate value="Yes"/>}
                />
                <FormControlLabel
                  value={false}
                  control={<Radio />}
                  label={<Translate value="No"/>}
                />
              </RadioGroup>
            </Box>

            <Box sx={{ display: "flex", flexDirection: "row", width: "100%" }}>
              <FormLabel id="" sx={{ width: "50%" }}>
                <Typography variant="h6"><Translate value="TurningOnTheDevice"/></Typography>
                <Typography variant="body">
                <Translate value="TurningOnTheDeviceText"/>
                </Typography>
              </FormLabel>
              <RadioGroup
                sx={{ ml: 2 }}
                data-test="mnaw"
                row
                value={testState.turningOn}
                onChange={(e) => setTestStateHelper(e, "turningOn")}
              >
                <FormControlLabel
                  value={true}
                  control={<Radio />}
                  label={<Translate value="Yes"/>}
                />
                <FormControlLabel
                  value={false}
                  control={<Radio />}
                  label={<Translate value="No"/>}
                />
              </RadioGroup>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "start",
                width: "100%",
                mt: 1,
              }}
            >
              <FormLabel id="" sx={{ width: "50%" }}>
                <Typography variant="h6"><Translate value="BatteryMode"/></Typography>
                <Typography variant="body"><Translate value="BatteryModeText"/></Typography>
              </FormLabel>
              <RadioGroup
                sx={{ ml: 2 }}
                row
                value={testState.batteryMode}
                onChange={(e) => setTestStateHelper(e, "batteryMode")}
              >
                <FormControlLabel
                  value={true}
                  control={<Radio />}
                  label={<Translate value="Yes"/>}
                />
                <FormControlLabel
                  value={false}
                  control={<Radio />}
                  label={<Translate value="No"/>}
                />
              </RadioGroup>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "start",
                width: "100%",
                mt: 1,
              }}
            >
              <FormLabel id="" sx={{ width: "50%" }}>
                <Typography variant="h6"><Translate value="AlarmSpeaker1"/></Typography>
                <Typography variant="body"><Translate value="AlarmSpeaker1Text"/></Typography>
              </FormLabel>
              <RadioGroup
                sx={{ ml: 2 }}
                row
                value={testState.alarmSpeakerOne}
                onChange={(e) => setTestStateHelper(e, "alarmSpeakerOne")}
              >
                <FormControlLabel
                  value={true}
                  control={<Radio />}
                  label={<Translate value="Yes"/>}
                />
                <FormControlLabel
                  value={false}
                  control={<Radio />}
                  label={<Translate value="No"/>}
                />
              </RadioGroup>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "start",
                width: "100%",
                mt: 1,
              }}
            >
              <FormLabel id="" sx={{ width: "50%" }}>
                <Typography variant="h6"><Translate value="OxygenFlow"/></Typography>
                <Typography variant="body"><Translate value="OxygenFlowText"/></Typography>
              </FormLabel>
              <RadioGroup
                sx={{ ml: 2 }}
                row
                value={testState.oxygenFlow}
                onChange={(e) => setTestStateHelper(e, "oxygenFlow")}
              >
                <FormControlLabel
                  value={true}
                  control={<Radio />}
                  label={<Translate value="Yes"/>}
                />
                <FormControlLabel
                  value={false}
                  control={<Radio />}
                  label={<Translate value="No"/>}
                />
              </RadioGroup>
            </Box>

            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "start",
                width: "100%",
                mt: 1,
              }}
            >
              <FormLabel id="" sx={{ width: "50%" }}>
                <Typography variant="h6"><Translate value="OxygenLeakage"/></Typography>
                <Typography variant="body"><Translate value="OxygenLeakageText"/></Typography>
              </FormLabel>
              <RadioGroup
                sx={{ ml: 2 }}
                row
                value={testState.oxygenLeakage}
                onChange={(e) => setTestStateHelper(e, "oxygenLeakage")}
              >
                <FormControlLabel
                  value={true}
                  control={<Radio />}
                  label={<Translate value="Yes"/>}
                />
                <FormControlLabel
                  value={false}
                  control={<Radio />}
                  label={<Translate value="No"/>}
                />
              </RadioGroup>
            </Box>

            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "start",
                width: "100%",
                mt: 1,
              }}
            >
              <FormLabel id="" sx={{ width: "50%" }}>
                <Typography variant="h6"><Translate value="BluetoothConnection"/></Typography>
                <Typography variant="body">
                  <Translate value="BluetoothConnectionText"/>
                </Typography>
              </FormLabel>
              <RadioGroup
                sx={{ ml: 2 }}
                row
                value={testState.bluetoothConnection}
                onChange={(e) => setTestStateHelper(e, "bluetoothConnection")}
              >
                <FormControlLabel
                  value={true}
                  control={<Radio />}
                  label={<Translate value="Yes"/>}
                />
                <FormControlLabel
                  value={false}
                  control={<Radio />}
                  label={<Translate value="No"/>}
                />
              </RadioGroup>
            </Box>

            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "start",
                width: "100%",
                mt: 1,
              }}
            >
              <FormLabel id="" sx={{ width: "50%" }}>
                <Typography variant="h6"><Translate value="ZeroFlow"/></Typography>
                <Typography variant="body">
                  <Translate value="ZeroFlowText"/>
                </Typography>
              </FormLabel>
              <RadioGroup
                sx={{ ml: 2 }}
                row
                value={testState.zeroFlow}
                onChange={(e) => setTestStateHelper(e, "zeroFlow")}
              >
                <FormControlLabel
                  value={true}
                  control={<Radio />}
                  label={<Translate value="Yes"/>}
                />
                <FormControlLabel
                  value={false}
                  control={<Radio />}
                  label={<Translate value="No"/>}
                />
              </RadioGroup>
            </Box>

            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "start",
                width: "100%",
                mt: 1,
              }}
            >
              <FormLabel id="" sx={{ width: "50%" }}>
                <Typography variant="h6"><Translate value="VisualInspection"/></Typography>
                <Typography variant="body">
                  <Translate value="VisualInspectionText"/>
                </Typography>
              </FormLabel>
              <RadioGroup
                sx={{ ml: 2 }}
                row
                value={testState.visualInspection}
                onChange={(e) => setTestStateHelper(e, "visualInspection")}
              >
                <FormControlLabel
                  value={true}
                  control={<Radio />}
                  label={<Translate value="Yes"/>}
                />
                <FormControlLabel
                  value={false}
                  control={<Radio />}
                  label={<Translate value="No"/>}
                />
              </RadioGroup>
            </Box>

            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "start",
                width: "100%",
                mt: 1,
              }}
            >
              <FormLabel id="" sx={{ width: "50%" }}>
                <Typography variant="h6"><Translate value="FirmwareVersion"/></Typography>
                <Typography variant="body"><Translate value="FirmwareVersionText"/></Typography>
              </FormLabel>
              {doneLoadingFirmware ? (
                <Select
                  sx={{ width: "200px" }}
                  required
                  id="firmware"
                  displayEmpty
                  value={testState.firmwareVersion}
                  onChange={(e) => setTestStateHelper(e, "firmwareVersion")}
                >
                  <MenuItem value={""}>
                    <Translate value="Select" /> firmware
                  </MenuItem>
                  {firmwareVersions.map((l) => {
                    return (
                      <MenuItem key={l.id} value={l.id}>
                        {l.value}
                      </MenuItem>
                    );
                  })}
                </Select>
              ) : null}
            </Box>

            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "start",
                width: "100%",
                mt: 1,
              }}
            >
              <FormLabel id="" sx={{ width: "50%" }}>
                <Typography variant="h6"><Translate value="SoftwareVersion"/></Typography>
                <Typography variant="body"><Translate value="SoftwareVersionText"/></Typography>
              </FormLabel>
              {doneLoadingSoftware ? (
                <Select
                  sx={{ width: "200px" }}
                  required
                  id="software"
                  displayEmpty
                  value={testState.softwareVersion}
                  onChange={(e) => setTestStateHelper(e, "softwareVersion")}
                >
                  <MenuItem value={""}>
                    <Translate value="Select" /> software
                  </MenuItem>
                  {softwareVersions.map((l) => {
                    return (
                      <MenuItem key={l.id} value={l.id}>
                        {l.value}
                      </MenuItem>
                    );
                  })}
                </Select>
              ) : null}
            </Box>

            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "start",
                width: "100%",
                mt: 1,
              }}
            >
              <FormLabel id="" sx={{ width: "50%" }}>
                <Typography variant="h6"><Translate value="ProcessorSerialNumber"/></Typography>
                <Typography variant="body">
                <Translate value="ProcessorSerialNumberText"/>{" "}
                </Typography>
                {testState.puid.error ? (
                  <Typography variant="body">
                    {testState.puid.errorText}
                  </Typography>
                ) : null}
              </FormLabel>
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <TextField
                  sx={{ width: "200px" }}
                  id="puid"
                  inputProps={{
                    pattern: "^[a-fA-F0-9]{16}$",
                  }}
                  required
                  onChange={handlePuidChange}
                  value={testState.puid.value}
                ></TextField>
              </Box>
            </Box>

            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "start",
                width: "100%",
                mt: 1,
              }}
            >
              <FormLabel id="" sx={{ width: "50%" }}>
                <Typography variant="h6"><Translate value="MacAddress" /></Typography>
                <Typography variant="body">
                <Translate value="MacAddressText"/>{" "}
                </Typography>
                {testState.mac.error ? (
                  <Typography variant="body">
                    {testState.mac.errorText}
                  </Typography>
                ) : null}
              </FormLabel>
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <TextField
                  sx={{ width: "200px" }}
                  id="mac"
                  inputProps={{
                    pattern: "^([0-9A-Fa-f]{12})$",
                  }}
                  required
                  onChange={handleMacChange}
                  value={testState.mac.value}
                ></TextField>
              </Box>
            </Box>
            <Button
              sx={{ mt: 2, alignSelf: "flex-start" }}
              disabled={submitState}
              type="submit"
              variant="contained"
            >
              <Translate value="Submit" />{" "}
            </Button>
          </Box>
        );
      }
    }
  };

  return (
    <PageContainer>
      <ConfirmDialog
        dialogIcon={<ErrorOutlineIcon color="error" sx={{ fontSize: 75 }} />}
        title={<Translate value="PleaseConfirm" />}
        message={<Translate value="DeviceWillBeMovedToRepair" />}
        declineText={<Translate value="Close" />}
        confirmText={<Translate value="Continue" />}
        confirmButtonIcon={<ConstructionIcon />}
        open={showNotValidTestPopUp}
        onClose={() => {
          setShowNotValidTestPopUp(false);
          setSubmitState(false);
        }}
        onConfirm={() => {
          setShowNotValidTestPopUp(false);
          if (DEVICE_TYPE.HOT.code === type) {
            hotSubmit();
          } else {
            proSubmit();
          }
        }}
      />
      <ConfirmDialog
        dialogIcon={<ErrorOutlineIcon color="error" sx={{ fontSize: 75 }} />}
        title={<Translate value="DuplicateEntry" />}
        message={
          translate("ContactO2maticToResolve") +
          ("\n" + translate(showDuplicateError.text))
        }
        confirmText={<Translate value="Continue" />}
        open={showDuplicateError.vissible}
        onConfirm={() => {
          setShowDuplicateError({ vissible: false });
        }}
      />
      <PageHeader leftAlign>
        <Translate value="Test" />
      </PageHeader>
      <Typography sx={{ mt: 1, mb: 1 }} variant="h5">
        <Translate value="TestingDeviceWithUDSN" />: {udsn}
      </Typography>

      {renderTest(type)}
      <AlertBox
        visible={alertBox.visibility}
        text={alertBox.text}
        error={alertBox.error}
        statusCode={alertBox.statusCode}
        onHide={dismissAlertBox}
      />
    </PageContainer>
  );
};
