import { Box } from "@mui/material";

export const O2maticLogoBlack = ({width,height}) => {
  return (
    <Box sx={{ width: width, height: height }}>
      <img
        style={{ width: "80%", height: "50" }}
        src="../o2Matic_logo_black.png"
        alt="Logo"
      />
    </Box>
  );
};
