import React, { useState, useLayoutEffect, forwardRef } from 'react'
import { Box } from '@mui/system'
import DownloadIcon from '@mui/icons-material/Download';
import { Dialog, Typography, Button, Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material'
import { PatientDetailText } from './PatientDetailText'
import { translate, Translate, Localize } from 'react-i18nify';
import TimeUtils from '../../utils/TimeUtils';
import { useRef, useEffect } from 'react';
import { Chart } from '../Session/DetailedSessionChartUplot';
import { MetadataService, SessionService } from '../../services/DataService';
import html2pdf from "html2pdf.js/dist/html2pdf.min";
import TreatmentControls from '../Treatment/TreatmentControls';
import CircularProgress from '@mui/material/CircularProgress';
import { GenerateTreatmentInfo } from '../../pages/Patient/GenerateTreatmentInfo';
import { DailyReportDownloadCsv } from "../../components/Patient/DailyReportDownloadCsv";
import { O2maticLogoBlack } from '../Layout/O2maticLogoBlack';
import { LogService } from '../../services/DataService';

const generateChartData = (data) => {
    try {
        const chartLength = data.reduce((total, batch) => total + batch.values.length, 0);
        const timestamp = Array.from({ length: chartLength }, () => null);
        const spo2Data = Array.from({ length: chartLength }, () => null);
        const pulseData = Array.from({ length: chartLength }, () => null);
        const o2Data = Array.from({ length: chartLength }, () => null);

        let currentIndex = 0;

        for (const batch of data) {
            const values = batch.values;
            const batchStartTime = new Date(TimeUtils.utcToLocal(batch.start, true)).getTime() / 1000;

            timestamp[currentIndex] = batchStartTime;
            spo2Data[currentIndex] = null;
            pulseData[currentIndex] = null;
            o2Data[currentIndex] = null;

            for (const value of values) {
                const timestampDataPoint = new Date(TimeUtils.utcToLocal(value.timestamp, true)).getTime() / 1000;

                timestamp[currentIndex] = timestampDataPoint;
                spo2Data[currentIndex] = value.spO2;
                pulseData[currentIndex] = value.pulse;
                o2Data[currentIndex] = value.o2;

                currentIndex++;
            }

            const batchEndTime = new Date(TimeUtils.utcToLocal(batch.end, true)).getTime() / 1000;

            timestamp[currentIndex] = batchEndTime;
            spo2Data[currentIndex] = null;
            pulseData[currentIndex] = null;
            o2Data[currentIndex] = null;

            currentIndex++;
        }

        const result = [timestamp, spo2Data, pulseData, o2Data];

        return result;
    } catch (error) {
        throw new Error('Error generating chart data: ' + error);
    }
};



const PrintContent = forwardRef((props, ref) => {
    return (
        <Box
            sx={{
                m: 0,
                p: 0,
                width: "100%",
                height: "100%",
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
            }}
            ref={ref}
        >
            {props.children}
        </Box>
    );
});

export const DailyReport = (props) => {

    const printElementRef = useRef(null);
    let forwardRef = props.forwardRef;


    let data = props.data
    let date = props.date
    let detailedSessionData = props.data?.detailedSessions
    let chartData = props.chartData
    let reportData = props.reportData
    let spo2Summary = data?.spO2Summary
    let o2Summary = data?.o2Summary
    let pulseSummary = data?.pulseSummary
    let patient = data?.patient?.patient
    let sessions = data?.sessions
    let treatments = data?.treatments
    let duration = props.data?.numberOfSamples
    let width = "1080"
    let xaxisRange = "compact"

    if (!props.open) {
        return null;
    }

    
    const generatePDF = () => {
        const printElement = printElementRef.current;
        var opt = {
            margin: [0, 0, 0, 0],
            filename: "O2matic - " + translate("Patient") + " " + props?.data?.patient?.patient?.id?.value + " - " + translate("DailyReport") + " " + TimeUtils.formatDateYYMMDD(date) + ".pdf",
            //filename: `O2matic - Patient ${props?.data?.patient?.patient?.id?.value} - Daily report ${date} .pdf`,
            image: { type: "jpeg", quality: 1 },
            pagebreak: { mode: "css" },
            html2canvas: {
                scale: 2,
                letterRendering: true,
                useCORS: true,
            },
            jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
            noPdfOpenParams: true,
        };

        html2pdf().set(opt).from(printElement).save();
        (async () => {
            await LogService.saveLog([TimeUtils.formatDateYYMMDD(date)], "Daily report", "pdf", patient.id.value).catch((error) => {
                console.error("Error saving log: ", error);
            })
        })();
    };    
    if (!props.open) {
        return null;
    }


    return (

        <Box
            maxWidth="800px"
            sx={{ width: "100%", p: 0 }}

        >
            {(!props.data || props.data == null) ?
            <Box sx={{ display: 'flex', justifyContent: 'center', height:"250px", alignItems: 'center' }}>
                <CircularProgress />
            </Box> 
            :
            <>
                <Box sx={{m:2, position:"absolute",top:65,right:20, display:"flex", flexDirection:"row"}}>
                    <Button sx={{mr:2}} variant="contained" onClick={() => generatePDF()} >{translate("DownloadPdf")} </Button>
                    <Button sx={{ alignSelf: "flex-end" }} variant="contained" onClick={() => DailyReportDownloadCsv(reportData,date)} >{translate("DownloadCsv")} </Button>
                </Box>
                
                <Box sx={{ p: 1 }} ref={forwardRef}>
                <Box sx={{ p: 1 }} ref={printElementRef}>

                    <Box sx={{position:"absolute",top:15,right:0}}> 
                        <O2maticLogoBlack width={"200px"}/>
                    </Box>


                    <Box sx={{ display: "flex", flexDirection: "column" }}>     
                        <Box sx={{display:"flex", flexDirection: "row"}}>
                            <Typography variant='h4'><Translate value="DailyReport"/></Typography>
                            <Typography variant='h4' sx={{ml:3}}> {TimeUtils.formatDateYYMMDD(new Date(props.date))}</Typography>
                        </Box>                     
                    </Box>

                    <Box sx={{ mt: 1, display: "flex", flexDirection: "column" }}>
                        <Typography variant='h6'><Translate value="PatientO2maticId"/>: {patient?.id?.value}</Typography>
                    </Box>
                    <Box sx={{ mb:2, display:"flex", flexDirection:"column"}}>
                        <Typography variant='body'><Translate value={"LengthOfRecordedData"}/>: {TimeUtils.secondsToHMS(duration)}</Typography>
                        <Typography variant='body'><Translate value="ReportGenerated"/>: {TimeUtils.formatDate(new Date())}</Typography>
                    </Box>

                    <Box sx={{ mx:-1, pl:1, width: "100%", mb:10}}>
                        {
                            detailedSessionData?.length == 0 ? "No chart data" :
                                <Chart width={width} data={chartData} xaxisRange={xaxisRange}/>
                        }
                    </Box>
                    {/* <Box sx={{ mt: 1, display: "flex", flexDirection: "column" }}>
                        <Typography variant='h5'><Translate value="PatientInformation"/></Typography>
                        <Typography variant='body'><Translate value="O2maticId"/>: {patient?.id?.value}</Typography>
                        <Typography variant='body'><Translate value={"LengthOfRecordedData"}/>: {TimeUtils.secondsToHMS(duration)}</Typography>
                    </Box> */}

                    <Box sx={{ pageBreakBefore: "always", mt:2, mx:-1, pl:1, mb:6}}>
                        <Typography sx={{m:0,p:0}} variant='h5'><Translate value="DetailedDataStatistics"/></Typography>

                        <Box sx={{mt: 1, display: "flex", flexDirection: "column" }}>
                            <Typography variant='h6'> <Translate value="SpO2"/> </Typography>
                            <ValuesTable overalDuration={duration} name="SpO2" unit="%" data={spo2Summary.values} />
                        </Box>

                        <Box sx={{ mt: 1, display: "flex", flexDirection: "column" }}>
                            <Typography variant='h6'> <Translate value="Pulse"/> </Typography>
                            <ValuesTable overalDuration={duration} name={<Translate value="Pulse"/>} unit={translate("bpm")} data={pulseSummary.values} />
                        </Box>

                        <Box sx={{ mt: 1, display: "flex", flexDirection: "column" }}>
                            <Typography variant='h6'> <Translate value="O2Flow"/> </Typography>
                            <ValuesTable overalDuration={duration} decimal={1} name={<Translate value="O2Flow"/>} unit={translate("l/min")} data={o2Summary.values} />
                        </Box>


                        <Typography variant='body'> <Box component="span" fontWeight='fontWeightMedium'><Translate value="ImportantNote"/>:</Box> <Translate value="DetailedDataDisclaimerTable"/></Typography>


                    </Box>

                    {
                        props.data.treatmentData.length > 1 ?
                            <Box sx={{ pageBreakBefore: "always",m:0,p:0}}>
                                <Typography sx={{m:0,p:0}} variant='h5'><Translate value="DetailedDataStatisticsByTreatmentProfile"/></Typography>

                                <Box sx={{ mt: "0px", display: "flex", flexDirection: "column" }}>
                                    <Typography variant='h6'> <Translate value="SpO2"/> </Typography>
                                    <TreatmentValuesTable unit="%" name="spO2Summary" data={props.data.treatmentData} />
                                </Box>

                                <Box sx={{  mt: "0px", display: "flex", flexDirection: "column" }}>
                                    <Typography variant='h6'> <Translate value="Pulse"/> </Typography>
                                    <TreatmentValuesTable unit="bpm" name="pulseSummary" data={props.data.treatmentData} />
                                </Box>

                                <Box sx={{  mt: "0px", display: "flex", flexDirection: "column" }}>
                                    <Typography variant='h6'> <Translate value="O2Flow"/> </Typography>
                                    <TreatmentValuesTable unit="l/min" decimal={1} name="o2Summary" data={props.data.treatmentData} />
                                </Box>
                            </Box> : null}

                    <Typography sx={{ pageBreakBefore: "always", mb:-2}} variant='h5'><Translate value="TreatmentProfiles"/></Typography>
                    <Box sx={{ display: "flex", flexDirection: "row", flexWrap: "wrap", justifyContent:" space-between"}}>
                        {treatments.map((profile, index) => {
                            return (<TreatmentProfile key={index} index={index} profile={profile} />)
                        })}
                    </Box>

                    <Typography sx={{ pageBreakBefore: "always", mt: 2, mb:0}} variant='h5'><Translate value="Sessions"/></Typography>
                    <Box sx={{ mt: 0, display: "flex", flexDirection: "row", flexWrap: "wrap",justifyContent:" space-between"}}>
                        {sessions.map((session, index) => {
                            return (<SessionValues key={index} index={index} session={session} />)
                        })}
                    </Box>



                </Box>
                </Box>
            </>
            }
        </Box >
    )
}

const TreatmentProfile = ({ profile, index }) => {
    return (
        <Box sx={{ width: "50%", mt: 3, display: "flex", flexDirection: "column", pageBreakBefore: (index + 1) % 4 == 0 ? "always" : "never " }}>
            <GenerateTreatmentInfo treatment={profile} />
        </Box>
    )
}

const SessionValues = ({ session, index }) => {
    return (
        <Box className={"html2pdf__page-break"} sx={{width:"250px",mt: 3, display: "flex", flexDirection: "column", pageBreakBefore: index == 0 ? "never" : "always" }}>
            <Typography variant='h6'><Translate value="Session" /> {session.deviceSessionId} ({session.id})</Typography>
            {/* <Typography variant='h7'><Translate value="Device serial number"/>{session.component.metadata[0]}</Typography> */}
            <NameValuePair name="Treatment" value={`${session.treatment.name} (${session.treatment.id})`} />
            <NameValuePair name="DeviceSn" value={`${session.serialnumber}`}/>

            <SessionStart data={session.parts.start} />
            <SessionMainValues name="AnalysingPhase" data={session.parts.analyzing} />
            <SessionMainValues name="AdjustingPhase" data={session.parts.adjusting} />
            <SessionEnd data={session.parts.end} />
        </Box>
    )
}

const SessionStart = ({ data }) => {
    return (
        <>
            <Typography sx={{ mt: 1 }} variant='h6'><Translate value="Start" /></Typography>
            <NameValuePair name="StartTime" value={TimeUtils.formatDate(TimeUtils.convertUTCDateToLocalDate(new Date(data.timestamp)))} />
            <NameValuePairUnit name="InitialSpO2" value={data.values.SpO2Initial} unit={"%"} />
            <NameValuePairUnit name="InitialPulse" value={data.values.PulseInitial} unit={translate("bpm")} />
            <NameValuePairUnit name="InitialO2Flow" value={data.values.OxygenInitial} unit={translate("l/min")} decimal={1} />

        </>

    )
}


const SessionEnd = ({ data }) => {
    return (
        <>
            <Typography sx={{ mt: 1 }} variant='h6'><Translate value="End" /></Typography>
            <NameValuePair name="EndTime" value={TimeUtils.formatDate(TimeUtils.convertUTCDateToLocalDate(new Date(data.timestamp)))} />
            {/* <NameValuePair name="NextDiagnosis" value={TimeUtils.formatDate(TimeUtils.convertUTCDateToLocalDate(new Date(data.values.NextDiagnosis)))} /> */}
            <NameValuePair name="NextSession" value={TimeUtils.formatDate(TimeUtils.convertUTCDateToLocalDate(TimeUtils.addSeconds(new Date(data.timestamp),parseInt(data.values.NextDiagnosisSeconds))))} />
            <NameValuePairUnit name="LastMeasuredSpO2" value={data.values.SpO2Final} unit={"%"} />
            <NameValuePairUnit name="LastMeasuredPulse" value={data.values.PulseFinal} unit={translate("bpm")} />
            <NameValuePairUnit name="O2FlowTarget" value={data.values.OxygenTarget} unit={translate("l/min")} decimal={1} />
            <NameValuePair name="Zone" value={data.values.Zone} />
        </>

    )
}

const SessionMainValues = ({ name, data }) => {
    return (
        <>
            <Typography sx={{ mt: 1 }} variant='h6'><Translate value={name} /></Typography>
            <NameValuePair name="StartTime" value={TimeUtils.formatDate(TimeUtils.convertUTCDateToLocalDate(new Date(data.timestamp)))} />

            <NameValuePairUnit replace={false} name="SpO2Min" value={data.values.SpO2Minimum} unit={"%"} />
            <NameValuePairUnit replace={false} name="SpO2Max" value={data.values.SpO2Maximum} unit={"%"} />
            <NameValuePairUnit replace={false} name="SpO2Average" value={data.values.SpO2Average} unit={"%"} />
            <NameValuePairUnit replace={false} name="SpO2Std" value={data.values.SpO2StandardDeviation} unit={"%"} />

            <NameValuePairUnit replace={false} name="PulseMin" value={data.values.PulseMinimum} unit={translate("bpm")} />
            <NameValuePairUnit replace={false} name="PulseMax" value={data.values.PulseMaximum} unit={translate("bpm")} />
            <NameValuePairUnit replace={false} name="PulseAverage" value={data.values.PulseAverage} unit={translate("bpm")} />
            <NameValuePairUnit replace={false} name="PulseStd" value={data.values.PulseStandardDeviation} unit={translate("bpm")} />

            <NameValuePairUnit replace={false} name="O2FlowMin" value={data.values.OxygenMinimum} unit={translate("l/min")} decimal={1} />
            <NameValuePairUnit replace={false} name="O2FlowMax" value={data.values.OxygenMaximum} unit={translate("l/min")} decimal={1} />
            <NameValuePairUnit replace={false} name="O2FlowAverage" value={data.values.OxygenAverage} unit={translate("l/min")} decimal={1} />
            <NameValuePairUnit replace={false} name="O2FlowStd" value={data.values.OxygenStandardDeviation} unit={translate("l/min")} decimal={1} />
        </>

    )
}

const NameValuePairUnit = ({ name, value, unit, decimal, replace }) => {
    return (
        <Box sx={{display:"flex", flexDirection:"row", justifyContent: 'space-between'}}>
            <Typography variant='body'><Translate value={name} />: </Typography>
            {decimal ? valueToFixed(value, decimal, replace) : value} {unit}
        </Box>
        
    )
}

const NameValuePair = ({ name, value }) => {
    return (
        <Box sx={{display:"flex", flexDirection:"row", justifyContent: 'space-between'}}>
            <Typography variant='body'><Translate value={name} />:</Typography>
            {value}
        </Box>
    )
}

const TreatmentValuesTable = ({ name, data, decimal, unit }) => {
    return (


        <Table sx={{ minWidth: 350 }} aria-label="simple table">
            <TableHead>
                <TableRow>
                    <TableCell width={155}><Translate value='TreatmentProfile' /> ({"ID"})</TableCell>
                    <TableCell align="right"><Translate value='MINIMUM' /> {unit} (<Translate value="TimeSpent" />%)</TableCell>
                    <TableCell align="right"><Translate value='MAXIMUM' /> {unit} (<Translate value="TimeSpent" />%)</TableCell>
                    <TableCell align="right"><Translate value='AverageRange' /> {unit} (<Translate value="TimeSpent" />%)</TableCell>
                    <TableCell align="right"><Translate value='MODE' /> {unit} (<Translate value="TimeSpent" />%)</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {data.map((row,index) => {
                    const overalDuration = row?.numberOfSamples
                    return (
                        <TableRow
                            key={index}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell component="th" scope="row">
                                {row.treatment.name} ({row.treatment.id})
                            </TableCell>

                            <TableCell align="right">{decimal ?
                                valueToFixed(row[name].values.find(l => l.type === "MINIMUM").value, decimal) :
                                row[name].values.find(l => l.type === "MINIMUM").value}{" "+unit+" "}
                                ({(row[name].values.find(l => l.type === "MINIMUM").duration / overalDuration * 100).toFixed(1)}%)
                            </TableCell>

                            <TableCell align="right">{decimal ?
                                valueToFixed(row[name].values.find(l => l.type === "MAXIMUM").value, decimal) :
                                row[name].values.find(l => l.type === "MAXIMUM").value}{" "+unit+" "}
                                ({(row[name].values.find(l => l.type === "MAXIMUM").duration / overalDuration * 100).toFixed(1)}%)
                            </TableCell>

                            <TableCell align="right">
                                {row[name].values.find(l => l.type === "AVERAGE").value}{" "+unit+" "}
                                ({(row[name].values.find(l => l.type === "AVERAGE").duration / overalDuration * 100).toFixed(1)}%)
                            </TableCell>

                            <TableCell align="right">{decimal ?
                                valueToFixed(row[name].values.find(l => l.type === "MODE").value, decimal) :
                                row[name].values.find(l => l.type === "MODE").value}{" "+unit+" "}
                                ({(row[name].values.find(l => l.type === "MODE").duration / overalDuration * 100).toFixed(1)}%)
                            </TableCell>

                        </TableRow>
                    )
                })}
            </TableBody>
        </Table>
    )
}


const valueToFixed = (value, decimal, replace) => {
    return parseFloat(replace ? value.replace(",", ".") : value).toFixed(decimal)
}

const ValuesTable = ({ name, unit, data, decimal, overalDuration }) => {

    return (
        <Table sx={{ minWidth: 150, maxWidth: 400 }} aria-label="simple table">
            <TableHead>
                <TableRow>
                    <TableCell></TableCell>
                    <TableCell align="right">{name} ({unit})</TableCell>
                    <TableCell align="right"><Translate value="TimeSpent" /> </TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {data.map((row) => (
                    <TableRow
                        key={row.type}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                        <TableCell component="th" scope="row">
                            <Translate value={row.type}/>
                        </TableCell>
                        {row.type==="AVERAGE"?<TableCell align="right">{ row.value+" "+unit}</TableCell>:
                        <TableCell align="right">{decimal ? parseFloat(row.value.replace(",", ".")).toFixed(decimal) : row.value}{" "+unit}</TableCell>}
                        {(row.duration / overalDuration * 100)<0.1? <TableCell align="right">{TimeUtils.secondsToHMS(row.duration)} ({(row.duration / overalDuration * 100).toFixed(2)}%)</TableCell>
                        : <TableCell align="right">{TimeUtils.secondsToHMS(row.duration)} ({(row.duration / overalDuration * 100).toFixed(0)}%)</TableCell>}
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    )
}
