import React from 'react';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Box, IconButton, Modal, Typography, Fade, Backdrop, Divider, Drawer, useMediaQuery , Alert} from '@mui/material';
import { useDispatch,useSelector } from 'react-redux';
import { valdidate, Roles } from '../../redux/actions/auth';
import { NavItem } from '../Navigation/NavItem';
import SubscriptionsIcon from '@mui/icons-material/Subscriptions';
import UpdateIcon from '@mui/icons-material/Update';
import SettingsIcon from '@mui/icons-material/Settings';
import FindInPageIcon from '@mui/icons-material/FindInPage';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import AppShortcutIcon from '@mui/icons-material/AppShortcut';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

import TabletIcon from '@mui/icons-material/Tablet';
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';
import {items, itemsBottom, itemsInfo} from '../../configuration/NavigationItems';
import LanguageSelector from '../LanguageSelector';
import { Translate, Localize } from 'react-i18nify';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "400px",
    bgcolor: '#FFFF00',
    border: '2px solid #000',
    boxShadow: 15,
    p: 4,
  };

export const DashboardSidebar = (props) => {
    const { open, onClose, vissible } = props;
    const lgUp = useMediaQuery((theme) => theme.breakpoints.up('lg'), {
        defaultMatches: true,
        noSsr: false
    });
    const state = useSelector((state) => state?.auth);
    const roles  = useSelector((state) => state?.auth?.roles);
    const roleData =roles?.data;

    const [oopen,setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    useEffect(
        () => {

            if (!open) {
                onClose?.();
            }
        },
    );

    function limit (string = '', limit = 0) {  
        return string.substring(0, limit)
      }


    const content = (
        <>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                    backgroundColor: process.env.REACT_APP_BACKGROUND_COLOR,
                }}
            >
                {/*Logo*/}
                <div>
                    <Box sx={{ p: 3, backgroundColor: process.env.REACT_APP_BACKGROUND_COLOR, height: "50px" }}> {/*neutral.900*/}
                        <Link to="/">
                            <Box sx={{ width: '100%', height: '50px' }} >
                                <img style={{ height: '50px' }} src="../O2matic_Logo_white.svg" alt="Logo" />
                            </Box>
                        </Link>
                    </Box>
                </div>

                {/* Environment text */}
                <Box sx={{ 
                    color: process.env.REACT_APP_MENU_TEXT_COLOR, 
                    fontWeight:"fontWeightBold",
                    fontFamily: 'Helvetica',
                    fontSize: "1.5rem",
                    fontStyle: 'italic',
                    textTransform: 'uppercase',
                    display: "flex",
                    mb: 0.5,
                    py: 0,
                    px: 4.5,
                    }}>
                    <Translate value={process.env.REACT_APP_ENV}/>
                </Box>

                {/* Notification */}
                <div>
                    {props?.notification?.isActive? <Alert variant="filled" severity="warning" sx={{backgroundColor: "#ffdd4a",color:"#000000",margin:"10px", noWrap:true, height:"60px"}} action={
                        <div>
                            <IconButton onClick={handleOpen}><MoreHorizIcon/></IconButton>
                            <Modal
                                open={oopen}
                                onClose={handleClose}
                                closeAfterTransition
                                slots={{ backdrop: Backdrop }}
                                slotProps={{
                                backdrop: {
                                    timeout: 500,
                                },
                                }}
                            >
                                <Fade in={oopen}>
                                <Box sx={{position: 'absolute',top: '50%',left: '50%',transform: 'translate(-50%, -50%)',width: 400,bgcolor: '#ffdd4a',border: '2px solid #000',boxShadow: 15,p: 4,borderRadius:"25px"}}>
                                    <Typography variant="h6" component="h2">
                                        <Translate value="Notification" />!
                                    </Typography>
                                    <Typography sx={{ mt: 2,whiteSpace:"pre-line" }}>
                                        {props.notification.message}
                                    </Typography>
                                </Box>
                                </Fade>
                            </Modal>
                        </div>
                      }> <strong><Translate value="Notification" />:</strong> <Typography sx={{width:"140px",textOverflow:"ellipsis",overflow:"hidden",whiteSpace:"nowrap"}}> {props.notification.message} </Typography> </Alert>
                    : null }
                </div>

                {/*  Navigation */}
                <Box sx={{ flexGrow: 1, my: 3 }}>
                    {items.map((item) => {
                        let shouldRender=valdidate(item.roles,roleData);
                        return (
                            shouldRender ?
                                <NavItem
                                    key={item.href}
                                    icon={item.icon}
                                    href={item.href}
                                    title={item.title}
                                    children={item.children}
                                /> : null
                        )
                    }
                    )}
                </Box>
                {/* <Divider sx={{ borderColor: '#2D3748' }} /> */}

            </Box>
        </>
    );

    const contentBottom = (
        <>
            <Box sx={{ flexGrow: 1, backgroundColor: process.env.REACT_APP_BACKGROUND_COLOR, }}>
                    {itemsBottom.map((itemBottom) => {
                        let shouldRender=valdidate(itemBottom.roles,roleData);
                        return (
                            shouldRender ?
                                <NavItem
                                    key={itemBottom.href}
                                    icon={itemBottom.icon}
                                    href={itemBottom.href}
                                    title={itemBottom.title}
                                    children={itemBottom.children}
                                /> : null
                        )
                    }
                    )}
                </Box>
        </>
    );

    const infoLanguage = (
        <>
        <Box sx={{ backgroundColor: process.env.REACT_APP_BACKGROUND_COLOR, display: 'flex', alignItems: 'center'}}>
            <>
            {itemsInfo.map((itemInfo) => {
                let shouldRender=valdidate(itemInfo.roles,roleData);
                return (
                    shouldRender ?
                        <NavItem
                            key={itemInfo.href}
                            icon={itemInfo.icon}
                            href={itemInfo.href}
                            title={itemInfo.title}
                            children={itemInfo.children}
                        /> : null
                )
            }
            )}
            </>

            <LanguageSelector/>
        </Box>
        </>
    );


    if (!vissible) {
        return (null);
    }
    if (lgUp) {
        return (
            <Drawer
                anchor="left"
                open
                PaperProps={{
                    sx: {
                        backgroundColor: 'neutral.900',
                        color: '#FFFFFF',
                        width: "280px"
                    }
                }}
                variant="permanent"
            >
            {content}
            {contentBottom}
            {infoLanguage}
            {/* <LanguageSelector/> */}
            </Drawer>
        );
    }

    return (
        <Drawer
            anchor="left"
            onClose={onClose}
            open={open}
            PaperProps={{
                sx: {
                    backgroundColor: 'neutral.900',
                    color: '#FFFFFF',
                    width: "280px"
                }
            }}
            sx={{ zIndex: (theme) => theme.zIndex.appBar + 100 }}
            variant="temporary"
        >
            {content}
            {contentBottom}
            {infoLanguage}
            {/* <LanguageSelector/> */}
        </Drawer>
    );
};

DashboardSidebar.propTypes = {
    onClose: PropTypes.func,
    open: PropTypes.bool
};
