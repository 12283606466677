import { Box, Button, Stack } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { PageHeader } from "../../components/Layout/PageHeader";
import PageContainer from "../../components/PageContainer";
import { LicenseService } from "../../services/DataService";
import { Translate, Localize, translate } from 'react-i18nify';
import AddIcon from '@mui/icons-material/Add';
import TimeUtils from "../../utils/TimeUtils";

const Licenses = (props) => {
    const [license, setLicense] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        (async () => {
            let apiResult = await LicenseService.getAll();
            setLicense(apiResult.data);
            setLoading(false);
        })();
    }, [])


    const columns = [
        {
            field: "id",
            headerName: "ID",
            width: 70,
            type: "number"
        },
        { field: "name", headerName: translate("AppLicenseName"), width: 260 },
        /* { field: "clientId", headerName: translate("ClientId"), width: 180 }, */
        {
            field: "created", headerName: translate("Created"), width: 150,
            valueGetter: (x) => {
                return TimeUtils.utcToLocal(x.row.created)
            }
        
        },
        {
            field: "location", headerName:  translate("Location"), width: 200,
            valueGetter: (x) => {
                return x.row.location?.name;
            }
            
        },
        {
            field: "roles", headerName:  translate("Roles"), flex:1,
            valueGetter: (x) => {
                return x.row.roles?.map(item=>translate(item?.name)).join(" + ");
            }
            
        }
    ];

    let navigate = useNavigate();
    const routeChange = (params, event) => {
        let path = "/license/" + params.row.id;
        navigate(path);
    };
    const routeToCreateLicense = (params, event) => {
        let path = "/license/create";
        navigate(path);
    };


    return (

        <PageContainer>

            <PageHeader leftAlign><Translate value="AppLicensesOverview" /></PageHeader>

            <Box sx={{ width: "100%", display: 'flex', justifyContent: { xs: 'flex-start', md: 'flex-start' } }}>
                <Button sx={{ mt: 2, minWidth: '200px' }} onClick={routeToCreateLicense} variant="contained" startIcon={<AddIcon />}><Translate value="AddAppLicense" /></Button>
            </Box>


            <DataGrid
                onRowClick={routeChange}
                sx={{
                    width: { xs: "100%", sm: "100%", md: "100%", lg: "1000px" },
                    maxWidth: {md: "1000px"},
                    minHeight: "620px", mt: 2,
                    '& .MuiDataGrid-cell:hover': {
                        cursor: "pointer",
                    },
                }}
                rows={license}
                columns={columns}
                pageSize={50}
                rowsPerPageOptions={[50]}
                loading={loading}
                components={{
                    NoRowsOverlay: () => (
                      <Stack height="100%" alignItems="center" justifyContent="center">
                        <Translate value="NoRowsLicenses" />
                      </Stack>
                    ),
                    NoResultsOverlay: () => (
                        <Stack height="100%" alignItems="center" justifyContent="center">
                          <Translate value="NoResults" />
                        </Stack>
                    )
                }}
            />
        </PageContainer>

    );
};
export default Licenses;
