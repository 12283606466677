import MenuIcon from '@mui/icons-material/Menu';
import { Box, useMediaQuery } from '@mui/material';
export const BurgerMenu = (props) => {
    const { vissible } = props;
    const lgUp = useMediaQuery((theme) => theme.breakpoints.down('lg'), {
        defaultMatches: true,
        noSsr: false
    });
    return (
        <Box onClick={() => props.setSidebarOpen(true)} sx={{ position: "fixed", zIndex: 10, "visibility": vissible && lgUp ? "visible" : "hidden" }}>
            <MenuIcon sx={{ backgroundColor: process.env.REACT_APP_BACKGROUND_COLOR, p: 2, color: "white", borderBottomRightRadius: "10px" }} fontSize="medium" />
        </Box>);
}