import React, { useState, useEffect } from 'react'
import PageContainer from '../../components/PageContainer';
import { PageHeader } from '../../components/Layout/PageHeader';
import { Box } from '@mui/system';
import { Typography, Button, FormControl, TextField, InputLabel, Select, MenuItem, FormGroup, FormControlLabel, Switch, Slider } from '@mui/material'
import { DateTimePicker } from '@mui/lab';
import RangeSliderControler from '../../components/Treatment/RangeSliderControler';
import { LocationService, TreatmentService, CountryService } from '../../services/DataService'
import FormHelperDialogWrapper from '../../components/Layout/Dialog/FormHelperDialogWrapper';
import InfoIcon from '@mui/icons-material/Info';
import SliderControler from '../../components/Treatment/SliderControler';
import TreatmentControls from "../../components/Treatment/TreatmentControls"
import { useNavigate } from 'react-router-dom';
import LocationForm from '../../components/Location/LocationForm';
import { Translate, Localize } from 'react-i18nify';


export default function LocationCreate() {


    let navigate = useNavigate();



    return (
        <PageContainer>
                <PageHeader leftAlign><Translate value="CreateLocation" /></PageHeader>

            <LocationForm location={{
                "name": "",
                "address": ""
                }} />




        </PageContainer >
    )
}
