import React, { useState, useLayoutEffect } from 'react'
import { Box } from '@mui/system'
import { Dialog, Typography, Button, FormControl, FormControlLabel, Radio, FormLabel, RadioGroup} from '@mui/material'
import { PatientDetailText } from './PatientDetailText'
import { translate, Translate, Localize } from 'react-i18nify';
import TimeUtils from '../../utils/TimeUtils';
import { useRef, useEffect } from 'react';
import { Chart } from '../Session/DetailedSessionChartUplot';
import { LogService, SessionService } from '../../services/DataService';
import PauseIcon from '@mui/icons-material/Pause';
import SensorsIcon from '@mui/icons-material/Sensors';

const generateChartData = (data) => {
    try {
        const chartLength = data.reduce((total, batch) => total + batch.values.length, 0);
        const timestamp = Array.from({ length: chartLength }, () => null);
        const spo2Data = Array.from({ length: chartLength }, () => null);
        const pulseData = Array.from({ length: chartLength }, () => null);
        const o2Data = Array.from({ length: chartLength }, () => null);

        let currentIndex = 0;

        for (const batch of data) {
            const values = batch.values;
            const batchStartTime = new Date(TimeUtils.utcToLocal(batch.start, true)).getTime() / 1000;

            timestamp[currentIndex] = batchStartTime;
            spo2Data[currentIndex] = null;
            pulseData[currentIndex] = null;
            o2Data[currentIndex] = null;

            for (const value of values) {
                const timestampDataPoint = new Date(TimeUtils.utcToLocal(value.timestamp, true)).getTime() / 1000;

                timestamp[currentIndex] = timestampDataPoint;
                spo2Data[currentIndex] = value.spO2;
                pulseData[currentIndex] = value.pulse;
                o2Data[currentIndex] = value.o2;

                currentIndex++;
            }

            const batchEndTime = new Date(TimeUtils.utcToLocal(batch.end, true)).getTime() / 1000;

            timestamp[currentIndex] = batchEndTime;
            spo2Data[currentIndex] = null;
            pulseData[currentIndex] = null;
            o2Data[currentIndex] = null;

            currentIndex++;
        }

        const result = [timestamp, spo2Data, pulseData, o2Data];

        return result;
    } catch (error) {
        throw new Error('Error generating chart data: ' + error);
    }
};


export const LiveDataDialog = (props) => {

    const DetailDialogContainerRef = useRef(null);
    const date = props.date;
    const liveData = props.liveData;
    const [width, setWidth] = useState(1100);
    const [chartLoading, setChartLoading] = useState(true);
    const [liveDataEnabled, setLiveDataEnabled] = useState({ value: false });
    const [liveDataState, setLiveDataState] = useState({});
    const [xaxisRange, setXaxisrange] = useState("30");
    const [chartData, setChartData] = useState([]);

    // console.log(liveData)
    
    const handleEntered = () => {
        if (DetailDialogContainerRef.current) {
            //setWidth(DetailDialogContainerRef.current.clientWidth);
        }
    };

    // useEffect(() => {
    //     if (liveDataEnabled.value) {
    //         console.log(liveDataEnabled.value)
    //         let interval = setInterval(() => {
    //             props.onDataUpdateRequest()
    //         }, 30000);
    //         setLiveDataState(prev => {
    //             let newState = { ...prev, interval: interval }
    //             return newState;
    //         })
    //     }
    //     else {
    //         console.log(liveDataEnabled.value)
    //         clearInterval(liveDataState.interval)
    //         setLiveDataState(prev => {
    //             let newState = { ...prev, interval: null }
    //             return newState;
    //         })
    //     }

    //     return () => {
    //         clearInterval(liveDataState.interval)
    //         setLiveDataState(prev => {
    //             let newState = { ...prev, value: false, interval: null }
    //             return newState;
    //         })
    //     }
    // }, [])

    useEffect(() => {
        if (props.open) {
            let interval = setInterval(() => {
                props.onDataUpdateRequest()
            }, 30000);
            setLiveDataState(prev => {
                let newState = { ...prev, interval: interval }
                return newState;
            })
        }
        else {
            clearInterval(liveDataState.interval)
            setLiveDataState(prev => {
                let newState = { ...prev, interval: null }
                return newState;
            })
        }

        return () => {
            clearInterval(liveDataState.interval)
            setLiveDataState(prev => {
                let newState = { ...prev, value: false, interval: null }
                return newState;
            })
        }
    }, [props.open])

    // useEffect(() => {
    //     setChartData([]);
    //     setChartLoading(true)
    //     setLiveDataEnabled({ value: true })
    // }, [props.open])

 
    useEffect(() => {
        setLiveDataEnabled({ value: true })
        setChartLoading(true)
        const orderedData=props?.liveData.sort((a, b) => new Date(a.start) - new Date(b.start));
        if (orderedData.length == 0) {
            setChartData([])
            setChartLoading(false)
        } else {
            const data = generateChartData(orderedData)
            setChartData(data)
            setChartLoading(false)
        }
            
    }, [liveData])


    if (!props.open) {
        return null;
    }

    const handleRadioChange = (event) => {
        setXaxisrange(event.target.value);
    };


    return (

        <Dialog
            fullWidth
            maxWidth="md"
            sx={{ width: "100%", p: 0 }}
            open={props.open}
            onClose={() => { props.onClose() }}
            TransitionProps={{ onEntered: handleEntered }}
        >
            <Box sx={{ p: 2 }}>

                <Box sx={{ display: "flex", justifyContent: "space-between"}}>
                    <FormControl>
                    <FormLabel id="demo-row-radio-buttons-group-label"> <Translate value="ShowDataFor:"/> </FormLabel>
                    <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        onChange={handleRadioChange}
                        defaultValue = "30"
                    >
                        <FormControlLabel value={15} control={<Radio />} label={translate("Last") + " 15 " + translate("min")} />
                        <FormControlLabel value={30} control={<Radio />} label={translate("Last") + " 30 " + translate("min")} />
                        <FormControlLabel value={45} control={<Radio />} label={translate("Last") + " 45 " + translate("min")} />
                        <FormControlLabel value={"all"} control={<Radio />} label={translate("EntireDay")} />
                    </RadioGroup>
                    </FormControl>
                    

                    {/* <Box sx={{ mt: 2, display: "flex", flexDirection: "row", justifyContent: "end" }}>

                        <Button sx={{ mr: 1, alignSelf: "flex-end" }} variant="contained" color={liveDataEnabled.value ? "primary" : "primary"} startIcon={liveDataEnabled.value? <PauseIcon/>: <SensorsIcon sx={{color:"red"}}/>} onClick={() => setLiveDataEnabled(prev => {
                            let newState = { ...prev, value: !prev.value }
                            return newState
                        })} >{liveDataEnabled.value ? translate("PauseLiveData") : translate("EnableLiveData")} </Button>
                    </Box> */}

                </Box>
                <Box sx={{ width: "100%"}}>
                    
                    {chartLoading? translate("Loading"):
                    
                    <>
                    {
                        props?.chartData?.length == 0 ? translate("NoData"):
                        <Chart width={width} data={chartData} xaxisRange={xaxisRange}/>
                    }
                    </>
                    }
                    {/* {
                        props?.liveData?.length == 0 ? translate("NoData") :
                            <Chart width={width} data={chartData} xaxisRange={xaxisRange}/>
                    } */}

                    
                </Box>

            </Box>
        </Dialog >
    )
}
