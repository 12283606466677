import { useEffect, useRef, useState } from "react";
import { Typography } from "@mui/material/"
import HelpIcon from '@mui/icons-material/Help';
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material";
import Tooltip from '@mui/material/Tooltip';


export const PatientDetailText = (props) => {
	return (
		<Box sx={{display:"flex"}}>
			<Box sx={{width: "55%"}}>
				<Typography

					sx={{
						alignItems: {
							xs: "flex-start",
							md: "flex-start",
						},
					}}
					component="h1"
					variant="body1"
					{...props}
				>
					{props.label}
				</Typography>
			</Box>

			<Box sx={{width: "45%",display:"flex"}}>
				<Typography

					sx={{
						fontWeight: 'bold',
						alignItems: {
							xs: "flex-start",
							md: "flex-start",
						},
					}}
					component="h1"
					variant="body1"
					{...props}
				>
					{props.value}
				</Typography>
				<Typography

					sx={{
						fontWeight: 'bold',
						ml:1
					}}
					component="h1"
					variant="body1"
					{...props}
				>
					{props.unit}
				</Typography>
			</Box>

		</Box>
	);
}

export const ReportDetailText = (props) => {
	return (
		<Box sx={{display:"flex", flexDirection:"row"}}>
			<Box sx={{width: "50%"}}>
				<Typography

					sx={{
						alignItems: {
							xs: "flex-start",
							md: "flex-start",
						},
					}}
					component="h1"
					variant="body1"
					{...props}
				>
					{props.label}
				</Typography>
			</Box>

			<Box sx={{width: "45%",display:"flex"}}>
				<Typography

					sx={{
						fontWeight: 'bold',
						alignItems: {
							xs: "flex-start",
							md: "flex-start",
						},
					}}
					component="h1"
					variant="body1"
					{...props}
				>
					{props.value}
				</Typography>
				<Typography

					sx={{
						fontWeight: 'bold',
						ml:1
					}}
					component="h1"
					variant="body1"
					{...props}
				>
					{props.unit}
				</Typography>
			</Box>

		</Box>
	);
}

export const PatientDetailTextInfo = (props) => {
	const [open, setOpen] = useState(false);
	const [infoBoxX, setInfoBoxX] = useState(0);
	const [infoBoxY, setInfoBoxY] = useState(0);
	const infoIconRef = useRef(null);
	const parentRef = useRef(null)
	

    const toggleHelp = () => {
        setOpen(old => !old);
    };

	const handleClickOutside = (event) => {
		if(open){
			if (infoIconRef.current && !infoIconRef.current.contains(event.target)) {
				setOpen(false);
			}		
		}
	}
	useEffect(()=> {
		document.addEventListener("mousedown", handleClickOutside)
		return() => {
			document.removeEventListener("mousedown", handleClickOutside)
		}
	},[open])


	useEffect(() => {
		setInfoBoxX(parentRef.current.getBoundingClientRect().right);
		setInfoBoxY(parentRef.current.getBoundingClientRect().left);

		
	})

	const helpBoxTheme = {
		width: { 
			
			helpBoxMd: "250px",
			md: "125px",
			xs: "200px",

		}, 
		position: "absolute", 
		display: "flex",
		marginLeft:{
			md: 44,
			xs: 30
		}, 
		marginBottom:{
			md: -14,
			xs: 1
		},   
		display: open ? "flex" : "none",
	}

	const helpIconTheme = {
		mr: {md:3, xs: 28},
		color: open ? "primary.light" : "neutral.100", 
		width:"18px",
	}

	return (
		<Box  sx={{display:"flex"}}>
			<Box  sx={{display:"inline-flex", width: "55%", }} ref={parentRef}>
				<Typography

					sx={{
						alignItems: {
							xs: "flex-start",
							md: "flex-start",
						},
						width:"200px"
					}}
					component="h1"
					variant="body1"
					{...props}
				>
					{props.label}
				</Typography>

                <HelpIcon ref={infoIconRef} onClick={toggleHelp} sx={helpIconTheme} />
				<Box sx={helpBoxTheme}  >
					<Box sx={{ p: 2, zIndex: 10, position: "absolute", backgroundColor: "primary.light",  borderRadius: "15px", }}>
						{props.info}
					</Box>
				</Box> 
			</Box>
			
			<Box sx={{width: "45%",}}>
				<Typography

					sx={{
						fontWeight: 'bold',
						alignItems: {
							xs: "flex-start",
							md: "flex-start",
						},
					}}
					component="h1"
					variant="body1"
					{...props}
				>
					{props.value}
				</Typography>
			</Box>
		</Box>
	);
}

export const PatientDetailTextWarning = (props) => {

	const theme = useTheme();

	return (
		<Box sx={{display:"flex"}}>
			<Box sx={{width: "55%",}}>
				<Typography

					sx={{
						alignItems: {
							xs: "flex-start",
							md: "flex-start",
						},
					}}
					component="h1"
					variant="body1"
					{...props}
				>
					{props.label}
				</Typography>
			</Box>

			{/* <Tooltip title={props.explanation} placement="right" arrow  enterDelay={500}> */}
			<Box sx={{width: "45%"}}>
				<Typography
					sx={{
						color: '#FFB020',
						fontWeight: 'bold',
						alignItems: {
							xs: "flex-start",
							md: "flex-start",
						},
						display:"flex",
						flexWrap: 'wrap'
					}}
					component="h1"
					variant="body1"
					{...props}
				>
					{props.value}
				</Typography>
			</Box>
			{/* </Tooltip> */}
		</Box>
	);
}