import { Translate, translate } from "react-i18nify";
import { PageHeader } from "../../../../components/Layout/PageHeader";
import PageContainer from "../../../../components/PageContainer";
import {
  Box,
  Button,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { DEVICE_TYPE } from "../../Production";

import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { useEffect, useState } from "react";
import {
  DeviceService,
  MetadataService,
} from "../../../../services/DataService";
import AlertBox from "../../../../components/AlertBox";

export const UnderInvestigation = (props) => {
  const { udsn, type } = useParams();

  const navigate = useNavigate();
  const [device, setDevice] = useState({});
  const [submitState, setSubmitState] = useState(false);
  const [status, setStatus] = useState("");
  const [statusList, setStatusList] = useState();
  const [alertBox, setAlertBox] = useState({
    visibility: false,
    statusCode: null,
    text: null,
    error: null,
  });

  const handleAlertBox = (visibility, statusCode, text, error) => {
    setAlertBox({
      visibility: visibility,
      statusCode: statusCode,
      text: text,
      error: error,
    });
  };

  const dismissAlertBox = () => {
    setAlertBox({
      visibility: false,
      statusCode: null,
      text: null,
      error: null,
    });
  };

  const handleStatusChange = (e) => {
    const newValue = e.target.value;
    setStatus(newValue);
  };

  const submitInvestigation = async () => {
    try {
      setSubmitState(true);
      let result = await DeviceService.underInvestigation(
        device.id,
        status
      );

      if (result.status >= 200 && result.status < 300) {
        handleAlertBox(true, result.status, "Device changed", null);
      } 

      else {
        handleAlertBox(true, result.status, result.statusText, null);
      }
      setTimeout(() => {
        navigate(`/production/${type}`);
      }, 1000);
    } catch (e) {
      console.log({e})
      if(e.response.status===422){
        handleAlertBox(true, e.response.status, "Duplicate entry:", e.response.data?.join(","));
      }
      else{
        handleAlertBox(true, e.response.status, e.response.statusText, e.response.message);
      }
    } finally {
      setSubmitState(false);
    }
  };

  useEffect(() => {
    (async () => {
      let deviceResult = await DeviceService.getDeviceByUdsn(udsn);
      setDevice(deviceResult.data);
      setStatus(deviceResult.data.metaData?.find((x) => x.metadataId == 2)?.id);
      //setLoadingDevice(true);
    })();

    (async () => {
      let values = await MetadataService.getValues();
      setStatusList(values.data.filter((x) => x.metaData.id == 2));
      //setMetadataLoading(true);
    })();
  }, []);


  return (
    <PageContainer>
      <PageHeader leftAlign>
        <Translate value="UnderInvestigation" />
      </PageHeader>
      <Typography sx={{ mt: 1, mb: 1 }} variant="h5">
        <Translate value="InvestigateDeviceWithUdsn" />: {udsn}
      </Typography>
      <Typography sx={{ mt: 1, mb: 1 }} variant="h6">
        <Translate value="SelectStatus" />
      </Typography>
      <Select
        required
        id="status"
        displayEmpty
        value={status}
        onChange={handleStatusChange}
        sx={{ width: 300 }}
      >
        {/* <MenuItem value={""}>
          <Translate value="SelectStatus" />
        </MenuItem> */}

        {statusList?.map((value) => {
          if (
            value?.value === "AtRepairO2matic" ||
            value?.value === "SCRAPPED" ||
            value?.value === "ReadyForInvestigation" ||
            value?.id === device?.metaData?.find((x) => x.metadataId == 2)?.id
          ) {
            return (
              <MenuItem key={value?.id} value={value?.id}>
                {value?.value}
              </MenuItem>
            );
          }
          // KAC: removed un-selectable options
          // return (
          //   <MenuItem disabled key={value?.id} value={value?.id}>
          //     {value?.value}
          //   </MenuItem>
          // );
        })}
      </Select>

      <Button
        sx={{ mt: 2 }}
        type="submit"
        disabled={submitState}
        onClick={submitInvestigation}
        variant="contained"
      >
        <Translate value="Submit" />{" "}
      </Button>

      <AlertBox
        visible={alertBox.visibility}
        text={alertBox.text}
        error={alertBox.error}
        statusCode={alertBox.statusCode}
        onHide={dismissAlertBox}
      />
    </PageContainer>
  );
};
