import { useSelector } from "react-redux";

const AuthHeader = () => {
    const user = JSON.parse(localStorage.getItem('user'));

    if (user?.accessToken){
        return { 'Authorization': " " + user.accessToken };
    }

    if (user?.user?.accessToken) {
        return { 'Authorization': " " + user.user.accessToken };
    } 
    return {};
}
export default AuthHeader;