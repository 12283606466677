
import { Box, Typography } from '@mui/material';
import { Translate } from 'react-i18nify';

const Home = () => {
    return (
        <Box sx={{
            width: '100%',
            height: '100vh',
            background: "linear-gradient(#65d1ff, #2688b2)",
            alignItems: "center",
            display: "flex",
            flexDirection: { xs: 'column', md: 'row' }
        }}
        >
            <Box
                sx={{
                    width:{ xs: '100%', md: '50%' } ,
                    alignItems: "center",
                    display: "flex",
                    flexDirection: 'column'
                }}
            >
                <Box component="img" sx={{
                    width: '20%', height: '50',
                    p: { xs: 2, md: 2 }
                }} src="./Bubble.svg" />

                <Box component="img" sx={{
                    width: '50%', height: '50',
                    p: { xs: 2, md: 2 }
                }} src="../Connectivity.svg" />
                <Typography sx={{
                    color: '#FFFFFF',
                    textAlign: "center",
                    p: { xs: 3, md: 5 }
                }} >
                    <Translate value="HomeMessage1"/>
                    <br /><br />
                    <Translate value="HomeMessage2"/>
                </Typography>
            </Box>


            <Box component="img" sx={{
                width: '40%',
                p: { xs: 2, md: 5 }
            }} src="../HOT.svg" />

        </Box>

    )
};

export default Home;