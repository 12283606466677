import { DataGrid } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { PageHeader } from "../../components/Layout/PageHeader";
import PageContainer from "../../components/PageContainer";
import UserLookup from '../../components/User/UserLookup'
import { UserService } from "../../services/DataService";
import { Translate, translate } from 'react-i18nify';
import { Button , Box , Typography, Stack } from '@mui/material'
import AddIcon from '@mui/icons-material/Add';
import { set } from "date-fns";
import {UpdatesBanner} from "../../components/UpdatesBanner";
import { styled } from '@mui/material/styles';



const UserOverview = (props) => {
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [searched, setSearched] = useState(false);

    /* useEffect(() => {
        (async () => {
            let apiResult = await UserService.getAll();
            setUsers(apiResult.data);
            setLoading(false)
        })();    
    }, []) */


    const columns = [
        { field: "id", headerName: translate("Id"), type: "number", flex:1, minWidth: 50},
        { field: "name", headerName: translate("FirstName"), flex:2,  minWidth: 100 },
        { field: "lastName", headerName: translate("LastName"), flex:2,   minWidth: 150 },
        { field: "email", headerName: translate("Email"), flex:4,  minWidth: 200 },
        {
            field: "location", headerName: translate("UserLocation"), flex:3,  minWidth: 150,
            valueGetter: (x) => {
                return x.row.location?.name;
            }
        
        },
        {
            field: "roles", headerName:  translate("Roles"), flex:3,  minWidth: 300,
            valueGetter: (x) => {

                let allRoles= x.row.roleLocations?.map(role=>{
                    return translate(role.roleName)
                });
                return allRoles.join(", ");
            },
            
        }
    ];

    let navigate = useNavigate();
    const routeChange = (params, event) => {
        let path = "/user/" + params.row.id;
        navigate(path);
    };

    const navigateCreate = () => {
        // 👇︝ navigate to /
        navigate('/user/create');
    };

    const queryUsers = (query) => {
        setLoading(true);

        var queryString = "";

        for (var key in query) {
            if (query.hasOwnProperty(key)) {
                queryString += key + "=" + query[key] + "&"
            }
        }
        queryString = queryString.slice(0, -1); // remove last &

        (async () => {
            await UserService.getSearch(queryString).then((apiResult) => { 
                setUsers(apiResult.data);
            })
            .catch((err) => {
                console.log(err)
                if(err.response.status === 404) {
                    setUsers([])
                }
            }).finally(() => setLoading(false));
        })();
        setSearched(true)
    }

    const StyledGridOverlay = styled('div')(({ theme }) => ({
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        '& .no-rows-primary': {
          fill: '#3D4751',
          ...theme.applyStyles('light', {
            fill: '#AEB8C2',
          }),
        },
        '& .no-rows-secondary': {
          fill: '#1D2126',
          ...theme.applyStyles('light', {
            fill: '#E8EAED',
          }),
        },
      }));

    function CustomNoRowsOverlay() {
        return (
            <StyledGridOverlay>
                <Typography variant="h6" sx={{ mt: 2, mb: 2 }}><Translate value="NoUsersFound" /></Typography>
            </StyledGridOverlay>
        )}

    return (

        <PageContainer>

            <PageHeader leftAlign><Translate value="UserOverview" /></PageHeader>

            <UpdatesBanner update_description="UpdatesDescriptionUsersPage"/>

            <Box sx={{ mt: 2, width: { xs: "100%", sm: "100%", md: "100%", lg: "1000px" }, maxWidth: {md: "1000px"}}}>
                <Box sx={{display:"flex", flexDirection:"row", justifyContent: 'space-between', mb:4 }}>
                    <Box sx={{width:"60%", display:"flex", flexDirection:"column"}}>
                    <Typography variant="h5" sx={{mb:1}}><Translate value="UserSearch" /></Typography>
                    <Typography variant="h7"><Translate value="UserLookupExplanation" /></Typography>
                    <Typography variant="h7"><Translate value="UserLookupExplanation1" /></Typography>
                    <Typography variant="h7"><Translate value="UserLookupExplanation2" /></Typography>
                    </Box>
                
                    
                    <Button onClick={navigateCreate} sx={{ mt: 2, alignSelf: "start" , minWidth:"200px"}} startIcon={<AddIcon />} variant="contained"><Translate value="AddUser" /></Button>
                    
                </Box>
                <UserLookup handleSearchQuery={queryUsers} />
            </Box>

            {searched?
                <Box sx={{ width: { xs: "100%", sm: "100%", md: "100%", lg: "1000px" }, maxWidth: {md: "1000px"} }}>
                <DataGrid
                onRowClick={routeChange}
                sx={{
                    width: { xs: "100%", sm: "100%", md: "100%", lg: "1000px" },
                    maxWidth: {md: "1000px"},
                    minHeight: "460px", mt: 2,
                    '& .MuiDataGrid-cell:hover': {
                        cursor: "pointer",
                    },
                }}
                rows={users}
                columns={columns}
                pageSize={50}
                rowsPerPageOptions={[50]}
                loading={loading}
                components={{
                    NoRowsOverlay: () => (
                      <Stack height="100%" alignItems="center" justifyContent="center">
                        <Translate value="NoRowsUsers" />
                      </Stack>
                    ),
                    NoResultsOverlay: () => (
                        <Stack height="100%" alignItems="center" justifyContent="center">
                          <Translate value="NoResults" />
                        </Stack>
                    )
                }}
                />
                </Box>
            : <></>
            }

        </PageContainer>

    );
};
export default UserOverview;
