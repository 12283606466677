import React, { useState, useEffect } from 'react'
import PageContainer from '../../components/PageContainer';
import { PageHeader } from '../../components/Layout/PageHeader';
import { Typography } from '@mui/material'
import { Translate, Localize } from 'react-i18nify';
import {UpdatesBanner} from "../../components/UpdatesBanner";

import PatientForm from '../../components/Patient/PatientForm';
export default function PatientCreate() {
    return (
        <PageContainer>


            <PageHeader leftAlign><Translate value="AddPatient" /></PageHeader>
            <UpdatesBanner update_description="UpdatesDescriptionPatientCreatePage"/>
            <Typography sx={{ mt: 1, mb: 1 }} variant="body">
                <Translate value="AddPatientText" />
            </Typography>
            <PatientForm />
        </PageContainer>
    )
}
