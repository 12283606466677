import React, { useState, useEffect } from 'react'
import PageContainer from '../../components/PageContainer'
import { Link } from 'react-router-dom'
import { PageHeader } from '../../components/Layout/PageHeader'
import TimeUtils from '../../utils/TimeUtils';
import { Box } from '@mui/system'
import { Stack, Typography, Button, FormControl, TextField, InputLabel, Select, MenuItem, FormGroup, FormControlLabel, Switch } from '@mui/material'
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { DataGrid } from '@mui/x-data-grid';
import { useNavigate } from 'react-router-dom'
import { DeviceService } from '../../services/DataService'
import { Translate, Localize , translate} from 'react-i18nify';

export const HotDevices = () => {


    const [devices, setDevices] = useState([]);
    const [loading, setLoading] = useState(true);

    const navigate = useNavigate();
    useEffect(() => {
        (async () => {
            let apiResult = await DeviceService.getByTemplate(12, 0, 500);
            setDevices(apiResult.data);
            setLoading(false)
        })();
    }, [])
    const goToDeviceDetails = (params, event) => {
        let row = params.row;
        var udsn = row.metaData.find(x => x.metadataId == 1).value; 
      
        let path = "/device/HOT/" + udsn;
        navigate(path);
    };
    
    
    const columns = [

        {
            field: 'udsn', headerName: 'UDSN', minWidth:150,
            valueGetter: (udsn) => {
                return udsn.row.metaData.find(x => x.metadataId == 1)?.value;
            }
        },
        
        {
            field: 'locationId', headerName: translate("Location"), flex: 1, minWidth:200, 
            valueGetter: (comp) => {
                return comp.row.location?.name;
            }
        },
        {
            field: 'ownerId', headerName: translate("Owner"), flex: 1, minWidth:200,
            valueGetter: (comp) => {
                return comp.row.owner?.name;
            }
        },
        {
            field: 'status', headerName: translate("Status"), flex: 1, minWidth:100, 
            valueGetter: (status) => {
                return translate(status.row.metaData.find(x => x.metadataId == 2)?.value);
            }
        },
        {
            field: 'sw', headerName: translate("Sw"), minWidth:80, 
            valueGetter: (sw) => {
                return sw.row.metaData.find(x => x.metadataId == 14)?.value;
            }
        },
        {
            field: 'fw', headerName: translate("Fw"), minWidth:80,
            valueGetter: (fw) => {
                return fw.row.metaData.find(x => x.metadataId == 15)?.value;
            }
        }
    ];

    const DownloadCsv = (devices) => {

        let rows = [
            ["sep=;"],
            [translate("HotDevices")],
            [translate("Downloaded"), TimeUtils.formatDateYYMMDD(new Date())],
            [translate("NumberOfDevices"), devices.length],
            []
        ];
    
        rows.push([translate("Device") + " " + translate("Id"),
            translate("DeviceOwner") + " " + translate("Id"),
            translate("DeviceOwner") + " " + translate("Name"),
            translate("LocationId"),
            translate("LocationName"),
            translate("Location") + " " + translate("Country"),
            translate("DeviceSerialNumber"),
            translate("Status"),
            translate("Dmr"),
            translate("Sw"),
            translate("Fw")])
        
        devices.forEach(device => {
            rows.push([device.id,
                device.ownerId,
                device.owner?.name,
                device.location?.id,
                device.location?.name,
                translate(("Country-" + device.location?.countryCode)),
                device.metaData.find(x => x.metadataId == 1)?.value, 
                translate(device.metaData.find(x => x.metadataId == 2)?.value),
                device.metaData.find(x => x.metadataId == 11)?.value,
                device.metaData.find(x => x.metadataId == 14)?.value,
                device.metaData.find(x => x.metadataId == 15)?.value
            ])
        
        })
       
        let csvContent = "data:text/csv;charset=utf-8,"
            + rows.map(e => e.join(";")).join("\n");
    
        var encodedUri = encodeURI(csvContent);
        var link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", "O2matic - " + translate("HotDevices") + ".csv");
        document.body.appendChild(link);
        link.click();
        if(setLoading)
            setLoading(false);
    
    
        // (async () => {
        //     await LogService.saveLog([TimeUtils.formatDateYYMMDD(new Date())], "Hot devices", "csv", patient?.id?.value).catch((error) => {
        //         console.error("Error saving log: ", error);
        //     })
        // })();
    
    }

    return (
        <PageContainer>
            <PageHeader leftAlign><Translate value="HotDevices" /></PageHeader>
            <Box sx={{ width: { xs: "100%", sm: "100%", md: "100%", lg: "1000px" },
          maxWidth: {md: "1000px"}, }}>
                <DataGrid
                    sx={{ height: 680, mt: 2 }}
                    columns={columns}
                    rows={devices}
                    pageSize={50}
                    rowsPerPageOptions={[50]}
                    onRowClick={goToDeviceDetails}
                    loading={loading}
                    components={{
                        NoRowsOverlay: () => (
                          <Stack height="100%" alignItems="center" justifyContent="center">
                            <Translate value="NoRowsDevices" />
                          </Stack>
                        ),
                        NoResultsOverlay: () => (
                            <Stack height="100%" alignItems="center" justifyContent="center">
                              <Translate value="NoResults" />
                            </Stack>
                        )
                    }}
                />

                <Button
                    sx={{ mt: 1.5, height: "30px", width:"200px", alignSelf: "start", pl:1.5, fontSize:13}}
                    color="primary"
                    variant="contained"
                    component="label"
                    onClick={() => DownloadCsv(devices)}
                >
                    <Translate value="DownloadCsv" />
                </Button>
            </Box>
        </PageContainer>
    )
}
