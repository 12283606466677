import React, { useState, useEffect } from 'react'
import PageContainer from '../../components/PageContainer';
import { PageHeader } from '../../components/Layout/PageHeader';
import { Box } from '@mui/system';
import { Typography, Button, FormControl, TextField, InputLabel, Select, MenuItem, FormGroup, FormControlLabel, Switch, Slider } from '@mui/material'
import { DateTimePicker } from '@mui/lab';
import RangeSliderControler from '../../components/Treatment/RangeSliderControler';
import { LocationService, TreatmentService, CountryService, MetadataService, DeviceService } from '../../services/DataService'
import FormHelperDialogWrapper from '../../components/Layout/Dialog/FormHelperDialogWrapper';
import InfoIcon from '@mui/icons-material/Info';
import SliderControler from '../../components/Treatment/SliderControler';
import TreatmentControls from "../../components/Treatment/TreatmentControls"
import { useNavigate } from 'react-router-dom';
import LocationForm from '../../components/Location/LocationForm';
import { Translate, Localize, translate } from 'react-i18nify';
import AlertBox from '../../components/AlertBox';
import { CheckBox } from '@mui/icons-material';
import { ConfirmDialog } from "../../components/Layout/Dialog/ConfirmDialog"
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import ConstructionIcon from '@mui/icons-material/Construction';

export default function HotDeviceCreate() {


    let navigate = useNavigate();

    const [checked, setChecked] = useState(false);
    const [locationName, setLocationName] = useState("");
    const [dmrVersionName, setDmrVersionName] = useState("");
    const [ownerLocationName, setOwnerLocationName] = useState("");
    const [requestSubmited, setRequestSubmited] = useState(false);
    const [confirmStatus, setConfirmStatus] = useState({status: 0, body: null});

    const [doneLoadingLocation, setDoneLoadingLocation] = useState(false);
    const [locations, setLocations] = useState([]);
    const [showDuplicateError, setShowDuplicateError] = useState({
        visible: false,
        data: []
    });

    const [doneLoadingDmr, setDoneLoadingDmr] = useState(false);
    const [dmrVersions, setDmrVersions] = useState([]);
    const [alertBox, setAlertBox] = useState({
        "visiblity": false,
        "statusCode": null,
        "text": null,
        "error": null
    }
    );

    const handleAlertBox = (visiblity, statusCode, text, error) => {
        setAlertBox({
            "visiblity": visiblity,
            "statusCode": statusCode,
            "text": text,
            "error": error
        }
        )
    }
    const dismissAlertBox = () => {
        setAlertBox({
            "visiblity": false,
            "statusCode": null,
            "text": null,
            "error": null

        })
    }


    const handleLocationChange = (event, newValue) => {
        setLocationName(event.target.value);
    };

    const handleOwnerChange = (event, newValue) => {
        setOwnerLocationName(event.target.value);
    };

    const handleDmrChange = (event, newValue) => {
        setDmrVersionName(event.target.value);
    };

    useEffect(() => {
        (async () => {
            let locationsResult = await LocationService.getByTag([], ["PRODUCTION"]);
            setLocations(locationsResult.data);
            setDoneLoadingLocation(true);

        })();

        (async () => {
            let dmrVersions = await MetadataService.getValuesByName("DMR_HOT_Version")
            let data = dmrVersions.data;
            //dmrVersions.reverse();
            data.reverse()
            setDmrVersions(data);
            setDoneLoadingDmr(true);
        })();
    }, []);

    const handleSubmit = (event) => {
        event.preventDefault();
        dismissAlertBox()
        setRequestSubmited(true)
        var body = {
            "printSerial": printSerial.value,
            "newBatch": newBatch,
            "flowmeterSerial": flowmeterSerial.value,
            "dmrMetadataId": dmrVersionName,
            "locationId": locationName
        }

        DeviceService.createHot(body).then(result => {
            navigate("/device/HOT/" + result.data.udsn, { replace: true });
        }).catch(e => {
            if (e?.response?.status === 400) {
                console.log({e})
                setShowDuplicateError({ vissible: true, data: e?.response?.data })

            }
        })
            .finally(() => { setRequestSubmited(false) })
    }


    const [printSerial, setPrintSerial] = useState({ value: "", error: false, errorText: null });
    const [newBatch, setNewBatch] = useState(false);
    const [flowmeterSerial, setFlowmeterSerial] = useState({ value: "", error: false, errorText: null });
    const [batterySerial, setBatterySerial] = useState("");


    const handlePrintSerialChange = (event) => {
        const reg = new RegExp("^AE[0-9]{5,7}$");
        const value = event.target.value;
        const valid = reg.test(value);
        setPrintSerial({ value: value, error: !valid, errorText: valid ? null : translate("Format") + " AE1234567" })
    }
    const handleNewBatchChange = (event) => {
        setNewBatch(event.target.value)
    }

    const handleFlowmeterSerialChange = (event) => {
        const reg = new RegExp("^[a-zA-Z0-9]{10}$");
        const value = event.target.value;
        const valid = reg.test(value);
        setFlowmeterSerial({ value: value, error: !valid, errorText: valid ? null : translate("Format") + " AbCd123456" })
    }

    const renderCreateHotForm = () => {
        return (
            <Box onSubmit={handleSubmit} component="form" sx={{ width: { xs: "95%", sm: "400px" }, mt: 2, display: "flex", flexDirection: "column" }}>

                <InputLabel id="printSerialLabel"><Translate value="PrintSerial" /></InputLabel>
                <TextField sx={{ width: "100%" }}
                    id="printSerial"
                    inputProps={{ pattern: "^AE[0-9]{5,7}$" }}
                    required
                    onChange={handlePrintSerialChange}
                    value={printSerial.value}
                >
                </TextField>
                {printSerial.error ? <Typography>{printSerial.errorText}</Typography> : null}

                <InputLabel id="flowmeterSerialLabel"><Translate value="FlowmeterSerial" /></InputLabel>
                <TextField sx={{ width: "100%" }}
                    id="flowmeterSerial"
                    inputProps={{ pattern: "^[a-zA-Z0-9]{10}$" }}
                    required
                    onChange={handleFlowmeterSerialChange}
                    value={flowmeterSerial.value}>
                </TextField>
                {flowmeterSerial.error ? <Typography>{flowmeterSerial.errorText}</Typography> : null}


                <InputLabel id="dmrLabel">DMR <Translate value="Version" /></InputLabel>
                {doneLoadingDmr ?
                    <Select
                        required
                        id="dmr"
                        displayEmpty
                        value={dmrVersionName}
                        onChange={handleDmrChange}
                    >
                        <MenuItem value={""}><Translate value="Select" /> DMR</MenuItem>
                        {dmrVersions.map(l => {
                            return <MenuItem key={l.id} value={l.id}>{l.value}</MenuItem>
                        })}
                    </Select>
                    : null}


                <InputLabel id="locationLabel"><Translate value="Location" /></InputLabel>
                {doneLoadingLocation ?
                    <Select
                        required
                        id="location"
                        displayEmpty
                        value={locationName}
                        onChange={handleLocationChange}
                    >
                        <MenuItem value={""}><Translate value="SelectLocation" /></MenuItem>
                        {locations.map(l => {
                            return <MenuItem key={l.id} value={l.id}>{l.name}</MenuItem>
                        })}
                    </Select>
                    : null}

                <InputLabel id="newBatchLabel">{<Translate value="NewBatch" />}</InputLabel>
                <Select
                    required
                    id="newBatch"
                    value={newBatch}
                    onChange={handleNewBatchChange}
                >
                    <MenuItem key={0} value={false}>{<Translate value="No" />}</MenuItem>
                    <MenuItem key={1} value={true}>{<Translate value="Yes" />}</MenuItem>
                </Select>

                <Button sx={{ mt: 2 }} disabled={requestSubmited} type="submit" variant='contained'><Translate value="Submit" /> </Button>
                <AlertBox visible={alertBox.visiblity} text={alertBox.text} error={alertBox.error} statusCode={alertBox.statusCode} onHide={dismissAlertBox} />

            </Box>


        )

    }


    return (
        <PageContainer>
            <ConfirmDialog
                dialogIcon={<ErrorOutlineIcon color="error" sx={{ fontSize: 75 }} />}
                title={<Translate value="DuplicateEntry" />}
                message={translate("ContactO2maticToResolve") +
                    showDuplicateError?.data?.map(text => "\n"+translate(text))}
                confirmText={<Translate value="Continue" />}
                open={showDuplicateError.vissible}
                onConfirm={() => { setShowDuplicateError({ vissible: false }) }}
                confirmStatus={confirmStatus}
            />
            <PageHeader leftAlign><Translate value="Create" /> HOT</PageHeader>

            {renderCreateHotForm()}



        </PageContainer >
    )
}
