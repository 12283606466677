import { useState, useEffect } from "react";
import { Typography, Button, Modal, Box, Backdrop, Fade, Skeleton} from "@mui/material";
import { Translate, translate } from "react-i18nify";
import { PatientDetailText , PatientDetailTextInfo, PatientDetailTextWarning} from "./PatientDetailText";
import PatientDashboardCategory from "./PatientDashboardCategory";
import PatientDashboardCategoryWarning from "./PatientDashboardCategoryWarning";
import WarningIcon from '@mui/icons-material/Warning';
import TimeUtils from "../../utils/TimeUtils";
import { PatientService } from "../../services/DataService";

export const PatientTreatmentInfoComponent = (props) => {
  const [open, setOpen] = useState(false);
  
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  
  return(
    <Box sx={{ width:"100%", mt: 1, borderRadius: "15px", border: 1, borderColor: "primary.main", bgcolor: "primary.main", color: "primary.contrastText", p: 2}}>
      <Typography sx={{ mt: 1 }} variant="body">
        {<Translate value="TreatmentInformation" />}
      </Typography>
    <Box sx={{ width:"100%", display: "flex"}}>
    {props.treatmentPending ? (
      <>
      <Box sx={{ width:"45%", ml: "auto",}}>
        <Button
          sx={{ mt: 1.5, height: "30px", width:"max-content", alignSelf: "start", pl:1.5, fontSize:13, position:"absolute"}}
          color="warning"
          variant="contained"
          component="label"
          startIcon={<WarningIcon/>}
          onClick={handleOpen}
        >
          <Translate value="PendingInstallation" />
        </Button>
        <Modal
          open={open}
          onClose={handleClose}
          closeAfterTransition
          slots={{ backdrop: Backdrop }}
          slotProps={{
          backdrop: {
              timeout: 500,
          },
          }}
        >
          <Fade in={open}>
          <Box sx={{position: 'absolute',top: '50%',left: '50%',transform: 'translate(-50%, -50%)',width: "500px",borderRadius: "15px", borderColor: "warning.main", bgcolor: "warning.contrastText",boxShadow: 15}}>
            <Box sx={{width:"468px", borderColor: "warning.contrastText", bgcolor: "warning.main",borderRadius: "15px", borderBottom: 15, color: "warning.contrastText",
            p:2, display: 'flex', alignItems: 'center'}}>
            <WarningIcon sx={{height:"30px", width:"30px", mx:2}}/>
            <Typography variant="h5" component="h2">
                <Translate value="InstallationOfTreatmentPending" />
              </Typography>
            </Box>
            <Box sx={{p:2}}>
              <Typography sx={{color: "#000000", whiteSpace:"pre-line" }}>
                <Translate value="CurrentTreatmentOnDeviceDescription" /> 
              </Typography>
              <Typography variant="h6" component="h2" sx={{mt:2, mb:-2, color: "#000000"}}>
                  <Translate value="CurrentTreatmentOnDevice" />
              </Typography>
            </Box>
            <Box sx={{p:2}}>
            <PatientTreatmentInfo patientId={props.patientId} treatment={props.previousTreatment} treatmentPending={false} deviceCurrentTreatment={props.deviceCurrentTreatment} treatmentPrescribed={false}/>
            </Box>
          </Box>
          </Fade>
        </Modal>
      </Box>
      </>
      ) : (
        <Box sx={{ width:"55%"}}>
        </Box>
      )}
      </Box>

      <PatientTreatmentInfo patientId={props.patientId} treatment={props.treatment} treatmentPending={props.treatmentPending} deviceCurrentTreatment={props.deviceCurrentTreatment} treatmentPrescribed={props.treatmentPrescribed}/>
    </Box>  
  )
}
  
const PatientTreatmentInfo = (props) => {
  const [treatmentPrescribed, setTreatmentPrescribed] = useState(null);
  const [deviceCurrentTreatmentTimestamp, setDeviceCurrentTreatmentTimestamp] = useState(null);
  const [treatment, setTreatment] = useState({
    id: null,
    name: null,
    spO2Minimum: 0,
    spO2Maximum: 0,
    lowO2ShortTerm: undefined,
    lowO2LongTerm: undefined,
    highO2ShortTerm: 0,
    highO2LongTerm: 0,
    durLod1: 0,
    OAD: 0,
    flowResponse: "",
    oxygenPrescription: 0,
    shortTerm: "",
  });

  useEffect(() => {
    if(props.treatment !== null && treatment.id == null){
      setTreatment({
        id: props.treatment?.id,
        name: props.treatment?.name,
        oxygenPrescription: props.treatment?.minutesPrescribed / 60,
        spO2Minimum: props.treatment?.values.find((item) => item.code === "LSpO2")?.value,
        spO2Maximum: props.treatment?.values.find((item) => item.code === "HSpO2")?.value,
        lowO2ShortTerm: props.treatment?.values.find((item) => item.code === "LO2ST")?.value,
        lowO2LongTerm: props.treatment?.values.find((item) => item.code === "LO2LT")?.value,
        highO2ShortTerm: props.treatment?.values.find((item) => item.code === "HO2ST")?.value,
        highO2LongTerm: props.treatment?.values.find((item) => item.code === "HO2LT")?.value,
        durLod1: props.treatment?.values.find((item) => item.code === "DurLod1")?.value,
        OAD: props.treatment?.values.find((item) => item.code === "OAD")?.value,
        flowResponse: 
          props.treatment?.values.find((item) => item.code === "Ki")?.value == 150 ? "Very fast" : 
          props.treatment?.values.find((item) => item.code === "Ki")?.value == 100 ? "Fast" : 
          props.treatment?.values.find((item) => item.code === "Ki")?.value == 25 ? "Normal" : 
          "Custom (" + props.treatment?.values.find((item) => item.code === "Ki")?.value + ")",
        shortTerm: 
          props.treatment?.values.find((item) => item.code === "OAD")?.value == 15 * 60 ? "15 min/hour" : 
          props.treatment?.values.find((item) => item.code === "OAD")?.value == 30 * 60 ? "30 min/hour" : 
          props.treatment?.values.find((item) => item.code === "OAD")?.value == 45 * 60 ? "45 min/hour" : 
          props.treatment?.values.find((item) => item.code === "OAD")?.value == 61 * 60 ? "Always" : ""
      })
    }
    if(props.treatment && props.patientId){ //This call is in its own useEffect because otherwise it breaks the app. It is not clear why...
      (async () => {
        await PatientService.getTreatmentPrescribedTimeByTreatmentId(props.patientId, props.treatment.id)
        .then((response) => {
          setTreatmentPrescribed(response.data)
        }).catch((error) => {
          console.error("Error getting treatment prescribed time by treatment id: ", error)
        })
      })();
    }
  }, [props.treatment]);

  useEffect(() => {
    if(props.deviceCurrentTreatment === "Unknown"){
      setDeviceCurrentTreatmentTimestamp("-")
    }else{
      setDeviceCurrentTreatmentTimestamp(TimeUtils.utcToLocal(props.deviceCurrentTreatment?.timestamp))
    }
  }, [props.deviceCurrentTreatment])

    return (
      <Box>
        <Box sx={{mt: 1}}>
          {treatment.name ? (
            props.treatmentPending === true ? (
            <PatientDashboardCategoryWarning // if "pending" then color treatment name as warning
             title={treatment.name}
            ></PatientDashboardCategoryWarning>
            ) : (
            <PatientDashboardCategory // if "pending" then color treatment name as warning
              title={treatment.name}
            ></PatientDashboardCategory>
            )) : (
              <Skeleton variant="rounded" width={"50%"} height={50} />
            )
          }
        </Box>

        <Box sx={{mt: 2}}>
          {
            (props.treatmentPending !== null && treatmentPrescribed !== null) ? (
              <>
              <PatientDetailText
              label={<Translate value="Prescribed" />}
              value={TimeUtils.utcToLocal(treatmentPrescribed)}
              ></PatientDetailText>
               { props.treatmentPending === true ? 
                  (
                    <PatientDetailTextWarning
                      label={<Translate value="Installed" />}
                      value={<Translate value="Pending" />}
                    ></PatientDetailTextWarning>
                  ) : (
                    <PatientDetailText
                      label={<Translate value="Installed" />}
                      value={deviceCurrentTreatmentTimestamp}
                    ></PatientDetailText> 
                  )
                }
              </> 
            ) 
            : 
            (
              <Skeleton variant="rounded" width={"90%"} height={48} />
            )
          }
        </Box>
        <Box sx={{mt: 3}}>
        {treatment.id ? (
          <>
          <PatientDetailText
            label={<Translate value="TreatmentId" />}
            value={treatment.id}
          ></PatientDetailText>
          <PatientDetailTextInfo
            // label={<Translate value="Lod1" />}
            label={<Translate value="AnalysingPhase" />}
            value={treatment.durLod1 == 300 ? translate("Yes") : translate("No")}
            info={<Translate value="AnalysingPhaseExplanationPatientPage" />}
            
          ></PatientDetailTextInfo>
          
          <PatientDetailTextInfo
            label={<Translate value="FlowResponse" />}
            value={treatment.flowResponse}
            info = {<Translate value="FlowResponseExplanation" />}
          ></PatientDetailTextInfo>

          <PatientDetailTextInfo
            // label={<Translate value="OxygenPrescription" />}
            label={<Translate value="O2ComplianceGoal" />}
            value={treatment.oxygenPrescription + " " + translate("hours/day")}
            info = {<Translate value="OxygenComplianceGoalExplanationPatientPage" />}
          ></PatientDetailTextInfo>
          <PatientDetailText
            label={<Translate value="SpO2Range" />}
            value={treatment.spO2Minimum + " - " + treatment.spO2Maximum + " %"}
          ></PatientDetailText>
          </>
        ) : (
          <Skeleton variant="rounded" animation="pulse" height={120}  width={"90%"} /> // 
        )
        }
        </Box>

        <Box sx={{mt: 2, mb:1}}>
          {treatment.lowO2LongTerm || treatment.lowO2ShortTerm >= 0 ? (
            <>
              <Typography variant="h6">
              <Translate value="O2FlowRanges" />
              </Typography>
              <PatientDetailText
                label={<Translate value="WithoutPO" />}
                value={treatment.lowO2LongTerm + " - " + treatment.highO2LongTerm + " " + translate("l/min")}
              ></PatientDetailText>
    
              <PatientDetailText
                label={<Translate value="WithPO" />}
                value={treatment.lowO2ShortTerm + " - " + treatment.highO2ShortTerm + " " +translate("l/min")}
              ></PatientDetailText>
            </>
          ) : (
            <Skeleton variant="rounded" animation="pulse" height={70}  width={"90%"} /> // 
          )}
        </Box>

        {treatment.shortTerm ? (
        <>
        <Box sx={{mb:-0.75}}>
          <Typography variant="h7">
            <Translate value="AllowedTimeWithin" />
          </Typography>
        </Box>
        
        <PatientDetailTextInfo
          // label={<Translate value="ShortTermLength" />}
          label={treatment.lowO2ShortTerm + " - " + treatment.lowO2LongTerm + " " + translate("and") + " " + treatment.highO2LongTerm + " - " + treatment.highO2ShortTerm + " " + translate("l/min")}
          value={treatment.shortTerm}
          info = {<Translate value="AllowedTimeWithinExplanation" />}
        ></PatientDetailTextInfo>

        <Box sx={{mt:-0.75}}>
          <Typography variant="h7">
            <Translate value="withPulseOximeterOn" />
          </Typography>
        </Box>
        </>
          ) : (
            <Skeleton variant="rounded" animation="pulse" height={60}  width={"90%"} /> // 
          )}
      </Box>
    );
};

// const GeneratePreviousTreatmentInfo = (props) => {
  
//   if (props.previousTreatment?.id == null) {
//     return null;
//   }
  
//   let SpO2Minimum,
//     SpO2Maximum,
//     LowO2ShortTerm,
//     LowO2LongTerm,
//     HighO2ShortTerm,
//     HihgO2LongTerm,
//     DurLod1,
//     OAD
//     ;
//   let name, treatmentId, FlowResponse;
//   let oxygenPrescription = props.previousTreatment?.minutesPrescribed / 60

//   name = previousTreatment.name;
//   treatmentId = previousTreatment.id;
//   previousTreatment.values.forEach((item) => {
//     switch (item.code) {
//       case "LSpO2": {
//         SpO2Minimum = item.value;
//         break;
//       }
//       case "DurLod1": {
//         DurLod1 = item.value;
//         break;
//       }
//       case "HO2LT": {
//         HihgO2LongTerm = item.value;
//         break;
//       }
//       case "HO2ST": {
//         HighO2ShortTerm = item.value;
//         break;
//       }
//       case "HSpO2": {
//         SpO2Maximum = item.value;
//         break;
//       }
//       case "OAD": {
//         OAD = item.value;
//         break;
//       }
//       case "Ki": {
//         FlowResponse = "Custom (" + item.value + ")";

//         if (item.value == 150) FlowResponse = "Very fast";

//         if (item.value == 100) FlowResponse = "Fast";

//         if (item.value == 25) FlowResponse = "Normal";

//         break;
//       }
//       case "LO2LT": {
//         LowO2LongTerm = item.value;
//         break;
//       }
//       case "LO2ST": {
//         LowO2ShortTerm = item.value;
//         break;
//       }
//     }
//   });

//   let shortTerm = ""
//   switch (OAD) {
//     case 15 * 60: shortTerm = "15"; translate("MinutesPerHour"); break;
//     case 30 * 60: shortTerm = "30"; translate("MinutesPerHour"); break;
//     case 45 * 60: shortTerm = "45"; translate("MinutesPerHour"); break;
//     case 61 * 60: shortTerm = translate("Always"); break;
//   }

//   return (
//     <Box sx={{ width:"468px", borderRadius: "15px", borderTop: 15, borderColor: "primary.contrastText", bgcolor: "primary.main", color: "primary.contrastText", p: 2}}>
//       <Typography sx={{ mt: 1 }} variant="body">
//         {<Translate value="PreviousTreatmentInformation" />}
//       </Typography>

//       <PatientDashboardCategory // treatment name
//         title={name}
//       ></PatientDashboardCategory> 
        
//       <Box sx={{mt: 2}}>
//         <PatientDetailText
//           label={<Translate value="Installed" />}
//           value={TimeUtils.utcToLocal(deviceCurrentTreatment?.timestamp)}
//         ></PatientDetailText>
//       </Box>

//       <Box sx={{mt: 3}}>
//         <PatientDetailText
//           label={<Translate value="TreatmentId" />}
//           value={treatmentId}
//         ></PatientDetailText>
        
//         {/* //First 5 min of treatment with fixed flow* */}
//         <PatientDetailText
//           // label={<Translate value="Lod1" />}
//           label={<Translate value="AnalysingPhase" />}
//           value={DurLod1 == 300 ? translate("Yes") : translate("No")}
//         ></PatientDetailText>
        
//         <PatientDetailText
//           label={<Translate value="FlowResponse" />}
//           value={FlowResponse}
//         ></PatientDetailText>

//         <PatientDetailText
//           // label={<Translate value="OxygenPrescription" />}
//           label={<Translate value="O2ComplianceGoal" />}
//           value={oxygenPrescription + " " + translate("hour/day")}
//         ></PatientDetailText>
//         <PatientDetailText
//           label={<Translate value="SpO2Range" />}
//           value={SpO2Minimum + " - " + SpO2Maximum + " %"}
//         ></PatientDetailText>
//       </Box>

//       <Box sx={{mt: 3, mb:1}}>
//         <Typography variant="h6">
//         <Translate value="O2FlowRanges" />
//         </Typography>
//         <PatientDetailText
//           label={<Translate value="WithoutPO" />}
//           value={LowO2LongTerm + " - " + HihgO2LongTerm + " " + translate("l/min")}
//         ></PatientDetailText>
//         <PatientDetailText
//           label={<Translate value="WithPO" />}
//           value={LowO2ShortTerm + " - " + HighO2ShortTerm + " " + translate("l/min")}
//         ></PatientDetailText>
//       </Box>

//       <Typography variant="h7">
//         <Translate value="AllowedTimeWithin" />
//         </Typography>
//       <PatientDetailText
//         // label={<Translate value="ShortTermLength" />}
//         label={LowO2ShortTerm + " - " + LowO2LongTerm + " and " + HihgO2LongTerm + " - " + HighO2ShortTerm + " " + translate("l/min")}
//         value={shortTerm}
//       ></PatientDetailText>

//       <Box sx={{mt:-0.75}}>
//         <Typography variant="h7">
//           <Translate value="withPulseOximeterOn" />
//         </Typography>
//       </Box>

//     </Box>
//   );
// };


export default PatientTreatmentInfo;
