import { Dialog, Typography, Box, Button, CircularProgress } from "@mui/material";
import AlertBox from "../../AlertBox";
import { useEffect, useState } from "react";


export const ConfirmDialog = (props) => {
    const confirmStatus = props.confirmStatus;
    const [loading, setLoading] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [confirmAlertBox, setConfirmAlertBox] = useState({
        "visiblity": false,
        "statusCode": null,
        "text": null,
        "error": null
    });

    const dismissAlertBox = () => {
        setConfirmAlertBox({
            "visiblity": false,
            "statusCode": null,
            "text": null,
            "error": null
        })
    } 

    useEffect(() => {
        if(confirmStatus?.status){
            //Confirm function started
            if(confirmStatus?.status === 1){
                setLoading(true)
                setDisabled(true)
            }
            //Confirm function finished with either success or error
            if(confirmStatus?.status === 2 || confirmStatus?.status === 3){
                setLoading(false)
                //Success
                if(confirmStatus?.status === 2){
                    setConfirmAlertBox({
                        "visiblity": true,
                        "statusCode": confirmStatus?.body?.status, //http status code
                        "text": "Success",
                        "error": false
                    })
                }
                //Error
                else if(confirmStatus?.status === 3){
                    setDisabled(false)
                    setConfirmAlertBox({
                        "visiblity": true,
                        "statusCode": confirmStatus.body?.status, //http status code
                        "text": null,
                        "error": confirmStatus.body?.data[0]
                    })
                }
            }
        }
    }, [confirmStatus])



    return (
        <Dialog
            fullWidth
            maxWidth="xs"
            sx={{ width: "100%" }}
            open={props.open}
            onClose={props.onClose}
        >
            <Box sx={{p:5}}>
                <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                    {props.dialogIcon}

                    <Typography sx={{ width: "100%", textAlign: "center" }} component="h2" variant="h5">
                        {props.title}
                    </Typography>
                </Box>
                <Typography sx={{ width: "100%", textAlign: "center" }} component="div" variant="body1">
                    {props.message}
                </Typography>
                <Box sx={{ display: "flex", justifyContent: "space-around", alignItems: "center" }}>
                    {props.onClose?<Button onClick={props.onClose} sx={{ ml: 2, mt: 2 , minWidth:"100px"}} variant="contained"> {props.declineText} </Button>:null}
                    {props.onConfirm?
                        <Button onClick={props.onConfirm} sx={{ mt: 2 ,minWidth:"110px"}} disabled={disabled} color={props.confirmButtonVariant?props.confirmButtonVariant:"error"} variant="contained" startIcon={loading?null:props.confirmButtonIcon}>
                        {loading ?
                            <CircularProgress size={24} sx={{color: "blue"}}/>
                        :
                            props.confirmText
                        }
                        </Button>
                    :
                        null}
                </Box>
                <AlertBox visible={confirmAlertBox.visiblity} text={confirmAlertBox.text} error={confirmAlertBox.error} statusCode={confirmAlertBox.statusCode} onHide={dismissAlertBox}/> 
            </Box>
        </Dialog>
    );
}
