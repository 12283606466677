import { Translate } from "react-i18nify"
import { PageHeader } from "../../../../components/Layout/PageHeader"
import PageContainer from "../../../../components/PageContainer"
import { Box, Typography, Stack } from "@mui/material"
import { useEffect, useState } from "react"
import { DataGrid } from "@mui/x-data-grid"
import { DeviceService, TemplateService } from "../../../../services/DataService"
import { useNavigate, useParams } from "react-router-dom"

export const TestOverview = (props)=>{

    const [devices, setDevices] = useState([]);
    const { type } = useParams();
    const navigate = useNavigate();

    const [loading, setLoading] = useState(true);

    const columns = [

        {
            field: 'udsn', headerName: 'UDSN', flex: 1, 
            valueGetter: (udsn) => {
                return udsn.row.metaData.find(x => x.metadataId == 1)?.value;
            }
        },
        {
            field: 'status', headerName: 'Status', flex: 1, 
            valueGetter: (status) => {
                return status.row.metaData.find(x => x.metadataId == 2)?.value;
            }
        },
    ];

    const goToDeviceDetails = (params, event) => {
        let row = params.row;
        var udsn = row.metaData.find(x => x.metadataId == 1).value; 
      
        let path = udsn;
        navigate(path);
    };

    useEffect(() => {
        (async () => {
            let getTemplatesResult = await TemplateService.getAll();
            let template=getTemplatesResult.data.find(i=>i.code==type);
            let apiResult = await DeviceService.getByTemplate(template.id, 0, 500,"Assembled",["Assembler"]);
            let data=apiResult.data;
            setDevices(data);
            setLoading(false)
        })();
    }, [])


    return(
        <PageContainer>
        <PageHeader leftAlign>
          <Translate value="Test" />
        </PageHeader>
        <Typography sx={{ mt: 1, mb: 1 }} variant="body">
          <Translate value="SelectDeviceForTestText" />
        </Typography>

        <Box sx={{ width: "100%" }}>
                <DataGrid
                    ColumnWidth="*"
                    sx={{ height: 480, mt: 2 }}
                    columns={columns}
                    rows={devices}
                    pageSize={50}
                    rowsPerPageOptions={[50]}
                    onRowClick={goToDeviceDetails}
                    loading={loading}
                    components={{
                        NoRowsOverlay: () => (
                          <Stack height="100%" alignItems="center" justifyContent="center">
                            <Translate value="NoRowsDevices" />
                          </Stack>
                        ),
                        NoResultsOverlay: () => (
                            <Stack height="100%" alignItems="center" justifyContent="center">
                              <Translate value="NoResults" />
                            </Stack>
                        )
                    }}
                />
            </Box>
        </PageContainer>
    )
}