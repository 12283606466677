import { Box } from '@mui/system'
import { Typography, Button,  IconButton, FormControl, TextField, InputLabel, Select, MenuItem, FormGroup, FormControlLabel, Switch } from '@mui/material'
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import FromToPicker from '../FromToPicker';
import { Translate, translate, Localize } from 'react-i18nify';
import React, { useState, useEffect } from 'react'
import { LocationService , RoleService } from '../../services/DataService';
import Checkbox from '@mui/material/Checkbox';
import ListItemText from '@mui/material/ListItemText';
import { DatePicker } from '@mui/lab';
import SearchIcon from '@mui/icons-material/Search';


export default function UserLookup(props) {

    const [loadingLocation, setloadingLocation] = useState(false);
    const [locations, setLocations] = useState([]);
    const [userLocationId, setUserLocationId] = useState();

    const [roles, setRoles] = useState([]);
    const [selectedRoles, setSelectedRoles] = useState([]);

    const [active, setActive] = useState(true);

    function convertUTCDateToLocalDate(date) {
        if (date == null || date == undefined)
            return "";

        var newDate = new Date(date.getTime() - date.getTimezoneOffset() * 60 * 1000);
        return newDate;
    }

    useEffect(() => {
        (async () => {
            let apiResult = await LocationService.getAll();
            setLocations(apiResult.data.sort((a, b) => a.name > b.name ? 1 : -1));
            let apiResultRoles = await RoleService.getRolesBasedOnUserLocation();
            setRoles(apiResultRoles.data); // get all roles
        })();
    }, []);

    const handleLocationChange = (event) => {
        setUserLocationId(event.target.value);
    }

    const handleRoleChange = (event) => {
        // add role to list of roles
        let selectedRole = event.target.value
        if (selectedRoles.indexOf(selectedRole) > -1) { // if role is already in list, remove it
            setSelectedRoles(selectedRoles.filter(role => role !== selectedRole))
        } else { // if role is not in list, add it
            setSelectedRoles(selectedRole)
        }
    }

    const activeHandler = (event) => {
        setActive(event.target.checked)
    }

    const handleSubmit = async (event) => {
        var data = event.target;
        event.preventDefault();
        var query = {}

        if (data?.o2maticId?.value) {
            query.userId = data.o2maticId.value;
        }
        if (data?.firstName?.value) {
            query.firstName = data?.firstName?.value;
        }
        if (data?.lastName?.value) {
            query.lastName = data?.lastName?.value;
        }
        if (data?.email?.value) {
            query.email = data?.email?.value;
        }
        if (userLocationId) {
            query.locationId = userLocationId;
        }
        if(selectedRoles.length > 0) {
            let rolesString = "";
            selectedRoles.forEach(role => {
                rolesString += role.id + ",";
            });
            query.roleIds = rolesString.slice(0, -1);
        }
        if (data?.identifierValue?.value) {
            query.identifier = data?.identifierValue?.value;
        }
        props.handleSearchQuery(query);
    }
    //justifyContent: 'space-between'

    return (
        <>
        <Box sx={{ display: "flex", flexDirection: "column", height: "70px" }}
            component="form" onSubmit={handleSubmit}
        >
            <Box variant="form" sx={{ mt: 2, display: "flex", flexDirection: "row", justifyContent:'space-between'}}>
                <Box sx={{ display: "flex", flexDirection: "column", color: "#9F9F9F", width:"5%", justifyContent: 'space-around', paddingTop:""}}>
                    <Translate value="Id" />
                    <TextField
                        id="o2maticId"
                        defaultValue=""
                        variant="standard"/>
                </Box>
                <Box sx={{ display: "flex", flexDirection: "column", color: "#9F9F9F", width:"15%", justifyContent: 'space-around'}}> 
                    <Translate value="FirstName" />
                    <TextField
                        id="firstName"
                        defaultValue=""
                        variant="standard" />
                </Box>
                <Box sx={{ display: "flex", flexDirection: "column", color: "#9F9F9F", width:"15%", justifyContent: 'space-around'}}>
                    <Translate value="LastName" />                    
                    <TextField
                        id="lastName"
                        defaultValue=""
                        variant="standard" />
                </Box>
                <Box sx={{ display: "flex", flexDirection: "column", color: "#9F9F9F", width:"15%", justifyContent: 'space-around'}}>
                    <Translate value="Email" />                
                    <TextField
                        id="email"
                        defaultValue=""
                        variant="standard"/>
                </Box>
                <Box sx={{ display: "flex", flexDirection: "column", color: "#9F9F9F", width:"20%"}}>
                <FormControl variant="standard">
                    <Translate value="UserLocation" />
                    <Select
                        labelId="userLocation"
                        id="userLocation"
                        value={userLocationId || ""}
                        onChange={handleLocationChange}
                        className='.MuiSelect-select'      
                       >
                        <MenuItem value={""}>None</MenuItem>
                        {locations.map(location => {
                            return <MenuItem key={location.id} value={location.id}>{location.name}</MenuItem>
                        })}
                    </Select>
                </FormControl> 
                </Box>
                <Box sx={{ display: "flex", flexDirection: "column", color: "#9F9F9F", width:"20%", justifyContent:"space-around"}}>
                <FormControl variant="standard">
                    <Translate value="Roles" />
                    <Select
                        labelId="roles"
                        multiple
                        id="roles"
                        value={selectedRoles}
                        onChange={handleRoleChange}
                        renderValue={(selected) =>  {
                            let rolesString = "";
                            selected.forEach(role => {
                                rolesString += translate(role.name) + ", ";
                            });
                            return rolesString
                        }}
                        >
                        {roles.map(role => {
                            return <MenuItem key={role.id} value={role}>
                                <Checkbox checked={selectedRoles.includes(role)} />
                                <ListItemText primary={translate(role.name)} />
                                </MenuItem>
                        })}
                    </Select>
                    </FormControl> 
                </Box>
                <Box sx={{backgroundColor:"primary.main",  borderRadius:"50%", justifyContent:"space-around", padding:"7px"}}>
                    <IconButton
                    type="submit"
                    variant="contained">
                    <SearchIcon sx={{color:"#FFFFFF"}}/>
                    </IconButton>
                    </Box> 
            </Box>
        </Box>
        <br></br>

        </>
    )
}
