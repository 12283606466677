import React from "react";
import { Box } from "@mui/system";
/* import { Link } from "react-router-dom"; */
import Link from '@mui/material/Link';
import { useMatches } from "react-router-dom";
import { Typography } from "@mui/material";
import { translate } from 'react-i18nify';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
function Breadcrumbs() {
  let matches = useMatches();
  let crumbs = matches.filter((match) => Boolean(match.handle?.crumb))
  let path=crumbs[0];
  let pathParts=path?.pathname.split("/");
  pathParts?.shift()

  String.prototype.Capitalize = function() {
    return this.charAt(0).toUpperCase() + this.slice(1);
}

  return (
      <Box sx={{my:2, display:"flex", flexDirection:"row", alignSelf: "start", bgcolor:'#E0EDFC', borderRadius:"10px", color:"#1672EC", p:1, justifyContent: 'flex-end'}}>
        {pathParts?.map((crumb, index) => (
          <Box key={index} sx={{my:0,mx:1, display:"flex"}}>
          <Link underline="hover" variant='inherit' href={"/"+pathParts.slice(0, index+1).join("/")}>{translate(crumb.Capitalize())}</Link>
          <Typography sx={{ml:2,mt:0.15}}> <ArrowForwardIosIcon sx={{height:"15px"}}/> </Typography>
          </Box>
        ))}
      </Box>
  );
}
export default Breadcrumbs;