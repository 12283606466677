import html2pdf from "html2pdf.js/dist/html2pdf.min";
import TimeUtils from '../../utils/TimeUtils';
import { LogService } from "../../services/DataService";
import { translate} from "react-i18nify";

export const GenerateReport = (element, patientId, date, setLoading) => {
    var opt = {
        margin: [0, 0, 0, 0],
        filename: "O2matic - " + translate("Patient") + " " + patientId + " - " + translate("DailyReport") + " " + date + ".pdf",
        //filename: `O2matic report-${date} Patient ID - ${patientId}.pdf`,
        image: { type: "jpeg", quality: 1 },
        pagebreak: { mode: "css" },
        html2canvas: {
            scale: 2,
            letterRendering: true,
            useCORS: true,
        },
        jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
        noPdfOpenParams: true,
    };
    html2pdf().set(opt).from(element).save().then(() => {
        setLoading(false);
    });      
    //Logs the download
    (async () => {
        await LogService.saveLog([date], "Daily report", "pdf", patientId).catch((error) => {
            console.error("Error saving log: ", error);
        })
    })();

}

export const generateChartData = (data) => {
    try {
        const chartLength = data.reduce((total, batch) => total + batch.values.length, 0);
        const timestamp = Array.from({ length: chartLength }, () => null);
        const spo2Data = Array.from({ length: chartLength }, () => null);
        const pulseData = Array.from({ length: chartLength }, () => null);
        const o2Data = Array.from({ length: chartLength }, () => null);

        let currentIndex = 0;

        for (const batch of data) {
            const values = batch.values;
            const batchStartTime = new Date(TimeUtils.utcToLocal(batch.start, true)).getTime() / 1000;

            timestamp[currentIndex] = batchStartTime;
            spo2Data[currentIndex] = null;
            pulseData[currentIndex] = null;
            o2Data[currentIndex] = null;

            for (const value of values) {
                const timestampDataPoint = new Date(TimeUtils.utcToLocal(value.timestamp, true)).getTime() / 1000;

                timestamp[currentIndex] = timestampDataPoint;
                spo2Data[currentIndex] = value.spO2;
                pulseData[currentIndex] = value.pulse;
                o2Data[currentIndex] = value.o2;

                currentIndex++;
            }

            const batchEndTime = new Date(TimeUtils.utcToLocal(batch.end, true)).getTime() / 1000;

            timestamp[currentIndex] = batchEndTime;
            spo2Data[currentIndex] = null;
            pulseData[currentIndex] = null;
            o2Data[currentIndex] = null;

            currentIndex++;
        }

        const result = [timestamp, spo2Data, pulseData, o2Data];

        return result;
    } catch (error) {
        throw new Error('Error generating chart data: ' + error);
    }
};