import React from 'react'
import { Typography } from '@mui/material'
import { Box } from '@mui/material'

export default function PatientDashboardCategoryWarning(props) {
    return (
        <>
            <Typography sx={{ mt: 1, color: '#FFB020' }} variant="h5">
                {props.title}
            </Typography>
            <Box>
                {props.children}
            </Box>
        </>
    )
}
