import React, { useState, useEffect } from 'react'
import PageContainer from '../../components/PageContainer'
import { Link } from 'react-router-dom'
import { PageHeader } from '../../components/Layout/PageHeader'
import { Box } from '@mui/system'
import { Typography, Stack, Button, FormControl, TextField, InputLabel, Select, MenuItem, FormGroup, FormControlLabel, Switch } from '@mui/material'
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { DataGrid } from '@mui/x-data-grid';
import { useNavigate } from 'react-router-dom'
import { LocationService, TreatmentService } from '../../services/DataService'
import { Translate, Localize,translate } from 'react-i18nify';
import AddIcon from '@mui/icons-material/Add';

export const Locations = () => {

    const [locations, setLocations] = useState([]);
    const [loading, setLoading] = useState(true);

    const navigate = useNavigate();
    useEffect(() => {
        (async () => {
            let apiResult = await LocationService.getAll();
            setLocations(apiResult.data);
            setLoading(false)
        })();
    }, [])
    const goToLocationDetails = (params, event) => {
        let row = params.row;
        let id = row.id;
        let path = "/location/" + id;
        navigate(path);
    };
    const navigateCreate = () => {
        // 👇️ navigate to /
        navigate('/location/create');
    };

    const columns = [

        {
            field: 'id', headerName: translate("Id"), type: "number", width:50
        },
        {
            field: 'name', headerName: translate("Name"), flex: 2
        },
        {
            field: 'address', headerName: translate("Address"), flex: 2
        },
        {
            field: 'countryCode', headerName: translate("Country"), width:150, flex:1,
            valueGetter: (sw) => {
                return translate("Country-"+sw.row?.countryCode);
            }
        },
        {
            field: 'ParentLocation', headerName: translate("ParentLocation"), flex: 1,
            valueGetter: (sw) => {
                return sw.row.parentLocation?.name;
            }
        },


        
    ];

    return (
        <PageContainer>
            <PageHeader leftAlign><Translate value="Locations" /></PageHeader>
            <Button onClick={navigateCreate} sx={{ mt: 2, minWidth:"200px", alignSelf: "start" }} variant="contained" startIcon={<AddIcon />}><Translate value="AddLocation" /></Button>
            <Box sx={{ width: { xs: "100%", sm: "100%", md: "100%", lg: "1000px" }, maxWidth: {md: "1000px"} }}>
                <DataGrid
                    ColumnWidth="*"
                    sx={{ minHeight: "600px", mt: 2 }}
                    columns={columns}
                    rows={locations}
                    pageSize={50}
                    rowsPerPageOptions={[50]}
                    onRowClick={goToLocationDetails}
                    loading={loading}
                    components={{
                        NoRowsOverlay: () => (
                          <Stack height="100%" alignItems="center" justifyContent="center">
                            <Translate value="NoRowsLocations" />
                          </Stack>
                        ),
                        NoResultsOverlay: () => (
                            <Stack height="100%" alignItems="center" justifyContent="center">
                              <Translate value="NoResults" />
                            </Stack>
                        )
                    }}
                />
            </Box>
        </PageContainer>
    )
}
