import React from 'react'
import PropTypes from 'prop-types'
import { Typography, Button, FormControl, TextField, InputLabel, Select, MenuItem, FormGroup, FormControlLabel, Switch, Slider } from '@mui/material'
import { Box } from '@mui/system'
import { Translate, Localize } from 'react-i18nify';

const PatientName = props => {
  return (
    <>
      <InputLabel id="nameLabel"><Translate value="Name" /></InputLabel>

      <TextField
        sx={{ mt:1, width: "100%" }}
        id="Name"
        onChange={props.handleNameChange}
        value={props.name.value}
        required
        inputProps={{
          pattern:
            "^.{1,30}$",
        }}
        label={<Translate value="FirstName" />}
      />
      {props.name.error ? (
        <Typography variant="body" sx={{color:"red"}}>
          {props.name.errorText}
        </Typography>
      ) : null}
      <TextField
        sx={{mt:1, width: "100%" }}
        id="LastName"
        onChange={props.handleLastnameChange}
        value={props.lastName}
        required
        label={<Translate value="LastName" />}
      />
    </>
  )
}


export default PatientName