import LogoutIcon from '@mui/icons-material/Logout';
import InfoIcon from '@mui/icons-material/InfoRounded'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { Roles } from '../redux/actions/auth';
import MedicalInformationIcon from '@mui/icons-material/MedicalInformation';
import PersonIcon from '@mui/icons-material/Person';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import AppSettingsAltIcon from '@mui/icons-material/AppSettingsAlt';
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';
import DomainAddIcon from '@mui/icons-material/DomainAdd';
import MonitorHeartIcon from '@mui/icons-material/MonitorHeart';
import { Translate, Localize } from 'react-i18nify';
import SmartScreenIcon from '@mui/icons-material/SmartScreen';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import BookIcon from '@mui/icons-material/Book';
import HandymanIcon from '@mui/icons-material/Handyman';


export const items = [

    {
        href: '/O2maticAdministrator',
        icon: (<AdminPanelSettingsIcon fontSize="small" />),
        title: <Translate value="O2maticAdministrator" />,
        roles: [Roles.O2maticAdministrator]
    },
    {
        href: '/patient',
        icon: (<PersonIcon fontSize="small" />),
        title: <Translate value="Patients" />,
        roles: [Roles.PatientManager, Roles.PatientMonitor]
    },
    {
        href: '/treatment',
        icon: (<LocalHospitalIcon fontSize="small" />),
        title: <Translate value="TreatmentProfiles" />,
        roles: [Roles.TreatmentTemplateManager]
    },
    {
        href: '/license',
        icon: (<AppSettingsAltIcon fontSize="small" />),
        title: <Translate value="AppLicenses" />,
        roles: [Roles.Administrator, Roles.DeviceManager]
    },
    {
        href: '/location',
        icon: (<DomainAddIcon fontSize="small" />),
        title: <Translate value="Locations" />,
        roles: [Roles.Administrator]
    },
    {
        href: '/user',
        icon: (<SupervisorAccountIcon fontSize="small" />),
        title: <Translate value="Users" />,
        roles: [Roles.Administrator]
    },
    {
        href: '/device',
        icon: (<SmartScreenIcon fontSize="small" />),
        title: <Translate value="Devices" />,
        roles: [Roles.DeviceManager]
    },
    {
        href: '/device/QRscanner',
        icon: (<QrCodeScannerIcon fontSize="small" />),
        title: <Translate value="QRScanner" />,
        roles: [Roles.DeviceManager]
    },
    {
        href: '/device/HOT',
        icon: (<SmartScreenIcon fontSize="small" />),
        title: <Translate value="HotDevices" />,
        roles: [Roles.DeviceManager]
    },
    {
        href: '/device/PRO',
        icon: (<SmartScreenIcon fontSize="small" />),
        title: <Translate value="ProDevices" />,
        roles: [Roles.DeviceManager]
    },
    {
        href: '/production',
        icon: (<HandymanIcon fontSize="small" />),
        title: <Translate value="Production" />,
        roles: [
            Roles.Assembler,
             Roles.Tester,
             Roles.Packager,
             Roles.ProductionQa,
             Roles.O2maticQa,
             Roles.Shipper,
             Roles.Repairer,
             Roles.O2maticTester
            ]
    },
    {
        href: '/log',
        icon: (<BookIcon fontSize="small" />),
        title: <Translate value="Logs" />,
        roles: [Roles.Auditor]
    },

    /* {
        href: '/information',
        icon: (<InfoIcon fontSize="small" />),
        title: <Translate value="Information" />,
        roles: []
    },

    {
        href: '/Logout',
        icon: (<LogoutIcon fontSize="small" />),
        title: <Translate value="LogOut" />,
        roles: []
    }, */
];

export const itemsBottom = [

    {
        href: '/Logout',
        icon: (<LogoutIcon fontSize="small" />),
        title: <Translate value="LogOut" />,
        roles: []
    },
];

export const itemsInfo = [

    {
        href: '/information',
        icon: (<InfoIcon fontSize="small" />),
        title: <Translate value="Info" />,
        roles: []
    },
];
//export default items;
