import React, {useState, useEffect} from "react";
import { useNavigate } from "react-router-dom";
import { PageHeader } from "../../components/Layout/PageHeader";
import PageContainer from "../../components/PageContainer";
import { Translate, Localize, translate } from 'react-i18nify';
import { Box, Typography, Alert } from "@mui/material";
import {QrScanner} from '@yudiel/react-qr-scanner';
import {UpdatesBanner} from "../../components/UpdatesBanner";


export const QRscanner = () => {

    const [wrongQRText, setWrongQRText] = useState("");
    const [isWrongQR, setIsWrongQR] = useState(false);
    const [color, setColor] = useState('rgba(255, 0, 0, 0.5)');

    let navigate = useNavigate();

    const handleError = (err) => {
        console.log(err)
    }
    const onNewScanResult = (data) => {
        var deviceNumber = data.substring(19,34)
        // if it is a HOT or PRO device
        if(deviceNumber.substring(0,6) == "020101") {
            let path = "/device/HOT/" + deviceNumber + "/edit";
            deviceNumber = null
            navigate(path);
        } else if(deviceNumber.substring(0,6) == "010101") {
            let path = "/device/PRO/" + deviceNumber + "/edit";
            deviceNumber = null
            navigate(path);
        } else {
            setIsWrongQR(true)
            setWrongQRText(translate("QrCodeError"))
        }
    };

    //Change the viewFinder settings here. 
    const ViewBox = () => {
        return(
            <svg
            viewBox="0 0 100 100"
            style={{
                "top": 0,
                "left": 0,
                "zIndex": 1,
                "boxSizing": 'border-box',
                "border": `25px solid rgba(0, 0, 0, 0.1)`,
                "position": 'absolute',
                "width": "100%",
                "height": "100%"
                
            }}
        >
            <path fill="none" d="M23,0 L0,0 L0,23" stroke={color} strokeWidth="5" />
            <path fill="none" d="M0,77 L0,100 L23,100" stroke={color} strokeWidth="5" />
            <path fill="none" d="M77,100 L100,100 L100,77" stroke={color} strokeWidth="5" />
            <path fill="none" d="M100,23 L100,0 77,0" stroke={color} strokeWidth="5" />
        </svg>
        )
    }

    return(
        <PageContainer>
            <PageHeader leftAlign><Translate value="QRScanner" /></PageHeader>

            <UpdatesBanner update_description="UpdatesDescriptionQRScannerPage"/>

            <Typography sx={{ mt: 1, mb: 1 }} variant="body">
                <Translate value="QRscannerText" />
            </Typography>

            <Box sx={{width:{xs: "100%", sm: "450px"}}}>
                <QrScanner
                    onError={handleError}
                    onDecode={onNewScanResult}
                    viewFinder={ViewBox}
                />
                {isWrongQR ? <Alert severity="error">{wrongQRText}</Alert> :<></> }
            </Box>
        </PageContainer>
    )
}


