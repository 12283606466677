import {
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    LOGOUT,
    SET_MESSAGE,
    LOGIN_EXPIRED,
} from "./types";
import { useSelector } from "react-redux";
import jwt_decode from "jwt-decode";
import axios from "axios";

export const Roles= {

Administrator:"Administrator",
TreatmentTemplateManager:"TreatmentTemplateManager",
PatientManager:"PatientManager",
PatientMonitor:"PatientMonitor",
Auditor:"Auditor",
DeviceManager:"DeviceManager",
MaintenanceApp:"MaintenanceApp",
InstallerTablet:"InstallerTablet",
PatientTablet:"PatientTablet",
DetailedSessionSender:"DetailedSessionSender",
CtManager:"CtManager",
Assembler:"Assembler",
Tester:"Tester",
Packager:"Packager",
ProductionQa:"ProductionQa",
O2maticQa:"O2maticQa",
O2maticTester:"O2maticTester",
Shipper:"Shipper",
Repairer:"Repairer",
O2maticAdministrator: "O2maticAdministrator",
}


export const isExpired = (token) => {
    var result = true;

    var now = Date.now();
    var expired = token.exp * 1000; // adjust for token  milliseconds

    if (now <= expired)
        result = false;

    return result;
}
export const validateRoles = (userRoles, requestRoles) => {
    let isArray = Array.isArray(userRoles)

    if (!isArray) {
        userRoles = [userRoles];
    }
    
    for (var x = 0; x < userRoles.length; x++) 
    {
        for (var i = 0; i < requestRoles.length; i++) 
        {
            
            if (userRoles[x].name === requestRoles[i]) 
            {
                return true;
            }
        }
    }
    return false;
}
export const isAuthenticatedTwo = (requestedRoles,userRoles)=>{

        const roleData =userRoles;
        let result = valdidate(requestedRoles, roleData);
        return(result);
}
export const isAuthenticated = (requestedRoles) => (dispatch) => {
    return new Promise(resolve => {
        //const { user } = useSelector((state) => state.auth);
        const  roles  = useSelector((state) => state?.auth?.roles);
        const roleData =roles?.data;

        let result = valdidate(requestedRoles, roleData);
        resolve(result);
    });
}
export const valdidate = (requestedRoles, userRoles) => {

    if (!Array.isArray(requestedRoles)) {
        return false;
    }
    if (requestedRoles.length === 0) {
        return true
    }
    if (userRoles== null) {
        return false;
    }


    var result = validateRoles(userRoles, requestedRoles)
    return result;

}
const handleLogin = (username, password) => {
    return axios


        .post("Token/AD", null, {
            headers: {
                "Content-type": "application/json",
                "Authorization": "Basic " + Buffer.from(username + ":" + password).toString('base64')
            }
        }
        )
        .then((response) => {
            if (response.data.accessToken) {
                localStorage.setItem("user", JSON.stringify(response.data));
            }
            return response.data;
        });
}

export const login = (username, password) => (dispatch) => {
    return handleLogin(username, password).then(
        (data) => {
            dispatch({
                type: LOGIN_SUCCESS,
                payload: { user: data },
            });
            return Promise.resolve();
        },
        (error) => {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            dispatch({
                type: LOGIN_FAIL,
            });
            dispatch({
                type: SET_MESSAGE,
                payload: message,
            });
            return Promise.reject();
        }
    );
};
export const logout = () => (dispatch) => {
    localStorage.removeItem("user");

    dispatch({
        type: LOGOUT,
    });
    return Promise.resolve();
};

export const loginExpired = () => (dispatch) => {
    localStorage.removeItem("user");
    dispatch({
        type: LOGIN_EXPIRED,
    });
    return Promise.resolve();
};