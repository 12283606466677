import React, { useState } from 'react'
import { InputLabel, FormGroup, Slider, Typography, FormControl } from '@mui/material'
import { FormHelperText } from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { Box } from '@mui/system';
import FormHelperDialog from '../Layout/Dialog/FormHelperDialog';

// var green = "#000000";
// var yellow = "#ffff66";
// var red = "#ff0000";

const RangeSliderControler = (props) => {
    return (
        <Box sx={{ width: "100%" }}>
            <Box sx={{ width: "100%" }}>
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                    <InputLabel sx={{ whiteSpace: "normal" }} id="spo2Range">
                        <Typography sx={{ color: "black" }} variant='h7'>{props.title}</Typography>
                    </InputLabel>
                    <Typography sx={{ mr: 2, color: "black", ml: 5, }} variant='h6'>{props.minValue} - {props.maxValue} {props.unit}</Typography>
                </Box>
                <Slider
                    disabled={props.disabled}
                    step={props.step}
                    sx={{ color: props.color + " !important", width: props.sliderSize }}
                    getAriaLabel={() => 'Spo2 range'}
                    value={[props.minValue, props.maxValue]}
                    min={props.min}
                    max={props.max}
                    onChange={props.onChange}
                    valueLabelDisplay="auto"
                />
            </Box>
        </Box>

    )
}

export const RangeSliderControlerNoValue = (props) => {
    return (
        <Box sx={{ width: "100%" }}>
            <Box sx={{ width: "100%" }}>
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                    <InputLabel sx={{ whiteSpace: "normal" }} id="spo2Range">
                        <Typography sx={{ color: "black" }} variant='h6'>{props.title}</Typography>
                    </InputLabel>
                </Box>
                <Slider
                    marks={props.marks}
                    disabled={props.disabled}
                    step={props.step}
                    sx={{ color: props.color + " !important", width: props.sliderSize,  
                    "& .MuiSlider-mark": {
                    backgroundColor: "#bfbfbf",
                    height: "30px",
                    width: "2px"
                    }
                    }}
                    getAriaLabel={() => 'Spo2 range'}
                    value={[props.minValue, props.maxValue]}
                    min={props.min}
                    max={props.max}
                    onChange={props.onChange}
                    valueLabelDisplay="auto"
                />
            </Box>
        </Box>

    )
}

export default RangeSliderControler
