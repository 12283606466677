import { DataGrid } from '@mui/x-data-grid';
import { Box, Stack } from "@mui/material";
import React, {useState, useEffect} from "react";
import { translate, Translate } from 'react-i18nify';
import { MetadataService } from "../../services/DataService";


export const MetadataValuesTable = () => {

    const [addablemetadataValues, setAddablemetadataValues] = useState([]);
    const [loadedMetadataValues, setLoadedMetadataValues] = useState(false); 

    const getAddableMetadataValues = async () => {
        await MetadataService.getAddableMetadataValues().then(result => {
            if(result.data != null){
                setAddablemetadataValues(result.data)
                setLoadedMetadataValues(true)
            }
        }).catch(err => {
            console.log(err)
        })
    }

    const columns = [
        {
            field: 'id', headerName: translate("MetadataValueId"), flex: 1,
            valueGetter: (metadataValue) => {
                return metadataValue?.row?.id;
            }
        },
        {
            field: 'metadataid', headerName: translate("MetadataId"), flex: 1,
            valueGetter: (metadataValue) => {
                return metadataValue?.row?.addableMetadataId;
            }
        },
        {
            field: 'name', headerName: translate("Name"), flex: 1,
            valueGetter: (metadataValue) => {
                return metadataValue?.row?.name;
            }
        },
        {
            field: 'value', headerName: translate("MetadataValue"), flex: 1,
            valueGetter: (metadataValue) => {
                return metadataValue?.row?.value;
            }
        },
    ]    

    useEffect(() => {
        getAddableMetadataValues()
    },[])

    return(
        <Box sx={{ width: { xs: "100%", sm: "100%", md: "100%", lg: "1000px" },
        maxWidth: {md: "1000px"} }}> 
        {loadedMetadataValues ?             
            <DataGrid
                sx={{ height: 470, mt: 2 }}
                columns={columns}
                rows={addablemetadataValues}
                pageSize={50}
                rowsPerPageOptions={[50]}
                getRowId={row => row.id}
                components={{
                    NoRowsOverlay: () => (
                      <Stack height="100%" alignItems="center" justifyContent="center">
                        <Translate value="NoRowsMetadata" />
                      </Stack>
                    ),
                    NoResultsOverlay: () => (
                        <Stack height="100%" alignItems="center" justifyContent="center">
                          <Translate value="NoResults" />
                        </Stack>
                    )
                }}
            />
            : <></>}
        </Box>
    )
}

