const Danish = {
    da: {
        
        // O2MATIC ADMINISTRATOR
        "O2maticAdministrator": "O2matic Administrator",
        "ReturnToO2maticAdministrator": "Tilbage til O2matic administrator",
        "ReturnToAdminpage": "Gå tilbage til administrator side",
                        // oximeter
        "AddOximeter":"Tilføj pulsoximeter",
        "AddOximeterToWhitelist":"Tilføj pulsoximeter til whitelist",
        "AddAnotherOximeter": "Tilføj endnu et pulsoximeter",
                        // metadata
        "Metadata": "Metadata",
        "AddMetadata":"Tilføj metadata",
        "AddMoreMetadata": "Tilføj mere metadata",
        "SelectMetadata": "Vælg metadata",
        "MetadataValue": "Metadata værdi",
        "MetadataValueHasBeenAddded": "Metadata værdi er blevet tilføjet",
        "AddMetadataValue": "Tilføj metadata værdi",
        "CreateMetadataValue": "Tilføj metadata værdi",
        "MetadataId": "Metadata ID",
        "MetadataValueId": "Metadata værdi ID",
                        // notification
        "Notification": "Notifikation",
        "AddNotification":"Tilføj notifikation",
        "AddNotificationText": "Tilføj en notifikation der vil blive vist til alle brugere.",
        "WriteMessageHere": "Skriv besked her",
        "NotificationActive": "Notifikation aktiv",
          
        
        // PATIENT PAGE (not done)
        "Patient": "Patient",
        "Patients": "Patienter",
        "PatientDashboard": "Patient dashboard", //UPDATE
        "PatientDashboardText": "Her kan du få et overblik over målinger og sessioner givet af patientens enhed.",
        "PatientLookup": "Patient opslag",
        "PatientSearch": "Søg efter patienter",
        "PatientLookupExplanation": "Søg efter patienter ved hjælp af et eller flere af søgekriterierne nedenfor.",
        "PatientLookupExplanation2": "Klik på en patient i tabellen for at åbne patientsiden.",
        "AddPatient": "Opret patient",
        "AddPatientText": "Her kan du oprette ny patient. Alle felter markeret med * er obligatoriske.",
        "EditPatient": "Rediger patient",
        "EditPatientText": "Her kan du redigere en patient. Alle felter markeret med * er obligatoriske.",
        "DeletePatientText": "Er du sikker på, at du vil slette denne patient? Alt patientdata går tabt.",
        "PatientData": "Patientdata",
        "PatientId": "Patient id",
        "PatientId": "patient id",
        "PatientO2maticId": "Patient O2matic ID",
        "PatientInformation": "Oplysninger om patienten",
        "PersonalInformation": "Personlig information",
        "Birthdate": "Fødselsdato",
        "DateOfBirth": "Fødselsdato",
        "Address": "Adresse",
        "AddressLine": "Gadenavn, nr",
        "PostalCode": "Postnummer",
        "City": "By",
        "Country": "Land",
        "Language": "Sprog",
        "LastName": "Efternavn",
        "FirstName": "Fornavn",
        "Gender": "Køn",
        "Female": "Kvinde",
        "female": "Kvinde",
        "Male": "Mand",
        "male": "Mand",
        "Contacts": "Kontakter",
        "PhoneNumber": "Telefonnummer",
        "HealthcareProvider": "Sundhedsudbyder ", //UPDATE
        "HealthcareProviderName": "Sundhedsudbyder navn",
        "ServiceProvider": "Service udbyder",
        "ServiceProviderName": "Service udbyder navn",
        "Installer": "Installatør",
        "CustomIdentifier": "Tilpasset identifikator",
        "Identifier": "Identifikator",
        "IdentifierType": "Identifikator type",
        "IdentifierValue": "Identifikator værdi",
        "locationsDoNotMatch": "lokationer stemmer ikke overens",
        "Prescribed": "Ordineret",
        "Installed": "Installeret",
        "DeviceAssignedToPatient": "Enhed tildelt patient",
        "LatestReceivedDeviceLog": "Seneste modtagede enheds log",
        "LastSession": "Seneste session",
        "LastSessionTime": "Sidste sessions tid",
        "NextSessionTime": "Næste sessions tid",
        "Pending": "Afventer",
        "PendingInstallation": "Afventer installation",
        "PreviousTreatmentInformation": "Oplysninger om forrige behandling",
        "CurrentTreatmentOnDevice": "Nuværende behandling på enheden:",
        "CurrentTreatmentOnDeviceDescription": "Den for nyligt ordinerede behandling er endnu ikke blevet installeret på patientens enhed. Den nuværende behandlingsprofil på patientens enhed er vist nedenunder. For at den nye behandling installeres på patientens enhed, kontakt da venligst service udbyder og informer dem om at patientens behandling skal opdateres i forbindelse med næste besøg hos patienten.",
        "InstallationOfTreatmentPending": "Installation af behandling afventer",
        "Compliance": "Overholdelse",
        "PatientLog": "Patient log",
        "O2maticId": "O2matic ID",
        "SelectLanguage": "Vælg sprog",
        "SelectCountry": "Vælg land",
        "PatientNotAssignedToDevice": "Patienten er i øjeblikket ikke tilføjet til nogen enhed",


        "Session": "Session",
        "SessionId": "Session ID",
        "LengthOfSession": "Længde af session",
        "InitialValues": "Oprindelige værdier",
        "Start": "Start",
        "StartOfSession": "Start af session",
        "End": "Slut",
        "EndOfSession": "Slutning af session",
        "Zone": "Zone",
        

        "SelectLocation": "Vælg lokation",
        

                        // data and charts
        "ChartHelp": "Diagram hjælp",
        "ChartPreview": "Diagram forhåndsvisning",

                        // reports and sessions
        "NextIntervention": "Næste intervention",
        "DetailedSessionData": "Detaljeret sessions data",
        
        
                        // SpO2, pulse and O2 flow
        "SpO2": "SpO2",
        "SpO2Range": "SpO2 interval",
        "SpO2Min": "SpO2 minimum",
        "SpO2Max": "SpO2 maksimum",
        "SpO2TargetRange": "SpO2 target interval",
        "SpO2RangeMax": "Øvre SpO2 target interval",
        "SpO2RangeMin": "Nedre SpO2 target interval",
        "SpO2Average": "SpO2 gennemsnit",
        "SpO2Std": "SpO2 std",
        "SpO2StandardDeviation": "SpO2 standardafvigelse",
        "TargetSpO2RangeExplanation": "Det ønskede SpO2 interval for patienten.",
        "SpO2Initial":"SpO2 indledende",
        "InitialSpO2": "Indledende SpO2",
        "LastMeasuredSpO2": "Sidst målte SpO2",
        
        "Pulse": "Puls",
        "PulseBpm": "Puls (bpm)",
        "PulseRange": "Pulsinterval",
        "PulseMin": "Puls minimum",
        "PulseMax": "Puls maksimum",
        "PulseAverage": "Puls gennemsnit",
        "PulseStd": "Puls std",
        "PulseStandardDeviation": "Puls standardafvigelse",
        "PulseInitial":"Puls indledende",
        "PulseHigh": "Puls High",
        "InitialPulse": "Indledende puls",
        "LastMeasuredPulse": "Sidst målte puls",

        "O2": "O2",
        "O2Flow": "O2 flow",
        "O2FlowMin": "O2 flow minimum",
        "O2FlowMax": "O2 flow maximum",
        "O2RangeMaxWith": "Øvre O2 target interval med sensor",
        "O2RangeMinWith": "Nedre O2 target interval med sensor",
        "O2RangeMaxWithout": "Øvre O2 target interval uden sensor",
        "O2RangeMinWithout": "Nedre O2 target interval uden sensor",
        "O2FlowAverage": "O2 flow gennemsnit",
        "O2FlowStd": "O2 flow std",
        "O2FlowStandardDeviation": "O2 flow standardafvigelse",
        "O2FlowRange": "O2 flow interval",
        "O2FlowInitial":"O2 flow indledende",
        "InitialO2Flow": "Indledende O2 flow",
        "LastMeasuredO2Flow": "Sidst målte O2 flow",

        "NextSession": "Næste session",
        "NoTreatments": "Ingen behandlinger",
        "SpO2InAdjustingPhase": "Gennemsnitligt SpO2 i justeringsfasen",
        "TargetO2FlowAtEndOfSession": "Target O2 flow i slutning af session",
        "SelectSessionExplanation": "Klik på en bestemt session i tabellen for at se detaljerede sessionsdata.",
        "SelectTreatment": "Vælg behandlings profil",
        "DetailedData": "Detaljeret data",
        "ShortTermLength": "Andel af tid med pulsoximeter",
        "ShortTermExplanation": "Hvor mange minutter i timen det kortsigtede O2 flow får lov til at være aktivt.", //UPDATE
        "UntilNextSession": "Indtil næste session",
        "ReportGenerated": "Rapport genereret",
        "TreatmentChanged": "Behandling ændret",
        "SessionsOverview": "Sessions oversigt",
        "Last7days": "Sidste 7 dage",
        "Last14days": "Sidst 14 dage",
        "ofComplianceGoal": "af overholdelses mål",
        "ComplianceGoalLegendPercent": "Procent af dagligt ilt overholdenses mål nået:",
        "ComplianceGoalLegendHours": "Antal timer per dag med aktiv brug af ilt:",
        "HoursOfUseUnverified": "Timer brugt er ikke verificeret",
        "2HoursOfUnverifiedUse": "2 timers ikke-verificeret brug",
        "2HoursOfVerifiedUse": "2 timers verificeret brug",
        "DailyReport": "Daglig rapport",
        "NoDetailedSessionData": "Ikke i stand til at hente data for angivet dato",
        "DataArchive": "Dataarkiv",
        "DataTableDescription": "Klik på en række i tabellen for at åbne filen eller brug afkrydsningsfelter til at vælge filer til download.",
        "FilesToBeDownloaded": "Filer der skal downloades",
        "ConfirmDownload": "Bekræft download",
        "ViewLiveData": "Se live data",
        "ShowDataFor:": "Vis data for:",
        "DownloadAllSessionsAsCsv": "Download alle sessioner som csv",
        "Session": "Session",
        "NewPreviewChartDescription1": "Grafen viser et overblik over de 50 sessioner fra hver side af tabellen ovenover. For at ændre hvilke 50 session der vises, brug pilene nederst i tabellen for at skifte side.",
        "NewPreviewChartDescription2": "De røde linjer indikerer SpO2 target intervallet af behandlings profilen, mens en orange prik indikerer en ændring af behandlings profilen.",
        "DetailedDataDescription": "Hjælp: Klik og træk musen for at zoome ind. Dobbelt-klik for at returnere til fuld visning.",
        "SessionInformation": "Sessions information",        
        "DisclaimerDataVal": "Disclaimer: Data er ikke valideret og bør ikke benyttes til at vurdere patientens tilstand.",
        "InvalidValue": "Invalid værdi",
        "NoSessionInSelectedInterval": "Ingen sessioner i valgt interval",
        "InvalidDate": "Invalid dato",
        "Create": "Opret",
        "SelectLocationFirst": "Vælg først en lokation",
        "AnalysingPhaseExplanation": "Indstil til ja, hvis du ikke ønsker, at de første 5 minutter af en session automatisk skal justere strømmen", //UPDATE
        "CreateReport": "Opret rapport",
        "ReportCreated": "Rapport oprettet",
        "Sessions": "Sessioner",
        "LogEntry": "LogPunkt", //UPDATE
        "EnableLiveData": "Aktivér live data",
        "PauseLiveData":"Pause live data",
        "DisableLiveData": "Deaktiver live data",
        "StartTime": "Start tid",
        "EndTime": "Sluttid",
        "NextSession": "Næste session",
        "TimeSpent": "Tid brugt",
        "TimeSpent(%)": "Tid brugt (%)",
        "LengthOfRecordedData": "Længde af optaget data",
        "GenerateReport": "Generér rapport",
        "Adjusting": "Justerer",
        "DownloadReport": "Hent rapport",
        "DaySummary": "O2matic dagssammenfatning",
        "DaySummaryDisclaimer": "Bemærk: Beregningerne, der bruges til at generere denne rapport, er ikke validerede og bør ikke bruges til at evaluere patientens tilstand",
        "DetailedDataDisclaimerChart": "Detaljeret data i denne graf er ikke validerede og ikke beregnet til klinisk beslutningstagning. Venligst konsulter sessionsdata, før du træffer kliniske beslutninger.",
        "DetailedDataDisclaimerTable": "Detaljeret data i denne tabel er ikke validerede og ikke beregnet til klinisk beslutningstagning. Venligst konsulter sessionsdata, før du træffer kliniske beslutninger.",
        "DetailedDataDisclaimerTableCsv": "Detaljeret data statistik er ikke valideret og ikke beregnet til klinisk beslutningstagning. Venligst konsulter sessionsdata, før du træffer kliniske beslutninger.",
        "ImportantNote": "Vigtig Note",
        "DetailedDataStatistics":"Detaljeret data statistik",
        "DetailedDataStatisticsByTreatmentProfile":"Detaljeret data statistik statistics efter behandlingsprofil",
        "SummaryDate": "Opsummerings dato",
        "Generated": "Genereret",
        "DataByTreatmentProfile": "Data efter behandlingsprofil",
        "Name": "Navn",


        // TREATMENT PAGE (note done)
        "Treatment": "Behandling",
        "Treatments": "Behandlinger",
        "TreatmentProfile": "Behandlings profil",
        "TreatmentProfiles": "Behandlings profiler",
        "TreatmentProfileDetails": "Behandlings profil detaljer",
        "TreatmentId": "Behandlings-id",
        "TreatmentInformation": "Oplysninger om behandling",
        "TreatmentName": "Navn på behandling",
        "ProfileName": "Profil navn",
        "ProfileSettings": "Profil indstillinger",
        "AddTreatmentProfile": "Opret behandlings profil",
        "AddTreatmentProfileText": "Her kan du oprette en ny behandlings profil. Alle felter markeret med * er obligatoriske.",
        "DisableTreatmentProfileText": "Vil du virkelig deaktivere denne behandlings profil?",
        "EnableTreatmentProfileText": "Vil du virkelig aktivere denne behandlings profil?",
        "ConsiderTheFolowing": "Overvej følgende",
        "QuestionType2RespiratoryFailure": "Er patienten i risiko for type 2 respirationssvigt?",
        "QuestionLevelOfActivity": "Hvad er patientens aktivitetsniveau?",
        "QuestionOxygenSource": "Hvor meget ilt er iltkilden i stand til at levere?",
        "TemplateId": "Template ID",
        "Customize": "Tilpas",
        "Customizing": "Tilpasser",
                        // analysing phase
        "AnalysingPhase": "Analysefase",
        "AnalysingPhaseExplanation": "Sær til Ja hvis det ønskes at de første 5 minutter af en session har fixed O2 flow.",
        "AnalysingPhaseDescription": "Første 5 minutter af session med fixed O2 flow",
        "DefineFixedFlow": "Første fem minutter af session med fixed O2 flow",
        "AnalysingPhaseDuration(s)": "Analysing phase varighed (s)",
        "DefineFixedFlowMessage": "De første minutter af hver ssession begynder med fem minutters fixed O2 flow. Derefter begynder enheden at justere O2 flowet",
        "AnalysingPhaseExplanationPatientPage": "Hvis Analysing Phase er slået til, vil patienten fortsætte med at modtage konstant O2 flow de første 5 minutter efter at have taget pulsoximeter på.",
        "AnalysingPhaseExplanationTreatmentPage": "Set til ja hvis patienten skal modtage konstant O2 flow de første 5 minutter efter at have taget pulsoximeter på.",
                        // adjusting phase
        "AdjustingPhase": "Adjusting phase",//"Justeringsfase",
        "AllowedLtRange": "Tilladt O2 flow UDEN pulsoximeter",
        "AllowedStRange": "Tilladt O2 flow MED pulsoximeter",
                        // flow response
        "FlowResponse": "Flow respons",
        "FlowResponseRate": "Flow respons rate",
        "FlowResponseExplanation": "Hvor hurtigt enheden ændrer O2 flowet under justeringsfasen.", //UPDATE
        "FlowResponseExplanation": "Flow responset bestemmer hvor hurtigt O2 flowet justeres op og ned af enheden.",
        "DefineFlowResponse": "Vælg det relevante flow respons",
        "DefineFlowResponseMessage": "Indstillingen for flow respons ændrer hvor hurtigt enheden justerer O2 flow baseret på patientens vitale",
        "Fast": "Hurtig",
        "AlwaysOn": "Altid på",
        "Always": "Altid",
        "AlwaysOff": "Altid af",
                        // allowed time within
        "AllowedTimeWithin": "Tilladt tid inden for",
        "AllowedTimeWithinExplanation": "Antal minutter per time patienten kan modtage det højeste/laveste O2 flow, selvom patienten har pulsoximeter på.",
                        // o2 compliance goal
        "O2ComplianceGoal": "Ilt overholdelses mål",
        "O2ComplianceGoal": "O2 overholdelses mål", // double
        "OxygenComplianceGoalExplanationPatientPage": "Minimum antal timer per dag det forventes at patienten modtager iltbehandling gennem enheden.",
        "OxygenComplianceGoalExplanationTreatmentPage": "Venligst angiv minimum antal timer per dag det forventes at patienten modtager iltbehandling gennem enheden.",
        "OxygenPrescription": "Minimum ilt recept",
        "OxygenPrescriptionExplanation": "Angiv det mindste antal timer om dagen, som patienten skal bruge iltbehandling.",
        "OxygenPrescriptionUnit": "timer/dag", //UPDATE
                        // spo2 range
        "VeryFast": "Meget hurtigt",
        "DefineSpO2": "Definer et acceptabelt SpO2-mætningsområde",
        "DefineSpO2Message": "Definer et acceptabelt SpO2-mætningsområde i henhold til patientens behov",
                        // o2 flow range
        "O2FlowRanges": "O2 flow intervaller",
        "O2FlowTarget": "O2 flow target",
        "LowFlowCompatibilityWarning": "Valg af et minimums O2 flow på mindre end 0,5 l/min understøttes muligvis ikke i patientopsætningen (dvs. koncentrator)", //UPDATE
        "validationError_WithoutPoMinIsSmaller": "Uden PO min er mindre eller lig med med PO min", //UPDATE
        "validationError_WithoutPoMaxIsBigger": "Uden PO max er større eller lig med PO max", //UPDATE
                                        // with pulse oximeter
        "WithoutPO": "Uden pulsoximeter",
        "O2RangeWithPO": "O2 flow interval MED pulsoximeter",
        "O2RangeWithPOExplanation": "O2 flow interval i l/min, når pulsoximeteret er på fingeren", //UPDATE
        "withPulseOximeterOn": "med pulsoximeter på",
        "WithPulseOximeterExplanation": "O2 flow interval i l/min når patienten har pulsoximeter på.",
        "DefineO2FlowWithPO": "Definer O2 flow interval med pulsoximeter tændt",
        "DefineO2FlowWithPOMessage": "Indstil O2 flow interval for, hvornår patienten er tilsluttet pulsoximeteret",
                                        // without pulse oximeter
        "WithPO": "Med pulsoximeter",
        "O2RangeWithoutPOExplanation": "O2 flow interval i l/min, når pulsoximeteret IKKE er på fingeren", //UPDATE
        "O2RangeWithoutPO": "O2 flow interval UDEN pulsoximeter",
        "DefineO2FlowWithOutPO": "Definer O2 flow interval uden pulsoximeter",
        "DefineO2FlowWithOutPOMessage": "O2 flow interval uden pulsoximeter må ikke overstige intervallet for O2 flow med pulsoximeter",
        "WithoutPulseOximeterExplanation": "O2 flow interval i l/min når patienten IKKE har pulsoximeter på.",
        
        
  
        
        
    
        
        

        
        

        // APP LICENSES PAGE
        "License": "Applicens",
        "AppLicenses": "App licenser",
        "AddAppLicense": "Tilføj app licens",
        "AddAppLicenses": "Tilføj app licenser",
        "AppLicensesOverview": "Oversigt over app licenser",
        "AppLicenseDetails": "Oplysninger om app licens",
        "AppLicenseName": "Navn på app licens",
        "ChangeSecret": "SkiftHemmelighed", //UPDATE
        "ClientId": "Klient id",
        "ClientSecret": "Klient hemmelighed", //UPDATE
        "DeleteAppLicenseText": "Vil du virkelig slette denne applicens?",
                    // roles
        "InstallerTablet": "Installatør Tablet",
        "InstallerTabletDescription": "Kan installere en patient og deres behandlingsprofil på en HOT-enhed og sende sessionsdata. Denne licens kan også opdatere og nulstille HOT-enheder til fabriksindstillinger.",
        "PatientTablet": "Patient Tablet",
        "PatientTabletDescription": "Kan sende og se de seneste sessioner samt overvåge aktuelle målinger af patientens SpO2, puls og O2-flow data.",
        "DetailedSessionSender": "Detaljeret Session Sender",
        "DetailedSessionSenderDescription": "En tilføjelseslicens til at tillade tabletten at sende detaljerede data.",


        // LOCATIONS PAGE
        "Location": "Lokation",
        "Locations": "Lokationer",
        "CreateLocation": "Tilføj lokation",
        "AddLocation": "Opret lokation",
        "EditLocation": "Rediger lokation",
        "SelectLocation": "Vælg lokation",
        "LocationDetails": "Oplysninger om lokation",
        "LocationId": "Lokations ID",
        "LocationName": "Lokations navn",
        "ParentLocation": "Overordnet lokation",
        "ClinicalTrial": "Klinisk forsøg",
        "DataRetentionPeriod": "Opbevaringsperiode for data",
        

        // USERS PAGE
        "User": "Bruger",
        "Users": "Brugere",
        "AddUser": "Opret bruger",
        "UserDetails": "Oplysninger om brugeren",
        "UserId": "Bruger ID",
        "UserName": "Bruger navn",
        "UserLocation": "Bruger lokation",
        "UserOverview": "Oversigt over brugere",
        "UserSearch": "Søg efter brugere",
        "UserLookupExplanation": "Søg efter bruger ved at benytte et eller flere af søgekriterierne nedenunder.",
        "UserLookupExplanation1": "Se alle brugere ved at søge uden kriterier.",
        "UserLookupExplanation2": "Klik på en bruger in tabllen for at åbne brugerens side.",
        "DeleteUser": "Slet bruger",
        "DeleteUserText": "Brugeren vil blive slettet permanent.",
                        // roles
        "Role": "Rolle",
        "Roles": "Roller",
        "AddRole": "Tilføj rolle",
        "ModifyRole": "Ændre rolle",
        "RoleLocation": "Rolle lokation",
        "EditRoles": "Rediger roller",
        "SaveRoles": "Gem roller",
        "SelectRoles": "Vælg roller",
        "Administrator": "Administrator",
        "AdministratorDescription": "Kan tilføje, redigere og se 'Lokationer', tilføje, se og slette 'Licenser' samt tilføje, redigere, se og slette 'Brugere'.",
        "Auditor": "Revisor",
        "AuditorDescription": "Kan se 'Log' over handlinger lavet af brugere.",
        "TreatmentTemplateManager": "Behandlingstemplate Manager",
        "TreatmentTemplateManagerDescription": "Kan tilføje og se 'Behandlinger'.",
        "DeviceManager": "Enheds Manager",
        "DeviceManagerDescription": "Kan tilføje, redigere og se 'Enheder' og enheds logs og tilføje, se og slette 'Licenser'.",
        "PatientMonitor": "Patient Overvåger",
        "PatientMonitorDescription": "Kan se 'Patienter'.",
        "PatientManager": "Patient Manager",
        "PatientManagerDescription": "Kan tilføje, redigere og se 'Patienter'.",
        "Assembler": "Samler",
        "AssemblerDescription": "Kan generere nye batches, oprette nye enheder og tilføje komponenter til enheder.",
        "Tester": "Tester",
        "TesterDescription": "Kan se enheder klar til test og godkende test af enheder.",
        "Packager": "Pakker",
        "PackagerDescription": "Kan pakke og godkende at alle elementer er i kassen inden forsendelse.",
        "ProductionQa": "Produktion QA",
        "ProductionQaDescription": "Kan godkende enheder og/eller batches.",
        "Shipper": "Forsender",
        "ShipperDescription": "Kan ændre ejer og lokation til forsendelses lokation.",
        "Repairer": "Reperatør",
        "RepairerDescription": "Kan sætte enhed til under undersøgelse eller til reparation.",
        "CtManager": "CT Manager",
        "CtManagerDescription": "Kan redigere lokations parametre til kliniske forsøg (max O2 flow og opbevaringsperiode for data).",
        "O2maticQa": "O2matic QA",
        "O2maticQaDescription": "Kan godkende batches.",
        "O2maticAdministator": "O2matic Administrator",
        "O2maticAdministatorDescription": "Kan tilføje og redigere notifikationer på siden, enheds metadata muligheder og whiteliste pulsoximetre.",
        "O2maticTester": "O2matic Tester",
        "O2maticTesterDescription": "Kan se enheder klar til test af O2matic og godkende test af enheder.",

        



        // DEVICE PAGE
        "Device": "Enhed",
        "Devices": "Enheder",
        "EditDevice": "Rediger enhed",
        "DeviceInformation": "Enheds information",
        "Dmr": "DMR",
        "Sw": "SW version",
        "Fw": "FW version",
        "DeviceOwner": "Enheds ejer",
        "Owner": "Ejer",
        "DeviceLocation": "Enheds lokation",
        "UDSN": "UDSN",
        "PUID": "PUID",
        "MAC": "MAC",
        "MacAddress": "MAC adresse",
        "DeviceSn": "Enheds SN",
        "DeviceSerialNumber": "Enheds serie nummer",
        "EventLog": "Hændelses log",
        "EventType": "Hændelsestype",
        "SystemLog": "System log",
        "DeviceLog": "Enheds log",
        "NumberOfDevices": "Antal enheder",
        "HOT": "HOT",
        "HotDevice": "HOT-enhed",
        "HotDevices": "HOT-enheder",
        "HotDeviceEdit": "Rediger HOT-enhed",
        "HotDeviceDetails": "Oplysninger om HOT-enhed",
        "PRO": "PRO",
        "ProDevice": "PRO-enhed",
        "ProDevices": "PRO-enheder",
        "ProDeviceEdit": "Rediger PRO-enhed",
        "ProDeviceDetails": "Oplysninger om PRO-enhed",
        "QrCode": "QR kode",
        "QRScanner":"QR scanner",        
        "QRscannerText":"Scan QR koden på bagsiden af PRO eller HOT enheden for at kunne se og ændre enhedens informationer.",
        "QrCodeError": "Den scannede QR kode tilhører ikke en O2matic enhed.",
        

        // PRODUCTION - TRACE
        "Production": "Produktion",
        "DevicePending": "Enheder afventer", //UPDATE
        "Assembled": "Samlet",
        "AtRepairO2matic": "Til reperation O2matic",
        "Packaged": "Pakket",
        "Prod QA Approved": "Produktions QA godkendt",
        "ReadyForInvestigation": "Klar til undersøgelse",
        "ReadyForO2maticApproval": "Klar til O2matic godkendelse",
        "ReadyForRepair": "Klar til repearation",
        "ReadyForTesting": "Klar til test",
        "ReleasedForSale": "Frigivet til salg",
        "SCRAPPED": "SKROTTET",
        "Shipped": "Afsendt",
        "Tested": "Testet",
                // assemble
        "Assemble":"Saml",
        "AssembleNewDevice":"Saml ny enhed",
        "NewBatch": "Ny batch",
                // test
        "Test": "Test",
        "O2maticTest":"O2matic Test",
        "SelectDeviceForTestText": "Vælg en enhed fra tabellen til test.",
        "TestingDeviceWithUDSN": "Test af enhed med UDSN",
        "DeviceTestedSuccessfully":"Enhed testet - succesfuldt",
        "DeviceMovedToRepair":"Enhed flyttes til reperation",
        "DeviceMovedToAtRepairO2matic":"Enhed flyttes til O2matic repearation",
        "DeviceWillBeMovedToRepair": "Ikke alle tests bestås, enheden flyttes til reparation",
        "TestProcedureFollowed":"Testprocedure fulgt",
        "TestProcedureFollowedText":"Bekræft, at testproceduren blev fulgt",
        "TurningOnTheDevice":"Tænd enheden",
        "TurningOnTheDeviceText":"Kontrollér, at enheden tænder",
        "DeviceStatus":"Device status",
        "DeviceStatusText":"Kontrollér device status",
        "BatteryMode":"Batteritilstand",
        "BatteryModeText":"Kontrollér batteritilstand",
        "PulseOximeter":"Pulsoximeter",
        "PulseOximeterText":"Kontrollér pulsoximeter",
        "AlarmSpeaker1":"Alarmhøjttaler 1",
        "AlarmSpeaker1Text":"Kontrollér alarmhøjttaler 1",
        "AlarmSpeaker2":"Alarmhøjttaler 2",
        "AlarmSpeaker2Text":"Kontrollér alarmhøjttaler 2",
        "OxygenFlow":"O2 flow",
        "OxygenFlowText":"Kontrollér O2 flow",
        "OxygenLeakage":"Iltlækage",
        "OxygenLeakageText":"Kontrollér iltlækage",
        "FirmwareVersion":"Firmwareversion",
        "FirmwareVersionText":"Vælg firmwareversion",
        "SoftwareVersion":"Softwareversion",
        "SoftwareVersionText":"Vælg softwareversion",
        "ProcessorSerialNumber":"Processorens serienummer (PUID)",
        "ProcessorSerialNumberText":"Indtast processorens serienummer (PUID)",
        "ZeroFlow":"Zero flow",
        "ZeroFlowText":"Kontrollér enhed ved 0 l/min",
        "VisualInspection":"Visuel inspektion",
        "VisualInspectionText":"Kontrollér for visuelle skader eller defekter",
        "MacAddressText":"Indtast MAC addresse",
        "BluetoothConnection":"Bluetooth forbindelse",
        "BluetoothConnectionText":"Kontrollér Bluetooth forbindelse",
                // package
        "Package": "Pakning",
        "DevicePacked":"Enhed pakket",
        "SelectDeviceForPackageText": "Vælg en enhed fra tabellen til pakning.",
        "PackagingDeviceWithUDSN": "Pakning af enhed med UDSN",
                        // hot steps
        "ContainsHotDevice": "Pakken indeholder HOT enhed",
        "TechnicalUserManual": "Teknisk brugermanual",
        "ContainsTechnicalUserManual": "Pakken indeholder teknisk brugermanual",
        "InstructionsForUse": "Brugsanvisning",
        "ContainsInstructionsForUse": "Pakken indeholder brugsanvisning",
        "DeclarationOfConformity": "Overensstemmelseserklæring",
        "ContainsDeclarationOfConformity": "Pakken indeholder overensstemmelseserklæring",
        "PowerSupply": "Strømforsyning",
        "ContainsPowerSupply": "Pakken indeholder strømforsyning",
                        // pro steps
        "ContainsProDevice": "Pakken indeholder PRO enhed",
        "Sensor": "Sensor",
        "ContainsSensor": "Pakken indeholder sensor",
        "ContainsUserManual": "Pakken indeholder brugermanual",
        "MasterPin": "Master PIN",
        "ContainsMasterPin": "Pakken indeholder master PIN",
        "OxygenOutputAdapter": "Oxygen udgangs adapter",
        "ContainsOxygenOutputAdapter": "Pakken indeholder oxygen udgangs adapter",
                // under investigation
        "UnderInvestigation": "Under undersøgelse",
        "UnderInvestigationMessage": "Vælg enhed under undersøgelse for næste trin",
        "InvestigateDeviceWithUdsn": "Undersøger enhed med UDSN",
        "RepairProdDescription": "Vælg en enhed til reperation under produktion.",
        "RepairO2maticDescription": "Vælg en enhed til reperation af O2matic.",
        "QAAprove": "QA godkendelse",
        "ProductionQAAproveMessage": "Vælg batch(er)/enhed(er) til produktion QA godkendelse",
        "ProductionQAAprove":"Produktion QA godkendelse",
        "O2maticQAAprove": "O2matic QA godkendelse",
        "O2maticQaApproveText": "Er du sikker på at du vil O2matic QA godkende denne enhed?",
        "O2maticQAAproveMessage": "Vælg batch(er)/enhed(er) til O2matic QA godkendelse",
        "O2maticTestedQAAproveMessage":"Vælg enhed til O2matic QA godkendelse",
                // QA
        "QA": "QA",
        "Qa": "QA",
        "ProductionQA":"Produktion QA",
        "O2maticQA": "O2matic QA",
        "O2maticqa":"O2matic QA",
        "ProductionQAApprovedReady":"Produktion QA godkendte enheder klar til O2matic QA godkendelse",
        "O2maticTestedReady":"O2matic testede enheder klar til O2matic QA godkendelse",
                // ship
        "Ship": "Afsend",
        "ShipperMessage": "Vælg batch(er)/enhed(er) og lokation til forsendelse",
                // repair
        "Repair": "Reparation",
        "RepairOverviewMessage": "Vælg enhed til reparation",
        "RepairDeviceWithUdsn": "Reparation af enhed med UDSN",

        "SelectLocationFirst": "Vælg lokation først",
        "ContactO2maticToResolve": "Verificer data, hvis problemet fortsætter, kontakt O2matic for at løse problemet",
        "PrintSerial": "Seriennr. til printplade",
        "FlowmeterSerial": "Seriennr. til flowmåler",
        "ValveSerial": "Seriennr. til ventil",
        "OximeterSerial": "Seriennr. til pulsoximeter",
        "Packaging": "Pakning",
        "OwnerLocationChanged": "Ejerens lokation er ændret",
        "SelectOwner": "Vælg ejer",
        "SelectStatus": "Vælg status",
        "ComponentId": "Komponent ID",
        "Components": "Komponenter",
        "Chassis": "Kabinet",
        "OriginalValue": "Oprindelige værdi",
        "NewValue": "Nye værdi",
        "Flowmeter": "Flowmåler",
        "Valve": "Ventil",
        "Print": "Printplade",
        "Screen": "Skærm",
        "Oximeter": "Pulsoximeter",
        "Hot100Chassis": "HOT 100 kabinet",
        "Hot100Print": "HOT 100 printplade",
        "Hot100Battery": "HOT 100 batteri",
        "Hot100Device" : "HOT 100 enhed",
        "Pro100Device":"PRO 100 enhed",
        "DuplicateEntry": "Duplikeret enhed(?)",
        "duplicateMAC": "Duplikeret MAC-adresse",
        "duplicatePrint": "Duplikeret udskriftsserie",
        "duplicateFlowmeter": "Duplikeret flowmåler serie",
        "duplicateValve": "Duplikeret ventil serie",
        "duplicateOximeter": "Duplikeret pulsoximeter serie",


        // LOGS PAGE
        "Log": "Log",
        "Logs": "Logs",
        "AuditorLogs": "Revisor Logs",
        "ListOfO2maticUsers": "Liste af O2matic brugere",
        "ListDownloaded":"Liste af O2matic brugere downloadet den:",
        "RolesApprovedBy":"Roller af alle O2matic brugere er godkendt den:",
        "Logs": "Logs",
        "Action": "Handling",
        "Actions": "Handlinger",
        "Domain": "Domæne",
        "LogDomain": "Log domæne",
        "TargetObjectId": "Målobjekt id",
        "TargetObjectLocation": "Målobjekt lokation",

        // INFORMATION PAGE
        "Info": "Info",
        "Information": "Information",
        "ForO2maticHotAppSee": "For 'O2matic - HOT' app se",
        "UserManual":"Bruger Manual",
        "TechnicalManual": "Teknisk Manual",
        "InstructionsAndGuides": "Instruktioner og guides",
        "InstructionsDescription": "Klik på det sprog du gerne vil se instruktion eller guide i. Filen åbnes som en pdf i et nyt vindue. For andre versioner og for instruktioner for PRO enheder, venligst besøg www.o2matic.com.",
        "ProReportGenerator": "PRO rapport værktøj",
        "ReleaseNotes": "Udgivelses noter",
        "ContactInformation": "Kontakt information",
        "Webpage": "Hjemmeside",


        // MAIN PAGE
        "HomeMessage1": "Forbinder patienter i iltbehandling med deres sundhedspersonale.",
        "HomeMessage2": "En løsning der forbedrer sikkerheden og livskvaliteten for patienterne, samtidig med at den letter arbejdsbyrden og reducerer behovet for fysisk kontakt for sundhedspersonale.",
        "LoggingOut": "Du bliver logget ud.",
        "LoginHelpText": "Kontakt din administrator for at få loginoplysninger",
        "LoginPageHeader": "Demo-side", //UPDATE
        "Training": "Træning",
        "LogOut": "Log ud",
        "SignIn": "Log ind",
        "UnauthorizedPage": "Du har ikke tilladelse til at se denne side.",
                        // updates banner
        "UpdatesBanner": "Opdateringer - Klik her for at se en beskrivelse af nye funktioner og ændringer på denne side",
        "UpdatesHeader": "Opdateringer - Her er en beskrivelse af nogle af de nye funktioner og ændringer",
        "UpdatesLine1": "For en oversigt over alle nye funktioner og ændringer i den seneste udgivelse, se venligst udgivelsesnoterne på 'Info'-siden.",
        "UpdatesLine2": "Nye funktioner på denne side:",
        "UpdatesDescriptionInformationPage": "Denne side er en ny funktion og indeholder generelle kontaktoplysninger, de seneste udgivelsesnoter fra O2matic Portalen sammen med instruktioner og vejledninger til O2matic Portalen og HOT-enheden.",
        "UpdatesDescriptionPROPage": "Denne side har fået et nyt layout, men indeholder den samme information som tidligere, og 'Event Log' kaldes nu 'Device Log'.",
        "UpdatesDescriptionHOTPage": "Denne side har fået et nyt layout, men indeholder den samme information som tidligere, og 'Event Log' kaldes nu 'Device Log'.",
        "UpdatesDescriptionDevicesPage": "Denne side giver adgang til enheds QR-scanner, HOT-enheder og PRO-enheder sider.",
        "UpdatesDescriptionPatientPage": "Denne side har fået et nyt layout, hvor al patientrelateret information er samlet i den venstre blå boks, al behandlingsrelateret information er i den højre blå boks og enhedens og tekniske informationer er i den nederste blå boks. NYT: Der er nu et tidsstempel for ordinering af behandlingen, hvilket er, når den seneste ændring er blevet foretaget i behandlingen. NYT: Behandlingsstatus angiver, om behandlingen er blevet installeret på HOT-enheden eller ej. Hvis behandlingen er blevet installeret, vises tidsstemplet for installationen, ellers vil installationsstatus sige 'Afventer'. NYT: Hvis patientens og enhedens lokation ikke stemmer overens, vil 'Lokationsmismatch' blive vist ved siden af ​​enhedens placering, og lokationsnavnet for HOT-enheden og patienten vil være orange. Lokationerne skal stemme overens for at sikre korrekt dataoverførsel, hvilket gøres ved at ændre enten patientens placering eller ved at kontakte en bruger med 'Device Manager'-rollen for at opdatere placeringen af ​​HOT-enheden. NYT: I den blå enhedsinformationsboks er nu inkluderet tidsstemplet for, hvornår enheden først blev tildelt patienten, sidst modtagne enhedslog, tidsstempel for den seneste session og tid indtil næste session skal udføres. Diagramlayoutene er blevet opdateret (en vejledning til læsning af diagrammerne findes i 'Portal Manual' på 'Info'-siden). Oversigtsdiagrammet for sessioner var tidligere placeret inde i en fane på siden, men vises nu øverst på siden for nem adgang. Den tidligere 'Sessions'-fane kaldes nu 'Dataarkiv' og indeholder både sessionsrapporter og daglige rapporter (for beskrivelser af de to rapporttyper, se 'Portal Manual'). 'Live data'-funktionen er blevet flyttet til toppen af ​​siden inde i den blå patientboks (se 'Portal Manual' for beskrivelse af live data-funktionen).",
        "UpdatesDescriptionPatientsPage": "Denne side har nu en bedre søgefunktion, hvor du kan søge efter patienter efter O2matic ID, Identifikationsværdi, Navn, Fødselsdato og Lokation. Søgning efter Lokation vil give dig en tabel over alle patienter på den lokation.",
        "UpdatesDescriptionUsersPage": "Denne side har nu en bedre søgefunktion, hvor du kan søge efter brugere efter ID, Fornavn, Efternavn, E-mail, Brugerplacering og specifikke roller. Klik på søgeknappen uden at udfylde nogen søgekriterier, vil give dig en tabel over alle brugere.",
        "UpdatesDescriptionUserPage": "Denne side har et nyt layout og en bedre oversigt over roller og tilsvarende placeringer af brugeren. Klik på 'Rediger roller' giver dig mulighed for at tilføje/fjerne roller, tilføje roller til nye placeringer eller slette brugeren.",
        "UpdatesDescriptionQRScannerPage": "Dette er en ny side, hvor du kan scanne QR-koden på bagsiden af en PRO- eller HOT-enhed for at se og redigere enhedens informationer.",
        "UpdatesDescriptionTreatmentPage": "Behandlingssiden har fået en omformulering af behandlingsparametrene, men indeholder de samme parametre som tidligere.",
        "UpdatesDescriptionTreatmentCreatePage":"Behandlingsprofil oprettelses siden har fået en omformulering af behandlingsparametrene, men indeholder de samme parametre som tidligere.",
        "UpdatesDescriptionLogsPage": "Denne side har nu dato-vælgere til at vælge et specifikt tidsinterval for at se logfiler. Logfilerne kan også downloades som en CSV-fil, men eventuelle filtre anvendt på tabellen vil ikke gælde i CSV-filerne.",
        "UpdatesDescriptionAppLicensePage": "Denne side har fået et nyt layout, men indeholder den samme information som tidligere.",
        "UpdatesDescriptionLocationPage": "Denne side har fået et nyt layout, men indeholder den samme information som tidligere.",
        "UpdatesDescriptionPatientCreatePage": "Behandlingsdelen af denne side har fået en omformulering af behandlingsparametrene, men indeholder de samme parametre som tidligere.",
        "UpdatesDescriptionPatientEditPage": "Behandlingsdelen af denne side har fået en omformulering af behandlingsparametrene, men indeholder de samme parametre som tidligere.",
                
        // PRO REPORT
        "ProReport": "PRO rapport",
        "CreateProReport": "Opret PRO rapport",
        "ProReportMessage": "Upload PRO sessionsfiler og generer rapporten. Ingen data gemmes eller overføres.",

        // GENERAL (not done)
        "Data": "Data",
        "SuperAdmin": "Super Administrator",
        "Active": "Aktiv",
        "and": "og",
        "To": "Til",
        "Yes": "Ja",
        "No": "Nej",
        "Loading": "Indlæser...",
        "Id": "ID",
        "From": "Fra",
        "GoBackTo": "Gå tilbage til",
        "Edit": "Rediger",
        "NoData": "Ingen data",
        "Added": "Tilføjet",
        "Enable": "Aktiver",
        "Enabled": "Aktiveret",
        "Disable": "Deaktiver",
        "Disabled": "Deaktiveret",
        "Close": "Luk", // changed from "lukke" to "luk"
        "Created": "Oprettet",
        "*empty*": "*tom*",  
        "Open": "Åben",
        "Tag": "Tag",
        "Owner": "Ejer",
        "SelectType": "Vælg type",
        "SelectTags": "Vælg tags",
        "Tags": "Tags",
        "Save": "Gem",
        "Date": "Dato",
        "Signature": "Signatur",
        "Last": "Sidste",
        "EntireDay": "Hele dagen",
        "DownloadAsPdf": "Download som pdf",
        "Downloaded": "Downloadet",
        "RegExpEmoji":"Tekst feltet kan ikke indeholde piktogrammer eller emojis.",
        "NoRows": "Ingen rækker",
        "Replace": "Erstat",
        "Analysing": "Analyserer",
        "Cancel":"Fortryd",
        
        "":"",
        "Delete": "Slet",
        "Email": "E-mail",
        "HomePage": "Startside",
        "Length": "Længde",
        
        "Time": "Tid",
        "LocalTime": "Lokal tid",
        "Timestamp": "Tidsstempel",
        "LocalTimestamp": "Lokalt-tidsstempel",
        "UnixTimestamp": "Unix-tidsstempel",
        "UTCTimestamp": "UTC-tidsstempel",
        

        "Remark": "Bemærkning",
        "Remarks": "Bemærkninger",
        "Success": "Succes",
        "BadRequest": "Dårlig anmodning",
        "Unauthorized": "Uautoriseret",
        "NotFound": "Ikke fundet",
        "IntenalServerError": "Intern server fejl",
        "UnknownError": "Ukendt fejl",
        "Error": "Fejl",
        "Lower": "Laveste",
        "Higher": "Højeste",
        "DownloadCSV": "Hent CSV",
        "Message": "Besked",
        "Format": "Format",
        "Version": "Version",
        "Select": "Vælg",
        "Back": "Tilbage",
        "Continue": "Fortsæt",
        "Finish": "Færdiggør",
        "Title": "Titel",
        "Submit": "Indsend",
        "Status": "Status",
        "AddLog": "Tilføj log",
        "Read": "Læs",
        "Update": "Opdater",
        "Delete": "Slet",
        "Download": "Download",
        "DownloadCsv": "Download som CSV",
        "DownloadPdf": "Download som pdf",
        "None": "Ingen",
        "NotSelected": "Ikke angivet",
        "Parameter": "Parameter",
        "Severity": "Alvorlighedsgrad",
        "Description": "Beskrivelse",
        "DragNDropFile": "Træk og slip filer her, eller klik for at vælge filer.",
        "Field1": "Felt 1",
        "Field2": "Felt 2",
        "Duration": "Varighed",
        "Clear": "Ryd",
        "Delay": "Forsinkelse",
        "Normal": "Normal",
        "NotFoundPage": "Siden kan ikke findes",
        "Search": "Søg",
        "PleaseConfirm": "Er du sikker?",
        "*empty*": "*tom*",

        "Sec": "Sek",
        "sec": "sek",
        "min": "min",
        "hours": "timer",
        "Days": "Dage",
        "Months": "Måneder",
        "Month": "Måned",
        "Year": "År",
        "Years": "År",
        "years": "år",
        "Mode": "Typetal",
        "MODE": "Typetal",
        "Minimum": "Minimum",
        "MINIMUM": "Minimum",
        "Maximum": "Maksimum",
        "MAXIMUM": "Maksimum",
        "Average": "Gennemsnit",
        "average": "gennemsnit",
        "AVERAGE": "Gennemsnit",
        "AverageRange": "Gennemsnitsinterval",
        
        "Statistics": "Statistik",
        "Min": "Min",
        "Max": "Max",
                        // units and time
        "BPM": "BPM",
        "bpm": "bpm",
        "LPM": "l/min",
        "l/min": "l/min",
        "min/hour": "min/time",
        "hour/day": "time(r)/dag",
        "hours/day": "timer/dag",
        "MinutesPerHour": "Minutter i timen",
                        // feedback
        "NoResults": "Filtrering returnerer ingen resultater.",
        "NoRowsOximeters": "Ingen oximetre fundet.",
        "NoRowsMetadata": "Ingen metadata fundet.",
        "NoRowsPatients": "Ingen patienter fundet.",
        "NoRowsTreatments": "Ingen behandlingsprofiler fundet.",
        "NoRowsLicenses": "Ingen licenser fundet.",
        "NoRowsLocations": "Ingen lokationer fundet.",
        "NoRowsUsers": "Ingen brugere fundet.",
        "NoRowsDevices": "Ingen enheder fundet.",
        "NoRowsLogs": "Ingen logfiler fundet.",
                // country and language
        "Country-CA": "Canada",
        "Country-IT": "Italien",
        "Country-DK": "Danmark",
        "Country-DE": "Tyskland",
        "Country-ES": "Spanien",
        "Country-VN": "Vietnam",
        "Country-UM": "Amerikanske Oversøiske Øer",
        "Country-AE": "De Forenede Arabiske Emirater",
        "Country-UA": "Ukraine",
        "Country-TR": "Tyrkiet",
        "Country-TH": "Thailand",
        "Country-TW": "Taiwan",
        "Country-CH": "Schweiz",
        "Country-LK": "Sri Lanka",
        "Country-ES": "Spanien",
        "Country-SI": "Slovenien",
        "Country-SK": "Slovakiet",
        "Country-SG": "Singapore",
        "Country-RS": "Serbien",
        "Country-SA": "Saudi-Arabien",
        "Country-RU": "Rusland",
        "Country-RO": "Rumænien",
        "Country-QA": "Qatar",
        "Country-PR": "Puerto Rico",
        "Country-PT": "Portugal",
        "Country-PL": "Polen",
        "Country-PH": "Filippinerne",
        "Country-PE": "Peru",
        "Country-PA": "Panama",
        "Country-OM": "Oman",
        "Country-NZ": "New Zealand",
        "Country-NL": "Holland",
        "Country-MC": "Monaco",
        "Country-MX": "Mexico",
        "Country-MT": "Malta",
        "Country-MY": "Malaysia",
        "Country-MK": "Nordmakedonien",
        "Country-LU": "Luxembourg",
        "Country-LI": "Liechtenstein",
        "Country-LV": "Letland",
        "Country-KW": "Kuwait",
        "Country-KR": "Sydkorea",
        "Country-KZ": "Kasakhstan",
        "Country-JO": "Jordan",
        "Country-JP": "Japan",
        "Country-IT": "Italien",
        "Country-IE": "Irland",
        "Country-ID": "Indonesien",
        "Country-IN": "Indien",
        "Country-IS": "Island",
        "Country-HU": "Ungarn",
        "Country-HK": "Hongkong",
        "Country-GR": "Grækenland",
        "Country-FR": "Frankrig",
        "Country-FI": "Finland",
        "Country-FO": "Færøerne",
        "Country-EE": "Estland",
        "Country-EG": "Egypten",
        "Country-EC": "Ecuador",
        "Country-CZ": "Tjekkiet",
        "Country-CY": "Cypern",
        "Country-HR": "Kroatien",
        "Country-CR": "Costa Rica",
        "Country-CN": "Kina",
        "Country-CA": "Canada",
        "Country-BG": "Bulgarien",
        "Country-BR": "Brasilien",
        "Country-BV": "Bouvetøen",
        "Country-BA": "Bosnien-Hercegovina",
        "Country-BE": "Belgien",
        "Country-BY": "Hviderusland",
        "Country-BD": "Bangladesh",
        "Country-BH": "Bahrain",
        "Country-AZ": "Aserbajdsjan",
        "Country-AT": "Østrig",
        "Country-AU": "Australien",
        "Country-AM": "Armenien",
        "Country-AR": "Argentina",
        "Country-DZ": "Algeriet",
        "Country-AL": "Albanien",
        "Country-GB": "Storbritannien",
        "Country-SE": "Sverige",
        "Country-LT": "Litauen",
        "Country-US": "Amerika",
        "Country-NO": "Norge",
        

        "Language-it": "Italiensk",
        "Language-en": "Englesk",
        "Language-da": "Dansk",
        "Language-de": "Tysk",
        "Language-es": "Spansk",
        }

}
export default Danish;