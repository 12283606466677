import {
    LOGIN_SUCCESS,
    ADD_ROLES,
    LOGIN_EXPIRED,
    LOGIN_FAIL,
    LOGOUT,
} from "../actions/types";
const user = JSON.parse(localStorage.getItem("user"));

const initialState = user 
    ? user
    : { isLoggedIn: false, user: null };

export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case LOGIN_SUCCESS:
            localStorage.setItem("user", JSON.stringify(payload.user.payload));
            return {
                ...state,
                isLoggedIn: true,
                user: payload.user.payload,
            };
        case ADD_ROLES:
            state.roles=payload.roles;
            localStorage.setItem("user", JSON.stringify(state));
            return {
                ...state,
            };
        case LOGIN_FAIL:
            return {
                ...state,
                isLoggedIn: false,
                user: null,
            };
        case LOGIN_EXPIRED:
            return {
                ...state,
                isLoggedIn: false,
                user: null,
                roles:null
            };
        case LOGOUT:
            return {
                ...state,
                isLoggedIn: false,
                user: null,
                roles:null
            };
        default:
            return state;
    }
}

