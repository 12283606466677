import React from 'react'
import { TextField, Box, InputLabel } from '@mui/material';
import { Translate, Localize } from 'react-i18nify';

const PatientContacts = (props) => {
    return (
        <>
            <InputLabel id="Address"><Translate value="Contacts" /></InputLabel>
            <Box sx={{ mt: 1, display: "flex" }}>

{/*                 <TextField
                    disabled
                    sx={{ width: "25%" , mr:1, }}
                    id="Installer"
                    required
                    value="Installer" /> */}
                <TextField
                    sx={{ width: "60%", mr: 1}}
                    id="ServiceProviderName"
                    value={props.installerName}
                    onChange={(event) => {
                        props.setInstallerName(event.target.value);
                    }}
                    label={<Translate value="ServiceProviderName" />}
                />
                <TextField
                    sx={{ width: "40%" }}
                    id="ServiceProviderValue"
                    value={props.installerValue.value}
                    inputProps={{
                        pattern:
                            "^.{1,16}$",
                    }}
                    onChange={(event) => {
                        props.setInstallValue(event.target.value);
                    }}
                    error={props.installerValue.error}
                    label={<Translate value="PhoneNumber" />}
                />
            </Box>
            <Box sx={{ mt: 1, display: "flex" }}>

                {/* <TextField
                    disabled

                    sx={{ width: "100%" }}
                    id="Healthcare"
                    required
                    value="Healthcare" /> */}
                <TextField
                    sx={{ width: "60%" , mr:1}}
                    id="Healthcare Name"
                    value={props.healthcareType}
                    onChange={(event) => {
                        props.setHealthcareType(event.target.value);
                    }}
                    label={<Translate value="HealthcareProviderName" />}
                />
                <TextField
                    inputProps={{
                        pattern:
                            "^.{1,16}$",
                    }}
                    error={props.healthcareValue.error}
                    sx={{ width: "40%" }}
                    value={props.healthcareValue.value}
                    id="HealthcarePhone"
                    onChange={(event) => {
                        props.setHealthcareValue(event.target.value);
                    }}
                    label={<Translate value="PhoneNumber" />}
                />
            </Box></>
    )
}

export default PatientContacts