import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router';
import { LocationService, PatientService, TreatmentService } from '../../services/DataService';
import PageContainer from '../../components/PageContainer';
import { PageHeader } from '../../components/Layout/PageHeader';
import { Box, Typography, Button, FormControl, TextField, InputLabel, Select, MenuItem, FormGroup, FormControlLabel, Switch, Slider } from '@mui/material'
//import { DateTimePicker } from '@mui/lab';
import { RequireRole } from "../../utils/RequireRole"
import { DatePicker } from '@mui/lab';
import TreatmentControls from "./../../components/Treatment/TreatmentControls"
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import PatientName from './PatientCreate/PatientName';
import Address from './PatientCreate/Address';
import CustomIdentifier from './PatientCreate/CustomIdentifier';
import PatientContacts from './PatientCreate/PatientContacts';
import { Translate, Localize } from 'react-i18nify';
import PatientUtils from '../../utils/PatientUtil'
import PatientUtil from '../../utils/PatientUtil';
import { Delete as DeleteIcon } from "@mui/icons-material";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { ConfirmDialog } from "../../components/Layout/Dialog/ConfirmDialog";
import AlertBox from '../AlertBox';
const PatientForm = props => {

    let patient = props.patient?.patient;
    let currentTreatment = props.patient?.currentTreatment;
    let patientLocation = props.patient?.location;

    const [name, setName] = React.useState(patient ? {
        value: patient?.name?.[0]?.given?.[0]?.value,
        error: false,
        errorText: null,
      } : {
        value: "",
        error: false,
        errorText: null,
      } );
    const [lastName, setLastName] = React.useState(patient ? patient?.name?.[0]?.family?.value : "");
    const [gender, setGender] = React.useState(patient ? patient?.gender?.value : "female");
    const [birthday, setBirthday] = React.useState(patient ? new Date(patient?.birthDate?.value) : new Date('1970-01-01'));
    const [address, setAddress] = React.useState(patient ? patient?.address[0]?.line[0]?.value : "");
    const [city, setCity] = React.useState(patient ? patient?.address[0]?.city.value : "");
    const [postalCode, setPostalCode] = React.useState(patient ? patient?.address[0]?.postalCode?.value : "");
    const [customIdType, setCustomIdType] = React.useState(patient ? patient?.identifier?.[0]?.system?.value : "");
    const [customIdValue, setCustomIdValue] = React.useState(patient ? patient?.identifier?.[0]?.value?.value : "");
    const [installerName, setInstallerName] = React.useState(patient ? PatientUtils.getContactString(patient?.contact, "Installer").name : "");
    const [installerValue, setInstallValue] = React.useState(patient ?{
        value: PatientUtils.getContactString(patient?.contact, "Installer")?.phone,
        error: false,
        errorText: null,
      }  : {
        value: "",
        error: false,
        errorText: null,
      });
    const [healthcareType, setHealthcareType] = React.useState(patient ? PatientUtils.getContactString(patient?.contact, "Healthcare")?.name : "");
    const [healthcareValue, setHealthcareValue] = React.useState(patient ? 
    {
        value: PatientUtils.getContactString(patient?.contact, "Healthcare")?.phone,
        error: false,
        errorText: null,
      }
    : {
        value: "",
        error: false,
        errorText: null,
      });
    const [location, setLocation] = useState(patientLocation ? patientLocation?.id : "");
    const [country, setCountry] = useState(patient ? patient?.address[0]?.country?.value : "");
    const [language, setLanguage] = useState(patient ? patient?.communication[0]?.language?.coding[0]?.code?.value : "");
    const [treatment, setTreatment] = useState(currentTreatment ? currentTreatment : "");
    const [customizeable, setCustomizable] = useState(false);
    const [customTreatment, setCustomTreatment] = useState();
    const [finalTreatment, setFinalTreatment] = useState();

    const [loadingLocation, setloadingLocation] = useState(false);
    const [locations, setLocations] = useState([]);
    const [loadingTreatment, setLoadingTreatment] = useState(false);
    const [treatments, setTreatments] = useState([]);
    const [requestSubmited, setRequestSubmited] = useState(false);
    const [confirmStatus, setConfirmStatus] = useState({status: 0, body: null});


    const [alertBox, setAlertBox] = useState({
        "visiblity": false,
        "statusCode": null,
        "text": null,
        "error": null
    }
    );



    const [showDeleteDialog, setShowDeleteDialog] = useState(false);

    const navigate = useNavigate();


    const handleAlertBox = (visiblity, statusCode, text, error) => {
        setAlertBox({
            "visiblity": visiblity,
            "statusCode": statusCode,
            "text": text,
            "error": error
        }
        )
    }
    const dismissAlertBox = () => {
        setAlertBox({
            "visiblity": false,
            "statusCode": null,
            "text": null,
            "error": null

        })
    }


    const handleNameChange = (event, newValue) => {
        let value=event.target.value;
        if(value.length>30){
            setName({value: value,
                error: true,
                errorText: "Maximum 30 characters",
              });
              return
        }
        setName({value: value,
            error: false,
            errorText:null,
          });
    };

    const handleInstallerPhoneChange = (value) => {

        if(value.length>16){
            setInstallValue({value: value,
                error: true,
                errorText: "Maximum 16 characters",
              });
              return
        }
        setInstallValue({value: value,
            error: false,
            errorText:null,
          });
    };
    const handleHealthPhoneChange = (value) => {
        if(value.length>16){
            setHealthcareValue({value: value,
                error: true,
                errorText: "Maximum 16 characters",
              });
              return
        }
        setHealthcareValue({value: value,
            error: false,
            errorText:null,
          });
    };
    const handleLastnameChange = (event, newValue) => {
        setLastName(event.target.value);
    };
    const handleLocationChange = (event, newValue) => {
        setLocation(newValue.props.value);
        setTreatment("")
        setCustomizable(false)
    };
    const handleTreatmentChange = (event, newValue) => {
        setTreatment(newValue.props.value);

    };
    const handleGenderChange = (event, newValue) => {
        setGender(newValue.props.value);
    };

    const closeDeleteDialog = () => {
        setShowDeleteDialog(false)
    }
    const openDeleteDialog = () => {
        setShowDeleteDialog(true)
    }
    const deletePatient = async (event, newValue) => {
        setConfirmStatus({status: 1, body: null});
        await PatientService.delete(props.id)
        .then((data) => {
            setConfirmStatus({status: 2, body: data});
            setTimeout(() => {
                navigate("/patient", { replace: true });
            }, 1000)
        }).catch((response) => {
            setConfirmStatus({status: 3, body: response});
        })
    };
    const handleCustomize = (event, newValue) => {
        setCustomizable(cur => {
            if (!cur) {
                let customTreatment = { ...treatment };
                customTreatment.name = "Custom treatment";
                setCustomTreatment(customTreatment);
            }
            else {
                setCustomTreatment({});
            }
            return !cur;
        });
    };
    const getGenderNumber = (gender) => {
        switch (gender) {
            case "male": return 0
            case "female": return 1
            case "other": return 2
            case "unknown": return 3
        }
    }

    const handleSubmit = async (event) => {
        setRequestSubmited(true)
        event.preventDefault();
        let patientJson = {
            "customIdentifierType": customIdType,
            "customIdentifierValue": customIdValue,
            "locationId": location,
            "firstName": name.value,
            "lastName": lastName,
            "gender": getGenderNumber(gender),
            "birthDate": birthday,
            "addressLine": address,
            "city": city,
            "postalCode": postalCode,
            "countryCode": country,
            "languageCode": language,
            "installerName": installerName,
            "installerPhone": installerValue.value,
            "healthcareName": healthcareType,
            "healthcarePhone": healthcareValue.value,
            "active": true
        }
        if (patient != null && patient?.id.value) {
            patientJson.patientId = patient?.id.value;
        }
        let patientData;
        if (!treatment) {
            //save patient without treatment
            try {
                if (patient != null && patient.id) {
                    patientData = await PatientService.update(patientJson);
                }
                else {
                    patientData = await PatientService.create(patientJson);
                }
                let patientId = patientData.data.patient.id.value;
                handleAlertBox(true, patientData.status, patientData.statusText, null)
                setTimeout(() => {
                    navigate("/patient/" + patientId, { replace: true });
                }, 2000)
                return;
            }
            catch (error) {
                setRequestSubmited(false)
                handleAlertBox(true, error?.response?.status, null, error?.response?.data?.join(","))
            }

        }


        if (!customizeable) {
            // save patient 
            // link patient to treatment
            try {
                if (patient != null && patient.id) {
                    patientData = await PatientService.update(patientJson);
                }
                else {
                    patientData = await PatientService.create(patientJson);
                }

                let patientId = patientData.data.patient.id.value;
                let treatmentId = treatment.id;
                let link = await PatientService.linkTreatment(patientId, treatmentId);
                handleAlertBox(true, patientData.status, patientData.statusText, null)
                setTimeout(() => {
                    navigate("/patient/" + patientId, { replace: true });
                }, 2000)
            } catch (error) {
                setRequestSubmited(false)
                try {
                    handleAlertBox(true, error?.response?.status, null, error?.response?.data?.join(","))
                } catch (error1) {
                    handleAlertBox(true, error?.response?.status, null, error?.response?.statusText)
                }

            }

            return;
        }
        else {
            try {

                let treatmentData = finalTreatment;
                treatmentData.parentTreatmentId = treatment.id;
                treatmentData.LocationID = location;

                let newTreatment = await TreatmentService.create(treatmentData);

                if (patient != null && patient.id) {
                    patientData = await PatientService.update(patientJson);
                }
                else {
                    patientData = await PatientService.create(patientJson);
                }

                let patientId = patientData.data.patient.id.value;
                let treatmentId = newTreatment.data.id;
                let link = await PatientService.linkTreatment(patientId, treatmentId);
                handleAlertBox(true, patientData.status, patientData.statusText, null)
                setTimeout(() => {
                    navigate("/patient/" + patientId, { replace: true });

                }, 2000)

            } catch (error) {
                setRequestSubmited(false)
                handleAlertBox(true, error?.response?.status, null, error?.response?.data?.join(","))
            }
            return;
        }
    }
    const updateTreatment = (treatment) => {
        setFinalTreatment(treatment);
    }

    useEffect(() => {
        (async () => {
            let apiResult = await LocationService.getByRoles(["PatientManager"]);
            setLocations(apiResult.data.sort((a, b) => a.name > b.name ? 1 : -1));
            setloadingLocation(true);
        })();
    }, []);
    useEffect(() => {
        (async () => {
            let newLocation = locations.find(loc => loc.id == location)
            let apiResult = await TreatmentService.getByLocationId( newLocation.id, newLocation?.clinicalTrial);
            setTreatments(apiResult.data.sort((a, b) => a.name > b.name ? 1 : -1));
            setLoadingTreatment(true);
        })();
    }, [location, locations])
    return (

        <Box
            component="form"
            onSubmit={handleSubmit}
        >


            <Box sx={{ width: "100%", display: "flex", flexDirection: { xs: "column", md: "row", mb:2 } }}>


                <Box variant="form" sx={{ width: { xs: "100%", sm: "400px" }, mt: 2, display: "flex", flexDirection: "column", mr: { xs: 0, md: 3 } }}>
                    <PatientName sx={{ width: "100%" }} name={name} lastName={lastName} handleNameChange={handleNameChange} handleLastnameChange={handleLastnameChange} />
                    <GenderSelectRender value={gender} onChange={handleGenderChange} />
                    <BirthdaySelector value={birthday} onChange={setBirthday} />
                    <Address address={address} city={city} postalCode={postalCode} setAddress={setAddress} setCity={setCity} setPostalCode={setPostalCode} />
                </Box>

                <Box variant="form" sx={{ width: { xs: "100%", sm: "400px" }, mt: 2, display: "flex", flexDirection: "column" }}>

                    <PatientContacts
                        setInstallerName={setInstallerName}
                        setInstallValue={handleInstallerPhoneChange}
                        setHealthcareType={setHealthcareType}
                        setHealthcareValue={handleHealthPhoneChange}
                        installerName={installerName}
                        installerValue={installerValue}
                        healthcareType={healthcareType}
                        healthcareValue={healthcareValue}
                    />
                    <CustomIdentifier
                        customIdType={customIdType}
                        customIdValue={customIdValue}
                        setCustomIdType={setCustomIdType}
                        setCustomIdValue={setCustomIdValue}
                    />
                    <InputLabel id="locationLabel"><Translate value="Location" /> *</InputLabel>
                    {loadingLocation ?
                        <Select
                            required
                            id="location"
                            displayEmpty
                            value={location || ""}
                            onChange={handleLocationChange}
                        >
                            <MenuItem value={""}><Translate value="SelectLocation" /></MenuItem>
                            {locations.map(location => {
                                return <MenuItem key={location.id} value={location.id}>{location.name}</MenuItem>
                            })}
                        </Select>
                        : <span><Translate value="Loading" /></span>}

                    <InputLabel id="country"><Translate value="Country" /> *</InputLabel>
                    <FormControl>

                        <Select
                            required
                            labelId="country"
                            id="country"
                            displayEmpty
                            value={country}
                            onChange={(event, value) => {
                                setCountry(value.props.value)
                            }}
                        >
                            <MenuItem value={""}><Translate value="SelectCountry" /></MenuItem>
                            <MenuItem value={"CA"}><Translate value="Country-CA" /></MenuItem>
                            <MenuItem value={"IT"}><Translate value="Country-IT" /></MenuItem>
                            <MenuItem value={"DK"}><Translate value="Country-DK" /></MenuItem>
                            <MenuItem value={"DE"}><Translate value="Country-DE" /></MenuItem>
                            <MenuItem value={"ES"}><Translate value="Country-ES" /></MenuItem>
                        </Select>
                    </FormControl>
                    <InputLabel id="language"><Translate value="Language" /> *</InputLabel>
                    <FormControl>

                        <Select
                            required
                            labelId="language"
                            id="language"
                            displayEmpty
                            value={language}
                            onChange={(event, props) => {
                                setLanguage(props.props.value);
                            }}
                        >
                            <MenuItem value={""}><Translate value="SelectLanguage" /></MenuItem>
                            <MenuItem value={"en"}><Translate value="Language-en" /></MenuItem>
                            <MenuItem value={"it"}><Translate value="Language-it" /></MenuItem>
                            <MenuItem value={"da"}><Translate value="Language-da" /></MenuItem>
                            <MenuItem value={"de"}><Translate value="Language-de" /></MenuItem>
                            <MenuItem value={"es"}><Translate value="Language-es" /></MenuItem>
                        </Select>
                    </FormControl>
                </Box>
            </Box>

            <Typography
                sx={{ alignSelf: "start", mt:2 }}
                component="h2"
                variant="h6">
                <Translate value="Treatment" />
            </Typography>
            <Box variant="form" sx={{ width: { xs: "100%", sm: "100%" }, mt: 2, display: "flex", flexDirection: "column" }}>
                <InputLabel id="treatment"><Translate value="SelectTreatment" /> *</InputLabel>
                <Box sx={{ display: "flex", }}>

                    <FormControl sx={{ width: "60%" }}>
                        {loadingTreatment ?
                            <Select
                                disabled={customizeable}
                                required
                                value={treatment}
                                id="treatment"
                                onChange={handleTreatmentChange}
                            >
                                {
                                treatments.length == 0 ? <MenuItem value={""}><Translate value="NoTreatments" /></MenuItem> :
                                treatments.map(treatment => {
                                    return <MenuItem key={treatment.id} value={treatment}>{treatment.name}</MenuItem>
                                })
                                }
                            </Select>
                            : <span><Translate value="SelectLocationFirst" /></span>}
                    </FormControl>
                    {treatment.id ?
                        !customizeable ?
                            <Button onClick={handleCustomize} sx={{ width: "40%", ml: 1, }} color="primary" variant="contained" startIcon={<EditIcon />}>
                                <Translate value="Customize" />
                            </Button>
                            : <Button onClick={handleCustomize} sx={{ width: "40%", ml: 1, }} color="success" variant="contained" startIcon={<EditIcon />}>
                                <Translate value="Customizing" />
                            </Button> : null}
                </Box>


                {treatment.id ?
                    <TreatmentControls isCT={customizeable ? locations.find(loc => loc.id == location)?.clinicalTrial : locations.find(loc => loc.id == location)?.clinicalTrial} updateTreatment={updateTreatment} disableTreatmentName={true} disabled={!customizeable} defaultTreatment={customizeable ? customTreatment : treatment} />
                    : null}
            </Box>

            <Box sx={{mt: 3, mb: 2, display:"flex", flexDirection:"row"}}>
                <Button
                    disabled={requestSubmited}
                    type="submit"
                    variant="contained"
                    color="success"
                    startIcon={<SaveIcon />}
                    sx={{ mr:2, width: "150px" }}>
                    <Translate value="Save" />
                </Button>
                <DeleteButtonRender deletePatientVissibility={props.deletePatientVissibility} openDeleteDialog={openDeleteDialog} />
            </Box>

            <AlertBox visible={alertBox.visiblity} text={alertBox.text} error={alertBox.error} statusCode={alertBox.statusCode} onHide={dismissAlertBox} />



            <ConfirmDialog
                dialogIcon={<ErrorOutlineIcon color="error" sx={{ fontSize: 75 }} />}
                title={<Translate value="PleaseConfirm" />}
                message={<Translate value="DeletePatientText" />}
                declineText={<Translate value="Close" />}
                confirmText={<Translate value="Delete" />}
                confirmButtonIcon={<DeleteIcon />}
                open={showDeleteDialog}
                onClose={closeDeleteDialog}
                onConfirm={deletePatient}
                confirmStatus={confirmStatus}
            >
            </ConfirmDialog>
        </Box>
    )
}
const DeleteButtonRender = (props) => {
    return (
        <>
        {props.deletePatientVissibility ?
            <Button sx={{ width:150 }} onClick={props.openDeleteDialog} color="error" variant="contained" startIcon={<DeleteIcon />}>
                <Translate value="Delete" />
            </Button> : null}
        </>
    )
}

const GenderSelectRender = (props) => {
    return (
        <>
            <InputLabel id="GenderLabel "><Translate value="Gender" /> *</InputLabel>
            <FormControl>
                <Select
                    required
                    labelId="gender"
                    id="gender"
                    value={props.value}
                    onChange={props.onChange}
                >
                    <MenuItem value={"male"}><Translate value="Male" /></MenuItem>
                    <MenuItem value={"female"}><Translate value="Female" /></MenuItem>
                </Select>
            </FormControl>
        </>
    )

}

const BirthdaySelector = (props) => {
    function convertUTCDateToLocalDate(date) {
        if (date == null || date == undefined)
            return "";

        var newDate = new Date(date.getTime() - date.getTimezoneOffset() * 60 * 1000);
        return newDate;
    }
    return (
        <>
            <InputLabel id="BirthdayLabel "><Translate value="DateOfBirth" /></InputLabel>
            <DatePicker
                required
                mask='____-__-__'
                inputFormat="yyyy-MM-dd"
                value={props.value}
                onChange={(newValue) => {
                    props.onChange(convertUTCDateToLocalDate(newValue))
                }}
                renderInput={(params) => <TextField {...params} />}
            />
        </>
    )

}

export default PatientForm