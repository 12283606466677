import React, { useState, useEffect } from 'react'


import { useParams, Navigate, useNavigate } from 'react-router-dom';

import { PageDetailText } from "../../components/PageDetailText";

import PageContainer from '../../components/PageContainer';
import { PageHeader } from '../../components/Layout/PageHeader';
import { Box } from '@mui/system';
import Button from "@mui/material/Button";
import { LocationService } from "../../services/DataService";
import { Typography } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { Translate, translate, Localize } from 'react-i18nify';
import PatientDashboardCategory from "../../components/Patient/PatientDashboardCategory";
import { PatientDetailText } from "../../components/Patient/PatientDetailText";
import {UpdatesBanner} from "../../components/UpdatesBanner";


const Location = (props) => {

    const [location, setLocation] = React.useState(null);
    const { id } = useParams();
    const [redirect, setRedirect] = useState(false);

    const navigate = useNavigate();
    useEffect(() => {
        (async () => {
            let apiResult = await LocationService.get(id);
            setLocation(apiResult.data);
        })();
    }, [])

    const navigateEdit = () => {
        navigate("/location/" + id + "/edit", { replace: true });
    }



    const renderLocation = (location) => {
        if (location == undefined)
            return <Typography><Translate value="Loading" /></Typography>

        return <Box sx={{ width: { xs: "500px", sm: "500px", md: "100%", lg: "1000px" }, maxWidth: "1000px" }}>
            <Box sx={{ p:2, mt: 1, borderRadius: "15px", border: 1, borderColor: "primary.main", bgcolor: "primary.main", color: "primary.contrastText", display: "flex", justifyContent: 'space-between', flexDirection: "column"}}>
                <Typography variant="body">
                    {<Translate value="LocationName" />}
                </Typography>
                <PatientDashboardCategory // patient name
                    title={location.name}
                >
                </PatientDashboardCategory>

                <Box sx={{mt: 2}}>
                    <PatientDetailText
                        label={<Translate value="Id" />}
                        value={location.id}
                    ></PatientDetailText>
                    <PatientDetailText
                        label={<Translate value="Name" />}
                        value={location.name}
                    ></PatientDetailText>
                    <PatientDetailText
                        label={<Translate value="Address" />}
                        value={location.address}
                    ></PatientDetailText>
                    <PatientDetailText
                        label={<Translate value="ParentLocation" />}
                        value={location.parentLocation?.name}
                    ></PatientDetailText>
                    <PatientDetailText
                        label={<Translate value="Country" />}
                        value={translate("Country-"+location.countryCode)}
                    ></PatientDetailText>

                    <br/>
                    
                    <PatientDetailText
                        label={<Translate value="DataRetentionPeriod" />}
                        value={location.dataRetentionDays}
                        unit={<Translate value="Days" />}
                    ></PatientDetailText>
                    <PatientDetailText
                        label={<Translate value="ClinicalTrial" />}
                        value={location.clinicalTrial ? <Translate value="Yes" /> : <Translate value="No" />}
                    ></PatientDetailText>
                </Box>
            
                <Typography variant='h5' sx={{mt:2}}><Translate value="Tags" /></Typography>
                {location.locationProperties.map(property => {
                    return(
                    <PageDetailText
                        label={<Translate value="Tag" />}
                        value={property.value}
                    />)
                })}
            
            
            
            
           {/*  <PageDetailText
                label={<Translate value="Id" />}
                value={location.id}
            />
            <PageDetailText
                label={<Translate value="Name" />}
                value={location.name}
            />
            <PageDetailText
                label={<Translate value="Address" />}
                value={location.address}
            />
            <PageDetailText
                label={<Translate value="ParentLocation" />}
                value={location.parentLocation?.name}
            />
            <PageDetailText
                label={<Translate value="Country" />}
                value={location.countryCode}
            />
            <PageDetailText
                label={<Translate value="DataRetentionPeriod" />}
                value={location.dataRetentionDays}
                type={<Translate value="Days" />}
            />
            <PageDetailText
                label={<Translate value="ClinicalTrial" />}
                value={location.clinicalTrial ? <Translate value="Yes" /> : <Translate value="No" />}
            /> */}
            
            </Box>
            <Button onClick={navigateEdit} sx={{ mt: 2, alignSelf: "start" }} color="secondary" variant="contained" startIcon={<EditIcon />}><Translate value="EditLocation" /></Button>

        </Box>

    }


    return (
        <>
            {redirect ? <Navigate to='/location' /> : null}

            <PageContainer>
                <PageHeader leftAlign>{<Translate value="LocationDetails" />}</PageHeader>
                <UpdatesBanner update_description="UpdatesDescriptionLocationPage"/>
                {renderLocation(location)}
            </PageContainer>

        </>
    )
}

export default Location