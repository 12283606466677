import React, { useState, useEffect } from 'react'
import PageContainer from '../../components/PageContainer';
import { PageHeader } from '../../components/Layout/PageHeader';
import { Box } from '@mui/system';
import { Typography, Button, FormControl, TextField, InputLabel, Select, MenuItem, FormGroup, FormControlLabel, Switch, Slider, listItemTextClasses, colors } from '@mui/material'
import { DateTimePicker } from '@mui/lab';
import RangeSliderControler from "./RangeSliderControler"
import { LocationService, TreatmentService } from '../../services/DataService'
import FormHelperDialogWrapper from '../../components/Layout/Dialog/FormHelperDialogWrapper';
import InfoIcon from '@mui/icons-material/Info';
import HelpIcon from '@mui/icons-material/Help';
import { Checkbox } from '@mui/material'
import { spo2RangeValidation, validationColors, o2FlowValidation } from './TreatmentValidation';
import SliderControler from "./SliderControler";
import { Translate, Localize, translate } from 'react-i18nify';

const TreatmentControls = (props) => {
    const treatmentArrayToJson = (treatment) => {
        let json = {};
        json.name = treatment.name;
        treatment?.values?.forEach(value => {
            json[value.code] = value.value;
        })
        return json;
    }
    let isCTVersion = props.isCT
    // let isCTVersion=props.isCT?props.isCT:props.defaultTreatment?props.defaultTreatment.clinicalTrial:false

    let O2RangeWithPO = isCTVersion ? 15 : 5
    let O2RangeWithoutPO = 4.9
    let sliderSize = isCTVersion ? "33%" : "100%"

    const green = "#a4ff80";
    const yellow = "#ffea85";
    const red = "#ff1111";
    let defaultTreatment;
    const [treatmentName, setTreatmentName] = React.useState(defaultTreatment ? {
        value: defaultTreatment.name,
        error: false,
        errorText: null,
      }:{
        value: "",
        error: false,
        errorText: null,
      });

    const [complaienceDuration, setComplaienceDuration] = useState(defaultTreatment ? defaultTreatment.minutesPrescribed : 24 *60 );
    const [durLod1, setDurLod1] = useState(defaultTreatment ? defaultTreatment.DurLod1 : 300);
    const [flowResponse, setFlowResponse] = useState(defaultTreatment ? defaultTreatment.Ki : 25);
    const [spo2Range, setSpo2Range] = useState(defaultTreatment ? [defaultTreatment.LSpO2, defaultTreatment.HSpO2] : [88, 92]);
    const [o2RangePO, setO2RangePO] = useState(defaultTreatment ? [defaultTreatment.LO2ST, defaultTreatment.HO2ST] : [1, 3.5]);
    const [o2RangeWOPO, setO2RangeWOPO] = useState(defaultTreatment ? [defaultTreatment.LO2LT, defaultTreatment.HO2LT] : [1.1, 3]);
    const [shortTermLength, setShortTermLength] = useState(defaultTreatment ? defaultTreatment.OAD / 60 : 61);

    useEffect(() => {
        if (props.defaultTreatment) {
            defaultTreatment = treatmentArrayToJson(props.defaultTreatment)
            setTreatmentName(defaultTreatment ? {
                value: defaultTreatment.name,
                error: false,
                errorText: null,
              }:{
                value: "",
                error: false,
                errorText: null,
              });
            setDurLod1(defaultTreatment ? defaultTreatment.DurLod1 : 300);
            setFlowResponse(defaultTreatment ? defaultTreatment.Ki : 25);
            setSpo2Range(defaultTreatment ? [defaultTreatment.LSpO2, defaultTreatment.HSpO2] : [88, 92]);
            setO2RangePO(defaultTreatment ? [defaultTreatment.LO2ST, defaultTreatment.HO2ST] : [1, 3.5]);
            setO2RangeWOPO(defaultTreatment ? [defaultTreatment.LO2LT, defaultTreatment.HO2LT] : [1.1, 3]);
            setShortTermLength(defaultTreatment ? defaultTreatment.OAD / 60 : 61);
            setComplaienceDuration(defaultTreatment? props.defaultTreatment.minutesPrescribed:24*60)
        }
    }, [props.defaultTreatment, props.rerender])

    useEffect(()=>{
        if(!isCTVersion){
          if(o2RangePO[1]>5){
            setO2RangePO(prev=>[prev[0],5])
          }
        }
    },[isCTVersion])




    const [spo2Color, setspo2Color] = React.useState("primary.main");
    const [O2POColor, setO2POColor] = React.useState("primary.main");
    const [O2WOPOColor, setO2WOPOColor] = React.useState("primary.main");
    const [shortTermLengthColor, setShortTermLengthColor] = React.useState("#009de0");
    const [ValidationError, setValidationError] = React.useState();

    const [lowFlowWarning, setLowFlowWarning] = useState(false);



    const createTreatmentData = (validationError, treatmentName, locationId, SpO2Low, SpO2High, O2LTLow, O2LTHigh, O2STLow, O2STHigh, shortTermLength, flowResponse, durLod1, clinicalTrial, complaienceDuration) => {
        let data = {
            ValidationError: validationError,
            Name: treatmentName,
            clinicalTrial: clinicalTrial,
            minutesPrescribed: complaienceDuration,
            treatmentValues: [
                { "code": "DurLod1", "value": durLod1 },
                { "code": "LSpO2", "value": SpO2Low },
                { "code": "HSpO2", "value": SpO2High },
                { "code": "LO2LT", "value": O2LTLow },
                { "code": "HO2LT", "value": O2LTHigh },
                { "code": "LO2ST", "value": O2STLow },
                { "code": "HO2ST", "value": O2STHigh },
                { "code": "OAD", "value": shortTermLength },
                { "code": "Ki", "value": flowResponse }
            ]
        };
        return data;
    }
    useEffect(() => {
        let o2validationResult = o2FlowValidation(o2RangeWOPO, o2RangePO);
        setO2WOPOColor(o2validationResult.o2RangeWOPOColor)
        setO2POColor(o2validationResult.o2RangePOColor)
        setValidationError(o2validationResult.validationError ? o2validationResult.validationError : null)
        setLowFlowWarning(o2validationResult.lowFlowWarning)
        if (o2RangeWOPO[0] <= o2RangePO[0]) {
            if(o2RangeWOPO[0] === 0 && o2RangePO[0] === 0){
                setO2WOPOColor(yellow)
                setO2POColor(yellow);
            }else{
                setO2WOPOColor(red)
                setO2POColor(red);
                setValidationError(translate("validationError_WithoutPoMinIsSmaller"));
                updateTreatment();
                return;
            }
        }
        if (o2RangeWOPO[1] >= o2RangePO[1]) {
            setO2WOPOColor(red)
            setO2POColor(red);
            setValidationError(translate("validationError_WithoutPoMaxIsBigger"));
            updateTreatment();
            return;
        }
        setValidationError();


        setO2WOPOColor(green)
        setO2POColor(green);

        if (o2RangeWOPO[0] > 3 || o2RangeWOPO[0] < 0.5) {
            setO2WOPOColor(yellow)
        }
        if (o2RangeWOPO[1] < 1 || o2RangeWOPO[1] > 4) {
            setO2WOPOColor(yellow)
        }
        if (o2RangePO[1] < 3 || o2RangePO[0] < 0.5) {
            setO2POColor(yellow)
        }

        if (o2RangeWOPO[0] < 0.5 || o2RangePO[0] < 0.5) {
            setLowFlowWarning(true);
        } else {
            setLowFlowWarning(false);
        }
        updateTreatment();
    }, [o2RangePO, o2RangeWOPO])

    useEffect(() => {
        let spo2ColorResult = spo2RangeValidation(spo2Range)
        setspo2Color(spo2ColorResult)
        updateTreatment();
    }, [spo2Range])

    const spo2RangeValidation = (spo2Range) => {
        if (spo2Range[0] == spo2Range[1]) {
            return validationColors.RED;
        }
        if (spo2Range[1] < 80) {
            return validationColors.RED;
        }
        if (spo2Range[0] < 85 || spo2Range[0] > 92) {
            return validationColors.YELLOW
        }
        if (spo2Range[1] < 88 || spo2Range[1] > 95) {
            return validationColors.YELLOW
        }
        return validationColors.GREEN;
    }

    useEffect(() => {
        updateTreatment();
    }, [ValidationError, treatmentName, spo2Range, o2RangeWOPO, , o2RangePO, shortTermLength, flowResponse, durLod1, complaienceDuration])


    const updateTreatment = () => {
        if (props.updateTreatment)
            props.updateTreatment(getTreatment());
    }

    const getTreatment = () => {
        if(complaienceDuration === null)
            setComplaienceDuration(0)
        return createTreatmentData(ValidationError, treatmentName.value, 0, spo2Range[0], spo2Range[1], o2RangeWOPO[0], o2RangeWOPO[1], o2RangePO[0], o2RangePO[1], shortTermLength, flowResponse, durLod1, isCTVersion, complaienceDuration);
    }

    const handleTreatmentNameChange = (event, newValue) => {
        let value=event.target.value
        if(value.length>20){
            setTreatmentName({value: value,
                error: true,
                errorText: "Maximum 20 characters",
              });
              return
        }
        if(value.length==0){
            setTreatmentName({value: value,
                error: true,
                errorText:"Can not be empty",
              });
              return
        }
        setTreatmentName({value: value,
            error: false,
            errorText:null,
          });
    };

    const handleDurLod1Change = (event, newValue) => {
        setDurLod1(event.target.value);
    };
    const handleFlowResponseChange = (event, newValue) => {
        setFlowResponse(event.target.value);
    };
    const handleComplaienceDurationChange = (event, newValue) => {
        let newDuration = event.target.value;
        if (newDuration < 0 || newDuration > 24) {
            return;
        }
        setComplaienceDuration(event.target.value * 60);
    };

    const handleShortTermLengthChange = (event, newValue) => {
        setShortTermLength(newValue.props.value)
    };
    const handleSpo2RangeChange = (event, newValue) => {
        setSpo2Range(newValue)
    };
    const handleO2RangePOChange = (event, newValue) => {
        let newO2RangeWOPOValue = o2RangeWOPO;
        let newO2WOPOBottom;

        if (newValue[0] < 5) {
            newO2WOPOBottom = newValue[0];
        }
        else {
            newO2WOPOBottom = 5;
        }

        setO2RangePO(newValue);
        setO2RangeWOPO(newO2RangeWOPOValue)

    };
    const handleO2RangeWOPOChange = (event, newValue) => {

        let newO2RangePOValue = o2RangePO;
        setO2RangePO(newO2RangePOValue);
        setO2RangeWOPO(newValue)
    };


    return (

        <Box  component="div" sx={{ mt: 0, display: "flex", flexDirection: "column" }}>

            <InputLabel htmlFor="treatmentName" id="treatmentNameLabel"><Translate value="TreatmentName" /></InputLabel>
            <TextField sx={{ width: "100%" }}
                id="treatmentName"
                required
                inputProps={{
                    pattern:
                      "^.{1,20}$",
                  }}
                  error={treatmentName.error}
                  errorText={treatmentName.errorText}
                disabled={props.disabled || props.disableTreatmentName}
                onChange={handleTreatmentNameChange}
                value={treatmentName.value}></TextField>
                {treatmentName.error ? (
                  <Typography variant="body" sx={{color:"red"}}>
                    {treatmentName.errorText}
                  </Typography>
                ) : null}

            

            <InputLabel htmlFor="durLod1" id="lod1"><Translate value="AnalysingPhase" /></InputLabel>
            <FormHelperDialogWrapper text={<Translate value="AnalysingPhaseExplanationTreatmentPage" />}>
                <Select

                    disabled={props.disabled}
                    sx={{ width: "100%" }}
                    required
                    value={durLod1}
                    id="durLod1"
                    onChange={handleDurLod1Change}
                >
                    <MenuItem value={300}><Translate value="Yes" /></MenuItem>
                    <MenuItem value={0}><Translate value="No" /></MenuItem>
                </Select>
            </FormHelperDialogWrapper>

            <InputLabel htmlFor="flowResponse" id="flowResponseLabel"><Translate value="FlowResponse" /></InputLabel>
            <FormHelperDialogWrapper text={<Translate value="FlowResponseExplanation" />}>
                <Select
                    data-testid="flowResponse-test"
                    disabled={props.disabled}
                    sx={{ width: "100%" }}
                    required
                    value={flowResponse}
                    id="flowResponse"
                    onChange={handleFlowResponseChange}
                >
                    <MenuItem value={25}><Translate value="Normal" /></MenuItem>
                    <MenuItem value={100}><Translate value="Fast" /></MenuItem>
                    <MenuItem value={150}><Translate value="VeryFast" /></MenuItem>
                </Select>
            </FormHelperDialogWrapper>



                




            <InputLabel htmlFor="complaienceDuration" id="complaienceDurationLabel"><><Translate value="O2ComplianceGoal" /> (<Translate value="hours/day" />)</></InputLabel>
            <FormHelperDialogWrapper text={<Translate value="OxygenComplianceGoalExplanationTreatmentPage" />}>
                <TextField sx={{ width: "100%" }}
                    id="complaienceDuration"
                    required
                    disabled={props.disabled}
                    type="number"
                    onChange={handleComplaienceDurationChange}
                    value={complaienceDuration  / 60}></TextField>
            </FormHelperDialogWrapper>


            
            

            <FormHelperDialogWrapper text={<Translate value="TargetSpO2RangeExplanation" />}>
                <RangeSliderControler disabled={props.disabled} sx={{ width: "100%" }} unit="%" title={<Translate value="SpO2Range" />} color={spo2Color} minValue={spo2Range[0]} maxValue={spo2Range[1]} min={75} max={100} onChange={handleSpo2RangeChange} />
            </FormHelperDialogWrapper>
            
            <FormHelperDialogWrapper text={<Translate value="WithoutPulseOximeterExplanation" />}>
                <RangeSliderControler disabled={props.disabled} sliderSize={sliderSize} sx={{ width: sliderSize }} unit="l/min" title={<Translate value="O2RangeWithoutPO" />} color={O2WOPOColor} step={0.1} minValue={(o2RangeWOPO[0])} maxValue={o2RangeWOPO[1]} min={0.0} max={O2RangeWithoutPO} onChange={handleO2RangeWOPOChange} />
            </FormHelperDialogWrapper>

            <FormHelperDialogWrapper text={<Translate value="WithPulseOximeterExplanation" />}>
                <RangeSliderControler disabled={props.disabled} sx={{ width: "100%" }} unit="l/min" title={<Translate value="O2RangeWithPO" />} color={O2POColor} step={0.1} minValue={o2RangePO[0]} maxValue={o2RangePO[1]} min={0} max={O2RangeWithPO} onChange={handleO2RangePOChange} />
            </FormHelperDialogWrapper>


            <InputLabel htmlFor="ShortTermLength" id="ShortTerm"><Translate value="ShortTermLength" /></InputLabel>
            <FormHelperDialogWrapper text={<Translate value="ShortTermExplanation" />}>
                <Select
                    disabled={props.disabled}
                    sx={{ width: "100%" }}
                    required
                    value={shortTermLength}
                    id="ShortTermLength"
                    onChange={handleShortTermLengthChange}
                >
                    {/**<MenuItem value={0}><Translate value="AlwaysOff" /></MenuItem> */}
                    <MenuItem value={15}>15 <Translate value="min/hour" /></MenuItem>
                    <MenuItem value={30}>30 <Translate value="min/hour" /></MenuItem>
                    <MenuItem value={45}>45 <Translate value="min/hour" /></MenuItem>
                    <MenuItem value={61}><Translate value="Always" /></MenuItem>
                </Select>

            </FormHelperDialogWrapper>
            


            {lowFlowWarning ?
                <Box sx={{ display: "flex" }}>
                    <InfoIcon />
                    <Typography variant='body'>
                        <Translate value="LowFlowCompatibilityWarning" />

                    </Typography>
                </Box>
                : null}
        </Box>
    )
}
export default TreatmentControls;