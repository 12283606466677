import React from 'react'
import { Translate, Localize } from 'react-i18nify';
import { TextField, InputLabel } from '@mui/material';
const Address = (props) => {
    return (
        <>
            <InputLabel id="Address"><Translate value="Address" /></InputLabel>
            <TextField
                sx={{ mt:1, width: "100%" }}
                id="StreetName"
                required
                onChange={(event) => {
                    props.setAddress(event.target.value);
                }}
                value={props.address}
                label={<Translate value="AddressLine" />} />
            <TextField
                sx={{ mt:1, width: "100%" }}
                id="City"
                required
                value={props.city}
                onChange={(event) => {
                    props.setCity(event.target.value);
                }}
                label={<Translate value="City" />}
            />
            <TextField
                sx={{ mt:1, width: "100%" }}
                id="PostalCode"
                required
                value={props.postalCode}
                onChange={(event) => {
                    props.setPostalCode(event.target.value);
                }}
                label={<Translate value="PostalCode" />}
            />
        </>
    )
}

export default Address