import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { DashboardSidebar } from './dashboard-sidebar';
import { BurgerMenu } from '../Navigation/BurgerMenu'
import LanguageSelector from '../LanguageSelector';
import { Navigate, useNavigate } from 'react-router-dom';
const DashboardLayoutRoot = styled('div')(({ padding, theme }) => ({
    display: 'flex',
    flexDirection: "column",
    flex: '1 1 auto',
    maxWidth: '100%',
    [theme.breakpoints.up('lg')]: {
        paddingLeft: padding
    }
}));

export const DashboardLayout = (props) => {
    // console.log({layout:props})
    const { children } = props;
    const { notification } = props
    const [isSidebarOpen, setSidebarOpen] = useState(false);
    const setSidebar = (value) => {
        setSidebarOpen(value);
    }
    const location=useLocation()
    if (!props.navigationVisibility&&location.pathname!="/login") {
        return <Navigate to="/login" />
    }
    return (
        <>


            <DashboardLayoutRoot padding={props.navigationVisibility ? 280 : 0}>
                <BurgerMenu vissible={props.navigationVisibility} setSidebarOpen={setSidebarOpen} />
                <Box
                    sx={{
                        display: 'flex',
                        flex: '1 1 auto',
                        flexDirection: 'column',
                        width: '100%'
                    }}
                >
                    {children}
                </Box>
            </DashboardLayoutRoot >

            <DashboardSidebar
                notification = {props.notification}
                vissible={props.navigationVisibility}
                onClose={() => setSidebar(false)}
                open={isSidebarOpen}
            />
        </>
    );
};
