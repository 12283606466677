import React, { useRef } from 'react';
import { useEffect, useState } from 'react';
import { Box } from '@mui/system';
import { Typography } from '@mui/material'
import uPlot from 'uplot';
import { Object } from 'core-js';
import UplotReact from 'uplot-react';
import 'uplot/dist/uPlot.min.css';
import TimeUtils from '../../utils/TimeUtils';
import { useTheme } from '@mui/material/styles';
import { translate, Translate } from 'react-i18nify';

export const Chart = (props) => {
    const start = new Date(props.data[0][0]*1000) // note: you have to multiply by 1000 to get unix to be convertable to a DateTime. You then have to divide by 1000 again if your want to convert back to unix.
    const endTime = new Date(props.data[0].at(-1)*1000) // note: you have to multiply by 1000 to get unix to be convertable to a DateTime. You then have to divide by 1000 again if your want to convert back to unix.
    const xaxisRange = props.xaxisRange; // number of minutes of data to include
    const theme = useTheme()

    // useEffect(() => {
    //     console.log("data: ", props)
    // })

    if (xaxisRange == "compact") { // for live data chart

        return (
            <Box sx={{ml:-1,  width:"100%", display: "flex", flexDirection: "column" }}>
                <Typography sx={{ml:1}}>{translate("DetailedDataDescription")}</Typography>
                <UplotReact
                    options={{
                        gridWidth: 0, // set the grid width to 0
                        gridColor: null, // set the grid color to null
                        height: props.width/1.7,
                        width: 790,
                        series: [
                            {
                                label: translate("Time"),
                                value: "{YYYY}-{MM}-{DD} {HH}:{mm}"
                            },
                            {
                                stroke: theme.palette.vitals.spo2,
                                label: translate("SpO2"),
                                width: 3,
                                scale: "%",
                                value: (self, raw) => raw
                            },
                            {
                                stroke: theme.palette.vitals.pulse,
                                label: translate("Pulse"),
                                width: 3,
                                scale: "bpm",
                                value: (self, raw) => raw
                            },
                            {
                                stroke: theme.palette.vitals.o2,
                                label: translate("O2Flow"),
                                width: 3,
                                scale: "l/min",
                                value: (self, raw) => raw
                            }
                        ],
                        axes: [
                            {
                                grid: {show: false},
                                labelSize: 75,
                                labelFont: "bold 14px Arial",
                                font: "14px Arial",
                                space: 40,
                                size: 75,
                                color: "red",
                                incrs: [
                                    //60,
                                    //60 * 5,
                                    //60 * 10,
                                    60 * 15,  //15 min increments
                                    60 * 30, //30 min increments
                                    60*60, //1h increments
                                    60*60*2, // 2h increments
                                    //3600, // day divisors
                                ],
                                values: [
                                    // tick incr          default           year                             month    day                        hour     min                sec       mode
                                      
                                      //[60,                "{HH}:{mm}",   "\n{YYYY}-{MM}-{DD}",               null,    "\n{YYYY}-{MM}-{DD}",      null,    null,              null,        1], // KAC: this was the old version. I changed it since charts with more than 10h of data did not display the x-axis at all. Fix included adding 2 hour increments
                                      [60,                "",       "{HH}:{mm}\n{YYYY}-{MM}-{DD}",               null,    "{HH}:{mm}\n{YYYY}-{MM}-{DD}",      "{HH}:{mm}",    "{HH}:{mm}",              null,        1],
                                    ]
                            },
                            {
                                label: (translate("SpO2") + " [%]"),
                                labelGap: 20,
                                grid: {
                                    show: true},
                                show:props.width>500?true:false,
                                labelSize: 50,
                                labelFont: "bold 12px Arial",
                                font: "14px Arial",
                                gap: 5,
                                size: 10,
                                scale: "%",
                                stroke: theme.palette.vitals.spo2,
                                // values: (self, ticks) => ticks.map(rawValue => rawValue.toFixed(0) + "%"),
                                values: (self, ticks) => ticks.map(rawValue => rawValue.toFixed(0)),
                                side: 3,
                                ticks: {
                                    show: true,
                                    stroke: theme.palette.vitals.spo2,
                                    width: 2,
                                    dash: [],
                                    size: 5,
                                },
                                border: {
                                    show: true
                                }
                            },
                            {
                                label: (translate("Pulse") + " [" + translate("bpm") + "]"),
                                labelGap: 60,
                                grid: {
                                    show: false},
                                show:props.width>500?true:false,
                                labelSize: 75,
                                labelFont: "bold 12px Arial",
                                font: "14px Arial ",
                                gap: 5,
                                size: -20,
                                scale: "bpm",
                                stroke: theme.palette.vitals.pulse,
                                // values: (self, ticks) => ticks.map(rawValue => rawValue.toFixed(0) + " bpm"),
                                values: (self, ticks) => ticks.map(rawValue => rawValue.toFixed(0)),
                                side: 3,
                                ticks: {
                                    show: true,
                                    stroke: theme.palette.vitals.pulse,
                                    width: 2,
                                    dash: [],
                                    size: 5,
                                },
                                incrs: [
                                    10
                                ],
                                border: {
                                    show: true
                                }
                            },
                            {
                                label: (translate("O2Flow") + " [" + translate("l/min") + "]"),
                                labelGap: 50,
                                grid: {
                                    show: false},
                                show:props.width>500?true:false,
                                labelSize: 75,
                                labelFont: "bold 12px Arial",
                                font: "14px Arial",
                                size: -30,
                                color: "red",
                                scale: "l/min",
                                stroke: theme.palette.vitals.o2,
                                // values: (self, ticks) => ticks.map(rawValue => rawValue.toFixed(0) + " l/min"),
                                values: (self, ticks) => ticks.map(rawValue => rawValue.toFixed(0)),
                                side: 1,
                                ticks: {
                                    show: true,
                                    stroke: theme.palette.vitals.o2,
                                    width: 2,
                                    dash: [],
                                    size: 5,
                                },
                                incrs: [
                                    1
                                ],
                                border: {
                                    show: true
                                }
                            },
                        ],
                        scales: {
                            "%": {
                                auto: false,
                                range: [80, 101],
                            },
                            "bpm": {
                                auto: false,
                                range: [40, 220],
                            },
                            "l/min": {
                                auto: false,
                                range: [0, 15],
                            }
                        }
                        
                    }}
                    data={props.data}
                />
                <Typography variant='body'> <Box component="span" fontWeight='fontWeightMedium'><Translate value="ImportantNote"/>:</Box> <Translate value="DetailedDataDisclaimerChart"/></Typography>
            </Box>
    
        );

    } else if (xaxisRange == "all") {

        return (
            <Box sx={{ml:-1,  width:"100%", display: "flex", flexDirection: "column" }}>
                <Typography sx={{ml:1}}>{translate("DetailedDataDescription")}</Typography>
                <UplotReact
                    options={{
                        gridWidth: 0, // set the grid width to 0
                        gridColor: null, // set the grid color to null
                        height: props.width/1.7,
                        width: 900,
                        series: [
                            {
                                label: translate("Time"),
                                value: "{YYYY}-{MM}-{DD} {HH}:{mm}"
                            },
                            {
                                stroke: theme.palette.vitals.spo2,
                                label: translate("SpO2"),
                                width: 3,
                                scale: "%",
                                value: (self, raw) => raw
                            },
                            {
                                stroke: theme.palette.vitals.pulse,
                                label: translate("Pulse"),
                                width: 3,
                                scale: "bpm",
                                value: (self, raw) => raw
                            },
                            {
                                stroke: theme.palette.vitals.o2,
                                label: translate("O2Flow"),
                                width: 3,
                                scale: "l/min",
                                value: (self, raw) => raw
                            }
                        ],
                        axes: [
                            {
                                grid: {show: false},
                                labelSize: 75,
                                labelFont: "bold 14px Arial",
                                font: "14px Arial",
                                space: 40,
                                size: 75,
                                color: "red",
                                incrs: [
                                    60,
                                    60 * 5,
                                    60 * 10,
                                    60 * 15,
                                    60 * 30,
                                    60 * 60,
                                    60 * 60 * 2,
                                ],
                                values: [
                                    // tick incr          default           year                             month    day                        hour     min                sec       mode
                                      
                                      //[60,                "{HH}:{mm}",   "\n{YYYY}-{MM}-{DD}",               null,    "\n{YYYY}-{MM}-{DD}",               null,    null,              null,        1],
                                      [60,                "",       "{HH}:{mm}\n{YYYY}-{MM}-{DD}",               null,    "{HH}:{mm}\n{YYYY}-{MM}-{DD}",      "{HH}:{mm}",    "{HH}:{mm}",              null,        1],
                                    ]
                            },
                            {
                                label: (translate("SpO2") + " [%]"),
                                labelGap: 20,
                                grid: {
                                    show: true},
                                show:props.width>500?true:false,
                                labelSize: 50,
                                labelFont: "bold 12px Arial",
                                font: "14px Arial",
                                gap: 5,
                                size: 10,
                                scale: "%",
                                stroke: theme.palette.vitals.spo2,
                                // values: (self, ticks) => ticks.map(rawValue => rawValue.toFixed(0) + "%"),
                                values: (self, ticks) => ticks.map(rawValue => rawValue.toFixed(0)),
                                side: 3,
                                ticks: {
                                    show: true,
                                    stroke: theme.palette.vitals.spo2,
                                    width: 2,
                                    dash: [],
                                    size: 5,
                                },
                                border: {
                                    show: true
                                }
                            },
                            {
                                label: (translate("Pulse") + " [" + translate("bpm") + "]"),
                                labelGap: 60,
                                grid: {
                                    show: false},
                                show:props.width>500?true:false,
                                labelSize: 75,
                                labelFont: "bold 12px Arial",
                                font: "14px Arial ",
                                gap: 5,
                                size: -20,
                                scale: "bpm",
                                stroke: theme.palette.vitals.pulse,
                                // values: (self, ticks) => ticks.map(rawValue => rawValue.toFixed(0) + " bpm"),
                                values: (self, ticks) => ticks.map(rawValue => rawValue.toFixed(0)),
                                side: 3,
                                ticks: {
                                    show: true,
                                    stroke: theme.palette.vitals.pulse,
                                    width: 2,
                                    dash: [],
                                    size: 5,
                                },
                                incrs: [
                                    10
                                ],
                                border: {
                                    show: true
                                }
                            },
                            {
                                label: (translate("O2Flow") + " [" + translate("l/min") + "]"),
                                labelGap: 50,
                                grid: {
                                    show: false},
                                show:props.width>500?true:false,
                                labelSize: 75,
                                labelFont: "bold 12px Arial",
                                font: "14px Arial",
                                size: -30,
                                color: "red",
                                scale: "l/min",
                                stroke: theme.palette.vitals.o2,
                                // values: (self, ticks) => ticks.map(rawValue => rawValue.toFixed(0) + " l/min"),
                                values: (self, ticks) => ticks.map(rawValue => rawValue.toFixed(0)),
                                side: 1,
                                ticks: {
                                    show: true,
                                    stroke: theme.palette.vitals.o2,
                                    width: 2,
                                    dash: [],
                                    size: 5,
                                },
                                incrs: [
                                    1
                                ],
                                border: {
                                    show: true
                                }
                            },
                        ],
                        scales: {
                            "%": {
                                auto: false,
                                range: [80, 100],
                            },
                            "bpm": {
                                auto: false,
                                range: [40, 220],
                            },
                            "l/min": {
                                auto: false,
                                range: [0, 15],
                            }
                        }
                        
                    }}
                    data={props.data}
                />
                <Typography variant='body'> <Box component="span" fontWeight='fontWeightMedium'><Translate value="ImportantNote"/>:</Box> <Translate value="DetailedDataDisclaimerChart"/></Typography>
            </Box>
    
        );

    } else { // live data chart
        var startTime = new Date(props.data[0].at(-1)*1000); // note: you have to multiply by 1000 to get unix to be convertable to a DateTime. You then have to divide by 1000 again if your want to convert back to unix.
        startTime.setSeconds(startTime.getSeconds()-parseInt(xaxisRange*60));

        return (
            <Box sx={{ width:"100%", display: "flex", flexDirection: "column" }}>
                <Typography>{translate("DetailedDataDescription")}</Typography>
                <UplotReact
                    options={{
                        gridWidth: 0, // set the grid width to 0
                        gridColor: null, // set the grid color to null
                        height: props.width/1.7,
                        width: 900,
                        series: [
                            {
                                label: translate("Time"),
                                value: "{YYYY}-{MM}-{DD} {HH}:{mm}"
                            },
                            {
                                stroke: theme.palette.vitals.spo2,
                                label: translate("SpO2"),
                                width: 3,
                                scale: "%",
                                value: (self, raw) => raw
                            },
                            {
                                stroke: theme.palette.vitals.pulse,
                                label: translate("Pulse"),
                                width: 3,
                                scale: "bpm",
                                value: (self, raw) => raw
                            },
                            {
                                stroke: theme.palette.vitals.o2,
                                label: translate("O2Flow"),
                                width: 3,
                                scale: "l/min",
                                value: (self, raw) => raw
                            }
                        ],
                        axes: [
                            {
                                grid: {show: false},
                                labelSize: 75,
                                labelFont: "bold 14px Arial",
                                font: "14px Arial",
                                //space: 40,
                                //size: 75,
                                //color: "red",
                                incrs: [
                                    //60,
                                    //60 * 5,
                                    //60 * 10,
                                    60 * 15,
                                    60 * 30,
                                    60 * 60,
                                    60 * 60 * 2,
                                ],
                                values: [
                                    // tick incr          default           year                             month    day                        hour     min                sec       mode
                                      
                                      //[60,                "{HH}:{mm}",   "\n{YYYY}-{MM}-{DD}",               null,    "\n{YYYY}-{MM}-{DD}",               null,    null,              null,        1],
                                      [60,                "",       "{HH}:{mm}\n{YYYY}-{MM}-{DD}",               null,    "{HH}:{mm}\n{YYYY}-{MM}-{DD}",      "{HH}:{mm}",    "{HH}:{mm}",              null,        1],
                                    ],
                                values: [
                                    // tick incr          default           year                             month    day                        hour     min                sec       mode
                                      
                                      //[60,                "{HH}:{mm}",   "\n{YYYY}-{MM}-{DD}",               null,    "\n{YYYY}-{MM}-{DD}",               null,    null,              null,        1],
                                      [60,                "",       "{HH}:{mm}\n{YYYY}-{MM}-{DD}",               null,    "{HH}:{mm}\n{YYYY}-{MM}-{DD}",      "{HH}:{mm}",    "{HH}:{mm}",              null,        1],
                                    ],
                            },
                            {
                                label: (translate("SpO2") + " [%]"),
                                labelGap: 20,
                                grid: {
                                    show: true},
                                show:props.width>500?true:false,
                                labelSize: 50,
                                labelFont: "bold 12px Arial",
                                font: "14px Arial",
                                gap: 5,
                                size: 10,
                                scale: "%",
                                stroke: theme.palette.vitals.spo2,
                                // values: (self, ticks) => ticks.map(rawValue => rawValue.toFixed(0) + "%"),
                                values: (self, ticks) => ticks.map(rawValue => rawValue.toFixed(0)),
                                side: 3,
                                ticks: {
                                    show: true,
                                    stroke: theme.palette.vitals.spo2,
                                    width: 2,
                                    dash: [],
                                    size: 5,
                                },
                                border: {
                                    show: true
                                }
                            },
                            {
                                label: (translate("Pulse") + " [bpm]"),
                                labelGap: 60,
                                grid: {
                                    show: false},
                                show:props.width>500?true:false,
                                labelSize: 75,
                                labelFont: "bold 12px Arial",
                                font: "14px Arial ",
                                gap: 5,
                                size: -20,
                                scale: "bpm",
                                stroke: theme.palette.vitals.pulse,
                                // values: (self, ticks) => ticks.map(rawValue => rawValue.toFixed(0) + " bpm"),
                                values: (self, ticks) => ticks.map(rawValue => rawValue.toFixed(0)),
                                side: 3,
                                ticks: {
                                    show: true,
                                    stroke: theme.palette.vitals.pulse,
                                    width: 2,
                                    dash: [],
                                    size: 5,
                                },
                                incrs: [
                                    10
                                ],
                                border: {
                                    show: true
                                }
                            },
                            {
                                label: (translate("O2Flow") + " [l/min]"),
                                labelGap: 50,
                                grid: {
                                    show: false},
                                show:props.width>500?true:false,
                                labelSize: 75,
                                labelFont: "bold 12px Arial",
                                font: "14px Arial",
                                size: -30,
                                color: "red",
                                scale: "l/min",
                                stroke: theme.palette.vitals.o2,
                                // values: (self, ticks) => ticks.map(rawValue => rawValue.toFixed(0) + " l/min"),
                                values: (self, ticks) => ticks.map(rawValue => rawValue.toFixed(0)),
                                side: 1,
                                ticks: {
                                    show: true,
                                    stroke: theme.palette.vitals.o2,
                                    width: 2,
                                    dash: [],
                                    size: 5,
                                },
                                incrs: [
                                    1
                                ],
                                border: {
                                    show: true
                                }
                            },
                        ],
                        scales: {
                            "%": {
                                auto: false,
                                range: [80, 100],
                            },
                            "bpm": {
                                auto: false,
                                range: [40, 220],
                            },
                            "l/min": {
                                auto: false,
                                range: [0, 15],
                            },
                            "x": {
                                range: (min, max) => [parseInt(startTime.getTime()/1000),parseInt(endTime.getTime()/1000)]
                                // range: [parseInt(startTime.getTime()/1000),parseInt(endTime.getTime()/1000)]
                                // note: use getTime() and dividing by 1000 to convert from DateTime to unix.
                            }
                        },
                        /* legend:{
                            show: true,
                            markers: {
                                fill: [theme.palette.vitals.o2,theme.palette.vitals.o2,theme.palette.vitals.o2],
                            }
                            
                        } */
                        /* cursor: {
                            drag: {
                                setScale: false,
                                x: true,
                                y: false,
                            }
                        }, */
                        /* hooks: {
                            init: [
                                u => {
                                    u.over.ondblclick = e => {
                                        console.log("Fetching data for full range");
        
                                        u.setData(props.data);
                                    }
                                }
                            ],
                            setSelect: [
                                u => {
                                    if (u.select.width > 0) {
                                        let min = u.posToVal(u.select.left, 'x');
                                        let max = u.posToVal(u.select.left + u.select.width, 'x');
        
                                        console.log("Fetching data for range...", {min, max});
        
                                        // set new data
                                        u.setData([
                                            [ 3, 4, 5, 6],
                                            [30,23,35,27],
                                        ], false);
        
                                        // zoom to selection
                                        u.setScale('x', {min, max});
        
                                        // reset selection
                                        u.setSelect({width: 0, height: 0}, false);
                                    }
                                }
                            ]
                        } */
                        
                    }}
                    data={props.data}
                />
                <Typography variant='body'> <Box component="span" fontWeight='fontWeightMedium'><Translate value="ImportantNote"/>:</Box> <Translate value="DetailedDataDisclaimerChart"/></Typography>
            </Box>
    
        );
        
    }

    // if (xaxisRange != "compact") { // for live data chart
    //     var startTime = new Date(props.data[0].at(-1)*1000); // note: you have to multiply by 1000 to get unix to be convertable to a DateTime. You then have to divide by 1000 again if your want to convert back to unix.
    //     startTime.setSeconds(startTime.getSeconds()-parseInt(xaxisRange*60));

    //     return (
    //         <Box sx={{ width:"100%", display: "flex", flexDirection: "column" }}>
    //             <Typography>{translate("DetailedSessionDescription")}</Typography>
    //             <UplotReact
    //                 options={{
    //                     gridWidth: 0, // set the grid width to 0
    //                     gridColor: null, // set the grid color to null
    //                     height: props.width/1.7,
    //                     width: 900,
    //                     series: [
    //                         {
    //                             label: translate("Time"),
    //                             value: "{YYYY}-{MM}-{DD} {HH}:{mm}"
    //                         },
    //                         {
    //                             stroke: theme.palette.vitals.spo2,
    //                             label: translate("SpO2"),
    //                             width: 3,
    //                             scale: "%",
    //                             value: (self, raw) => raw
    //                         },
    //                         {
    //                             stroke: theme.palette.vitals.pulse,
    //                             label: translate("Pulse"),
    //                             width: 3,
    //                             scale: "bpm",
    //                             value: (self, raw) => raw
    //                         },
    //                         {
    //                             stroke: theme.palette.vitals.o2,
    //                             label: translate("O2Flow"),
    //                             width: 3,
    //                             scale: "l/min",
    //                             value: (self, raw) => raw
    //                         }
    //                     ],
    //                     axes: [
    //                         {
    //                             grid: {show: false},
    //                             labelSize: 75,
    //                             labelFont: "bold 14px Arial",
    //                             font: "14px Arial",
    //                             //space: 40,
    //                             //size: 75,
    //                             //color: "red",
    //                             incrs: [
    //                                 60,
    //                                 60 * 5,
    //                                 60 * 10,
    //                                 60 * 15,
    //                                 60 * 30,
    //                                 // day divisors
    //                                 3600,
    //                             ],
    //                             values: [
    //                                 // tick incr          default           year                             month    day                        hour     min                sec       mode
                                      
    //                                   [60,                "{HH}:{mm}",   "\n{YYYY}-{MM}-{DD}",               null,    "\n{YYYY}-{MM}-{DD}",               null,    null,              null,        1],
    //                                 ],
    //                             values: [
    //                                 // tick incr          default           year                             month    day                        hour     min                sec       mode
                                      
    //                                   [60,                "{HH}:{mm}",   "\n{YYYY}-{MM}-{DD}",               null,    "\n{YYYY}-{MM}-{DD}",               null,    null,              null,        1],
    //                                 ],
    //                         },
    //                         {
    //                             label: (translate("SpO2") + " [%]"),
    //                             labelGap: 20,
    //                             grid: {
    //                                 show: true},
    //                             show:props.width>500?true:false,
    //                             labelSize: 50,
    //                             labelFont: "bold 12px Arial",
    //                             font: "14px Arial",
    //                             gap: 5,
    //                             size: 10,
    //                             scale: "%",
    //                             stroke: theme.palette.vitals.spo2,
    //                             // values: (self, ticks) => ticks.map(rawValue => rawValue.toFixed(0) + "%"),
    //                             values: (self, ticks) => ticks.map(rawValue => rawValue.toFixed(0)),
    //                             side: 3,
    //                             ticks: {
    //                                 show: true,
    //                                 stroke: theme.palette.vitals.spo2,
    //                                 width: 2,
    //                                 dash: [],
    //                                 size: 5,
    //                             },
    //                             border: {
    //                                 show: true
    //                             }
    //                         },
    //                         {
    //                             label: (translate("Pulse") + " [bpm]"),
    //                             labelGap: 60,
    //                             grid: {
    //                                 show: false},
    //                             show:props.width>500?true:false,
    //                             labelSize: 75,
    //                             labelFont: "bold 12px Arial",
    //                             font: "14px Arial ",
    //                             gap: 5,
    //                             size: -20,
    //                             scale: "bpm",
    //                             stroke: theme.palette.vitals.pulse,
    //                             // values: (self, ticks) => ticks.map(rawValue => rawValue.toFixed(0) + " bpm"),
    //                             values: (self, ticks) => ticks.map(rawValue => rawValue.toFixed(0)),
    //                             side: 3,
    //                             ticks: {
    //                                 show: true,
    //                                 stroke: theme.palette.vitals.pulse,
    //                                 width: 2,
    //                                 dash: [],
    //                                 size: 5,
    //                             },
    //                             incrs: [
    //                                 10
    //                             ],
    //                             border: {
    //                                 show: true
    //                             }
    //                         },
    //                         {
    //                             label: (translate("O2Flow") + " [l/min]"),
    //                             labelGap: 50,
    //                             grid: {
    //                                 show: false},
    //                             show:props.width>500?true:false,
    //                             labelSize: 75,
    //                             labelFont: "bold 12px Arial",
    //                             font: "14px Arial",
    //                             size: -30,
    //                             color: "red",
    //                             scale: "l/min",
    //                             stroke: theme.palette.vitals.o2,
    //                             // values: (self, ticks) => ticks.map(rawValue => rawValue.toFixed(0) + " l/min"),
    //                             values: (self, ticks) => ticks.map(rawValue => rawValue.toFixed(0)),
    //                             side: 1,
    //                             ticks: {
    //                                 show: true,
    //                                 stroke: theme.palette.vitals.o2,
    //                                 width: 2,
    //                                 dash: [],
    //                                 size: 5,
    //                             },
    //                             incrs: [
    //                                 1
    //                             ],
    //                             border: {
    //                                 show: true
    //                             }
    //                         },
    //                     ],
    //                     scales: {
    //                         "%": {
    //                             auto: false,
    //                             range: [80, 100],
    //                         },
    //                         "bpm": {
    //                             auto: false,
    //                             range: [40, 220],
    //                         },
    //                         "l/min": {
    //                             auto: false,
    //                             range: [0, 15],
    //                         },
    //                         "x": {
    //                             range: (min, max) => [parseInt(startTime.getTime()/1000),parseInt(endTime.getTime()/1000)]
    //                             // range: [parseInt(startTime.getTime()/1000),parseInt(endTime.getTime()/1000)]
    //                             // note: use getTime() and dividing by 1000 to convert from DateTime to unix.
    //                         }
    //                     },
    //                     /* legend:{
    //                         show: true,
    //                         markers: {
    //                             fill: [theme.palette.vitals.o2,theme.palette.vitals.o2,theme.palette.vitals.o2],
    //                         }
                            
    //                     } */
    //                     /* cursor: {
    //                         drag: {
    //                             setScale: false,
    //                             x: true,
    //                             y: false,
    //                         }
    //                     }, */
    //                     /* hooks: {
    //                         init: [
    //                             u => {
    //                                 u.over.ondblclick = e => {
    //                                     console.log("Fetching data for full range");
        
    //                                     u.setData(props.data);
    //                                 }
    //                             }
    //                         ],
    //                         setSelect: [
    //                             u => {
    //                                 if (u.select.width > 0) {
    //                                     let min = u.posToVal(u.select.left, 'x');
    //                                     let max = u.posToVal(u.select.left + u.select.width, 'x');
        
    //                                     console.log("Fetching data for range...", {min, max});
        
    //                                     // set new data
    //                                     u.setData([
    //                                         [ 3, 4, 5, 6],
    //                                         [30,23,35,27],
    //                                     ], false);
        
    //                                     // zoom to selection
    //                                     u.setScale('x', {min, max});
        
    //                                     // reset selection
    //                                     u.setSelect({width: 0, height: 0}, false);
    //                                 }
    //                             }
    //                         ]
    //                     } */
                        
    //                 }}
    //                 data={props.data}
    //             />
    //         </Box>
    
    //     );

    // } else {
    //     return (
    //         <Box sx={{ml:-1,  width:"100%", display: "flex", flexDirection: "column" }}>
    //             <Typography sx={{ml:1}}>{translate("DetailedSessionDescription")}</Typography>
    //             <UplotReact
    //                 options={{
    //                     gridWidth: 0, // set the grid width to 0
    //                     gridColor: null, // set the grid color to null
    //                     height: props.width/1.7,
    //                     width: 790,
    //                     series: [
    //                         {
    //                             label: translate("Time"),
    //                             value: "{YYYY}-{MM}-{DD} {HH}:{mm}"
    //                         },
    //                         {
    //                             stroke: theme.palette.vitals.spo2,
    //                             label: translate("SpO2"),
    //                             width: 3,
    //                             scale: "%",
    //                             value: (self, raw) => raw
    //                         },
    //                         {
    //                             stroke: theme.palette.vitals.pulse,
    //                             label: translate("Pulse"),
    //                             width: 3,
    //                             scale: "bpm",
    //                             value: (self, raw) => raw
    //                         },
    //                         {
    //                             stroke: theme.palette.vitals.o2,
    //                             label: translate("O2Flow"),
    //                             width: 3,
    //                             scale: "l/min",
    //                             value: (self, raw) => raw
    //                         }
    //                     ],
    //                     axes: [
    //                         {
    //                             grid: {show: false},
    //                             labelSize: 75,
    //                             labelFont: "bold 14px Arial",
    //                             font: "14px Arial",
    //                             space: 40,
    //                             size: 75,
    //                             color: "red",
    //                             incrs: [
    //                                 60,
    //                                 60 * 5,
    //                                 60 * 10,
    //                                 60 * 15,
    //                                 60 * 30,
    //                                 // day divisors
    //                                 3600,
    //                             ],
    //                             values: [
    //                                 // tick incr          default           year                             month    day                        hour     min                sec       mode
                                      
    //                                   [60,                "{HH}:{mm}",   "\n{YYYY}-{MM}-{DD}",               null,    "\n{YYYY}-{MM}-{DD}",               null,    null,              null,        1],
    //                                 ]
    //                         },
    //                         {
    //                             label: (translate("SpO2") + " [%]"),
    //                             labelGap: 20,
    //                             grid: {
    //                                 show: true},
    //                             show:props.width>500?true:false,
    //                             labelSize: 50,
    //                             labelFont: "bold 12px Arial",
    //                             font: "14px Arial",
    //                             gap: 5,
    //                             size: 10,
    //                             scale: "%",
    //                             stroke: theme.palette.vitals.spo2,
    //                             // values: (self, ticks) => ticks.map(rawValue => rawValue.toFixed(0) + "%"),
    //                             values: (self, ticks) => ticks.map(rawValue => rawValue.toFixed(0)),
    //                             side: 3,
    //                             ticks: {
    //                                 show: true,
    //                                 stroke: theme.palette.vitals.spo2,
    //                                 width: 2,
    //                                 dash: [],
    //                                 size: 5,
    //                             },
    //                             border: {
    //                                 show: true
    //                             }
    //                         },
    //                         {
    //                             label: (translate("Pulse") + " [" + translate("bpm") + "]"),
    //                             labelGap: 60,
    //                             grid: {
    //                                 show: false},
    //                             show:props.width>500?true:false,
    //                             labelSize: 75,
    //                             labelFont: "bold 12px Arial",
    //                             font: "14px Arial ",
    //                             gap: 5,
    //                             size: -20,
    //                             scale: "bpm",
    //                             stroke: theme.palette.vitals.pulse,
    //                             // values: (self, ticks) => ticks.map(rawValue => rawValue.toFixed(0) + " bpm"),
    //                             values: (self, ticks) => ticks.map(rawValue => rawValue.toFixed(0)),
    //                             side: 3,
    //                             ticks: {
    //                                 show: true,
    //                                 stroke: theme.palette.vitals.pulse,
    //                                 width: 2,
    //                                 dash: [],
    //                                 size: 5,
    //                             },
    //                             incrs: [
    //                                 10
    //                             ],
    //                             border: {
    //                                 show: true
    //                             }
    //                         },
    //                         {
    //                             label: (translate("O2Flow") + " [" + translate("l/min") + "]"),
    //                             labelGap: 50,
    //                             grid: {
    //                                 show: false},
    //                             show:props.width>500?true:false,
    //                             labelSize: 75,
    //                             labelFont: "bold 12px Arial",
    //                             font: "14px Arial",
    //                             size: -30,
    //                             color: "red",
    //                             scale: "l/min",
    //                             stroke: theme.palette.vitals.o2,
    //                             // values: (self, ticks) => ticks.map(rawValue => rawValue.toFixed(0) + " l/min"),
    //                             values: (self, ticks) => ticks.map(rawValue => rawValue.toFixed(0)),
    //                             side: 1,
    //                             ticks: {
    //                                 show: true,
    //                                 stroke: theme.palette.vitals.o2,
    //                                 width: 2,
    //                                 dash: [],
    //                                 size: 5,
    //                             },
    //                             incrs: [
    //                                 1
    //                             ],
    //                             border: {
    //                                 show: true
    //                             }
    //                         },
    //                     ],
    //                     scales: {
    //                         "%": {
    //                             auto: false,
    //                             range: [80, 100],
    //                         },
    //                         "bpm": {
    //                             auto: false,
    //                             range: [40, 220],
    //                         },
    //                         "l/min": {
    //                             auto: false,
    //                             range: [0, 15],
    //                         }
    //                     }
                        
    //                 }}
    //                 data={props.data}
    //             />
    //         </Box>
    
    //     );
    // }

    


    
};