import React, { useState, useEffect } from 'react'


import { useParams, Navigate, useNavigate } from 'react-router-dom';
import LocationForm from '../../components/Location/LocationForm';

import { PageDetailText } from "../../components/PageDetailText";

import PageContainer from '../../components/PageContainer';
import { PageHeader } from '../../components/Layout/PageHeader';
import { Box } from '@mui/system';
import { LocationService } from "../../services/DataService";
import TreatmentControls from '../../components/Treatment/TreatmentControls';
import { Typography, Button, FormControl, TextField, InputLabel, Select, MenuItem, FormGroup, FormControlLabel, Switch, Slider } from '@mui/material'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { ConfirmDialog } from '../../components/Layout/Dialog/ConfirmDialog';
import { Translate, Localize } from 'react-i18nify';

const Location = (props) => {

    
    const [location, setLocation] = React.useState(null);
    const { id } = useParams();
    const [redirect, setRedirect] = useState(false);

    const [parentLocation, setParentLocation] = useState();
    const [address, setAddress] = useState();
    const [locationName, setLocationName] = useState();
    const [country, setCountry] = useState();



    const navigate = useNavigate();
    useEffect(() => {
        (async () => {
            let apiResult = await LocationService.get(id);
            setLocation(apiResult.data);
        })();
    }, [])






    return (
        <>
            {redirect ? <Navigate to='/location' /> : null}

            <PageContainer>
                <PageHeader leftAlign><Translate value="EditLocation" /></PageHeader>
                {location?<LocationForm location = {location}/>:null}
            </PageContainer>

        </>
    )
}

export default Location