import { Box, Button, Divider, Typography } from "@mui/material";
import { Translate } from "react-i18nify";
import { useNavigate } from "react-router-dom";
import { RequireRole } from "../../utils/RequireRole";
export const ProductionStep = (props) => {
  const navigate = useNavigate();

  const onOpenClicked = () => {
    navigate(props.redirect);
  };
  return (
    <Box sx={{ display: "flex", flexDirection: "row"}}>
        <Divider sx={{height:"50px",mr:2}} orientation="vertical" flexItem />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          minWidth: "300px",
          minHeight: "75px",
          flexGrow: 1
        }}
      >
        <Typography variant="h5">{props.label}</Typography>
        <Typography variant="body">
          {props.pending} <Translate value="DevicePending" />
        </Typography>
      </Box>      
      <RequireRole authorize={props.authorize}>
      <Button
        onClick={onOpenClicked}
        sx={{ mt: 1, height: "40px" }}
        color="primary"
        disabled={props.pending == 0}
        variant="contained"
      >
        <Translate value="Open" />
      </Button>
      </RequireRole>
    </Box>
  );
};
