import PageContainer from '../components/PageContainer';
import { Box } from '@mui/material';
import { Typography } from '@mui/material';
import { Translate, Localize } from 'react-i18nify';


import { Link } from "react-router-dom"

const UnAuthorized = () => {
    return (
        <PageContainer
            maxWidth="xs"
            display="flex"
            padding="0"
            alignItems="center"
        >
            <Box>
                <Typography
                    sx={{
                        fontSize: 55,
                    }}
                    variant="h4">
                    401
                </Typography>

                <Typography
                    sx={{
                        fontSize: 25,
                    }}
                    variant="h4">
                    <Translate value="UnauthorizedPage" />

                </Typography>

                <Typography sx={{
                    pt: 1,
                    fontSize: 15,
                }} component="h1" variant="h5" fontWeight='light'>
                    <Translate value="GoBackTo" /> <Link to="/"><Translate value="HomePage" /></Link>
                </Typography>
            </Box>
        </PageContainer>
    );
}
export default UnAuthorized;