import React, { useState, useEffect } from 'react'
import PageContainer from '../../components/PageContainer'
import { Link } from 'react-router-dom'
import { PageHeader } from '../../components/Layout/PageHeader'
import { Box } from '@mui/system'
import { Typography, Stack, Button, FormControl, TextField, InputLabel, Select, MenuItem, FormGroup, FormControlLabel, Switch } from '@mui/material'
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { DataGrid } from '@mui/x-data-grid';
import { useNavigate } from 'react-router-dom'
import { TreatmentService } from '../../services/DataService'
import AddIcon from '@mui/icons-material/Add';
import { Translate, Localize, translate} from 'react-i18nify';


export const Treatments = () => {

    const [treatments, setTreatments] = useState([]);
    const [loading, setLoading] = useState(true);

    const navigate = useNavigate();
    useEffect(() => {
        (async () => {
            let apiResult = await TreatmentService.getAll();
            setTreatments(apiResult.data);
            setLoading(false)
        })();
    }, [])
    const goToTreatmentDetails = (params, event) => {
        let row = params.row;
        let id = row.id;
        let path = "/treatment/" + id;
        navigate(path);
    };
    const navigateCreate = () => {
        // 👇︝ navigate to /
        navigate('/treatment/create');
    };

    const columns = [

        {
            field: 'id', headerName: translate("Id"), flex: 0
        },
        {
            field: 'name', headerName: translate("Name"), flex: 1
        },
        {
            field: 'location', headerName: translate("Location"), flex: 1,
            valueGetter: (x) => {
                return x.row.location?.name;
            }
        },
        {
            field: 'disabled', headerName: translate("Status"), flex: 1,
            valueGetter: (x) => {
                if (x.row.disabled){
                    //return x.row.disabled;
                    return translate("Disabled");
                }
                return translate("Enabled");


            }
        }
    ];

    return (
        <PageContainer>
            <PageHeader leftAlign><Translate value="TreatmentProfiles" /></PageHeader>
            <Button onClick={navigateCreate} sx={{ mt: 2, minWidth: '200px', alignSelf: "start" }} variant="contained" startIcon={<AddIcon />} ><Translate value="AddTreatmentProfile" /></Button>
            <Box sx={{ width: { xs: "100%", sm: "100%", md: "100%", lg: "1000px" }, maxWidth: {md: "1000px"} }}>
            <DataGrid
                ColumnWidth="*"
                sx={{ width: { xs: "100%", sm: "100%", md: "100%", lg: "1000px" }, maxWidth: {md: "1000px"}, minHeight: "640px", mt: 2 }}
                columns={columns}
                rows={treatments}
                pageSize={50}
                rowsPerPageOptions={[50]}
                onRowClick={goToTreatmentDetails}
                loading={loading}
                components={{
                    NoRowsOverlay: () => (
                      <Stack height="100%" alignItems="center" justifyContent="center">
                        <Translate value="NoRowsTreatments" />
                      </Stack>
                    ),
                    NoResultsOverlay: () => (
                        <Stack height="100%" alignItems="center" justifyContent="center">
                          <Translate value="NoResults" />
                        </Stack>
                    )
                }}
                />
            </Box>

        </PageContainer>
    )
}
