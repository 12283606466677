
import React, { useState, useEffect } from 'react'
import { useParams,useNavigate } from 'react-router-dom';
import PageContainer from '../../components/PageContainer';
import { PageHeader } from '../../components/Layout/PageHeader';
import { Typography} from '@mui/material'
import { PatientService } from '../../services/DataService';
import PatientForm from '../../components/Patient/PatientForm';
import { Translate, Localize } from 'react-i18nify';
import {UpdatesBanner} from "../../components/UpdatesBanner";


const PatientEdit = props => {
    const { id } = useParams();
    const [patient, setPatient] = React.useState();

    useEffect(() => {
        (async () => {
            let apiResult = await PatientService.get(id);
            setPatient(apiResult.data);
        })();
    }, [])

    return (
        <PageContainer>


            <PageHeader leftAlign><Translate value="EditPatient" /></PageHeader>
            <UpdatesBanner update_description="UpdatesDescriptionPatientEditPage"/>
            <Typography sx={{ mt: 2, mb: 2, minWidth:"200px"}} variant="body">
            <Translate value="EditPatientText" />
            
            </Typography>   
            {patient?<PatientForm id={id} deletePatientVissibility patient={patient}/>:null}
        </PageContainer>
    )
}
export default PatientEdit