import React, { useState, useEffect } from 'react'
import PageContainer from '../../components/PageContainer'
import { Link } from 'react-router-dom'
import { PageHeader } from '../../components/Layout/PageHeader'
import { Box } from '@mui/system'
import { PatientDetailText } from "../../components/Patient/PatientDetailText";
import { Typography, Button, FormControl, TextField, InputLabel, Select, MenuItem, FormGroup, FormControlLabel, Switch } from '@mui/material'
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { DataGrid } from '@mui/x-data-grid';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import EditIcon from '@mui/icons-material/Edit';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import { EventLogService } from '../../services/DataService'
import { LogService } from '../../services/DataService'
import { UserService, DeviceService } from '../../services/DataService'
import { Translate, Localize, translate } from 'react-i18nify';
import { useParams, useNavigate } from 'react-router-dom';
import { SystemDetailPopUp } from '../../components/SystemDetailPopUp'
import TimeUtils from '../../utils/TimeUtils'
import PatientDashboardCategory from "../../components/Patient/PatientDashboardCategory";
import {UpdatesBanner} from "../../components/UpdatesBanner";

export const HotDevice = () => {


    const [device, setDevice] = useState([]);
    const [eventLogs, setEventLogs] = useState([]);
    const [logs, setLogs] = useState([]);
    const { udsn } = useParams();
    const navigate = useNavigate();

    const [systemLogOpen, setSystemLogOpen] = useState(false)
    const [systemLogData, setSystemLogData] = useState()

    const [userRoleLocations, setUserRoleLocations] = useState([]);
    const [isO2maticDeviceManager, setIsO2maticDeviceManager] = useState(false);

    const openSystemLog = (systemLogData) => {
        setSystemLogData(systemLogData)
        setSystemLogOpen(true)
    }
    const closeSystemLog = () => {
        setSystemLogOpen(false)
    }

    useEffect(() => {
        (async () => {
            await UserService.getActiveUserRolesLocations()
            .then((data) => {
                setUserRoleLocations(data.data);
            }).catch((error) => {
                console.log(error);
            })
        })();
    }, [])

    // get locations of user with repairer role
    useEffect(() => {
        let isO2maticDeviceManager = false;
        if(userRoleLocations.length !== 0){
            userRoleLocations.forEach(role => {
                if(role.roleName === "DeviceManager" && role.locationId === 21){
                    isO2maticDeviceManager = true;
                }
            })
        }
        setIsO2maticDeviceManager(isO2maticDeviceManager);
    },[userRoleLocations])

    useEffect(() => {
        (async () => {
            let displayedDeviceLogEvents = [3,4,125,210,211,230,231,232,240,241,242,250,251,290,301,302,303,304,305,306,307,308,309,310,311,320,330,331,332,340,402,404,405,406]
            // let eventTypesResult = await EventLogService.getEventTypes();
            let deviceResult = await DeviceService.getHotByUdsn(udsn);
            let logResult = await LogService.getDeviceLogs(udsn);
            let eventLogResult = await EventLogService.getWithAllParameters(udsn, null, null, displayedDeviceLogEvents, false);
            // setEventTypes(eventTypesResult.data)
            setDevice(deviceResult.data);
            setEventLogs(eventLogResult.data); // device logs
            setLogs(logResult.data); // system logs
        })();
    }, [])


    const handleEdit = () => {
        navigate("/device/HOT/" + udsn + "/edit", { replace: true });
    }

    const columns = [
        {
            field: 'eventId', headerName: translate('Id'), minWidth:100,

        },

        {
            field: 'eventType', headerName: translate('EventType'), flex: 1,
            valueGetter: (log) => {
                return log.row.eventtype.name
            }
        },

        {
            field: 'timestamp', headerName: translate('Timestamp'), flex: 1,
            /* valueGetter: (log) => {
                var parts = new Date(log.row.timestamp).toISOString().split("T")
                return parts[0] + " " + parts[1].slice(0, 8);
            } */
            valueGetter: (log) => {
                return TimeUtils.utcToLocal(log.row.timestamp, true)
            }
        },
        {
            field: 'severity', headerName: translate('Severity'), minWidth:150,
            valueGetter: (log) => {
                return log.row.eventtype.severity;

            }
        },
        {
            field: 'parameter', headerName: translate('Parameter'), flex: 1,
            valueGetter: (log) => {
                if(!log.row.parameterDescription || log.row.parameterDescription === ""){
                    return ""
                }else{
                    return "" + log.row.parameterDescription + "";
                }
                // var parameter = log.row.parameter;
                // return parameter == 0 ? "Off" : "On";

            }
        },

    ];

    const logColumns = [
        {
            field: 'user', headerName: translate('User'), minWidth:150,            
            valueGetter: (params) => {
                return(`${params?.value?.name} (${params?.value?.id})`)
            },

        },
        {
            field: 'logAction', headerName: translate('Action'), minWidth:100,

        },
        {
            field: 'timestamp', headerName: translate('Timestamp'), minWidth: 150,
            valueGetter: (params) => {
                try {
                    let oldDate = params.value
                    let addUtc = TimeUtils.addUTCTimezone(oldDate)
                    return TimeUtils.formatDate(addUtc)
                }
                catch (e) {
                    return params.value
                }
            },

        },
        {
            field: 'verbose', headerName: translate('Description'), flex: 1,

        }


    ];

    return (
        <PageContainer>
            <PageHeader leftAlign><Translate value="HotDeviceDetails" /></PageHeader>

            <UpdatesBanner update_description="UpdatesDescriptionHOTPage"/>

            <Box sx={{width: { xs: "100%", sm: "100%", md: "100%", lg: "1000px" }, maxWidth: {md: "1000px"}}}>

                <Box sx={{ width:"100%"-"30px", mt: 1, borderRadius: "15px", border: 1, borderColor: "primary.main", bgcolor: "primary.main", color: "primary.contrastText", p: 2, display: "flex", justifyContent: 'space-between', flexDirection: { xs: "column", sm: "column", md: "row", lg: "row" }}}>
            
                    <Box sx={{flexGrow: 1, display: 'flex', flexDirection: 'column'}}>
                        <Typography variant="body">
                            {<Translate value="DeviceSerialNumber" />}
                        </Typography>
                        <PatientDashboardCategory
                            title={device?.udsn? device.udsn : "-"}
                        >
                        </PatientDashboardCategory>

                        <Box sx={{mt: 2}}>
                            <PatientDetailText
                                label={<Translate value="Id" />}
                                value={device?.id? device.id : "-" }
                            ></PatientDetailText>
                            <PatientDetailText
                                label={<Translate value="UDSN" />}
                                value={device?.udsn? device.udsn : "-" }
                            ></PatientDetailText>
                            <PatientDetailText
                                label={<Translate value="PUID" />}
                                value={device?.puid? device.puid : "-"}
                            ></PatientDetailText>
                            <PatientDetailText
                                label={<Translate value="MAC" />}
                                value={device?.mac? device.mac : "-" }
                            ></PatientDetailText>
                        </Box>

                        <Box sx={{mt: 2, flexGrow: 1}}>
                            <PatientDetailText
                                label={<Translate value="Status" />}
                                value={device?.status?.value? <Translate value={device.status.value}/> : "-" }
                            ></PatientDetailText>
                            <PatientDetailText
                                label={<Translate value="DeviceOwner" />}
                                value={device?.owner?.name? device.owner.name : "-"}
                            ></PatientDetailText>
                            <PatientDetailText
                                label={<Translate value="DeviceLocation" />}
                                value={device?.location?.name? device.location.name : "-"}
                            ></PatientDetailText>
                        </Box>

                        <Button onClick={handleEdit} sx={{ mt: 2 , width:"200px", }} color="secondary" variant="contained" startIcon={<EditIcon />}>
                            <Translate value="EditDevice" />
                        </Button>
                    </Box>

                    <Box component="img" sx={{
                    width: { xs: "0%", sm: "0%", md: "30%", lg: "30%" },
                    p: { md: 5 , lg: 5}
                    }} src="../HOT.svg" />

                </Box>

            </Box>

            {/* <Box sx={{ width:{xs:"50%", sm:"400px"}}}>

                
                <PatientDetailText
                    label={<Translate value="Id" />}
                    value={device.id}
                ></PatientDetailText>
                <PatientDetailText
                    label={<Translate value="UDSN" />}
                    value={device.udsn}
                ></PatientDetailText>
                <PatientDetailText
                    label={<Translate value="PUID" />}
                    value={device.puid}
                ></PatientDetailText>
                <PatientDetailText
                    label={<Translate value="MAC" />}
                    value={device.mac}
                ></PatientDetailText>
                <PatientDetailText
                    label={<Translate value="DeviceOwner" />}
                    value={device.owner?.name}
                ></PatientDetailText>
                <PatientDetailText
                    label={<Translate value="DeviceLocation" />}
                    value={device.location?.name}
                ></PatientDetailText>

            </Box> */}

            <Accordion sx={{ width: { xs: "100%", sm: "100%", md: "100%", lg: "1000px" }, maxWidth: {md: "1000px"}, mt: 2 }}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography variant="h5"><Translate value="DeviceLog" /></Typography>
                </AccordionSummary>

                <AccordionDetails>
                    <DataGrid
                        ColumnWidth="*"
                        sx={{ height: 480, mt: 2 }}
                        columns={columns}
                        rows={eventLogs}
                        pageSize={100}
                        rowsPerPageOptions={[100]}
                    />
                </AccordionDetails>
            </Accordion>

            {isO2maticDeviceManager?
            <Accordion sx={{ width: { xs: "100%", sm: "100%", md: "100%", lg: "1000px" }, maxWidth: {md: "1000px"} }}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography variant="h5"><Translate value="SystemLog" /></Typography>
                </AccordionSummary>

                <AccordionDetails>
                    <DataGrid
                        ColumnWidth="*"
                        sx={{ height: 480, mt: 2 }}
                        columns={logColumns}
                        rows={logs}
                        onRowClick={
                            (params, event, detail) => {
                                openSystemLog(params.row)
                            }
                        }
                        pageSize={100}
                        rowsPerPageOptions={[100]}
                    />
                </AccordionDetails>
            </Accordion>
            : null }
            <SystemDetailPopUp systemlog={systemLogData} onClose={closeSystemLog} open={systemLogOpen} />
        </PageContainer>
    )
}
