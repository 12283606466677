import { Dialog } from '@material-ui/core'
import CloseIcon from '@mui/icons-material/Close';
import HelpIcon from '@mui/icons-material/Help';
import React, { useState } from 'react'
import { Box } from '@mui/system'
const FormHelperDialogWrapper = (props) => {
    const [open, setOpen] = useState(false);


    const toggleHelp = () => {
        setOpen(old => !old);
    };
    const closeHelp = () => {
        setOpen(false);
    };

    const helpIconTheme = {
		//mr: {md:3, xs: 28},
		color: open ? "primary.dark" : "neutral.800", 
		width:"20px",
        mr: 1
	}

    
    return (
        <Box sx={{ position: "relative", display: "flex" }}>    
            {props.children}
            <Box sx={{ position: "absolute", right: -35 }}>
                <HelpIcon  onClick={toggleHelp} sx={helpIconTheme} />
            </Box>
            <Box sx={{ position: "absolute",right:-35, width: "250px", display: open ? "flex" : "none" }}>
                    <Box sx={{ p: 2, zIndex: 10, position: "absolute", backgroundColor: "primary.main" }}>
                        <CloseIcon onClick={closeHelp} sx={{ position: "absolute", left: 3, top: 3 }} />
                        <Box sx={{mt:1}}>
                            {props.text}
                        </Box>
                </Box>
            </Box>
        </Box>
    )
}

export default FormHelperDialogWrapper
