import React, { useState, useEffect } from 'react'
import { Box } from '@mui/system';
import { Typography, Button, FormControl, TextField, InputLabel, Select, MenuItem, FormGroup, FormControlLabel, Switch, Slider } from '@mui/material'
import { Translate, Localize, translate, getLocale,setLocale } from 'react-i18nify';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

const LanguageSelector = (props) => {


    let currentLocale = getLocale();
    const [selectedLanguge, setSelectedLanguage] = useState(currentLocale ? currentLocale : "en")


    let languages =
        [
            {
                code: "en",
                name: "English",
                flag: "en"
            },
            {
                code: "da",
                name: "Dansk",
                flag: "da"
            },
            {
                code: "es",
                name: "Español",
                flag: "es"
            }
        ]
    const handleChangeLanguage = (event, newValue) => {
        setSelectedLanguage(event.target.value);
        setLocale(event.target.value);
        localStorage.setItem("language",event.target.value)
    }

    const renderFlag = (flag) => {
        return (<img
            src={`${process.env.PUBLIC_URL + "/flags/" + flag}.png`}
            alt={flag}
            loading="lazy"
            width="25px"
            heigth="25px"
        />)
    }
    const getLanguageByCode = (code) => {
        return languages.find(language => language.code === code)
    }


    return (
        
        <Box sx={{marginRight:"15px", mb: 0.5, }}>
            <Select
                sx={{
                    boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: 0 },
                    
                    color: "white",
                    
                }}
                value={selectedLanguge}
                id="languageSettings"
                onChange={handleChangeLanguage}
                renderValue={(value) => {
                    return (
                        <Box sx={{ display: "flex", height: "100%", justifyContent: "start", alignItems: "center", gap: 1 , fontSize:15, color: process.env.REACT_APP_TEXT_MENU_COLOR}}>
                            {renderFlag(value)}
                            {getLanguageByCode(value)?.name}
                        </Box>
                    );
                }}
            >
                {languages.map(language => {
                    return (
                        <MenuItem key={language.code} sx={{}} value={language.code}>
                            {renderFlag(language.flag)}{language.name}</MenuItem>)
                })}
            </Select>
        </Box>

    )
}
export default LanguageSelector;