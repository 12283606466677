import React, { useState, useEffect } from 'react'
import PageContainer from '../../components/PageContainer'
import { Link } from 'react-router-dom'
import { PageHeader } from '../../components/Layout/PageHeader'
import { Box } from '@mui/system'
import { Typography, Button, FormControl, TextField, InputLabel, Select, MenuItem, FormGroup, FormControlLabel, Switch } from '@mui/material'
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { DataGrid } from '@mui/x-data-grid';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Accordion from '@mui/material/Accordion';
import EditIcon from '@mui/icons-material/Edit';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import { EventLogService } from '../../services/DataService'
import { UserService, DeviceService, LogService } from '../../services/DataService'
import { Translate, Localize, translate } from 'react-i18nify';
import { useParams, useNavigate } from 'react-router-dom';
import { SystemDetailPopUp } from '../../components/SystemDetailPopUp'
import { PatientDetailText } from "../../components/Patient/PatientDetailText";
import TimeUtils from '../../utils/TimeUtils'
import PatientDashboardCategory from "../../components/Patient/PatientDashboardCategory";
import {UpdatesBanner} from "../../components/UpdatesBanner";

export const ProDevice = () => {


    const [device, setDevice] = useState([]);
    const [logs, setLogs] = useState([]);
    const { udsn } = useParams();
    const navigate = useNavigate();

    const [systemLogOpen, setSystemLogOpen] = useState(false)
    const [systemLogData, setSystemLogData] = useState()

    const [userRoleLocations, setUserRoleLocations] = useState([]);
    const [isO2maticDeviceManager, setIsO2maticDeviceManager] = useState(false);

    const openSystemLog = (systemLogData) => {
        setSystemLogData(systemLogData)
        setSystemLogOpen(true)
    }
    const closeSystemLog = () => {
        setSystemLogOpen(false)
    }

    useEffect(() => {
        (async () => {
            await UserService.getActiveUserRolesLocations()
            .then((data) => {
                setUserRoleLocations(data.data);
            }).catch((error) => {
                console.log(error);
            })
        })();
    }, [])

    // get locations of user with repairer role
    useEffect(() => {
        let isO2maticDeviceManager = false;
        if(userRoleLocations.length !== 0){
            userRoleLocations.forEach(role => {
                if(role.roleName === "DeviceManager" && role.locationId === 21){
                    isO2maticDeviceManager = true;
                }
            })
        }
        setIsO2maticDeviceManager(isO2maticDeviceManager);
    },[userRoleLocations])


    useEffect(() => {
        (async () => {
            let deviceResult = await DeviceService.getProByUdsn(udsn);
            let logResult = await LogService.getDeviceLogs(udsn);
            setDevice(deviceResult.data);
            console.log(deviceResult.data);
            setLogs(logResult.data);
        })();
    }, [])


    const handleEdit = () => {
        navigate("/device/PRO/" + udsn + "/edit", { replace: true });
    }



    const logColumns = [
        {
            field: 'user', headerName: translate('User'), flex: 0,            
            valueGetter: (params) => {
                return(`${params?.value?.name} (${params?.value?.id})`)
            },

        },
        {
            field: 'logAction', headerName: translate('Action'), flex: 0,

        },
        {
            field: 'timestamp', headerName: translate('Timestamp'), flex: 0, width: 150,
            valueGetter: (params) => {
                try {
                    let oldDate = params.value
                    let addUtc = TimeUtils.addUTCTimezone(oldDate)
                    return TimeUtils.formatDate(addUtc)
                }
                catch (e) {
                    return params.value
                }
            },

        },
        {
            field: 'verbose', headerName: translate('Message'), flex: 1,

        }


    ];

    return (
        <PageContainer>
            <PageHeader leftAlign><Translate value="ProDeviceDetails" /></PageHeader>

            <UpdatesBanner update_description="UpdatesDescriptionPROPage"/>

            <Box sx={{width: { xs: "100%", sm: "100%", md: "100%", lg: "1000px" }, maxWidth: {md: "1000px"},}}>

                <Box sx={{ width:"100%"-"30px", mt: 1, borderRadius: "15px", border: 1, borderColor: "primary.main", bgcolor: "primary.main", color: "primary.contrastText", p: 2, display: "flex", justifyContent: 'space-between', flexDirection: { xs: "column", sm: "column", md: "row", lg: "row" }}}>
            
                    <Box sx={{flexGrow: 1}}>
                        <Typography variant="body">
                            {<Translate value="DeviceSerialNumber" />}
                        </Typography>
                        <PatientDashboardCategory
                            title={device?.udsn? device.udsn : "-"}
                        >
                        </PatientDashboardCategory>

                        <Box sx={{mt: 2}}>
                            <PatientDetailText
                                label={<Translate value="Id" />}
                                value={device?.id? device.id : "-" }
                            ></PatientDetailText>
                            <PatientDetailText
                                label={<Translate value="UDSN" />}
                                value={device?.udsn? device.udsn : "-" }
                            ></PatientDetailText>
                            <PatientDetailText
                                label={<Translate value="PUID" />}
                                value={device?.processorSerial? device.processorSerial : "-" }
                            ></PatientDetailText>
                            {/* <PatientDetailText
                                label={<Translate value="Printboard" />}
                                value={device?.printSerial? device.printSerial : "-"}
                            ></PatientDetailText>
                            <PatientDetailText
                                label={<Translate value="Valve" />}
                                value={device?.valveSerial? device.valveSerial : "-"}
                            ></PatientDetailText>
                            <PatientDetailText
                                label={<Translate value="Flowmeter" />}
                                value={device?.flowmeterSerial? device.flowmeterSerial : "-"}
                            ></PatientDetailText>
                            <PatientDetailText
                                label={<Translate value="Oximeter" />}
                                value={device?.oximeterSerial? device.oximeterSerial : "-"}
                            ></PatientDetailText>
                            <PatientDetailText
                                label={<Translate value="Dmr" />}
                                value={device?.dmrMetadata?.value? device.dmrMetadata.value : "-"}
                            ></PatientDetailText> */}
                            <PatientDetailText
                                label={<Translate value="Sw" />}
                                value={device?.softwareVersion?.value? device.softwareVersion.value : "-"}
                            ></PatientDetailText>
                            <PatientDetailText
                                label={<Translate value="Fw" />}
                                value={device?.firmwareVersion?.value? device.firmwareVersion.value : "-"}
                            ></PatientDetailText>
                        </Box>

                        <Box sx={{mt: 2}}>
                            <PatientDetailText
                                label={<Translate value="Status" />}
                                value={device?.status?.value? device.status.value : "-"}
                            ></PatientDetailText>

                            <PatientDetailText
                                label={<Translate value="DeviceOwner" />}
                                value={device.owner?.name? device.owner.name : "-"}
                            ></PatientDetailText>
                            <PatientDetailText
                                label={<Translate value="DeviceLocation" />}
                                value={device.location?.name? device.location.name : "-"}
                            ></PatientDetailText>
                        </Box>

                        <Button onClick={handleEdit} sx={{ mt:2, minWidth:"150px" }} color="secondary" startIcon={<EditIcon />} variant="contained">
                            <Translate value="EditDevice" />
                        </Button>

                        </Box>

                        <Box component="img" sx={{
                        width: { xs: "0%", sm: "0%", md: "30%", lg: "30%" },
                        p: { md: 8 , lg: 8}
                    }} src="../PRO1.svg" />


                </Box>
            </Box>


            {/* <Box sx={{ width:{xs:"100%", sm:"400px"}}}>
                <Button onClick={handleEdit} sx={{ mt:2, mb:2, minWidth:"200px" }} color="primary" variant="contained">
                    <Translate value="EditDevice" />
                </Button>
                


                <Typography>ID: {device.id}</Typography>
                <Typography>UDSN: {device?.udsn}</Typography>
                <Typography>Printboard: {device?.printSerial}</Typography>
                <Typography>Valve: {device?.valveSerial}</Typography>
                <Typography>Flowmeter: {device?.flowmeterSerial}</Typography>
                <Typography>Oximeter: {device?.oximeterSerial}</Typography>

                <Typography>DMR: {device?.dmrMetadata?.value}</Typography>
                <Typography>SW: {device?.softwareVersion?.value}</Typography>
                <Typography>FW: {device?.firmwareVersion?.value}</Typography>
                <Typography>Status: {device?.status?.value}</Typography>

                <Typography>Location: {device.location?.name}</Typography>
                <Typography>Owner: {device.owner?.name}</Typography>

            </Box> */}

            {console.log("isO2maticDeviceManager", isO2maticDeviceManager)}

            {isO2maticDeviceManager?
            <Accordion sx={{ width: { xs: "100%", sm: "100%", md: "100%", lg: "1000px" }, maxWidth: {md: "1000px"}, mt: 2 }}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography variant="h5"><Translate value="SystemLog" /></Typography>

                </AccordionSummary>
                <AccordionDetails>
                    <DataGrid
                        ColumnWidth="*"
                        sx={{ height: 480, mt: 2 }}
                        columns={logColumns}
                        rows={logs}
                        onRowClick={
                            (params, event, detail) => {
                                openSystemLog(params.row)
                            }
                        }
                        pageSize={100}
                        rowsPerPageOptions={[100]}
                    />

                </AccordionDetails>
            </Accordion>
            : null }
            <SystemDetailPopUp systemlog={systemLogData} onClose={closeSystemLog} open={systemLogOpen} />
        </PageContainer>
    )
}
