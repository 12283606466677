import { useState } from "react";
import { useDispatch } from "react-redux";
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { login } from "../redux/actions/auth";
import { Navigate } from 'react-router-dom';
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../configuration/AzureLoginConfig";
import { Translate, Localize } from 'react-i18nify';
const theme = createTheme();
export default function Login() {
    const dispatch = useDispatch();
    const [redirect, setRedirect] = useState(false);
    const { instance } = useMsal();
    const handleLogin = (event, instance) => {
        event.preventDefault();
        instance.loginPopup(loginRequest).then(data => {
            setRedirect(true);
        }).catch(e => {
            console.error(e);
        });
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        dispatch(login(data.get('email'), data.get('password')))
            .then(() => {
                setRedirect(true);
            })
            .catch(() => {
                //setLoading(false);
            });
    };
    if (redirect) {
        return <Navigate to="/" />
    }
    return (
        <ThemeProvider theme={theme}>
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <img src="./o2Matic_logo_black.png" alt="React Logo" />
                    {process.env.REACT_APP_ENV!==""?
                        <Typography component="h1" variant="h5">
                            <Translate value={process.env.REACT_APP_ENV} />
                        </Typography>
                        :
                        null
                    }

                    <Typography sx={{ mt: 8 }} component="h1" variant="h5">
                        <Translate value="SignIn" />
                    </Typography>
                    <Box component="form" onSubmit={(event) => handleLogin(event, instance)} noValidate sx={{ mt: 1 }}>


                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                        >
                            <Translate value="SignIn" />
                        </Button>
                        <Box>
                            <Typography color="gray" align="center">
                                <Translate value="LoginHelpText" />


                            </Typography>
                        </Box>

                    </Box>
                </Box>
            </Container>
        </ThemeProvider>
    );
}

