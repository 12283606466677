import { useState } from "react";
import { useEffect } from 'react'
import { useDispatch } from "react-redux";
import { Navigate, useNavigate } from 'react-router-dom'
import CssBaseline from '@mui/material/CssBaseline';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { logout } from "../redux/actions/auth";
import { useMsal } from "@azure/msal-react";
import { Translate, Localize } from 'react-i18nify';


const theme = createTheme();

export default function Logout() {
    const [redirect, setRedirect] = useState(false);
    const dispatch = useDispatch();
    const { instance } = useMsal();
    const navigate = useNavigate();
    useEffect(() => {
        instance.logoutPopup().catch(e => {
            console.error(e);
        });
        dispatch(logout(instance))
            .then(() => {
                setTimeout(() => {
                    setRedirect(true);
                },2000)

            });
    }, [])
    if (redirect) {
        return <Navigate to="/login" />
    }
    return (
        <ThemeProvider theme={theme}>
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Typography component="h1" variant="h5">
                    <Translate value="LoggingOut" />
                        
                    </Typography>
                </Box>

            </Container>
        </ThemeProvider>
    );
}
function Copyright(props) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright � '}
            <Link color="inherit" href="https://mui.com/">
                Your Website
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}