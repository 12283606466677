import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import PageContainer from "../../../components/PageContainer";
import { PageHeader } from "../../../components/Layout/PageHeader";
import { Translate } from "react-i18nify";
import { Box, Button, Typography, Stack } from "@mui/material";
import AlertBox from "../../../components/AlertBox";
import { DeviceService } from "../../../services/DataService";
import { BatchTable } from "../../../components/Production/BatchTable";
import { DEVICE_TYPE } from "../Production";
import { Roles } from "../../../redux/actions/auth";
import { DataGrid } from '@mui/x-data-grid';

import { ConfirmDialog } from "../../../components/Layout/Dialog/ConfirmDialog";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';


export const O2maticQa=(props)=>{
    const { udsn, type } = useParams();

    const [loading, setLoading] = useState(true);

    const [state, setState] = useState({});
    const navigate = useNavigate();
    const [submitState, setSubmitState] = useState(false);
    const [submitStateReadyForApproval, setSubmitStateReadyForApproval] = useState(false);
  
    const [selectedDevices, setSelectedDevices] = useState({});
    const [selectedDeviceReadyForApproval, setSelectedDeviceReadyForApproval] = useState({});
  
    const [devices, setDevices] = useState({});
    const [devicesReadyForApproval, setDevicesReadyForApproval] = useState({});

    const [showConfirmDialog, setShowConfirmDialog] = useState(false);
    const [udsnToApprove, setUdsnToApprove] = useState(null);
    const [confirmStatus, setConfirmStatus] = useState({status: 0, body: null});
  
    const [alertBox, setAlertBox] = useState({
      visibility: false,
      statusCode: null,
      text: null,
      error: null,
    });
  
    const handleAlertBox = (visibility, statusCode, text, error) => {
      setAlertBox({
        visibility: visibility,
        statusCode: statusCode,
        text: text,
        error: error,
      });
    };
  
    const dismissAlertBox = () => {
      setAlertBox({
        visibility: false,
        statusCode: null,
        text: null,
        error: null,
      });
    };
  
    // get device data for devices that have just been Production QA Approved
    useEffect(() => {
      (async () => {
        let templateId=-1;
        switch(type){
          case DEVICE_TYPE.PRO100.code:{
            templateId=1
            break;
          }
          case DEVICE_TYPE.HOT.code:{
            templateId=12
            break;
          }
  
        }
        let result = await DeviceService.getBatchByTemplate("Prod QA Approved", templateId,[Roles.O2maticQa]);
        setDevices(result.data);
      })();
    }, []);

    // get device data for devices that are "Ready for approval" (O2matic tested devices)
    useEffect(() => {
      (async () => {
        let templateId=-1;
        switch(type){
          case DEVICE_TYPE.PRO100.code:{
            templateId=1
            break;
          }
          case DEVICE_TYPE.HOT.code:{
            templateId=12
            break;
          }
  
        }

        let results = await DeviceService.getByTemplate(templateId, 0, 500,"ReadyForO2maticApproval",[Roles.O2maticQa]);
        setDevicesReadyForApproval(results.data);
        setLoading(false);
      })();
    }, []);
  

  
    const handleSubmit = async (e) => {
      e.preventDefault();
      let data = [];
      Object.keys(selectedDevices)?.forEach((batch) => {
        selectedDevices[batch].devices?.forEach((device) => {
          if(device.selected){
            data.push(device.device.id);
          }
        });
      });
      if(data.length==0){
        return;
      }
      setSubmitState(true);
        try {
          let result = await DeviceService.o2maticQaApproveDevice(data);
  
          if (result.status >= 200 && result.status < 300) {
            handleAlertBox(true, result.status, "Device(s) O2matic QA Approved", null);
          } else {
            handleAlertBox(true, result.status, result.statusText, null);
          }
          setTimeout(() => {
            navigate(`/production/${type}`);
          }, 2000);
        } catch (e) {
          handleAlertBox(true, e.status, e.statusText, e.message);
          setSubmitState(false);
        }
  
    };

    // Content for: O2matic tested devices
    const columns = [

      {
          field: 'udsn', headerName: 'UDSN', flex: 1, 
          valueGetter: (udsn) => {
              return udsn.row.metaData.find(x => x.metadataId == 1)?.value;
          }
      },
      {
          field: 'status', headerName: 'Status', flex: 1, 
          valueGetter: (status) => {
              return status.row.metaData.find(x => x.metadataId == 2)?.value;
          }
      },
    ];

    const handleApprovePopup = (params, event) => {
      let row = params.row;
      var udsn = row.metaData.find(x => x.metadataId == 1).value;
      setUdsnToApprove(udsn);

      // get device id based on udsn
      devicesReadyForApproval.forEach((device) => {
        if (device.metaData.find(x => x.metadataId == 1).value === udsn) {
          let id = device.id;
          setSelectedDeviceReadyForApproval(id)
          setShowConfirmDialog(true);
        }
      });
    };

    const handleApprove = (params, event) => {
      let data = [];
      data.push(selectedDeviceReadyForApproval);
      try {
        DeviceService.o2maticQaApproveDeviceFromO2maticTested(data).then(result => {
          handleAlertBox(true, result.status, "Device O2matic QA Approved", null);
        }).catch(result =>{
          handleAlertBox(true, result.status, result.statusText, null);
        });
        setTimeout(() => {
          navigate(`/production/${type}`);
        }, 2000);
      } catch (e) {
        handleAlertBox(true, e.status, e.statusText, e.message);
      }

    };

    const closeConfirmDialog = async () => {
        setShowConfirmDialog(false);
        setUdsnToApprove(null);
    }



    return (
      <>
      <PageContainer>
        <PageHeader leftAlign>
          <Translate value="O2maticQAAprove" />
        </PageHeader>


        <Typography sx={{ mt: 1, mb: 1 }} variant="h5">
          <Translate value="ProductionQAApprovedReady"/>
        </Typography>

        <Typography sx={{ mt: 1, mb: 1 }} variant="body">
          <Translate value="O2maticQAAproveMessage" />
        </Typography>
        <BatchTable
          data={devices}
          onSelectedUpdate={(devices) => setSelectedDevices(devices)}
        />
        
        <Button
          disabled={submitState}
          onClick={handleSubmit}
          sx={{ mt: 2 }}
          color="primary"
          variant="contained"
        >
          <Translate value="Submit"/>
        </Button>

        <Typography sx={{ mt: 5, mb: 1 }} variant="h5">
          <Translate value="O2maticTestedReady"/>
        </Typography>
        <Typography sx={{ mt: 1, mb: 1 }} variant="body">
          <Translate value="O2maticTestedQAAproveMessage" />
        </Typography>

        <Box sx={{ width: "100%" }}>
          <DataGrid
              ColumnWidth="*"
              sx={{ height: 300, mt: 2 }}
              columns={columns}
              rows={devicesReadyForApproval}
              pageSize={50}
              rowsPerPageOptions={[50]}
              onRowClick={handleApprovePopup}
              loading={loading}
              components={{
                NoRowsOverlay: () => (
                  <Stack height="100%" alignItems="center" justifyContent="center">
                    <Translate value="NoRowsDevices" />
                  </Stack>
                ),
                NoResultsOverlay: () => (
                    <Stack height="100%" alignItems="center" justifyContent="center">
                      <Translate value="NoResults" />
                    </Stack>
                )
            }}
          />
        </Box>

        <AlertBox
          visible={alertBox.visibility}
          text={alertBox.text}
          error={alertBox.error}
          statusCode={alertBox.statusCode}
          onHide={dismissAlertBox}
        />

      </PageContainer>
      <ConfirmDialog
        dialogIcon={<Translate value="O2maticQAAprove" />}
        title={udsnToApprove}
        message={<Translate value="O2maticQaApproveText" />}
        declineText={<Translate value="Close" />}
        confirmText={<Translate value="Approve" />}
        //confirmButtonIcon={<DeleteIcon />}
        confirmButtonVariant="success"
        open={showConfirmDialog}
        onClose={closeConfirmDialog}
        onConfirm={handleApprove}
        confirmStatus={confirmStatus}
    >
    </ConfirmDialog>
    </>
    );
}