import { useState, useEffect } from "react";
import { Typography, Box, Link, Skeleton,} from "@mui/material";
import { Translate, translate } from "react-i18nify";
import { PatientDetailText, PatientDetailTextWarning} from "./PatientDetailText";
import PatientDashboardCategory from "./PatientDashboardCategory";
import PatientDashboardCategoryWarning from "./PatientDashboardCategoryWarning";
import TimeUtils from "../../utils/TimeUtils";
import { PatientService, SessionService } from "../../services/DataService";
import { DeviceService } from "../../services/DataService";
import { EventLogService } from "../../services/DataService";
import PatientUtils from "../../utils/PatientUtil";



const PatientDeviceInformation = ({patient, device, locationMismatch, awaitingDevice}) => {
    const [deviceMac, setDeviceMac] = useState(null);
    const [deviceUdsn, setDeviceUdsn] = useState(null);
    const [latestDeviceLog, setLatestDeviceLog] = useState(null);
    const [latestSession, setLatestSession] = useState(null);
    const [patientComponent, setPatientComponent] = useState(null);
    const [deviceLocation, setDeviceLocation] = useState(null);
    const [awaitingLastSession, setAwaitingLastSession] = useState(true);
    const [awaitingLastLog, setAwaitingLastLog] = useState(true);

    useEffect(() => {
      setDeviceUdsn(device?.metaData.find(metadata => metadata.name === "Serial Number")?.value)
      setDeviceLocation(device?.location.name)
      if(!awaitingDevice){
        if(device){
          (async () => {
            await DeviceService.getHotByUdsn(device?.metaData[0].value).then(response => {
              setDeviceMac(response.data.mac)  
            }).catch(error => {
              console.error("unable to fetch MAC address\n", error)
              setDeviceMac("-");
            });
          })();
    
          (async () => {
            // setawaitingLastLog(true)
            await EventLogService.getLatestByUdsn(device?.metaData[0].value).then(response => {
              setLatestDeviceLog(response?.data)
            }).catch(error => {
              console.error("unable to fetch latest device log\n", error)
            }).finally(() => {
              setAwaitingLastLog(false)
            })
          })();
        }else{
          setDeviceUdsn("PatientNotAssignedToDevice"); 
          setDeviceMac("-");
          setDeviceLocation("-"); 
          setAwaitingLastLog(false)
        }
      }
    },[device, awaitingDevice]);

  
    useEffect(() => {
      if(patient?.patient){
        (async () => {
            try{
                let apiLatestSession = await SessionService.getLatestByPatientId(patient.patient.id.value);
                setLatestSession(apiLatestSession.data);    
            }catch(error){
                console.error("unable to fetch latest session\n" + error.message)
            }
            await PatientService.getPatientComponent(patient?.patient.id.value, device?.id).then((response) => {
              setPatientComponent(response.data)
            }).catch((error) => {
              console.error("unable to fetch Patient component\n", error)
              setPatientComponent("-")
            });
        })();
        (async () => { // find patient, patient logs and session
          await SessionService.getLatestByPatientId(patient?.patient?.id).then(response => {
            setLatestSession(response.data);
          }).catch(error => {
            console.error("unable to fetch latest session\n", error)
          }).finally(() => {
            setAwaitingLastSession(false)
          });
        })();
      }
    }, [patient])
    
    return (
      <Box sx={{ width:"100%", m: 1, p: 2 }}>
        <Typography sx={{ mt: 1 }} variant="body">
            {<Translate value="DeviceSerialNumber"/>}
        </Typography>
        { !deviceUdsn ? 
          <Skeleton variant="rounded" height={45} width={"40%"} sx={{ mt: 1}} />
        :         
          deviceUdsn !== "PatientNotAssignedToDevice" ?      
          <PatientDashboardCategory
            title={ <Link href={"device/HOT/" + deviceUdsn} color="inherit" underline="hover">{deviceUdsn} </Link>}
          />
          :
          <PatientDashboardCategoryWarning
            title={translate(deviceUdsn)}
          />
        }
          <Box sx={{width:"100%", display:"flex", flexDirection:{xs: "column", sm: "row"}, justifyContent: 'space-between'}}>
            <Box sx={{width:{xs:"100%", sm:"45%"}}}>
              <Box sx={{ width:"100%", mt: 2}}>
              {deviceMac && deviceLocation && patientComponent ? (
                <>
                  {locationMismatch? // add warning color to location if there is a mismatch between patient location and device location
                    <PatientDetailTextWarning
                      label={translate("Location")}
                      value={deviceLocation + " (" + translate("locationsDoNotMatch") + ")"}
                    ></PatientDetailTextWarning>
                    :
                    <PatientDetailText
                    label={translate("Location")}
                    value={deviceLocation}
                    />
                  }
                  <PatientDetailText
                    label={translate("MacAddress")}
                    value={deviceMac}
                    />
                  <PatientDetailText
                    label={translate("DeviceAssignedToPatient")}
                    value={ patientComponent && patientComponent !== "-" ? TimeUtils.utcToLocal(patientComponent.start) : translate(patientComponent)}
                  />
                </>
              )
              :
              <Skeleton variant="rounded" height={73} width={"90%"} />
              }
              </Box>

              <Box sx={{ width:"100%", mt: 2}}>
              {!awaitingLastSession && !awaitingLastLog ?
                <>
                {(latestDeviceLog === null)? 
                <PatientDetailText
                  label={translate("LatestReceivedDeviceLog")}
                  value={"-"}
                ></PatientDetailText>
                : 
                <PatientDetailText
                  label={translate("LatestReceivedDeviceLog")}
                  value={TimeUtils.utcToLocal(latestDeviceLog)}
                ></PatientDetailText>}
                {(!latestSession)? 
                <PatientDetailText
                  label={translate("LastSession")}
                  value={"-"}
                ></PatientDetailText>
                : 
                <PatientDetailText
                  label={translate("LastSession")}
                  value={TimeUtils.utcToLocal(latestSession?.timeStamp)}
                ></PatientDetailText>}
                {(!latestSession)? 
                <PatientDetailText
                  label={translate("NextSession")}
                  value={"-"}
                ></PatientDetailText>
                : 
                <PatientDetailText
                  label={translate("NextSession")}
                  value={TimeUtils.formatDate((TimeUtils.addSeconds(TimeUtils.utcToLocalDate(latestSession?.timeStamp),parseInt(latestSession?.value))))}
                ></PatientDetailText>}
                </>
              :
                <Skeleton variant="rounded" height={73} width={"90%"} />
              }
              </Box>
            </Box>

            <Box sx={{width:{xs:"100%", sm:"45%"}}}>
              {patient ? 
              <Box sx={{mt: 1}}>
                <PatientDetailText
                  label={<Translate value="ServiceProvider" />}
                  value={
                    PatientUtils.getContactString(patient?.patient?.contact,"Installer").name? PatientUtils.getContactString(patient?.patient?.contact,"Installer").name : "-"
                  }
                ></PatientDetailText>
                <PatientDetailText
                  label={<Translate value="PhoneNumber" />}
                  value={
                    PatientUtils.getContactString(patient?.patient?.contact,"Installer").phone? PatientUtils.getContactString(patient?.patient?.contact,"Installer").phone : "-"
                  }
                ></PatientDetailText>
              </Box>
              :
              <Skeleton variant="rounded" height={49} width={"70%"}  sx={{mt:2}} />
              }
              {patient ? 
              
              <Box sx={{mt: 3}}> 
                <PatientDetailText
                  label={<Translate value="HealthcareProvider" />}
                  value={
                    PatientUtils.getContactString(patient?.patient?.contact,"Healthcare").name? PatientUtils.getContactString(patient?.patient?.contact,"Healthcare").name : "-"
                  }
                ></PatientDetailText>
                <PatientDetailText
                  label={<Translate value="PhoneNumber" />}
                  value={
                    PatientUtils.getContactString(patient?.patient?.contact,"Healthcare").phone? PatientUtils.getContactString(patient?.patient?.contact,"Healthcare").phone : "-"
                  }
                ></PatientDetailText>
              </Box>
              :
              <Skeleton variant="rounded" height={49} width={"70%"}  sx={{mt:2}} />
              }
            </Box>
          </Box>

        
      </Box>
    );
};

export default PatientDeviceInformation;