import React, {useEffect, useState} from "react";
import { useNavigate } from "react-router-dom";
import { MetadataService, OximeterService } from "../../services/DataService";
import { Translate , translate} from 'react-i18nify';
import { PageHeader } from "../../components/Layout/PageHeader";
import { Box, InputLabel, Button, TextField, Select, MenuItem } from "@mui/material";
import { MetadataValuesTable } from "./MetadataValuesTable";
import AlertBox from "../../components/AlertBox";
import PageContainer from "../../components/PageContainer";


export const CreateMetadataValue = () => {
    
    const [hasBeenCreated, setHasBeenCreated] = useState(false);
    const [metadataList, setMetadataList] = useState();
    const [selectedMetadata, setSelectedMetadata] = useState("");
    const [metadataValue, setMetadataValue] = useState("");
    const [isSaved, setIsSaved] = useState(false);
    const [savedMetadataValue, setSavedMetadataValue] = useState();
    const [isLoaded, setIsloaded] = useState(false);
    const [alertBox, setAlertBox] = useState({
        "visiblity": false,
        "statusCode": null,
        "text": null,
        "error": null
        }
    );
    const navigate = useNavigate();
    
    const handleAlertBox = (visiblity, statusCode, text, error) => {
        setAlertBox({
            "visiblity": visiblity,
            "statusCode": statusCode,
            "text": text,
            "error": error
        }
        );
    }

    const dismissAlertBox=()=>{
        setAlertBox({
            "visiblity": false,
            "statusCode": null,
            "text": null,
            "error": null

        });
    }
    
    const getMetaData = async () => {
        await MetadataService.getAddableMetadata().then(result => {
            setMetadataList(result.data);
            setIsloaded(true);
        }).catch(err => {
            handleAlertBox(true, err?.response?.status, err?.response?.data.title, null);
        });
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        const body = {
            "metadataId": selectedMetadata.metadataId,
            "name": selectedMetadata.name,
            "value": metadataValue
        }
        await MetadataService.createMetadataValue(body).then(result => {
            if(result.data !== null){
                setIsSaved(true)
                setSavedMetadataValue(result.data)
                dismissAlertBox();
            }
        }).catch(err => {
            if(err.response.status === 403){
                handleAlertBox(true, err?.response?.status, "Your role is not authorized to make this request", err?.response?.data); 
            }else{
                handleAlertBox(true, err?.response?.status, null, err?.response?.data); 
            }
        })
    }

    const handleMetadataChange = (e, newValue) => {
        setSelectedMetadata({"metadataId": newValue.props.value, "name": newValue.props.children});
    }

    const handleValueChange = (e) => {
        e.preventDefault();
        setMetadataValue(e.target.value);
    }
    
    const navigateAdmin = (e) => {
        e.preventDefault();
        navigate('/O2maticAdministrator');
    };

    const addAnother = (e) => {
        e.preventDefault();
        setIsSaved(false);
        setSavedMetadataValue(undefined);
        setSelectedMetadata("");
    }

    useEffect(() =>  {
        getMetaData();
    },[])

    const IsCreatedBox = () => {
        return(
            <Box  justifyContent="space-between">
            <h3> <Translate value="MetadataValueHasBeenAddded"/> <br/> {savedMetadataValue?.metaData?.name}: {savedMetadataValue.value}</h3>
            
            <Button onClick={addAnother} sx={{ mt: 4,  minWidth: '250px' }} variant="contained"><Translate value="AddMoreMetadata" /></Button>
            <br/>
            <Button onClick={navigateAdmin} sx={{ mt: 4,  minWidth: '250px' }} variant="contained"><Translate value="ReturnToO2maticAdministrator" /></Button> 
            
            </Box>
        )
    }

    return(
        <PageContainer>
            <PageHeader leftAlign>
                <Translate value="AddMetadata" />
            </PageHeader>
            {isLoaded ?(
                isSaved ? (
                    <IsCreatedBox/>
                ):(
                    <Box component="form" onSubmit={handleSubmit} sx={{ mt: 2, width:{xs:"100%", sm:"100%"}}}>
                    <Select
                        sx={{ mb:2, width:{xs:"100%", sm:"200px"}}}
                        required
                        displayEmpty
                        id="metadata"
                        value={selectedMetadata.metadataId || ""}
                        onChange={handleMetadataChange}
                        style={{minWidth: 200}}
                    >
                        <MenuItem value={""}><Translate value="SelectMetadata"/></MenuItem>
                        {metadataList.map(metadata => {
                            return <MenuItem key={metadata.id} value={metadata.id}>{metadata.name}</MenuItem>
                        })}
                    </Select>
                    <InputLabel id="MetadataValue"><Translate value="MetadataValue"/></InputLabel>
                    <TextField
                        sx={{ width:{xs:"100%", sm:"200px"}}}
                        id="metadataValue" 
                        required
                        placeholder={translate("MetadataValue")}
                        onChange={handleValueChange}
                    >
                    </TextField>     
                    <br/>
                    <Button sx={{ mt: 2, width:{xs:"100%", sm:"200px"}}} color="primary" variant="contained" type="submit">
                        <Translate value="AddMetadata" />
                    </Button>
                    <AlertBox visible={alertBox.visiblity} text={alertBox.text} error={alertBox.error} statusCode={alertBox.statusCode} onHide={dismissAlertBox} style={{width:{xs:"100%", sm:"500px"}}} />         
                        <MetadataValuesTable/>
                    </Box>
                    )
                ):( 
                    <Box>
                        <span><Translate value="Loading" /></span> 
                        <AlertBox visible={alertBox.visiblity} text={alertBox.text} error={alertBox.error} statusCode={alertBox.statusCode} onHide={dismissAlertBox} />         
                    </Box>
                ) 
                }
        </PageContainer>
        
    );
}