import React from 'react'
import { Box } from '@mui/system'
import { Typography, Button, FormControl, TextField, InputLabel, Select, MenuItem, FormGroup, FormControlLabel, Switch } from '@mui/material'
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { DatePicker } from '@mui/lab';
import TimeUtils from '../utils/TimeUtils';
import { Translate, Localize, getLocale} from 'react-i18nify';

export default function FromToPicker(props) {
    return (

        <Box sx={{ mt: 2, display: 'flex', flexDirection: "column" }}>
            <InputLabel id={props.title}>{props.title}</InputLabel>
            <FormControl sx={{ display: 'flex', flexDirection: "row" }}>

                <DateTimePicker
                    labelId={props.title}
                    renderInput={(props) => <TextField sx={{ mt: 2 }} {...props} />}
                    label={<Translate value="From" />}
                    value={props.from}
                    ampm={false}
                    onChange={(newValue) => {
                        props.setFromValue(newValue);
                    }}
                />
                <DateTimePicker
                    sx={{ m: 2 }}
                    renderInput={(props) => <TextField sx={{ mt: 2, ml: 2 }} {...props} />}
                    label={<Translate value="To" />}
                    value={props.to}
                    ampm={false}
                    onChange={(newValue) => {
                        if(props.from >= newValue){
                            props.from.setDate(newValue.getDate()-7)
                            props.setFromValue(TimeUtils.getEndingOfDay(props.from));
                        }
                        props.setToValue(TimeUtils.getEndingOfDay(newValue))
                    }}
                />
            </FormControl>
        </Box>
    )
}

export function FromToPickerDate(props) {
    //let weekDays = TimeUtils.getWeekDays(locale)
    let today = new Date();
    let minDateLimit = new Date().setFullYear('2010');
    let maxDateLimit = new Date(today.getFullYear(),12,30,23,59,59);

    return (

        <Box sx={{ mt: 2, display: 'flex', flexDirection: "column" }}>
            <InputLabel id={props.title}>{props.title}</InputLabel>
            <FormControl sx={{ display: 'flex', flexDirection: "row" }}>

                <DatePicker
                    views={['year', 'day']}
                    labelId={props.title}
                    label={<Translate value="From" />}
                    //format='YYYY/MM/DD' // does not work
                    timezone="UTC"
                    value={props.from}
                    disableFuture={true}
                    minDate={minDateLimit}
                    maxDate={props.to}
                    ampm={false}
                    renderInput={(params) => <TextField size="small" sx={{ mr: 2, mb: 1, width:"150px"}} {...params} helperText={null}/>}
                    onChange={(newValue) => {
                    }}
                    onAccept={(newValue) => {
                        props.setFromValue(TimeUtils.getBeginningOfDay(newValue));
                    }}
                />
                <DatePicker
                    views={['year', 'day']}
                    sx={{ ml: 2 , height:"40px"}}
                    label={<Translate value="To" />}
                    //format='yyyy-MM-dd' // does not work
                    timezone="UTC"
                    value={props.to}
                    disableFuture={true}
                    minDate={minDateLimit}
                    maxDate={maxDateLimit}
                    ampm={false}
                    renderInput={(params) => <TextField size="small" sx={{ mb: 1, width:"150px"}} {...params} helperText={null}/>}
                    onChange={(newValue) => {
                    }}
                    onAccept={(newValue) => {
                        if(props.from >= newValue){
                            props.from.setDate(newValue.getDate()-7)
                            props.setFromValue(TimeUtils.getEndingOfDay(props.from));
                        }
                        props.setToValue(TimeUtils.getEndingOfDay(newValue))
                    }}
                />
            </FormControl>
        </Box>
    )
}

