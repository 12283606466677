import { setTranslations, setLocale,getLocale,setHandleMissingTranslation } from 'react-i18nify';
import English from "./English";
import Danish from "./Danish";
import Spanish from "./Spanish";
//https://icons8.com/icon/set/flags/fluency
const defaultLanguage="en";


const TranslationConfig=()=>{
    setTranslations({...English,...Danish,...Spanish});
    let currentLocale = localStorage.getItem("language")
    setLocale(currentLocale?currentLocale:defaultLanguage);
}
setHandleMissingTranslation((key, replacements, options, err) => {
    console.error({"Missing translation": key});
    return key;
});
export default TranslationConfig;


