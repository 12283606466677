import {
    Link,
    useLocation,
} from "react-router-dom";
import { Box, Button, ListItem } from "@mui/material";

export const NavItem = (props) => {
    const { href, icon, title, ...others } = props;
    const location = useLocation();
    const active = href ? location.pathname.includes(href) : false;
    const child = href.split("/").length

    return (
        <ListItem
            disableGutters
            sx={{
                display: "flex",
                mb: 0.5,
                py: 0,
                px: 2+2*(child-2),
            }}
            {...others}
        >
            <Link style={{ textDecoration: "none" }} to={href}>
                <Button
                    startIcon={icon}
                    disableRipple
                    sx={{
                        backgroundColor: active && "rgba(255,255,255, 0.08)",
                        borderRadius: 1,
                        color: active ? "primary.main" : process.env.REACT_APP_TEXT_MENU_COLOR,
                        fontWeight: active && "fontWeightBold",
                        justifyContent: "flex-start",
                        px: 3,
                        textAlign: "left",
                        textTransform: "none",
                        width: "100%",
                        "& .MuiButton-startIcon": {
                            color: active ? "primary.main" : process.env.REACT_APP_TEXT_MENU_COLOR,
                        },
                        "&:hover": {
                            backgroundColor: "rgba(255,255,255, 0.08)",
                        },
                    }}
                >
                    <Box sx={{ flexGrow: 1 }}>{title}</Box>
                </Button>
            </Link>
        </ListItem>
    );
};
