import React, { useRef } from 'react';
import { useEffect, useState } from 'react';
import { Box } from '@mui/system';
import uPlot from 'uplot';
import { Object } from 'core-js';
import UplotReact from 'uplot-react';
import 'uplot/dist/uPlot.min.css';
import { useTheme } from '@mui/material/styles';

export const Spo2Chart = (props) => {
    const spo2LowerRange=props.spo2LowerRange;
    const spo2UpperRange=props.spo2UpperRange;

    const theme = useTheme()
    return (
        <Box sx={{ display: "flex", flexDirection: "column",marginBottom:"-15px"}}>
            <UplotReact
                options={{
                    legend:{
                        show:false
                    },
                    gridWidth: 0, // set the grid width to 0
                    gridColor: null, // set the grid color to null
                    height: props.height,
                    width: props.width,
                    bpad: 0,
                    series: [
                        {},
                        { // manual mode
                            spanGaps:false,
                            stroke: "rgba(211,211,211,0.5)",
                            points: {show:false},
                            label: "Alerts",
                            fill: "rgba(211,211,211,0.5)",
                            width: 1,
                            scale: "%",
                            value: (self, raw) => raw,
                        },
                        { // missing data
                            spanGaps:false,
                            stroke: "rgba(255,255,0,0.5)",
                            points: {show:false},
                            label: "Alerts",
                            fill: "rgba(255,255,0,0.5)",
                            width: 1,
                            scale: "%",
                            value: (self, raw) => raw,
                        },
                        {
                            spanGaps:false,
                            stroke: theme.palette.vitals.spo2,
                            label: "SpO2",
                            width: 3,
                            scale: "%",
                            value: (self, raw) => raw,
                        }
                    ],
                    cursor: {
                        sync: {
                          key: "charts"
                        }
                      },
                    hooks: {
                        drawSeries: [
                            (u, si) => {
                                let ctx = u.ctx;
    
                                ctx.save();
    
                                let s  = u.series[si];
                                let xd = u.data[0];
                                let yd = u.data[si];

                                let [i0, i1] = s.idxs;

                                let lineYLower=u.valToPos(spo2LowerRange, '%', true);
                                let lineYUpper=u.valToPos(spo2UpperRange, '%', true);

    
                                let x0 = u.valToPos(xd[i0], 'x', true);
                                let x1 = u.valToPos(xd[i1], 'x', true);
                                const offset = (s.width % 2) / 2;

    
                                ctx.translate(offset, offset);

                                // maximum line
                                ctx.beginPath();
                                ctx.lineWidth=1;
                                ctx.strokeStyle = "#191a30";
                                ctx.setLineDash([5,5]);
                                ctx.moveTo(x0, lineYUpper);
                                ctx.lineTo(x1, lineYUpper);
                                ctx.stroke();

                                // maximum text
                                ctx.textAlign = "left";
                                ctx.fillStyle = "#191a30";
                                ctx.textBaseline = "bottom";
                                ctx.fillText('Max',x0,lineYUpper)
    
                                // minimum line
                                ctx.beginPath();
                                ctx.lineWidth=1;
                                ctx.strokeStyle = "#191a30";
                                ctx.setLineDash([5,5]);
                                ctx.moveTo(x0, lineYLower);
                                ctx.lineTo(x1, lineYLower);
                                ctx.stroke();

                                // minimum text
                                ctx.textAlign = "left";
                                ctx.fillStyle = "#191a30";
                                ctx.textBaseline = "top";
                                ctx.fillText('Min',x0,lineYLower);


                                ctx.translate(-offset, -offset);
    
                                ctx.restore();
                            }
                        ]
                    },
                    axes: [
                        {
                            grid: {show: true},
                            labelSize: 75,
                            labelFont: "bold 14px Arial",
                            font: "14px Arial",
                            size: 75,
                            color: "red",
                            values: [
                                // tick incr          default           year                             month    day                        hour     min                sec       mode
                                  [3600 * 24 * 365,   "{YYYY}",         null,                            null,    null,                      null,    null,              null,        1],
                                  [3600 * 24 * 28,    "{MMM}",          "\n{YYYY}",                      null,    null,                      null,    null,              null,        1],
                                  [3600 * 24,         "{MM}/{DD}",        "\n{YYYY}",                      null,    null,                      null,    null,              null,        1],
                                  [3600,              "{HH}:{mm}",        "\n{YYYY}-{MM}-{DD}",                null,    "\n{MM}-{DD}",               null,    null,              null,        1],
                                  [60,                "{HH}:{mm}",   "\n{YYYY}-{MM}-{DD}",                null,    "\n{MM}-{DD}",               null,    null,              null,        1],
                                  //[1,                 ":{ss}",          "\n{YYYY}-{MM}-{DD} {HH}:{mm}",   null,    "\n{MM}-{DD} {HH}:{mm}",  null,    "\n{HH}:{mm}",  null,        1],
                                  //[0.001,             ":{ss}.{fff}",    "\n{YYYY}-{MM}-{DD} {HH}:{mm}",   null,    "\n{MM}-{DD} {HH}:{mm}",  null,    "\n{h}:{mm}",  null,        1],
                                ],

                        },
                        {
                            grid: {show: true},
                            show:props.width>500?true:false,
                            labelSize: 50,
                            labelFont: "bold 12px Arial",
                            font: "14px Arial",
                            gap: 5,
                            size: 50,
                            scale: "%",
                            values: (self, ticks) => ticks.map(rawValue => {
                                return rawValue.toFixed(0)
                            }),
                            side: 3,
                            ticks: {
                                show: true,
                                stroke: theme.palette.vitals.spo2,
                                width: 2,
                                dash: [],
                                size: 10,
                            }
                        }
                    ],
                    scales: {
                        "%": {
                            auto: false,
                            range: [75, 100],
                        }
                        
                    }
                }}
                data={props?.data}
            />
        </Box>

    );
};