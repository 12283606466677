import React, { useRef } from "react";
import { useEffect, useState } from "react";
import { Box } from "@mui/system";
import uPlot from "uplot";
import { Object } from "core-js";
import UplotReact from "uplot-react";
import "uplot/dist/uPlot.min.css";
import { useTheme } from "@mui/material/styles";

export const PulseChart = (props) => {
  const uPlotData = props?.data[0]?.data;
  const theme = useTheme();

  const state = [props.data[2]];

  return (
    <Box sx={{ display: "flex", flexDirection: "column", marginBottom:"-15px"}}>
      <UplotReact
        options={{
          legend: {
            show: false,
          },
          gridWidth: 0, // set the grid width to 0
          gridColor: null, // set the grid color to null
          height: props.height,
          width: props.width,
          bpad: 0,
          series: [
            {},
            { // manual mode
              spanGaps:false,
              stroke: "rgba(211,211,211,0.5)",
              points: {show:false},
              label: "Alerts",
              fill: "rgba(211,211,211,0.5)",
              width: 1,
              scale: "bpm",
              value: (self, raw) => raw,
            },
            { // missing data
              spanGaps:false,
              stroke: "rgba(255,255,0,0.5)",
              points: {show:false},
              scale: "bpm",
              label: "Alerts",
              fill: "rgba(255,255,0,0.5)",
              value: (self, raw) => raw,
            },
            {
              stroke: theme.palette.vitals.pulse,
              label: "Pulse",
              width: 3,
              scale: "bpm",
              value: (self, raw) => raw,
            }
          ],
          cursor: {
            sync: {
              key: "charts"
            }
          },
          axes: [
            {
              grid: { show: true },
              labelSize: 75,
              labelFont: "bold 14px Arial",
              font: "14px Arial",
              size: 75,
              color: "red",
              values: [
                // tick incr          default           year                             month    day                        hour     min                sec       mode
                  [3600 * 24 * 365,   "{YYYY}",         null,                            null,    null,                      null,    null,              null,        1],
                  [3600 * 24 * 28,    "{MMM}",          "\n{YYYY}",                      null,    null,                      null,    null,              null,        1],
                  [3600 * 24,         "{MM}/{DD}",        "\n{YYYY}",                      null,    null,                      null,    null,              null,        1],
                  [3600,              "{HH}:{mm}",        "\n{YYYY}-{MM}-{DD}",                null,    "\n{MM}-{DD}",               null,    null,              null,        1],
                  [60,                "{HH}:{mm}",   "\n{YYYY}-{MM}-{DD}",                null,    "\n{MM}-{DD}",               null,    null,              null,        1],
                  //[1,                 ":{ss}",          "\n{YYYY}-{MM}-{DD} {HH}:{mm}",   null,    "\n{MM}-{DD} {HH}:{mm}",  null,    "\n{HH}:{mm}",  null,        1],
                  //[0.001,             ":{ss}.{fff}",    "\n{YYYY}-{MM}-{DD} {HH}:{mm}",   null,    "\n{MM}-{DD} {HH}:{mm}",  null,    "\n{h}:{mm}",  null,        1],
                ],
            },
            {
              spanGaps: true,
              grid: { show: true },
              show: props.width > 500 ? true : false,
              labelSize: 50,
              labelFont: "bold 12px Arial",
              font: "14px Arial",
              gap: 5,
              size: 50,
              scale: "bpm",
              values: (self, ticks) =>
                ticks.map((rawValue) => {
                  return rawValue.toFixed(0);
                }),
              side: 3,
              ticks: {
                show: true,
                stroke: theme.palette.vitals.pulse,
                width: 2,
                dash: [],
                size: 10,
              },
            },
          ],
          scales: {
            "bpm": {
              auto: false,
              range: [40,200]
            },
            x: {
            },
          },
        }}
        data={props?.data}
      />
    </Box>
  );
};
