import React from 'react';
import { Provider } from 'react-redux';
import './index.css';
import App from './App.js';
import { BrowserRouter as Router } from 'react-router-dom';
import reportWebVitals from './reportWebVitals';
import './configuration/axios-config'
import store from "./redux/store/store.js"
import { ThemeProvider } from '@mui/material/styles';
import { theme } from './theme';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import ReactDOM from "react-dom/client";
import { PublicClientApplication } from "@azure/msal-browser"
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./configuration/AzureLoginConfig"
import { AlertModal } from './components/AlertBox';
import { Route } from 'react-router-dom';
import { RequireAuth } from './utils/RequireAuth';



const msalInstance = new PublicClientApplication(msalConfig);
window.isDevelopment = false;
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <React.StrictMode>
        <MsalProvider instance={msalInstance}>

            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <Provider store={store}>
                    <ThemeProvider theme={theme}>

                        <App />

                    </ThemeProvider>
                </Provider>

            </LocalizationProvider>
        </MsalProvider>
    </React.StrictMode>
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
