class TimeUtils {
  getWeekDays(locale) {
    var baseDate = new Date(Date.UTC(2017, 0, 2));
    var weekDays = [];
    for (let i = 0; i < 7; i++) {
      weekDays.push(baseDate.toLocaleDateString(locale, { weekday: "long" }));
      baseDate.setDate(baseDate.getDate() + 1);
    }
    return weekDays;
  }
  getDayName(dateStr, locale) {
    var date = new Date(dateStr);
    return date.toLocaleDateString(locale, { weekday: "long" });
  }

  zeroPad(num, places) {
    return String(num).padStart(places, "0");
  }
  convertUTCDateToLocalDate(date) {
    var newDate = new Date(
      date.getTime() + date.getTimezoneOffset() * 60 * 1000
    );

    var offset = date.getTimezoneOffset() / 60;
    var hours = date.getHours();

    newDate.setHours(hours - offset);

    return newDate;
  }

  utcToLocalDate(dateString) {
    var date = new Date(
      Date.UTC(
        parseInt(dateString.substring(0, 4)), // Year
        parseInt(dateString.substring(5, 7)) - 1, // Month (0-indexed)
        parseInt(dateString.substring(8, 10)), // Day
        parseInt(dateString.substring(11, 13)), // Hour
        parseInt(dateString.substring(14, 16)), // Minute
        parseInt(dateString.substring(17, 19)) // Second
      )
    );
    return date;
  }
  utcToLocal(dateString, showSeconds = false) {
    var yourDate = new Date(dateString);
    const offset = yourDate.getTimezoneOffset();
    yourDate = new Date(yourDate.getTime() - offset * 60 * 1000);

    var result = yourDate.getFullYear();

    var month = this.zeroPad(parseInt(yourDate.getMonth()) + 1, 2);
    var date = this.zeroPad(parseInt(yourDate.getDate()), 2);

    result += "-" + month + "-" + date + " ";

    var hours = this.zeroPad(parseInt(yourDate.getHours()), 2);
    var minutes = this.zeroPad(parseInt(yourDate.getMinutes()), 2);
    var seconds = this.zeroPad(parseInt(yourDate.getSeconds()), 2);

    result += hours + ":" + minutes;

    if (showSeconds == true) {
      result += ":" + seconds;
    }

    return result;
  }
  localToUtc(dateString, showSeconds = false) {
    var yourDate = new Date(dateString);
    const offset = yourDate.getTimezoneOffset();
    yourDate = new Date(yourDate.getTime() + offset * 60 * 1000);

    var result = yourDate.getFullYear();

    var month = this.zeroPad(parseInt(yourDate.getMonth()) + 1, 2);
    var date = this.zeroPad(parseInt(yourDate.getDate()), 2);

    result += "-" + month + "-" + date + " ";

    var hours = this.zeroPad(parseInt(yourDate.getHours()), 2);
    var minutes = this.zeroPad(parseInt(yourDate.getMinutes()), 2);
    var seconds = this.zeroPad(parseInt(yourDate.getSeconds()), 2);

    result += hours + ":" + minutes;

    if (showSeconds == true) {
      result += ":" + seconds;
    }

    return result;
  }

  dateToUTC(date) {
    return Date.UTC(
      date.getUTCFullYear(),
      date.getUTCMonth(),
      date.getUTCDate(),
      date.getUTCHours(),
      date.getUTCMinutes(),
      date.getUTCSeconds()
    );
  }

  dateDiffToHHMMSS(startDate, endDate) {
    const timeDifference = Math.abs(endDate - startDate); 
    const hours = Math.floor(timeDifference / 3600000);
    const minutes = Math.floor((timeDifference % 3600000) / 60000); 
    const seconds = Math.floor((timeDifference % 60000) / 1000); 

    const formattedTime = `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
    
    return formattedTime;
  }

  padTo2Digits(num) {
    return num.toString().padStart(2, "0");
  }
  addUTCTimezone(date) {
    date += "+0000";
    return new Date(date);
  }
  getBeginningOfMonth(date) {
    const year = date.getFullYear();
    const month = date.getMonth();
    const startOfMonth=new Date(Date.UTC(year, month,1,0,0,0,1));
    return startOfMonth;
  }
  getEndingOfMonth(date) {
    const year = date.getFullYear();
    const month = date.getMonth();
    const nextMonth = month + 1;
    const lastDayOfMonth = new Date(year, nextMonth, 0).getDate();
    const endOfMonth=new Date(Date.UTC(year, month,lastDayOfMonth,23,59,59,999));
    return endOfMonth
  }


  getBeginningOfDay(date) {
    const year = date.getFullYear();
    const month = date.getMonth();
    const day = date.getDate();
    const startOfDay=new Date(year, month,day,0,0,0);
    return startOfDay;
  }
  getEndingOfDay(date) {
    const year = date.getFullYear();
    const month = date.getMonth();
    const day = date.getDate();
    const endOfDay=new Date(year, month,day,23,59,59);
    return endOfDay
  }

 
  formatDate(date) {
    date = new Date(date);
    return (
      [
        date.getFullYear(),
        this.padTo2Digits(date.getMonth() + 1),
        this.padTo2Digits(date.getDate())
      ].join("-") +
      " " +
      [
        this.padTo2Digits(date.getHours()),
        this.padTo2Digits(date.getMinutes()),
        // this.padTo2Digits(date.getSeconds()),
      ].join(":")
    );
  }

  formatDateHHmm(date) {
    date = new Date(date);
    return (
      [
        this.padTo2Digits(date.getHours()),
        this.padTo2Digits(date.getMinutes()),
        // this.padTo2Digits(date.getSeconds()),
      ].join(":")
    );
  }

  formatDateDDMMYY(date) {
    date = new Date(date);
    return (
      [
        this.padTo2Digits(date.getDate()),
        this.padTo2Digits(date.getMonth() + 1),
        date.getFullYear(),
      ].join("-")
    );
  }
  formatDateYYMMDD(date) {
    date = new Date(date);
    return [
      date.getFullYear(),
      this.padTo2Digits(date.getMonth() + 1),
      this.padTo2Digits(date.getDate()),
    ].join("-");
  }

  getDaysInMonthUTC(month, year) {
    // var date = new Date(Date.UTC(year, month, 1, 0, 0, 0, 0));
    // var days = [];
    // while (date.getUTCMonth() === month) {
    //   days.push(new Date(date));
    //   date.setUTCDate(date.getUTCDate() + 1);
    // }
    // return days;
    var date = new Date(year, month, 1, 0, 0, 0, 0);
    var days = [];
    while (date.getMonth() === month) {
      days.push(new Date(date));
      date.setDate(date.getDate() + 1);
    }
    return days;
  }

  getDatesBetweenTwoDates(startDate, stopDate) {
    var dateArray = [];
    var currentDate = startDate;
    while (currentDate <= stopDate) {
      dateArray.push(new Date(currentDate));
      currentDate.setDate(currentDate.getDate() + 1);
    }
    return dateArray;
  }

  isTheSameDate(firstDate, secondDate) {
    if (
      firstDate.getFullYear() === secondDate.getFullYear() &&
      firstDate.getMonth() === secondDate.getMonth() &&
      firstDate.getDate() === secondDate.getDate()
    ) {
      return true;
    }
    return false;
  }

  getAge(bday) {
    var today = new Date();
    var birthDate = new Date(bday);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  }

  secondsToHMS(seconds) {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = Math.floor(seconds % 60);

    const formattedHours = String(hours).padStart(2, '0');
    const formattedMinutes = String(minutes).padStart(2, '0');
    const formattedSeconds = String(remainingSeconds).padStart(2, '0');
    return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
  }


addSeconds(date,seconds) {
  date.setSeconds(date.getSeconds()+seconds);
  return date
}


}
Date.prototype.addDays = function (days) {
  this.setDate(this.getDate() + days);
  return this;
};
Date.prototype.addHours = function (hours) {
  this.setHours(this.getHours() + hours);
  return this;
};
Date.prototype.addSeconds = function (seconds) {
  this.setSeconds(this.getSeconds() + seconds);
  return this;
};
export default new TimeUtils();
