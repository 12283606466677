

class PatientUtils {

    getContactString = (contacts, type) => {
        var contact = contacts?.find(x => x?.relationship?.[0]?.coding?.[0].code.value == type);
        var name = contact?.name?.given?.[0]?.value;
        var phone = contact?.telecom?.[0]?.value?.value;


        if (name && phone) {
            return { "name": name, "phone": phone }
        }
        
        return { "name": "", "phone": "" }
    }

}
export default new PatientUtils();