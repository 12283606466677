import React, { useState, useEffect } from 'react'
import PageContainer from '../../components/PageContainer'
import { Link } from 'react-router-dom'
import { PageHeader } from '../../components/Layout/PageHeader'
import { Box } from '@mui/system'
import { Typography, Button, Stack, FormControl, TextField, InputLabel, Select, MenuItem, FormGroup, FormControlLabel, Switch } from '@mui/material'
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { DataGrid } from '@mui/x-data-grid';
import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from 'react-router-dom'
import PatientLookup from '../../components/Patient/PatientLookup'
import { PatientService } from '../../services/DataService'
import { Translate, Localize, translate } from 'react-i18nify';
import {UpdatesBanner} from "../../components/UpdatesBanner";

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import { RequireRole } from "../../utils/RequireRole"
export default function Patients() {

    const navigate = useNavigate();
    const [patients, setPatients] = useState([]);
    const [searched, setSearched] = useState(false);
    const [loading, setLoading] = useState(true);


    /* useEffect(() => {
        (async () => {
            let apiResult = await PatientService.getAll();
            setPatients(apiResult.data);
            setLoading(false);
        })();
    }, []) */

    useEffect(() => {
        setLoading(false)
    }, [patients])



    const queryPatients = (query) => {
        //setPatients([]);
        setLoading(true);

        var queryString = "";
        var index = 0;

        for (var key in query) {
            if (query.hasOwnProperty(key)) {
                queryString += key + "=" + query[key] + "&"
            }
        }
        queryString = queryString.slice(0, -1); // remove last &

        (async () => {
            await PatientService.getAllByQuery(queryString).then((apiResult) => {
                setPatients(apiResult.data);
                setLoading(false);
            }).catch((error) => {
                setPatients([]);
                setLoading(false);
                console.log(error)
            })
        })();
        setSearched(true)
    }

    const goToPatientDetails = (params, event) => {
        let row = params.row;
        let id = row.patient.id.value;
        let path = "/patient/" + id;
        navigate(path);
    };


    const columns = [

        {
            field: 'o2maticID', headerName: translate('O2maticId'), flex: 0, width: 120, valueGetter: (params) => {
                let row = params.row;
                let id = row.patient.id.value;
                return id;
            }
        },
        {
            field: 'identifierValue', headerName: translate('IdentifierValue'), flex: 1, valueGetter: (params) => {
                let row = params.row;
                if(row.patient.identifier === undefined){
                    return "n/a";
                }
                let identifierValue = row.patient.identifier[0].value.value;
                return identifierValue;
            }
        },
        {
            field: 'firstName', headerName: translate('FirstName'), flex: 1, valueGetter: (params) => {
                let row = params.row;
                if (row?.patient?.name === undefined) {
                    return "n/a";
                }
                let names = row?.patient?.name[0]?.given ? row.patient.name[0].given : "n/a";
                let result = "";
                names.forEach(item => {
                    result += item.value;
                });
                return result;
            }
        },
        {
            field: 'lastName', headerName: translate('LastName'), flex: 1, valueGetter: (params) => {
                let row = params.row;
                if (row?.patient?.name === undefined) {
                    return "n/a";
                }
                let id = row.patient.name[0].family.value;
                return id;
            }
        },
        {
            field: 'birthday', headerName: translate('DateOfBirth'), flex: 1, valueGetter: (params) => {
                let row = params.row;
                if (row?.patient?.birthDate === undefined) {
                    return "n/a";
                }
                if (row?.patient?.birthDate?.value === undefined) {
                    return "n/a";
                }
                let id = row.patient.birthDate.value;
                return id;
            }
        },
        /* {
            field: 'profileName', headerName: translate('Treatment'), flex: 1, valueGetter: (params) => {
                let row = params.row;
                let treatment = row.currentTreatment.name
                return treatment;
            }
        }, */
        {
            field: 'gender', headerName: translate('Gender'), flex: 1, valueGetter: (params) => {
                let row = params.row;
                let id = row.patient.gender.value
                return translate(id);
            }
        }
    ];

    console.log(patients)

    const navigateCreate = () => {
        // 👇︝ navigate to /
        navigate('/patient/create');
    };

    return (
        <PageContainer>
            <PageHeader leftAlign><Translate value="Patients" /></PageHeader>

            <UpdatesBanner update_description="UpdatesDescriptionPatientsPage"/>

            <Box sx={{ mt:2, width: { xs: "100%", sm: "100%", md: "100%", lg: "1000px" }, maxWidth: {md: "1000px"} }}>
                <Box sx={{display:"flex", flexDirection:"row", justifyContent: 'space-between', mb:4 }}>
                    <Box sx={{width:"60%", display:"flex", flexDirection:"column"}}>
                    <Typography variant="h5" sx={{mb:1}}><Translate value="PatientSearch" /></Typography>
                    <Typography variant="h7"><Translate value="PatientLookupExplanation" /></Typography>
                    <Typography variant="h7"><Translate value="PatientLookupExplanation2" /></Typography>
                    </Box>
                
                    <RequireRole authorize={["PatientManager"]}>
                        <Button onClick={navigateCreate} sx={{ mt: 2, minWidth:"200px", alignSelf: "start" }} variant="contained" startIcon={<AddIcon />}><Translate value="AddPatient" /></Button>
                    </RequireRole>
                </Box>
                <PatientLookup handleSearchQuery={queryPatients} />
            </Box>



            {searched?
                <Box sx={{ width: { xs: "100%", sm: "100%", md: "100%", lg: "1000px" }, maxWidth: {md: "1000px"} }}>
                <DataGrid
                    getRowId={(r) => {
                        return r.patient.id.value;
                    }}
                    ColumnWidth="*"
                    sx={{ height: 450, mt: 2 }}
                    columns={columns}
                    rows={patients}
                    pageSize={50}
                    rowsPerPageOptions={[50]}
                    onRowClick={goToPatientDetails}
                    loading={loading}
                    components={{
                        NoRowsOverlay: () => (
                          <Stack height="100%" alignItems="center" justifyContent="center">
                            <Translate value="NoRowsPatients" />
                          </Stack>
                        ),
                        NoResultsOverlay: () => (
                            <Stack height="100%" alignItems="center" justifyContent="center">
                              <Translate value="NoResults" />
                            </Stack>
                        )
                    }}
                />
                </Box>
            : <></>
            }
            
        </PageContainer>
    )
}
