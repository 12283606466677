import React, {useState, useEffect} from "react";
import { useNavigate } from "react-router-dom";
import { PageHeader } from "../../components/Layout/PageHeader";
import PageContainer from "../../components/PageContainer";
import { Translate, Localize, translate } from 'react-i18nify';
import { Button } from '@mui/material'
import AddIcon from '@mui/icons-material/Add';

export const O2maticAdministrator = () => {

    let navigate = useNavigate();
    const routeChange = (params, event) => {
        let path = "/O2maticAdministrator/" + params.row.id;
        navigate(path);
    };

    const navigateOximeter = () => {
        // 👇️ navigate to /
        navigate('/O2maticAdministrator/oximeter');
    };

    const navigateMetadata = () => {
        // 👇️ navigate to /
        navigate('/O2maticAdministrator/metadata');
    };

    const navigateNotification = () => {
        // 👇️ navigate to /
        navigate('/O2maticAdministrator/notification');
    };




    return(
        <PageContainer>
            <PageHeader leftAlign><Translate value="O2maticAdministrator" /></PageHeader>
            <Button onClick={navigateOximeter} sx={{ mt: 2, alignSelf: "start", minWidth: '300px' }} variant="contained" startIcon={<AddIcon />}><Translate value="AddOximeterToWhitelist" /></Button>
            <Button onClick={navigateMetadata} sx={{ mt: 2, alignSelf: "start", minWidth: '300px' }} variant="contained" startIcon={<AddIcon />}><Translate value="AddMetadata" /></Button>
            <Button onClick={navigateNotification} sx={{ mt: 2, alignSelf: "start", minWidth: '300px' }} variant="contained" startIcon={<AddIcon />}><Translate value="AddNotification" /></Button>
        </PageContainer>
    )
}