import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom"
import { Roles } from "./redux/actions/auth";
import { RequireAuth } from "./utils/RequireAuth";
import { Routes, Route } from "react-router-dom";
import { NotificationService, PermissionService } from "./services/DataService";
import { Link } from "react-router-dom";
import { DashboardLayout } from "./components/Layout/dashboard-layout";
import UserGuide from "./pages/UserGuide";
import Login from './pages/Login';
import Logout from './pages/Logout';
import NotFound from "./pages/NotFound";
import UnAuthorized from "./pages/UnAuthorized";
import Home from "./pages/Home";
import Patients from "./pages/Patient/Patients";
import Patient from "./pages/Patient/Patient";
import PatientCreate from "./pages/Patient/PatientCreate";
import Licenses from "./pages/License/LicenseOverview";
import LicenseDetail from "./pages/License/LicenseDetail";
import LicenseCreate from "./pages/License/LicenseCreate";
import { Locations } from "./pages/Location/Locations";
import LocationCreate from "./pages/Location/LocationCreate";
import Location from "./pages/Location/Location";
import LocationEdit from "./pages/Location/LocationEdit";
import Logs from "./pages/Log/Logs";

import { Device } from "./pages/Device/Device";

import { QRscanner } from "./pages/QRscanner/QRscanner";

import { ProDevices } from "./pages/ProDevices/ProDevices";
import { ProDevice } from "./pages/ProDevices/ProDevice";
import { ProDeviceEdit } from "./pages/ProDevices/ProDeviceEdit";

import { HotDevices } from "./pages/HotDevices/HotDevices";
import { HotDevice } from "./pages/HotDevices/HotDevice";
import { HotDeviceEdit } from "./pages/HotDevices/HotDeviceEdit";
import { Treatments } from "./pages/Treatment/Treatments";
import TreatmentCreate from "./pages/Treatment/TreatmentCreate";
import Treatment from "./pages/Treatment/Treatment";

import { O2maticAdministrator } from "./pages/O2maticAdministrator/O2maticAdministrator";
import Notification from "./pages/O2maticAdministrator/Notification";
import { CreateMetadataValue } from "./pages/Metadata/CreateMetadataValue";

import { Information } from "./pages/Information/Information";

import UserOverview from "./pages/User/UserOverview"
import UserDetail from "./pages/User/UserDetail"
import UserCreate from "./pages/User/UserCreate"
import { Outlet } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import { EventType } from "@azure/msal-browser"
import { useIsAuthenticated } from "@azure/msal-react";
import { useDispatch } from "react-redux";
import {
	LOGIN_SUCCESS,
	LOGIN_EXPIRED,
	LOGIN_FAIL,
	LOGOUT,
	ADD_ROLES,
} from "./redux/actions/types";
import PatientEdit from "./pages/Patient/PatientEdit";

import TranslationConfig from "./configuration/Translation/translation-config";
import { BrowserRouter as Router } from "react-router-dom";
import {
	createBrowserRouter,
	RouterProvider,
	createRoutesFromElements
} from "react-router-dom";
import HotDeviceCreate from "./pages/HotDevices/HotDeviceCreate";
import ProDeviceCreate from "./pages/ProDevices/ProDeviceCreate";
import { Production } from "./pages/Production/Production";
import { DeviceTest } from "./pages/Production/Steps/Test/DeviceTest";
import { TestOverview } from "./pages/Production/Steps/Test/TestOverview";
import { O2maticTest } from "./pages/Production/Steps/O2maticTest/O2maticTest";
import { O2maticTestOverview } from "./pages/Production/Steps/O2maticTest/O2maticTestOverview";
import { Qa } from "./pages/Production/Steps/Qa";
import { O2maticQa } from "./pages/Production/Steps/O2maticQa";
import { Ship } from "./pages/Production/Steps/Ship";
import { PackageOverview } from "./pages/Production/Steps/Package/PackageOverview";
import { Package } from "./pages/Production/Steps/Package/Package";
import { RepairOverview } from "./pages/Production/Steps/Repair/RapairOverview";
import { Repair } from "./pages/Production/Steps/Repair/Repair";
import { UnderInvestigationOverview } from "./pages/Production/Steps/UnderInvestigation/UnterIntevstigationOverview";
import { UnderInvestigation } from "./pages/Production/Steps/UnderInvestigation/UnderInvestigation";
import { Oximeter } from "./pages/Oximeter/Oximeter";
import { ProReport } from "./pages/ProReport/ProReport";
import { TranslationEditor } from "./configuration/Translation/TranslationEditor";


const App = (props) => {

	const [notification, setNotification] = useState({ "isActive": false, "message": "" });

	useEffect(() => {
		(async () => {
			try {
				let apiResult = await NotificationService.getNotification();
				setNotification(apiResult.data);
			} catch (error) {

			}
		})();
	}, [])


	const { user } = useSelector((state) => state.auth);
	const { isLoggedIn } = useSelector(state => state.auth);
	const [isSidebarOpen, setSidebarOpen] = useState(false);
	const { instance } = useMsal();
	const dispatch = useDispatch();
	const router = createBrowserRouter(
		createRoutesFromElements(
			<>
				<Route path="/" element={
					<DashboardLayout
						notification={notification}
						onSidebarOpen={() => setSidebarOpen(true)}
						onClose={() => setSidebarOpen(false)}
						open={isLoggedIn && isSidebarOpen}
						navigationVisibility={isLoggedIn}>

						<Outlet />
					</DashboardLayout>
				} >
					<Route index element={
						<RequireAuth authorize={[]} redirectTo="/unauthorized">
							<Home />
						</RequireAuth>} />

					<Route 
						handle={{ crumb: (data) => data }}
						path="/information" element={
						<RequireAuth authorize={[]} redirectTo="/unauthorized">
							<Information />
						</RequireAuth>} />

					<Route
						handle={{ crumb: (data) => data }}
						path="/userguide"
						element={
							<RequireAuth authorize={[]} redirectTo="/unauthorized">
								<UserGuide />
							</RequireAuth>
						}
					/>
					<Route
						handle={{ crumb: (data) => data }}
						path="/login" element={<Login />} />


					<Route
						handle={{ crumb: (data) => data }}
						path="/logout" element={<Logout />} />

					<Route
						handle={{ crumb: (data) => data }}
						path="/O2maticAdministrator" element={
							<RequireAuth authorize={["O2maticAdministrator"]} redirectTo="/unauthorized">
								<O2maticAdministrator />
							</RequireAuth>} />
					<Route
						handle={{ crumb: (data) => data }}
						path="/O2maticAdministrator/notification" element={
							<RequireAuth authorize={["O2maticAdministrator"]} redirectTo="/unauthorized">
								<Notification />
							</RequireAuth>} />
					<Route
						handle={{ crumb: (data) => data }}
						path="/O2maticAdministrator/oximeter" element={
							<RequireAuth authorize={["O2maticAdministrator"]} redirectTo="/unauthorized">
								<Oximeter />
							</RequireAuth>} />
					<Route
						handle={{ crumb: (data) => data }}
						path="/O2maticAdministrator/metadata" element={
							<RequireAuth authorize={["O2maticAdministrator"]} redirectTo="/unauthorized">
								<CreateMetadataValue />
							</RequireAuth>} />
					<Route
						handle={{ crumb: (data) => data }}
						path="/patient" element={
							<RequireAuth authorize={["PatientManager", "PatientMonitor"]} redirectTo="/unauthorized">
								<Patients />
							</RequireAuth>} />
					<Route
						handle={{ crumb: (data) => data }}
						path="/patient/create" element={
							<RequireAuth authorize={["PatientManager"]} redirectTo="/unauthorized">
								<PatientCreate />
							</RequireAuth>
						} />
					<Route
						handle={{ crumb: (data) => data }}
						path="patient/:id" element={
							<RequireAuth authorize={["PatientManager", "PatientMonitor"]} redirectTo="/unauthorized">
								<Patient />
							</RequireAuth>
						} />
					<Route
						handle={{ crumb: (data) => data }}
						path="/patient/:id/edit" element={
							<RequireAuth authorize={["PatientManager"]} redirectTo="/unauthorized">
								<PatientEdit />
							</RequireAuth>
						}
					/>
					<Route
						handle={{ crumb: (data) => data }}
						path="/license" element={
							<RequireAuth authorize={["Administrator","DeviceManager"]} redirectTo="/unauthorized">
								<Licenses />
							</RequireAuth>
						} />
					<Route
						handle={{ crumb: (data) => data }}
						path="/license/create" element={
							<RequireAuth authorize={["Administrator","DeviceManager"]} redirectTo="/unauthorized">
								<LicenseCreate />
							</RequireAuth>
						} />
					<Route
						handle={{ crumb: (data) => data }}
						path="/license/:id" element={
							<RequireAuth authorize={["Administrator","DeviceManager"]} redirectTo="/unauthorized">
								<LicenseDetail />
							</RequireAuth>
						} />
					<Route
						handle={{ crumb: (data) => data }}
						path="/treatment" element={
							<RequireAuth authorize={["TreatmentTemplateManager"]} redirectTo="/unauthorized">
								<Treatments />
							</RequireAuth>
						} />
					<Route
						handle={{ crumb: (data) => data }}
						path="/treatment/create" element={
							<RequireAuth authorize={["TreatmentTemplateManager"]} redirectTo="/unauthorized">
								<TreatmentCreate />
							</RequireAuth>
						} />
					<Route
						handle={{ crumb: (data) => data }}
						path="/treatment/:id" element={
							<RequireAuth authorize={["TreatmentTemplateManager"]} redirectTo="/unauthorized">
								<Treatment />
							</RequireAuth>
						} />
					<Route
						handle={{ crumb: (data) => data }}
						path="/location" element={
							<RequireAuth authorize={["Administrator"]} redirectTo="/unauthorized">
								<Locations />
							</RequireAuth>
						} />
					<Route
						handle={{ crumb: (data) => data }}
						path="/location/create" element={
							<RequireAuth authorize={["Administrator"]} redirectTo="/unauthorized">
								<LocationCreate />
							</RequireAuth>
						} />
					<Route
						handle={{ crumb: (data) => data }}
						path="/location/:id" element={
							<RequireAuth authorize={["Administrator"]} redirectTo="/unauthorized">
								<Location />
							</RequireAuth>
						} />
					<Route
						handle={{ crumb: (data) => data }}
						path="/location/:id/edit" element={
							<RequireAuth authorize={["Administrator"]} redirectTo="/unauthorized">
								<LocationEdit />
							</RequireAuth>
						}
					/>
					<Route
						handle={{ crumb: (data) => data }}
						path="/device/" element={
							<RequireAuth authorize={["DeviceManager"]} redirectTo="/unauthorized">
								<Device />
							</RequireAuth>
						}
					/>
					<Route
						handle={{ crumb: (data) => data }}
						path="/device/QRscanner" element={
							<RequireAuth authorize={["DeviceManager"]} redirectTo="/unauthorized">
								<QRscanner />
							</RequireAuth>
						}
					/>
					<Route
						handle={{ crumb: (data) => data }}
						path="/device/HOT" element={
							<RequireAuth authorize={["DeviceManager"]} redirectTo="/unauthorized">
								<HotDevices />
							</RequireAuth>
						}
					/>
					<Route
						handle={{ crumb: (data) => data }}
						path="/device/HOT/create" element={
							<RequireAuth authorize={["DeviceManager"]} redirectTo="/unauthorized">
								<HotDeviceCreate />
							</RequireAuth>
						}
					/>
					<Route
						handle={{ crumb: (data) => data }}
						path="/device/HOT/:udsn/edit" element={
							<RequireAuth authorize={["DeviceManager"]} redirectTo="/unauthorized">
								<HotDeviceEdit />
							</RequireAuth>
						}
					/>
					<Route
						handle={{ crumb: (data) => data }}
						path="/device/HOT/:udsn" element={
							<RequireAuth authorize={["DeviceManager"]} redirectTo="/unauthorized">
								<HotDevice />
							</RequireAuth>
						}
					/>

					<Route
						handle={{ crumb: (data) => data }}
						path="/device/PRO" element={
							<RequireAuth authorize={["DeviceManager"]} redirectTo="/unauthorized">
								<ProDevices />
							</RequireAuth>
						}
					/>
					<Route
						handle={{ crumb: (data) => data }}
						path="/device/PRO/create" element={
							<RequireAuth authorize={["DeviceManager"]} redirectTo="/unauthorized">
								<ProDeviceCreate />
							</RequireAuth>
						}
					/>
					<Route
						handle={{ crumb: (data) => data }}
						path="/device/PRO/:udsn/edit/" element={
							<RequireAuth authorize={["DeviceManager"]} redirectTo="/unauthorized">
								<ProDeviceEdit />
							</RequireAuth>
						}
					/>
					<Route
						handle={{ crumb: (data) => data }}
						path="/device/PRO/:udsn" element={
							<RequireAuth authorize={["DeviceManager"]} redirectTo="/unauthorized">
								<ProDevice />
							</RequireAuth>
						}
					/>

					<Route
						handle={{ crumb: (data) => data }}
						path="/production/:type?" element={
							<RequireAuth authorize={[Roles.Assembler, Roles.Tester, Roles.Packager, Roles.ProductionQa, Roles.O2maticQa, Roles.Shipper, Roles.Repairer, Roles.O2maticTester]} redirectTo="/unauthorized">
								<Production />
							</RequireAuth>
						}
					/>
					<Route
						handle={{ crumb: (data) => data }}
						path="/production/test/:type" element={
							<RequireAuth authorize={[Roles.Tester]} redirectTo="/unauthorized">
								<TestOverview />
							</RequireAuth>
						}
					/>
					<Route
						handle={{ crumb: (data) => data }}
						path="/production/test/:type/:udsn" element={
							<RequireAuth authorize={[Roles.Tester]} redirectTo="/unauthorized">
								<DeviceTest />
							</RequireAuth>
						}
					/>

					<Route
						handle={{ crumb: (data) => data }}
						path="/production/o2maticTest/:type" element={
							<RequireAuth authorize={[Roles.O2maticTester]} redirectTo="/unauthorized"> 
								<O2maticTestOverview />
							</RequireAuth>
						}
					/>

					<Route
						handle={{ crumb: (data) => data }}
						path="/production/o2maticTest/:type/:udsn" element={
							<RequireAuth authorize={[Roles.O2maticTester]} redirectTo="/unauthorized">
								<O2maticTest />
							</RequireAuth>
						}
					/>
					<Route
						handle={{ crumb: (data) => data }}
						path="/production/package/:type" element={
							<RequireAuth authorize={[Roles.Packager]} redirectTo="/unauthorized">
								<PackageOverview />
							</RequireAuth>
						}
					/>
					<Route
						handle={{ crumb: (data) => data }}
						path="/production/package/:type/:udsn" element={
							<RequireAuth authorize={[Roles.Packager]} redirectTo="/unauthorized">
								<Package />
							</RequireAuth>
						}
					/>
					<Route
						handle={{ crumb: (data) => data }}
						path="/production/qa/:type" element={
							<RequireAuth authorize={[Roles.ProductionQa]} redirectTo="/unauthorized">
								<Qa />
							</RequireAuth>
						}
					/>
					<Route
						handle={{ crumb: (data) => data }}
						path="/production/O2maticQa/:type" element={
							<RequireAuth authorize={[Roles.O2maticQa]} redirectTo="/unauthorized">
								<O2maticQa />
							</RequireAuth>
						}
					/>
					<Route
						handle={{ crumb: (data) => data }}
						path="/production/ship/:type" element={
							<RequireAuth authorize={[Roles.Shipper]} redirectTo="/unauthorized">
								<Ship />
							</RequireAuth>
						}
					/>

					<Route
						handle={{ crumb: (data) => data }}
						path="/production/repair/:type" element={
							<RequireAuth authorize={[Roles.Repairer]} redirectTo="/unauthorized">
								<RepairOverview />
							</RequireAuth>
						}
					/>


					<Route
						handle={{ crumb: (data) => data }}
						path="/production/repair/:type/:udsn" element={
							<RequireAuth authorize={[Roles.Repairer]} redirectTo="/unauthorized">
								<Repair />
							</RequireAuth>
						}
					/>


					<Route
						handle={{ crumb: (data) => data }}
						path="/production/underInvestigation/:type" element={
							<RequireAuth authorize={[Roles.Repairer]} redirectTo="/unauthorized">
								<UnderInvestigationOverview />
							</RequireAuth>
						}
					/>

			
					<Route
						handle={{ crumb: (data) => data }}
						path="/production/underInvestigation/:type/:udsn" element={
							<RequireAuth authorize={[Roles.Repairer]} redirectTo="/unauthorized">
								<UnderInvestigation />
							</RequireAuth>
						}
					/>
					<Route
						handle={{ crumb: (data) => data }}
						path="/log" element={
							<RequireAuth authorize={["Auditor"]} redirectTo="/unauthorized">
								<Logs />
							</RequireAuth>
						}
					/>
					<Route
						handle={{ crumb: (data) => data }}
						path="/user" element={
							<RequireAuth authorize={["Administrator"]} redirectTo="/unauthorized">
								<UserOverview />
							</RequireAuth>
						} />
					<Route
						handle={{ crumb: (data) => data }}
						path="/user/:id" element={
							<RequireAuth authorize={["Administrator"]} redirectTo="/unauthorized">
								<UserDetail />
							</RequireAuth>
						} />
					<Route
						handle={{ crumb: (data) => data }}
						path="/user/create" element={
							<RequireAuth authorize={["Administrator"]} redirectTo="/unauthorized">
								<UserCreate />
							</RequireAuth>
						} />

					<Route
						handle={{ crumb: (data) => data }}
						path="/unauthorized" element={
							<UnAuthorized />}
					/>
					<Route
						handle={{ crumb: (data) => data }}
						path="*" element={
							<RequireAuth authorize={[]} redirectTo="/login">
								<NotFound />
							</RequireAuth>}

					/> </Route>

				 <Route
					handle={{ crumb: (data) => data }}
					path="/proReport" element={
						<ProReport />
					}
				/>

				{/* Outcomment the code below and go to /editor in the browser to see overview of all translations */}
				{/* <Route
					handle={{ crumb: (data) => data }}
					path="/editor" element={
						<TranslationEditor />
					}
				/> */}
			</>
		)
	);

	TranslationConfig();
	instance.addEventCallback(message => {
		if (message.eventType == EventType.LOGIN_SUCCESS || message.eventType === EventType.ACQUIRE_TOKEN_SUCCESS) {

			if (user?.roles == null) {
				PermissionService.get().then((roles) => {
					dispatch({

						type: ADD_ROLES,
						payload: { roles: roles },
					});

				})
			}

			dispatch({

				type: LOGIN_SUCCESS,
				payload: { user: message },
			});

		}
	});
	return (
		<>
			<RouterProvider
				router={router}
			>

				{/* <DashboardLayout
					test={"test"}
					onSidebarOpen={() => setSidebarOpen(true)}
					onClose={() => setSidebarOpen(false)}
					open={isLoggedIn && isSidebarOpen}
					navigationVisibility={isLoggedIn}>

					<Outlet />
				</DashboardLayout> */}

			</RouterProvider>

		</>
	);
};
export default App;
